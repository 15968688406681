"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.array.find");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _FollowDialog = _interopRequireDefault(require("./components/FollowDialog"));

var _initData = require("../initData");

var _TypeDialog = _interopRequireDefault(require("./components/TypeDialog"));

var _cache = require("@/utils/cache");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Chance',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default,
    FollowDialog: _FollowDialog.default,
    TypeDialog: _TypeDialog.default
  },
  data: function data() {
    return {
      activeName: '2',
      courseDrawTime: '',
      searchParam: {},
      tableData: [],
      listLoading: false,
      total: 0,
      initSaleData: _initData.initData,
      typeArr: [{
        value: '',
        label: '全部'
      }].concat(_initData.typeList),
      serviceLineArr: [{
        value: '',
        label: '全部'
      }].concat(_initData.serviceLine),
      clientTypeArr: [{
        value: '',
        label: '全部'
      }].concat(_initData.clientTypeList)
    };
  },
  created: function created() {
    var fieldJson = (0, _cache.find)('fieldJson');

    if (fieldJson && fieldJson[this.$route.name]) {
      _initData.initData.chance.columns = fieldJson[this.$route.name];
    }

    this.initSaleData = _initData.initData; // this.uid = JSON.parse(localStorage.getItem('userInfo')).data.backUserInfo.uid;

    this.reset();
  },
  methods: {
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.searchParam.course_time_start = new Date(val[0]).getTime() / 1000;
        this.searchParam.course_time_end = new Date(val[1]).getTime() / 1000;
      } else {
        this.searchParam.course_time_start = '';
        this.searchParam.course_time_end = '';
      }
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getChanceList(this.searchParam).then(function (response) {
        _this.tableData = response.data.infos.map(function (item) {
          return _bean.default.changeBean(item);
        });
        _this.total = response.data.totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    handleFieldChange: function handleFieldChange(val) {
      this.initSaleData.chance.columns = val;
    },
    reset: function reset() {
      this.searchParam = {
        uid: '',
        cate_id: '',
        uname: '',
        course: '',
        course_time_start: '',
        course_time_end: '',
        homework_num: '',
        phone: '',
        channel: '',
        nocallback: '',
        status_chance: '',
        client_type: '',
        page: 1,
        page_size: 20
      };
      this.courseDrawTime = '';
      this.searchParam.nocallback = Number(this.activeName) === 2 ? 1 : '';
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    handleClick: function handleClick(e) {
      this.searchParam.nocallback = Number(this.activeName) === 2 ? 1 : '';
      this.searchParam.page = 1;
      this.fetchData();
    },
    showDialog: function showDialog(item) {
      this.$refs.followDialog.showDialog(item);
    },
    // 类型操作
    doType: function doType(item) {
      this.$refs.typeDialog.showDialog(item, "\u3010".concat(item.uid, "\u3011").concat(item.userName), 'Chance');
    }
  }
};
exports.default = _default;