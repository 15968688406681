"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _initData = require("../initData");

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CourseList',
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      activeName: '1',
      putAwayTime: '',
      soldOutTime: '',
      searchParam: {},
      tableData: null,
      listLoading: true,
      total: 0,
      initCourseData: _initData.initData,
      queryShowMore: false
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.searchParam.start_time = new Date(val[0]).getTime() / 1000;
        this.searchParam.end_time = new Date(val[1]).getTime() / 1000;
      } else {
        this.searchParam.start_time = '';
        this.searchParam.end_time = '';
      }
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getCourseList(this.searchParam).then(function (response) {
        _this.tableData = response.data.data.map(function (item) {
          return _bean.default.courseBean(item);
        });
        _this.total = response.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.searchParam = {
        id: '',
        video_title: '',
        page: 1,
        per_page: 20
      };
      this.putAwayTime = '';
      this.soldOutTime = '';
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    jumpPage: function jumpPage(item, path) {
      this.$router.push({
        name: path,
        params: {
          id: item.id
        }
      });
    },
    handleCourse: function handleCourse(type, item) {
      this.$message({
        message: '该功能暂未开放~',
        type: 'warning'
      }); // const id = type === 'add' ? '-1' : item.id;
      // this.$router.push({ name: 'HandleCourse', params: { id: id } });
    },
    // todo list tap
    handleClick: function handleClick() {}
  }
};
exports.default = _default;