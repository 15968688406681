"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _FollowDialog = _interopRequireDefault(require("./components/FollowDialog"));

var _CourseDialog = _interopRequireDefault(require("./components/CourseDialog"));

var _initData = require("../initData");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'OrderClient',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default,
    FollowDialog: _FollowDialog.default,
    CourseDialog: _CourseDialog.default
  },
  data: function data() {
    return {
      searchParam: {},
      tableData: [],
      listLoading: false,
      total: 0,
      initSaleData: _initData.initData
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getBuyersList(this.searchParam).then(function (response) {
        _this.tableData = response.data.infos.map(function (item) {
          return _bean.default.buyerBean(item);
        });
        _this.total = response.data.totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.searchParam = {
        uid: '',
        uname: '',
        phone: '',
        page: 1,
        page_size: 20
      };
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    showDialog: function showDialog(ref, item) {
      this.$refs[ref].showDialog(item);
    }
  }
};
exports.default = _default;