"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var BASE_URL = process.env.VUE_APP_BASE_API;
var PROXY_ID = ''; // const BASE_URL = '';
// const PROXY_ID = '/api';

var _default = {
  /**
   * 我的线索列表数据
   * @param params
   */
  getClueList: function getClueList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/saleman-customers"),
      method: 'get',
      params: params
    });
  },

  /**
   * 开通体验课
   * @param data
   */
  grantCourse: function grantCourse(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-customer-trycourse"),
      method: 'post',
      data: data
    });
  },

  /**
   * 将线索数据设置为机会数据
   * @param data
   */
  setChance: function setChance(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-customer-chance"),
      method: 'post',
      data: data
    });
  },

  /**
   * 我的机会列表数据
   * @param params
   */
  getChanceList: function getChanceList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/saleman-clists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 我的成单客户列表数据
   * @param params
   */
  getBuyersList: function getBuyersList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/saleman-buyers"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取销售关注的客户课程列表数据
   * @param params
   */
  getOrderCourseList: function getOrderCourseList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/order-courses"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取跟进记录列表数据
   * @param params
   */
  getFollowRecordList: function getFollowRecordList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/saleman-callbacks"),
      method: 'get',
      params: params
    });
  },

  /**
   * 添加跟进记录
   * @param data
   */
  addFollowRecord: function addFollowRecord(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/saleman-callbacks/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 机会数据作品列表数据
   * @param params
   */
  getWorksList: function getWorksList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/homework-callbacks"),
      method: 'get',
      params: params
    });
  },

  /**
   * 我的销售订单列表数据
   * @param params
   */
  getSaleOrderList: function getSaleOrderList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-orders"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取销售榜单数据
   * @param params
   */
  getSaleRankList: function getSaleRankList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-ranks"),
      method: 'get',
      params: params
    });
  },

  /**
   * 销售人员列表数据
   * @param params
   */
  getSaleStaffList: function getSaleStaffList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-peoples"),
      method: 'get',
      params: params
    });
  },

  /**
   * 二维码列表数据
   * @param params
   */
  getSaleQrCodeList: function getSaleQrCodeList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-manyservices"),
      method: 'get',
      params: params
    });
  },

  /**
   * 添加销售二维码
   * @param data
   */
  addSaleQrCode: function addSaleQrCode(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-manyservices/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑销售二维码
   * @param data
   */
  editSaleQrCode: function editSaleQrCode(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-manyservices/update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 客户最新动态
   * @param params
   */
  saleNotify: function saleNotify(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-notice"),
      method: 'get',
      params: params
    }, true);
  },

  /**
   * 记录用户点击动态的行为
   * @param params
   */
  reportSaleNotify: function reportSaleNotify(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-rnotice"),
      method: 'get',
      params: params
    }, true);
  },

  /**
   * 课程包列表数据
   * @param params
   */
  saleCourses: function saleCourses(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-courses"),
      method: 'get',
      params: params
    }, true);
  },

  /**
   * 设置计划、线索数据类型
   * @param data
   */
  setCustomerType: function setCustomerType(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-customer-update"),
      method: 'post',
      data: data
    }, false);
  },

  /**
   * 客服组列表数据
   * @param params
   */
  getServiceGroupList: function getServiceGroupList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-serviceteam/index"),
      method: 'get',
      params: params
    }, true);
  },

  /**
   * 客服组二维码列表数据
   * @param params
   */
  getServiceCodeList: function getServiceCodeList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-manyservice-tlists"),
      method: 'get',
      params: params
    }, true);
  },

  /**
   * 删除指定客服组二维码列表的单个item数据
   * @param data
   */
  deleteServiceCode: function deleteServiceCode(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-manyservices/sdelete"),
      method: 'post',
      data: data
    }, false);
  },

  /**
   * 添加指定二维码到指定客服组
   * @param data
   */
  addSaleQrCodeToService: function addSaleQrCodeToService(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-manyservices/copy"),
      method: 'post',
      data: data
    }, false);
  },

  /**
   * 每日微信统计列表数据
   * @param params
   */
  getWxStatisticsList: function getWxStatisticsList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-manyservice-wechats"),
      method: 'get',
      params: params
    }, false);
  },

  /**
   * 记录指定日期添加的二维码数量
   * @param data
   */
  addWxStatisticsRecord: function addWxStatisticsRecord(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/saleman-wechat/record"),
      method: 'post',
      data: data
    }, false);
  },

  /**
   * 获取销售组数据
   * @param params
   */
  getSaleGroupList: function getSaleGroupList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-groups"),
      method: 'get',
      params: params
    }, true);
  },

  /**
   * 导出每日微信统计数据
   * @param params
   */
  exportWxStatisticsList: function exportWxStatisticsList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/sale-manyservice-wdownload"),
      method: 'get',
      params: params
    }, true);
  },

  /**
  * 销售发货列表
  * @param params
  */
  getShipmentReviewList: function getShipmentReviewList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/shoporder-reviewlist"),
      // url: `/api/sale/childa/shoporder-reviewlist`,
      method: 'get',
      params: params
    });
  },

  /**
  * 建单弹窗判断接口
  * @param params
  */
  buildPopSales: function buildPopSales(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/shoporder-preOrderMsg"),
      // url: `/api/sale/childa/shoporder-preOrderMsg`,
      method: 'get',
      params: params
    });
  },

  /**
  * 申请修改商品接口
  * @param data
  */
  changeGoods: function changeGoods(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/shoporder-createRemark"),
      // url: `/api/sale/childa/shoporder-createRemark`,
      method: 'post',
      data: data
    }, false);
  },

  /**
  * 展示课程包接口
  * @param data
  */
  classBaoList: function classBaoList(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/bao-baodata?business_id=") + data,
      // url: `/api/sale/childa/bao-baodata?business_id=`+data,
      method: 'get',
      data: data
    }, false);
  }
};
exports.default = _default;