"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var BASE_URL = "".concat(process.env.VUE_APP_BASE_API);
var PROXY_USER_ID = '/userCenter/api/v1';
var PROXY_COMMENT_ID = '/commentMiddle/api/v1';
var _default = {
  /**
   * 点评中台：获取用户列表
   * @param data
   */
  getUserToCommentMiddle: function getUserToCommentMiddle(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_COMMENT_ID, "/class/selectClassTeacher"),
      method: 'post',
      data: data
    });
  },

  /**
   * 点评中台：添加用户
   * @param data
   */
  insertUserToCommentMiddle: function insertUserToCommentMiddle(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_USER_ID, "/userBack/addUser"),
      method: 'post',
      data: data
    });
  },

  /**
   * 点评中台：编辑用户
   * @param data
   */
  updateUserToCommentMiddle: function updateUserToCommentMiddle(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_USER_ID, "/userBack/updateUser"),
      method: 'post',
      data: data
    });
  },

  /**
   * 点评中台：删除用户
   * @param data
   */
  deleteUserToCommentMiddle: function deleteUserToCommentMiddle(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_USER_ID, "/class/deleteClassTeacher"),
      method: 'post',
      data: data
    });
  },

  /**
   * 点评中台：添加班级
   * @param data
   */
  insertClassToCommentMiddle: function insertClassToCommentMiddle(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_COMMENT_ID, "/class/addClass"),
      method: 'post',
      data: data
    });
  }
};
exports.default = _default;