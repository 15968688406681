"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _utils = require("@/utils");

var _initData = require("../initData");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'WxStatistics',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default
  },
  data: function data() {
    return {
      month: new Date(),
      searchParam: {},
      tableData: [],
      listLoading: false,
      total: 0,
      columns: [],
      selectMonth: '',
      saleGroup: [],
      saleGroupArr: [],
      serviceLineArr: [{
        value: '',
        label: '全部'
      }].concat(_initData.serviceLine)
    };
  },
  created: function created() {
    this.reset();
    this.initView();
    this.initGroup();
  },
  methods: {
    setDisabled: function setDisabled(val) {
      var selectMonth = this.selectMonth;
      var cDay = parseInt(val, 10);
      var nowDate = new Date();
      var nowDay = new Date().getDate();
      var nowMonth = new Date().getMonth() + 1;
      var INTERVAL = 7;
      var diff = nowDay - INTERVAL;
      var condition = nowMonth === selectMonth && cDay <= nowDay && cDay > diff;

      if (diff > 0) {
        return condition;
      } else {
        var days = new Date(nowDate.getFullYear(), nowMonth - 1, 0).getDate();
        return condition || nowMonth - 1 === selectMonth && cDay > days + diff;
      }
    },
    initView: function initView() {
      var defaultColumns = [{
        label: 'ID',
        width: '60',
        prop: 'id',
        fix: 'left'
      }, {
        label: '二维码',
        width: '100',
        prop: 'qrCodeUrl',
        fix: 'left'
      }, {
        label: '分类',
        width: '80',
        prop: 'lineType',
        fix: 'left'
      }, {
        label: '微信昵称',
        width: '80',
        prop: 'weChatName',
        fix: 'left'
      }, {
        label: '销售人',
        width: '90',
        prop: 'saleManName',
        fix: 'left'
      }, {
        label: '所属组',
        width: '90',
        prop: 'saleManGroup',
        fix: 'left'
      }];
      var cDate = new Date(this.month);
      var days = new Date(cDate.getFullYear(), cDate.getMonth() + 1, 0).getDate();
      var monthColumns = [];

      for (var i = 1; i <= days; i++) {
        monthColumns.push({
          label: i.toString(),
          width: '85',
          prop: "".concat(i, "month")
        });
      }

      this.columns = defaultColumns.concat(monthColumns);
    },
    initGroup: function initGroup() {
      var _this = this;

      _api.default.getSaleGroupList().then(function (res) {
        _this.saleGroupArr = (res.data || []).map(function (item) {
          item.subInfos = [{
            sale_group_id: '',
            title: '所有组'
          }].concat(item.subInfos || []);
          return item;
        });
      });
    },
    handleChange: function handleChange(val) {
      this.searchParam.saleman_team_id = val[0] || '';
      this.searchParam.group_id = val[1] || '';
    },
    dateChange: function dateChange(val) {
      this.searchParam.month = val ? (0, _utils.parseTime)(val, '{y}/{m}') : '';
    },
    reset: function reset() {
      var nowDate = new Date();
      this.searchParam = {
        saleman_name: '',
        month: (0, _utils.parseTime)(nowDate, '{y}/{m}'),
        saleman_team_id: '',
        group_id: '',
        cid: '',
        page: 1,
        per_page: 20
      };
      this.saleGroup = [];
      this.month = nowDate;
      this.selectMonth = nowDate.getMonth() + 1;
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this2 = this;

      this.listLoading = true;

      _api.default.getWxStatisticsList(this.searchParam).then(function (response) {
        _this2.tableData = response.data.infos.map(function (item) {
          return _bean.default.wxStatisticsBean(item);
        });
        _this2.total = response.data.totalNum;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.tableData = null;
        _this2.listLoading = false;
      });
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.selectMonth = new Date(this.searchParam.month).getMonth() + 1;
      this.initView();
      this.fetchData();
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    onInputBlur: function onInputBlur(row, column) {
      var _this3 = this;

      var num = row[column.property];

      if (num < 0) {
        this.$message({
          type: 'warning',
          message: '输入的值有误~'
        });
        return;
      }

      var postData = {
        manyservice_id: row.id,
        date: "".concat(this.searchParam.month, "/").concat(column.label),
        num: num
      };

      _api.default.addWxStatisticsRecord(postData).then(function () {
        _this3.$message({
          type: 'success',
          message: '操作成功~'
        });

        _this3.fetchData();
      });
    },
    exportData: function exportData() {
      _api.default.exportWxStatisticsList(this.searchParam).then(function (response) {
        window.location.href = response.data;
      });
    }
  }
};
exports.default = _default;