"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var BASE_URL = process.env.VUE_APP_BASE_API;
var PROXY_ID = '';
var _default = {
  /**
   * 直播课列表
   * @param params
   */
  getLiveList: function getLiveList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/api/adult/live_course"),
      method: 'get',
      params: params
    });
  },

  /**
   * 某直播课用户列表
   * @param params
   */
  getLiveUserList: function getLiveUserList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/api/adult/order_user/").concat(params.id),
      method: 'get',
      params: params
    });
  },

  /**
   * 讲师列表
   * @param params
   */
  getAdultTeacherList: function getAdultTeacherList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/api/adult/teacher"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取直播的课程列表
   * @param params
   */
  getLiveCourseList: function getLiveCourseList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/api/adult/video"),
      method: 'get',
      params: params
    });
  },

  /**
   * 直播课添加编辑
   * @param data
   */
  submitLiveInfo: function submitLiveInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/api/adult/add_live"),
      method: 'post',
      data: data
    });
  },

  /**
   * 用户作品添加编辑
   * @param data
   */
  submitUserOpus: function submitUserOpus(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/api/adult/add_work"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取直播课详情信息
   * @param params
   */
  getAdultLiveDetail: function getAdultLiveDetail(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/api/adult/live_detail/").concat(params.id),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取用户作品列表
   * @param params
   */
  getAdultUserOpusList: function getAdultUserOpusList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/api/adult/student_work"),
      method: 'get',
      params: params
    });
  },

  /**
   * 删除用户作品
   * @param data
   */
  deleteAdultUserOpus: function deleteAdultUserOpus(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/api/adult/delete_work"),
      method: 'post',
      data: data
    });
  },

  /**
  * 成人发货审核列表
  * @param params
  */
  getShipmentReviewListAdult: function getShipmentReviewListAdult(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/shoporder-reviewlist"),
      // url: `/api/sale/childa/shoporder-reviewlist`,
      method: 'get',
      params: params
    });
  },

  /**
  * 旺店通确定建单接口
  * @param data
  */
  buildOrderAdult: function buildOrderAdult(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/shoporder-createOrder2center"),
      // url: `/api/sale/childa/shoporder-createOrder2center`,
      method: 'post',
      data: data
    });
  },

  /**
  * 修改商品接口
  * @param params
  */
  changeGoodsAdult: function changeGoodsAdult(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/shoporder-orderinfo"),
      // url: `/api/sale/childa/shoporder-orderinfo`,
      method: 'get',
      params: params
    });
  },

  /**
  * 保存商品接口
  * @param data
  */
  saveGoodsAdult: function saveGoodsAdult(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/shoporder-editPreOrder"),
      // url: `/api/sale/childa/shoporder-editPreOrder`,
      method: 'post',
      data: data
    });
  },

  /**
  * 建单弹窗判断接口
  * @param params
  */
  buildPopAdult: function buildPopAdult(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/shoporder-preOrderMsg"),
      // url: `/api/sale/childa/shoporder-preOrderMsg`,
      method: 'get',
      params: params
    });
  },

  /**
  * 友情链接
  * @param data
  */
  friendsUrl: function friendsUrl(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/bao-listlink"),
      // url: `/api/sale/childa/bao-listlink`,
      method: 'get',
      data: data
    }, false);
  },

  /**
  * 友情链接添加
  * @param data
  */
  addLink: function addLink(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/bao-addlink"),
      // url: `/api/sale/childa/bao-addlink`,
      method: 'post',
      data: data
    }, false);
  },

  /**
  * 友情链接编辑
  * @param data
  */
  editLink: function editLink(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/bao-editlink"),
      // url: `/api/sale/childa/bao-editlink`,
      method: 'post',
      data: data
    }, false);
  }
};
exports.default = _default;