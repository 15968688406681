var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.type === "add" ? "添加" : "编辑",
        visible: _vm.dialogVisible,
        width: "40%"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "span",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.confirm("form")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.loading ? "提交中 ..." : "确 定"))]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": _vm.formLabelWidth
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "昵称：", prop: "name", required: "" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入点评师昵称",
                  autocomplete: "off",
                  clearable: true
                },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "display-flex" },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "flex-shrink": "0", "margin-right": "20px" },
                  attrs: {
                    label: "分配作业上限：",
                    prop: "isTop",
                    required: ""
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.isTop,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "isTop", $$v)
                        },
                        expression: "formData.isTop"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("无上限")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("有上限")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "top_num", "label-width": "0" } },
                [
                  _c("el-input", {
                    style: {
                      visibility:
                        +_vm.formData.isTop === 1 ? "visible" : "hidden"
                    },
                    attrs: {
                      type: "number",
                      placeholder: "区间：0~10000的整数",
                      autocomplete: "off",
                      clearable: true
                    },
                    model: {
                      value: _vm.formData.top_num,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "top_num", $$v)
                      },
                      expression: "formData.top_num"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "可点评课程：", prop: "course_id", required: "" }
            },
            [
              _vm.courseArr.length
                ? _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.formData.course_id,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "course_id", $$v)
                        },
                        expression: "formData.course_id"
                      }
                    },
                    _vm._l(_vm.courseArr, function(item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                disabled:
                                  _vm.type === "edit" &&
                                  _vm.currentDisabledId.includes(
                                    item.course_id
                                  ),
                                label: item.course_id
                              }
                            },
                            [_vm._v(_vm._s(item.video_title))]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }