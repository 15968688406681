"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CourseDialog',
  components: {
    PageTable: _PageTable.default
  },
  filters: {
    statusText: function statusText(val) {
      var data = {
        1: '更新中',
        2: '已完结'
      };
      return data[val] || '';
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formLabelWidth: '90px',
      searchParam: {
        page: 1,
        page_size: 10,
        id: '',
        video_title: ''
      },
      tableData: [],
      total: 0,
      selectList: [],
      columns: [{
        label: '课程ID',
        width: '80',
        prop: 'id'
      }, {
        label: '课程名称',
        width: '100',
        prop: 'video_title'
      }, {
        label: '课程状态',
        width: '100',
        prop: 'is_update' // "课程状态1：更新中2：已完结"

      }],
      selectItem: ''
    };
  },
  methods: {
    showDialog: function showDialog() {
      this.dialogVisible = true;
      this.selectItem = '';
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;

      _api.default.getLiveCourseList(this.searchParam).then(function (response) {
        var data = response.data,
            count = response.count;
        _this.tableData = data;
        _this.total = count;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    currentChange: function currentChange(val) {
      this.selectItem = val;
    },
    confirm: function confirm() {
      if (!this.selectItem) {
        this.$message.warning('请选择数据~');
        return;
      }

      this.$emit('confirm', this.selectItem);
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;