"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _UserDialog = _interopRequireDefault(require("../../../components/Dialog/UserDialog"));

var _class = require("./class");

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'StudentList',
  components: {
    PageTable: _PageTable.default,
    UserDialog: _UserDialog.default
  },
  data: function data() {
    return {
      tableData: null,
      listLoading: true,
      total: 0,
      initClassData: _class.initData,
      queryShowMore: false,
      listQuery: {},
      moduleUrl: 'http://xsjy-1254153797.cos.ap-shanghai.myqcloud.com/smartpen/courseware/pc/2020/10/30/%E7%8F%AD%E7%BA%A7%E6%88%90%E5%91%98.xlsx',
      isMyStudent: true
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.isMyStudent = route.name === 'MyStudentList';
      },
      immediate: true
    }
  },
  created: function created() {
    var _this$$route$params = this.$route.params,
        classId = _this$$route$params.classId,
        courseId = _this$$route$params.courseId;
    this.classId = classId;
    this.courseId = courseId;
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getStudentList(this.listQuery).then(function (response) {
        var _response$data = response.data,
            infos = _response$data.infos,
            totalNum = _response$data.totalNum;
        _this.tableData = (infos || []).map(function (item) {
          return _bean.default.studentBean(item);
        });
        _this.total = totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.listQuery.page = val;
      this.fetchData();
    },
    reset: function reset() {
      var classId = this.classId;
      this.listQuery = {
        page: 1,
        page_size: 10,
        class_id: classId
      };
      this.fetchData();
    },
    jumpPage: function jumpPage(item, pageName) {
      var courseId = this.courseId;
      this.$router.push({
        name: pageName,
        params: {
          classId: 0,
          uid: item.uid,
          courseId: courseId,
          teacherId: 0,
          assistantId: 0
        }
      });
    },
    showDialog: function showDialog() {
      this.$refs.userDialog.showDialog();
    },
    deleteItem: function deleteItem(row) {
      var _this2 = this;

      this.$confirm('确定要删除该记录吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _api.default.deleteClassRoomStudent({
          id: row.id
        }).then(function (response) {
          _this2.$message.success('操作成功~');

          _this2.reset();
        });
      });
    },
    confirm: function confirm(item) {
      var _this3 = this;

      _api.default.addClassRoomStudent({
        class_id: this.classId,
        uid: item.uid
      }).then(function () {
        _this3.$message.success('操作成功~');

        _this3.reset();
      });
    },
    uploadRequest: function uploadRequest(params) {
      var _this4 = this;

      var _formData = new FormData();

      _formData.append('excel_file', params.file);

      _api.default.importClassRoomStudent(_formData).then(function () {
        _this4.$message.success('操作成功~');

        _this4.reset();
      });
    }
  }
};
exports.default = _default;