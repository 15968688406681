var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.showDialog("editQrCodeDialog")
                    }
                  }
                },
                [_vm._v("添加二维码")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", [
            _c("span", [_vm._v("昨日总量：" + _vm._s(_vm.yesterdayAll))])
          ]),
          _vm._v(" "),
          _c("el-form-item", [
            _c("span", [_vm._v("今日总量：" + _vm._s(_vm.todayAll))])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "data-list": _vm.tableData,
          columns: _vm.columns,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size,
          "show-index": false,
          selection: false,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.doOption(props.row, "add")
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                class: props.row.isDelete
                                  ? "text-success"
                                  : "text-danger"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    props.row.isDelete
                                      ? "添加至多客服"
                                      : "取消添加至多客服"
                                  )
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        !props.row.isDelete
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.doOption(props.row, "status")
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class: props.row.off
                                      ? "text-success"
                                      : "text-danger"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(props.row.off ? "开启" : "禁用")
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.showDialog(
                                  "editQrCodeDialog",
                                  props.row
                                )
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.showDialog("shiftDialog", props.row)
                              }
                            }
                          },
                          [_vm._v("转移")]
                        )
                      ],
                      1
                    )
                  : props.column.label === "二维码"
                  ? _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "80px", height: "60px" },
                          attrs: {
                            src: props.row.qrCodeUrl,
                            "preview-src-list": [props.row.qrCodeUrl]
                          }
                        })
                      ],
                      1
                    )
                  : props.column.label === "单日上限"
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { type: "number", min: "0", size: "mini" },
                          on: {
                            blur: function($event) {
                              return _vm.doOption(props.row, "toplimit")
                            }
                          },
                          model: {
                            value: props.row[props.column.property],
                            callback: function($$v) {
                              _vm.$set(props.row, props.column.property, $$v)
                            },
                            expression: "props.row[props.column.property]"
                          }
                        })
                      ],
                      1
                    )
                  : props.column.label === "是否开启"
                  ? _c("div", [
                      _c(
                        "span",
                        {
                          class: props.row.off ? "text-danger" : "text-success"
                        },
                        [_vm._v(_vm._s(props.row[props.column.property]))]
                      )
                    ])
                  : props.column.label === "是否添加至多客服"
                  ? _c("div", [
                      _c(
                        "span",
                        {
                          class: props.row.isDelete
                            ? "text-danger"
                            : "text-success"
                        },
                        [_vm._v(_vm._s(props.row[props.column.property]))]
                      )
                    ])
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("edit-qr-code-dialog", {
        ref: "editQrCodeDialog",
        on: { refresh: _vm.reset }
      }),
      _vm._v(" "),
      _c("shift-dialog", { ref: "shiftDialog", on: { refresh: _vm.reset } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }