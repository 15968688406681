"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _default = {
  data: function data() {
    return {
      reviewEl: null,
      workEl: null
    };
  },
  activated: function activated() {
    var name = this.$route.name;

    if (name === 'Clue' || name === 'Chance') {
      this.initNotify();
    }
  },
  deactivated: function deactivated() {
    this.reviewEl && this.reviewEl.close();
    this.workEl && this.workEl.close();
    this.reviewEl = null;
    this.workEl = null;
  },
  methods: {
    initNotify: function initNotify() {
      var _this = this;

      _api.default.saleNotify().then(function (response) {
        var _response$data = response.data,
            newReview = _response$data.newReview,
            newHomework = _response$data.newHomework;

        if (newReview) {
          if (!_this.reviewEl) {
            _this.reviewEl = _this.showNotify('新的点评', newReview, 'review');
          }
        }

        if (newHomework) {
          setTimeout(function () {
            if (!_this.workEl) {
              _this.workEl = _this.showNotify('新的作品提交', newHomework, 'homework');
            }
          }, 200);
        }
      });
    },
    showNotify: function showNotify(msg, data, type) {
      var _this2 = this;

      return this.$notify.info({
        position: 'bottom-right',
        title: "\u6D88\u606F",
        message: "".concat(msg, "\uFF08").concat(data, "\uFF09"),
        duration: 0,
        showClose: false,
        onClick: function onClick() {
          _api.default.reportSaleNotify({
            type: type
          }).then(function () {
            if (type === 'review') {
              _this2.reviewEl && _this2.reviewEl.close();
            } else {
              _this2.workEl && _this2.workEl.close();
            }
          });
        },
        onClose: function onClose(e) {}
      });
    }
  }
};
exports.default = _default;