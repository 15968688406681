"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _EditSectionDialog = _interopRequireDefault(require("./components/EditSectionDialog"));

var _initData = require("../initData");

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SectionList',
  components: {
    PageTable: _PageTable.default,
    EditSectionDialog: _EditSectionDialog.default
  },
  data: function data() {
    return {
      tableData: null,
      listLoading: true,
      total: 0,
      initSectionData: _initData.initData,
      searchParam: {},
      moduleUrl: '//xsjy-1254153797.cos.ap-shanghai.myqcloud.com/smartpen/courseware/pc/2020/09/17/%E7%AB%A0%E8%8A%82.xlsx'
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getSectionList(this.searchParam).then(function (response) {
        var _response$data = response.data,
            infos = _response$data.infos,
            totalNum = _response$data.totalNum;
        _this.tableData = (infos || []).map(function (item) {
          return _bean.default.sectionListBean(item);
        });
        _this.total = totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      var id = this.$route.params.id;
      this.searchParam = {
        page: 1,
        page_size: 10,
        course_id: id
      };
      this.fetchData();
    },
    showDialog: function showDialog(ref, type, item) {
      this.$refs[ref].showDialog(type, (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
        course_id: this.searchParam.course_id
      }));
    },
    refresh: function refresh() {
      this.fetchData();
    },
    // 删除章节
    del: function del(item) {
      var _this2 = this;

      this.$confirm('确定要删除该记录吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _api.default.delSectionList({
          id: item.id
        }).then(function () {
          _this2.$message.success('操作成功~');

          _this2.fetchData();
        });
      });
    },
    jumpPage: function jumpPage(name, params) {
      this.$router.push({
        name: name,
        params: params
      });
    },
    uploadRequest: function uploadRequest(params) {
      var _this3 = this;

      var _formData = new FormData();

      _formData.append('excel_file', params.file);

      _api.default.importSectionList(_formData).then(function () {
        _this3.$message.success('操作成功~');

        _this3.reset();
      });
    },
    onInputBlur: function onInputBlur(row, column) {
      var _this4 = this;

      var num = row[column.property];

      if (!num || num < 0) {
        this.$message({
          type: 'warning',
          message: '输入的值有误~'
        });
        return;
      }

      _api.default.updateSectionList({
        id: row.id,
        sort: row.sort
      }).then(function () {
        _this4.$message({
          type: 'success',
          message: '操作成功~'
        });

        _this4.fetchData();
      });
    },
    changeRow: function changeRow(val, item) {
      var _this5 = this;

      item.status = !val;
      this.$confirm('确定要执行该操作吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        // status 0: 准备中, 1: 上架
        _api.default.updateSectionList({
          id: item.id,
          status: val ? 1 : 0
        }).then(function () {
          item.status = val;

          _this5.$message.success('操作成功~');
        });
      });
    }
  }
};
exports.default = _default;