import { render, staticRenderFns } from "./HandleClass.vue?vue&type=template&id=9dd8c652&scoped=true&"
import script from "./HandleClass.vue?vue&type=script&lang=js&"
export * from "./HandleClass.vue?vue&type=script&lang=js&"
import style0 from "./HandleClass.vue?vue&type=style&index=0&id=9dd8c652&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dd8c652",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9dd8c652')) {
      api.createRecord('9dd8c652', component.options)
    } else {
      api.reload('9dd8c652', component.options)
    }
    module.hot.accept("./HandleClass.vue?vue&type=template&id=9dd8c652&scoped=true&", function () {
      api.rerender('9dd8c652', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/online/class/HandleClass.vue"
export default component.exports