"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lineTypeOptions = exports.classStatusOptions = exports.initData = void 0;

var _initData = require("../sales/initData");

var initData = {
  classList: {
    name: 'classList',
    columns: [{
      label: '班级ID',
      width: '80',
      prop: 'id'
    }, {
      label: '班级名称',
      width: '120',
      prop: 'className'
    }, {
      label: '关联课程',
      width: '120',
      prop: 'courseName'
    }, {
      label: '学生数',
      width: '120',
      prop: 'studentNum'
    }, {
      label: '打卡数',
      width: '120',
      prop: 'dkNum'
    }, {
      label: '打卡未点评',
      width: '120',
      prop: 'dkNotComment'
    }, {
      label: '完课率',
      width: '120',
      prop: 'classFinishedPercent'
    }, {
      label: '续费率',
      width: '120',
      prop: 'renewPercent'
    }, {
      label: '点评老师',
      width: '120',
      prop: 'commentName'
    }, {
      label: '班主任',
      width: '120',
      prop: 'classTeacherName'
    }, {
      label: '开班时间',
      width: '170',
      prop: 'startTime'
    }, {
      label: '结课剩余时间',
      width: '120',
      prop: 'lastDay'
    }, {
      label: '班级状态',
      width: '120',
      prop: 'classStatus'
    }, {
      label: '操作',
      width: '200',
      fix: 'right'
    }]
  },
  studentList: {
    name: 'studentList',
    columns: [{
      label: 'UID',
      width: '80',
      prop: 'uid'
    }, {
      label: '学生昵称',
      width: '120',
      prop: 'uname'
    }, {
      label: '手机号（绑定）',
      width: '120',
      prop: 'bind_phone'
    }, {
      label: '手机号',
      width: '120',
      prop: 'phone'
    }, {
      label: '打卡数',
      width: '120',
      prop: 'dk_num'
    }, {
      label: '上墙数',
      width: '120',
      prop: 'on_wall_num'
    }, {
      label: '获赞数',
      width: '120',
      prop: 'zan_num'
    }, {
      label: '学习进度',
      width: '120',
      prop: 'study_percent'
    }, {
      label: '上次打卡时间',
      width: '120',
      prop: 'lastDkTime'
    }, {
      label: '上次打卡课时',
      width: '120',
      prop: 'lastDkCourse'
    }, {
      label: '操作',
      width: '200',
      fix: 'right'
    }]
  },
  courseList: {
    name: 'courseList',
    columns: [{
      label: '编号',
      width: '80',
      prop: 'id'
    }, {
      label: '课程名称',
      width: '120',
      prop: 'name'
    }, {
      label: '业务类型',
      width: '120',
      prop: 'lineTypeText'
    }, {
      label: '关联点播课',
      width: '120',
      prop: 'videoTitle'
    }, {
      label: '开班总数',
      width: '120',
      prop: 'classNum'
    }, {
      label: '现有班级数',
      width: '120',
      prop: 'nowClickNum'
    }, {
      label: '创建时间',
      width: '120',
      prop: 'createTime'
    }, {
      label: '操作',
      width: '200',
      fix: 'right'
    }]
  },
  instructorList: {
    name: 'instructorList',
    columns: [{
      label: 'UID',
      width: '80',
      prop: 'uid'
    }, {
      label: '班主任昵称',
      width: '120',
      prop: 'nickname'
    }, {
      label: '班主任姓名',
      width: '120',
      prop: 'userName',
      edit: false
    }, {
      label: '班级数',
      width: '120',
      prop: 'classCount'
    }, {
      label: '状态',
      width: '120',
      prop: 'workStatusTxt'
    }, {
      label: '操作',
      width: '200',
      fix: 'right'
    }]
  }
};
exports.initData = initData;
var classStatusOptions = [{
  value: '',
  label: '全部'
}, {
  value: 0,
  label: '未开班'
}, {
  value: 1,
  label: '已开班'
}, {
  value: 2,
  label: '已结课'
}];
exports.classStatusOptions = classStatusOptions;
var lineTypeOptions = _initData.serviceLine;
exports.lineTypeOptions = lineTypeOptions;