"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _initData = require("../initData");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Userlist',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default
  },
  data: function data() {
    return {
      createTime: '',
      searchParam: {},
      tableData: [],
      listLoading: false,
      total: 0,
      initUserData: _initData.initData
    };
  },
  created: function created() {
    this.reset();
    console.log(888);
  },
  methods: {
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.searchParam.start_time = new Date(val[0]).getTime() / 1000;
        this.searchParam.end_time = new Date(val[1]).getTime() / 1000;
      } else {
        this.searchParam.start_time = '';
        this.searchParam.end_time = '';
      }
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.childUserList(this.searchParam).then(function (response) {
        console.log(response); // const { infos, totalNum } = response.data;

        var infos = response.data.data;
        var totalNum = response.data.total; // console.log(infos);

        _this.tableData = (infos || []).map(function (item) {
          return _bean.default.childUserBean(item);
        });
        _this.total = totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.searchParam = {
        uid: '',
        uname: '',
        phone: '',
        start_time: '',
        end_time: '',
        page: 1,
        page_size: 5
      };
      this.createTime = '';
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    jumpPage_hw: function jumpPage_hw(item) {
      this.$router.push({
        path: 'userHw/' + item.uid
      });
    },
    jumpPage_course: function jumpPage_course(item) {
      this.$router.push({
        path: 'childCourse/' + item.uid
      });
    },
    jumpPage_eva: function jumpPage_eva(item) {
      this.$router.push({
        path: 'childEva/' + item.uid
      });
    }
  }
};
exports.default = _default;