"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      dialogVisible: false,
      championDay: [],
      championMonth: []
    };
  },
  methods: {
    showDialog: function showDialog() {
      this.dialogVisible = true;
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;

      _api.default.getSaleRankList().then(function (response) {
        _this.championDay = response.data.today;
        _this.championMonth = response.data.month;
      }).catch(function () {
        _this.championDay = [];
        _this.championMonth = [];
      });
    }
  }
};
exports.default = _default;