"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      listLoading: false,
      searchParam: {},
      columns: [{
        label: 'UID',
        width: '60',
        prop: 'uid'
      }, {
        label: '销售人',
        width: '60',
        prop: 'userName'
      }, {
        label: '销售手机号码',
        width: '60',
        prop: 'mobile'
      }],
      total: 0,
      tableData: [],
      dialogVisible: false,
      formData: {},
      selectItem: ''
    };
  },
  methods: {
    showDialog: function showDialog(item) {
      this.formData.id = item.id;
      this.dialogVisible = true;
      this.reset();
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getSaleStaffList(this.searchParam).then(function (response) {
        _this.tableData = response.data.infos.map(function (item) {
          return _bean.default.salesStaffBean(item);
        });
        _this.total = response.data.totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    currentChange: function currentChange(val) {
      this.selectItem = val;
      this.formData.saleman_id = val.id;
    },
    onSearch: function onSearch() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      if (!this.selectItem) {
        this.$message({
          type: 'warning',
          message: '请选择要转移的销售人员~'
        });
        return;
      }

      _api.default.editSaleQrCode(this.formData).then(function (response) {
        _this2.$message({
          type: 'success',
          message: '操作成功~'
        });

        _this2.dialogVisible = false;

        _this2.$emit('refresh');
      });
    },
    reset: function reset() {
      this.searchParam = {
        page: 1,
        page_size: 10,
        name: '',
        phone: ''
      };
      this.fetchData();
    }
  }
};
exports.default = _default;