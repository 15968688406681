"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _UploadVideo = _interopRequireDefault(require("@/components/UploadVideo"));

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditCourseware',
  components: {
    uploadVideo: _UploadVideo.default
  },
  data: function data() {
    return {
      formData: {
        name: '',
        type: 1,
        fileId: '',
        url: ''
      },
      formLabelWidth: '100px',
      drawer: false,
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '请输入视频名称',
          trigger: 'change'
        }],
        fileId: [{
          required: true,
          message: '请上传视频',
          trigger: 'change'
        }]
      },
      dialogType: '',
      isUpload: false
    };
  },
  methods: {
    showDialog: function showDialog(item, type) {
      this.isUpload = false;
      this.dialogType = type;
      this.drawer = true;

      if (type === 'edit') {
        this.formData.id = item.id;
        this.formData.name = item.name;
        this.formData.url = item.videoUrl;
        this.formData.fileId = item.fileId;
      }
    },
    cancelForm: function cancelForm() {
      this.loading = false;
      this.drawer = false;
    },
    handleClose: function handleClose(done) {
      var _this = this;

      if (this.loading) {
        return;
      }

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var url = _this.dialogType === 'add' ? 'addUploadInfo' : 'editUploadInfo';

          _this.$confirm('确定要提交数据吗？').then(function (_) {
            _this.loading = true;

            _api.default[url](_this.formData).then(function () {
              if (_this.isUpload) {
                _this.transcoding();
              }

              _this.loading = false;
              _this.formData = {
                name: '',
                type: 1,
                fileId: '',
                url: ''
              };

              _this.$refs.form.resetFields();

              _this.$emit('refresh');

              done();
            }).then(function () {
              _this.loading = false;
            });
          }).catch(function (_) {
            _this.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    uploadResult: function uploadResult(val) {
      this.isUpload = true;
      this.formData.url = val.videoUrl;
      this.formData.fileId = val.fileId;
    },
    transcoding: function transcoding() {
      var formData = this.formData;

      _api.default.transcodeTask({
        fileId: formData.fileId
      }).then(function () {});
    }
  }
};
exports.default = _default;