var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "show-index": false,
          "data-list": _vm.tableData,
          columns: _vm.initCourseData.courseList.columns,
          "page-no": _vm.listQuery.page,
          "page-size": _vm.listQuery.page_size,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "课程封面"
                  ? _c(
                      "div",
                      [
                        props.row.cover
                          ? _c("el-image", {
                              staticStyle: { width: "80px", height: "60px" },
                              attrs: {
                                src: props.row.cover,
                                "preview-src-list": [props.row.cover]
                              }
                            })
                          : _c("div", [_vm._v("无")])
                      ],
                      1
                    )
                  : props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _vm.hasPerm("SmartPenCourseList:list")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                attrs: {
                                  "track-params": "智能笔-课程列表-章节列表",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.jumpPage("SectionList", {
                                      id: props.row.course_id
                                    })
                                  }
                                }
                              },
                              [_vm._v("章节列表\n                ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPerm("SmartPenCourseList:classList")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                attrs: {
                                  "track-params": "智能笔-班级列表-班级列表",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.jumpPage("ClassList", {
                                      courseId: props.row.course_id,
                                      teacherId: 0,
                                      assistantId: 0
                                    })
                                  }
                                }
                              },
                              [_vm._v("班级列表")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }