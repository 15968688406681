"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = {
  name: 'MenuItem',
  functional: true,
  props: {
    meta: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          icon: ''
        };
      }
    }
  },
  render: function render(h, context) {
    var _context$props$meta = context.props.meta,
        icon = _context$props$meta.icon,
        title = _context$props$meta.title;
    var vnodes = [];

    if (icon) {
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
    }

    if (title) {
      vnodes.push(h("span", {
        "slot": "title"
      }, [title]));
    }

    return vnodes;
  }
};
exports.default = _default2;