"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BUCKET_NAME_ENUM = exports.PRODUCT_ENUM = exports.FILE_TYPE_ENUM = void 0;
var FILE_TYPE_ENUM = {
  VIDEO: 'video',
  AUDIO: 'audio',
  IMAGE: 'image',
  APK: 'apk',
  APPLICATION: 'document',
  DOC: 'document',
  XLS: 'document',
  PPT: 'document',
  DOCX: 'document',
  XLSX: 'document',
  PPTX: 'document',
  OTHER: 'other'
};
exports.FILE_TYPE_ENUM = FILE_TYPE_ENUM;
var PRODUCT_ENUM = {
  TABLET: 'tablet',
  // 掌上碑帖
  EDU: 'edu',
  // 在线教育
  TRAINING: 'training',
  // 教培
  SMART_PEN: 'smartpen' // 智能笔

};
exports.PRODUCT_ENUM = PRODUCT_ENUM;
var BUCKET_NAME_ENUM = {
  TABLET: 'zsbt-1254153797',
  // 掌上碑帖
  EDU: 'xsjy-1254153797' // 在线教育

};
exports.BUCKET_NAME_ENUM = BUCKET_NAME_ENUM;