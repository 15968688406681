var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("search-box", {
        attrs: { showMore: false },
        on: { search: _vm.onSubmit, reset: _vm.reset },
        scopedSlots: _vm._u([
          {
            key: "basic",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "直播名称：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "直播名称" },
                      model: {
                        value: _vm.searchParam.title,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "title", $$v)
                        },
                        expression: "searchParam.title"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "直播时间：" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        "range-separator": "~",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateRangeChange },
                      model: {
                        value: _vm.createTime,
                        callback: function($$v) {
                          _vm.createTime = $$v
                        },
                        expression: "createTime"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "是否推荐：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchParam.recommend,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "recommend", $$v)
                          },
                          expression: "searchParam.recommend"
                        }
                      },
                      _vm._l(_vm.options, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _vm.hasPerm("Live:add")
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track:click",
                          arg: "click"
                        }
                      ],
                      attrs: {
                        "track-params": "成人-直播-添加",
                        type: "primary",
                        icon: "el-icon-plus"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleLive("add")
                        }
                      }
                    },
                    [_vm._v("添加\n            ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          "data-list": _vm.tableData,
          loading: _vm.listLoading,
          columns: _vm.columns,
          "show-index": false,
          selection: false,
          total: _vm.total,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _vm.hasPerm("Live:edit")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                attrs: {
                                  "track-params": "成人-直播-编辑",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleLive("edit", props.row)
                                  }
                                }
                              },
                              [_vm._v("编辑\n                ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPerm("Live:user")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                attrs: {
                                  "track-params": "成人-直播-用户",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showDialog(props.row)
                                  }
                                }
                              },
                              [_vm._v("用户")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : props.column.label === "设为推荐"
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("recommendText")(props.row.recommend)) +
                          "\n            "
                      )
                    ])
                  : props.column.label === "关联点播课"
                  ? _c(
                      "div",
                      [
                        _vm.hasPerm("Live:classList")
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  href:
                                    _vm.webSiteUrl +
                                    "/course/" +
                                    props.row.video_id +
                                    ".html",
                                  type: "primary",
                                  target: "_blank"
                                }
                              },
                              [_vm._v(_vm._s(props.row.video_title))]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("user-info-dialog", { ref: "userInfoDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }