var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "日常销售榜", visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("p", [_vm._v("当日销榜")]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: { border: "", data: _vm.championDay, "show-header": false }
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "saleman_uname",
                  label: "姓名",
                  width: "200"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "totalprice", label: "金额" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("p", [_vm._v("本月销榜")]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                data: _vm.championMonth,
                "show-header": false
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "saleman_uname",
                  label: "姓名",
                  width: "200"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "totalprice", label: "金额" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }