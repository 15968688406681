"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _api = _interopRequireDefault(require("@/api"));

var _UserDialog = _interopRequireDefault(require("@/components/Dialog/UserDialog"));

var _validate = require("@/utils/validate");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditClassDialog',
  components: {
    UserDialog: _UserDialog.default
  },
  data: function data() {
    var _this = this;

    var checkPhone = function checkPhone(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入手机号'));
      }

      if (!(0, _validate.isPoneAvailable)(value)) {
        callback('请输入正确的手机号');
      } else {
        callback();
      }
    };

    var validateTeacher = function validateTeacher(rule, value, callback) {
      if (Number(_this.form.has_comment) === 1 && !value) {
        callback(new Error('请选择主点评老师'));
      } else {
        callback();
      }
    };

    return {
      userType: '',
      dialogFormVisible: false,
      form: {
        course_id: [],
        name: '',
        phone: '',
        assistant_id: '',
        assistantName: '',
        has_comment: '1',
        teacher_id: '',
        teacherName: '',
        start_time: ''
      },
      formLabelWidth: '120px',
      dialogType: '',
      rules: {
        name: [{
          required: true,
          message: '请输入班级名称',
          trigger: 'blur'
        }],
        assistantName: [{
          required: true,
          message: '请选择班主任',
          trigger: 'change'
        }],
        teacherName: [{
          validator: validateTeacher,
          trigger: 'change'
        }],
        phone: [{
          required: true,
          validator: checkPhone,
          trigger: 'blur'
        }],
        course_id: [{
          required: true,
          message: '请选择关联课程',
          trigger: 'change'
        }]
      },
      loading: false,
      courseArr: []
    };
  },
  methods: {
    showDialog: function showDialog(type) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.dialogType = type;
      this.form.id = data.id || '';
      this.form.name = data.name || '';
      this.form.assistant_id = data.assistantId || '';
      this.form.assistantName = data.assistantName || '';
      this.form.phone = data.phone || '';
      this.form.has_comment = data.hasComment && data.hasComment.toString() || '1';
      this.form.teacher_id = data.teacherId || '';
      this.form.teacherName = data.teacherName || '';
      this.form.start_time = data.startTime || '';
      this.form.course_id = data.courseIds || [];
      this.dialogFormVisible = true;
      this.initData();
    },
    initData: function initData() {
      var _this2 = this;

      _api.default.getCourseList_2().then(function (res) {
        _this2.courseArr = res.data.infos;
      });
    },
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.form.start_time = (0, _utils.parseTime)(val);
      } else {
        this.form.start_time = '';
      }
    },
    // 保存编辑 或 添加
    save: function save(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          var url = _this3.dialogType === 'add' ? 'addPackageClassRoomInfo' : 'editPackageClassRoomInfo';

          _api.default[url](_this3.form).then(function (res) {
            if (res) {
              _this3.$message.success('操作成功');

              _this3.dialogFormVisible = false;

              _this3.$refs.editClassForm.resetFields();

              _this3.$emit('refresh');
            }

            _this3.loading = false;
          }).catch(function () {
            _this3.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    choiceUser: function choiceUser(type) {
      this.userType = type;
      this.$refs["".concat(type, "Dialog")].showDialog();
    },
    confirm: function confirm(item) {
      if (this.userType === 'assistant') {
        this.form.assistant_id = item.id;
        this.form.assistantName = item.uname;
      } else {
        this.form.teacher_id = item.id;
        this.form.teacherName = item.uname;
      }
    }
  }
};
exports.default = _default;