"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.asyncRoutes = exports.constantRoutes = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.function.name");

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _classRoom = _interopRequireDefault(require("./modules/classRoom"));

var _sales = _interopRequireDefault(require("./modules/sales"));

var _bt = _interopRequireDefault(require("./modules/bt"));

var _smartPen = _interopRequireDefault(require("./modules/smartPen"));

var _onlineBusiness = _interopRequireDefault(require("./modules/onlineBusiness"));

var _online = _interopRequireDefault(require("./modules/online"));

_vue.default.use(_vueRouter.default);
/* Layout */
// import Layout from '@/layout';

/* Router Modules */


// import child from './modules/child';
// import adult from './modules/adult';

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, // {
//     path: '/',
//     component: Layout,
//     redirect: '/home'
// },
{
  path: '/changePwd',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/changePwd'));
    });
  },
  hidden: true
} // {
//     path: '/home',
//     component: Layout,
//     redirect: '/home/charts',
//     name: 'Home',
//     meta: { title: '首页', affix: true },
//     children: [
//         {
//             path: 'charts',
//             name: 'Charts',
//             component: () => import('@/views/home/charts/index'),
//             meta: { title: '图表' }
//         },
//         {
//             path: 'tree',
//             name: 'Tree',
//             component: () => import('@/views/home/permission/index'),
//             meta: { title: '角色权限' }
//         }
//     ]
// }
];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

exports.constantRoutes = constantRoutes;
var asyncRoutes = [_classRoom.default, _sales.default, _bt.default, _smartPen.default, _onlineBusiness.default, _online.default];
exports.asyncRoutes = asyncRoutes;

var saveRouterJson = function saveRouterJson(routers) {
  var filter = routers.filter(function (item) {
    return !item.hidden;
  });
  return filter.map(function (item) {
    return {
      menuName: item.meta.title,
      menuCode: item.name,
      menuUrl: item.path.replace('/', ''),
      childMenus: item.children ? saveRouterJson(item.children) : []
    };
  });
};

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;