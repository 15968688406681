"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.regexp.split");

require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/core-js/modules/es6.array.iterator.js");

require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/core-js/modules/es6.promise.js");

require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/core-js/modules/es6.object.assign.js");

require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("@/icons");

require("@/permission");

require("./directives/track");

/*
 * @Author: your name
 * @Date: 2021-05-31 10:37:06
 * @LastEditTime: 2021-06-04 15:50:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt-online-edu-admin/src/main.js
 */
// A modern alternative to CSS resets
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n
// global css
// icon
// permission control

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }
// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
_vue.default.use(_elementUi.default);

_vue.default.config.productionTip = false; // let btnLimits = store.getters.btnLimts;

function hasBtnPermission(code, name) {
  var btnLimts = _store.default.getters.btnLimts;

  if (name) {
    if (code.split(':')[0] === name) {
      return btnLimts.indexOf(code) > -1;
    } else {
      return false;
    }
  } else {
    return btnLimts.indexOf(code) > -1;
  }
}

_vue.default.prototype.hasPerm = hasBtnPermission;
// eslint-disable-next-line
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});