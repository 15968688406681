"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _class = require("./class");

var _bean = _interopRequireDefault(require("@/bean"));

var _EditClassDialog = _interopRequireDefault(require("./components/EditClassDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ClassList',
  components: {
    PageTable: _PageTable.default,
    EditClassDialog: _EditClassDialog.default
  },
  data: function data() {
    return {
      tableData: null,
      listLoading: true,
      total: 0,
      queryShowMore: false,
      listQuery: {},
      isMyClass: false,
      columns: []
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.isMyClass = route.name === 'MyClassList' || route.params.teacherId || route.params.assistantId;
        this.columns = Number(this.isMyClass) ? _class.initData.myClass.columns : _class.initData.class.columns;
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    var _this$$route$params = this.$route.params,
        courseId = _this$$route$params.courseId,
        teacherId = _this$$route$params.teacherId,
        assistantId = _this$$route$params.assistantId;
    this.courseId = courseId;
    this.teacherId = teacherId;
    this.assistantId = assistantId;
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getClassRoomList(this.listQuery).then(function (response) {
        var _response$data = response.data,
            infos = _response$data.infos,
            totalNum = _response$data.totalNum;
        _this.tableData = (infos || []).map(function (item) {
          return _bean.default.classRoomBean(item);
        });
        _this.total = totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.listQuery.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.listQuery = {
        page: 1,
        page_size: 10,
        course_id: this.courseId,
        teacher_id: this.teacherId,
        assistant_id: this.assistantId,
        point_scene: this.$route.name === 'MyClassList' ? 'my' : ''
      };
      this.fetchData();
    },
    jumpPage: function jumpPage(pageName, params) {
      this.$router.push({
        name: pageName,
        params: params
      });
    },
    showDialog: function showDialog(type) {
      var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.$refs.editClassDialog.showDialog(type, item);
    },
    deleteItem: function deleteItem(row) {
      var _this2 = this;

      this.$confirm('确定要删除该记录吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _api.default.deleteClassRoom({
          id: row.id
        }).then(function (response) {
          _this2.$message.success('操作成功~');

          _this2.reset();
        });
      });
    }
  }
};
exports.default = _default;