var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "show-index": false,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.per_page,
          "data-list": _vm.tableData,
          columns: _vm.columns,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "推送类型"
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("checkType")(props.row[props.column.property])
                        )
                      )
                    ])
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }