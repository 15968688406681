"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _AssetDialog = _interopRequireDefault(require("../../../components/Dialog/AssetDialog"));

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'HandleCourse',
  components: {
    AssetDialog: _AssetDialog.default
  },
  data: function data() {
    return {
      formData: {
        name: '',
        type: '',
        length: '',
        page_code: '',
        map_code: '',
        word_resource_id: '',
        gray_word_resource_id: '',
        stroke_resource_id: '',
        gif_resource_id: '',
        video_resource_id: '',
        attention_resource_id: '',
        red_pic_resource_id: '',
        default_pic_resource_id: '',
        wordResourceName: '',
        grayWordResourceName: '',
        strokeResourceName: '',
        gifResourceName: '',
        videoName: '',
        attentionResourceName: '',
        redPicResourceName: '',
        defaultPicResourceName: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入课时名称',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择活课时类型',
          trigger: 'change'
        }],
        length: [{
          required: true,
          message: '请输入练字时长',
          trigger: 'blur'
        }],
        page_code: [{
          required: true,
          message: '请输入编码',
          trigger: 'blur'
        }],
        map_code: [{
          required: true,
          message: '请输入映射编码',
          trigger: 'blur'
        }],
        word_resource_id: [{
          required: true,
          message: '请选择单字图（黑）',
          trigger: 'change'
        }],
        gray_word_resource_id: [{
          required: true,
          message: '请选择单字图（灰）',
          trigger: 'change'
        }],
        stroke_resource_id: [{
          required: true,
          message: '请选择笔顺图',
          trigger: 'change'
        }],
        gif_resource_id: [{
          required: true,
          message: '请选择GIF图',
          trigger: 'change'
        }],
        video_resource_id: [{
          required: true,
          message: '请选择单字视频',
          trigger: 'change'
        }],
        attention_resource_id: [{
          required: true,
          message: '请选择书写要点',
          trigger: 'change'
        }],
        red_pic_resource_id: [{
          required: true,
          message: '请选择描红图片（红)',
          trigger: 'change'
        }],
        default_pic_resource_id: [{
          required: true,
          message: '请选择描红图片',
          trigger: 'change'
        }]
      },
      lessonType: [{
        value: 1,
        label: '控笔'
      }, {
        value: 2,
        label: '常规字'
      }],
      currentField: '',
      loading: false
    };
  },
  created: function created() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;

      var id = this.$route.params.id;

      if (id < 0) {
        return;
      }

      _api.default.getCourseLessonDetail({
        id: id
      }).then(function (res) {
        // eslint-disable-next-line
        for (var i in _this.formData) {
          _this.formData[i] = res.data[i] || '';
        }
      });
    },
    choiceCourseware: function choiceCourseware(type, fieldId, fieldName) {
      this.currentField = {
        id: fieldId,
        name: fieldName
      };
      this.$refs.assetDialog.showDialog({
        fileType: type,
        lineType: 3
      });
    },
    getFileList: function getFileList(item) {
      var currentField = this.currentField;
      this.formData[currentField.id] = item[0].id;
      this.formData[currentField.name] = item[0].name;
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      if (this.loading) {
        return;
      }

      var _this$$route$params = this.$route.params,
          id = _this$$route$params.id,
          sectionId = _this$$route$params.sectionId;
      var params = (0, _objectSpread2.default)({
        section_id: sectionId
      }, this.formData);
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          var url;

          if (parseInt(id, 10) === -1) {
            url = 'addCourseLessonInfo';
          } else {
            url = 'editCourseLessonInfo';
            params.id = id;
          }

          _api.default[url](params).then(function () {
            _this2.$message.success('操作成功~');

            _this2.loading = false;
            setTimeout(function () {
              _this2.$router.replace({
                name: 'LessonList',
                params: {
                  id: sectionId
                }
              });
            }, 2000);
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;