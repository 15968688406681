"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.save = save;
exports.find = find;
exports.remove = remove;

require("core-js/modules/es6.regexp.split");

var db = window.localStorage || '';
var dbtype = window.localStorage ? 'h5' : 'cookie';

function save(key, val, exdays) {
  if (dbtype === 'h5') {
    var currentDays = exdays || 30;
    var curTime = new Date().getTime();
    db.setItem(key, JSON.stringify({
      data: val,
      time: curTime + currentDays * 60 * 60 * 24 * 1000
    }));
    return true;
  }

  if (dbtype === 'cookie') {
    setCookie(key, val, exdays);
    return true;
  }

  return false;
}

function find(key) {
  if (dbtype === 'h5') {
    var item = JSON.parse(db.getItem(key));

    if (db.getItem(key) === null) {
      return '';
    } else {
      if (item.time > new Date().getTime()) {
        return item.data;
      } else {
        remove(key);
        return '';
      }
    }
  }

  if (dbtype === 'cookie') {
    return getCookie(key);
  }

  return '';
}

function remove(key) {
  if (dbtype === 'h5') {
    db.removeItem(key);
    return true;
  }

  if (dbtype === 'cookie') {
    clearCookie(key);
    return true;
  }

  return false;
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  var currentDays = exdays || 30;
  d.setTime(d.getTime() + currentDays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + '; ' + expires;
}

function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) !== -1) return c.substring(name.length, c.length);
  }

  return '';
}

function clearCookie(name) {
  setCookie(name, '', -1);
}