"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _SaleRankDialog = _interopRequireDefault(require("./components/SaleRankDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SaleOrder',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default,
    SaleRankDialog: _SaleRankDialog.default
  },
  data: function data() {
    return {
      createTime: '',
      searchParam: {},
      tableData: [],
      courseList: {},
      columns: [{
        label: 'id',
        width: '60',
        prop: 'id'
      }, {
        label: '销售id',
        width: '60',
        prop: 'saleManId'
      }, {
        label: '销售',
        width: '80',
        prop: 'saleManName'
      }, {
        label: '销售课程名称',
        width: '100',
        prop: 'courseName'
      }, {
        label: '原关联课程',
        width: '120',
        prop: 'oldCourseName'
      }, {
        label: '订单金额',
        width: '80',
        prop: 'totalPrice'
      }, {
        label: '用户名',
        width: '80',
        prop: 'userName'
      }, {
        label: '用户手机号',
        width: '120',
        prop: 'mobile'
      }, {
        label: '订单时间',
        width: '120',
        prop: 'createTime'
      }],
      listLoading: false,
      total: 0
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.searchParam.start_time = new Date(val[0]).getTime() / 1000;
        this.searchParam.end_time = new Date(val[1]).getTime() / 1000;
      } else {
        this.searchParam.start_time = '';
        this.searchParam.end_time = '';
      }
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getSaleOrderList(this.searchParam).then(function (response) {
        _this.tableData = response.data.infos.map(function (item) {
          return _bean.default.saleOrderBean(item);
        });
        _this.total = response.data.totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });

      _api.default.saleCourses().then(function (response) {
        _this.courseList = response.data || {};
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    showDialog: function showDialog(ref) {
      this.$refs[ref].showDialog();
    },
    reset: function reset() {
      this.searchParam = {
        course: '',
        amount: '',
        amount_type: 'greater',
        page: 1,
        per_page: 20,
        start_time: '',
        end_time: ''
      };
      this.createTime = '';
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    }
  }
};
exports.default = _default;