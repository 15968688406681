"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOPIC_ENUM = exports.SECTION_CATE_ENUM = exports.LESSON_CATE_ENUM = void 0;
var LESSON_CATE_ENUM = {
  '1': '趣味视频',
  '2': '边看边学',
  '3': '提高练习'
};
exports.LESSON_CATE_ENUM = LESSON_CATE_ENUM;
var SECTION_CATE_ENUM = {
  '5': '小测验',
  '6': '练字挑战'
};
exports.SECTION_CATE_ENUM = SECTION_CATE_ENUM;
var TOPIC_ENUM = {
  video: '视频',
  // image: '图片',
  // audio: '语音',
  radio: '选择题'
};
exports.TOPIC_ENUM = TOPIC_ENUM;