var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      ref: "drawer",
      attrs: {
        visible: _vm.drawer,
        direction: "rtl",
        "modal-append-to-body": false,
        "show-close": false,
        "custom-class": "drawer",
        size: "40%",
        "destroy-on-close": true,
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.drawer = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.dialogType === "add" ? "添加" : "编辑") + "课件"
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer__content" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.formData, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "视频名称：",
                    "label-width": _vm.formLabelWidth,
                    prop: "name"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "类型：", "label-width": _vm.formLabelWidth }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.formData.type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("视频")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("音频")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上传视频：",
                    "label-width": _vm.formLabelWidth,
                    prop: "fileId"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { display: "none" },
                    model: {
                      value: _vm.formData.fileId,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "fileId", $$v)
                      },
                      expression: "formData.fileId"
                    }
                  }),
                  _vm._v(" "),
                  _c("upload-video", {
                    ref: "fileId",
                    on: { result: _vm.uploadResult }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drawer__footer" },
            [
              _c("el-button", { on: { click: _vm.cancelForm } }, [
                _vm._v("取 消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.$refs.drawer.closeDrawer()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.loading ? "提交中 ..." : "确 定"))]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }