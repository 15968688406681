"use strict";

require("core-js/modules/es6.array.find");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _cache = require("@/utils/cache");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'FieldDialog',
  props: {
    columns: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      fieldList: []
    };
  },
  methods: {
    showDialog: function showDialog() {
      this.dialogVisible = true;
      this.fieldList = JSON.parse(JSON.stringify(this.columns));
    },
    handleTap: function handleTap(index) {
      this.fieldList.map(function (item, i) {
        if (Number(i) === Number(index)) {
          item.hide = !item.hide;
        }

        return item;
      });
    },
    confirm: function confirm() {
      var fieldList = this.fieldList;
      var isHas = fieldList.some(function (item) {
        return item.hide === false;
      });

      if (!isHas) {
        this.$message({
          message: '列表字段选择不能为空哦~',
          type: 'warning'
        });
        return;
      }

      this.dialogVisible = false;
      var fieldJson = (0, _cache.find)('fieldJson');
      var newJson = fieldJson || {};
      newJson[this.$route.name] = fieldList;
      (0, _cache.save)('fieldJson', newJson);
      this.$emit('handleFieldChange', fieldList);
    }
  }
};
exports.default = _default2;