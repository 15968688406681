var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dv"
    },
    [
      _c("div", { staticClass: "line" }, [
        _c("div", { staticClass: "labelC" }, [_vm._v("首页banner图片：")]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("configUpload1", {
              ref: "default1",
              on: { getLink1: _vm.getLink1_ }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }, [
        _c("div", { staticClass: "labelC" }, [_vm._v("首页banner跳转地址：")]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              staticClass: "ipt",
              attrs: { placeholder: "请输入首页banner跳转地址" },
              model: {
                value: _vm.query.home.redirect_url,
                callback: function($$v) {
                  _vm.$set(_vm.query.home, "redirect_url", $$v)
                },
                expression: "query.home.redirect_url"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }, [
        _c("div", { staticClass: "labelC" }, [
          _vm._v("评测结果页的广告图片：")
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("configUpload2", {
              ref: "default2",
              on: { getLink2: _vm.getLink2_ }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }, [
        _c("div", { staticClass: "labelC" }, [
          _vm._v("评测结果页的领课按钮的跳转地址：")
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              staticClass: "ipt",
              attrs: { placeholder: "请输入评测结果页的领课按钮的跳转地址" },
              model: {
                value: _vm.query.result.redirect_url,
                callback: function($$v) {
                  _vm.$set(_vm.query.result, "redirect_url", $$v)
                },
                expression: "query.result.redirect_url"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }, [
        _c("div", { staticClass: "labelC" }, [
          _vm._v("范字对比弹窗的广告图片：")
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("configUpload3", {
              ref: "default3",
              on: { getLink3: _vm.getLink3_ }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }, [
        _c("div", { staticClass: "labelC" }, [
          _vm._v("范字对比弹窗的领课按钮的跳转地址：")
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              staticClass: "ipt",
              attrs: { placeholder: "请输入范字对比弹窗的领课按钮的跳转地址" },
              model: {
                value: _vm.query.compare.redirect_url,
                callback: function($$v) {
                  _vm.$set(_vm.query.compare, "redirect_url", $$v)
                },
                expression: "query.compare.redirect_url"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }, [
        _c(
          "div",
          { staticClass: "labelC", staticStyle: { visibility: "hidden" } },
          [_vm._v("按钮")]
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.hasPerm("Contconfig:saveConfig")
              ? _c(
                  "el-button",
                  {
                    directives: [
                      { name: "track", rawName: "v-track:click", arg: "click" }
                    ],
                    attrs: {
                      "track-params": "电商-内容配置-保存配置",
                      type: "primary"
                    },
                    on: { click: _vm.save }
                  },
                  [_vm._v("保存配置")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }