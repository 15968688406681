"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _CoursewareData = _interopRequireDefault(require("./CoursewareData"));

//
//
//
//
//
//
var _default = {
  name: 'CoursewareDialog',
  components: {
    CoursewareData: _CoursewareData.default
  },
  data: function data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    showDialog: function showDialog() {
      this.dialogVisible = true;
    },
    confirm: function confirm(item) {
      this.$emit('confirm', item);
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;