"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var classRoomRouter = {
  path: '/classRoom',
  component: _layout.default,
  redirect: '/classRoom/vodCourseManager/courseList',
  name: 'ClassRoom',
  meta: {
    title: '课程'
  },
  children: [{
    path: 'vodCourseManager',
    name: 'VodCourseManager',
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/vodCourseManager/index.vue'));
      });
    },
    meta: {
      title: '点播课管理',
      code: 'VodCourseManager'
    },
    children: [{
      path: 'courseList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/vodCourseManager/CourseList.vue'));
        });
      },
      name: 'CourseList',
      meta: {
        title: '点播课程',
        code: 'CourseList'
      }
    }, {
      path: 'handleCourset/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/vodCourseManager/HandleCourse.vue'));
        });
      },
      name: 'HandleCourse',
      hidden: true,
      meta: {
        title: '操作课程',
        noCache: true
      }
    }, {
      path: 'classHourList/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/vodCourseManager/ClassHourList.vue'));
        });
      },
      name: 'ClassHourList',
      hidden: true,
      meta: {
        title: '课时管理',
        noCache: true
      }
    }, {
      path: 'studyLearn/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/vodCourseManager/StudyLearn.vue'));
        });
      },
      name: 'StudyLearn',
      hidden: true,
      meta: {
        title: '课时管理',
        noCache: true
      }
    }, {
      path: 'coursewareList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/vodCourseManager/CoursewareList.vue'));
        });
      },
      name: 'CoursewareList',
      meta: {
        title: '课件库',
        code: 'CoursewareList'
      }
    }]
  }, {
    path: 'funManager',
    name: 'FunManager',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/funManager/index'));
      });
    },
    meta: {
      title: '功能管理'
    },
    children: [{
      path: 'pushHistory',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/funManager/PushHistory.vue'));
        });
      },
      name: 'PushHistory',
      meta: {
        title: '推送历史',
        code: 'PushHistory'
      }
    }]
  }]
};
var _default = classRoomRouter;
exports.default = _default;