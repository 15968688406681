"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

// const BASE_URL = `${process.env.VUE_APP_BASE_API}`;
var PROXY_ID = '';
var _default = {
  /**
   * 通过UID获取用户信息
   * @param params
   */
  getOldUserInfoByUID: function getOldUserInfoByUID(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/user-detail"),
      method: 'get',
      params: params
    });
  },

  /**
   * 添加班主任
   * @param data
   */
  addClassTeacher: function addClassTeacher(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-teacher-create"),
      method: 'post',
      data: data
    });
  },

  /**
   * 删除班主任-产品说暂时用不到
   * @param data
   */
  delClassTeacher: function delClassTeacher(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-teacher-delete"),
      method: 'post',
      data: data
    });
  },

  /**
   * 修改班主任
   * @param data
   */
  updateClassTeacher: function updateClassTeacher(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-teacher-update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取班级列表
   * @param data
   */
  getOnlineClassList: function getOnlineClassList(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-list"),
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑班级配置
   * @param params
   */
  getOnlineClassDetail: function getOnlineClassDetail(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-detail"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取班级的学习计划
   * @param params
   */
  getOnlineClassStudyPlan: function getOnlineClassStudyPlan(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-study-plan"),
      method: 'get',
      params: params
    });
  },

  /**
   * 更新设置课时学习计划
   * @param data todo
   */
  updateOnlineCourseStudyPlan: function updateOnlineCourseStudyPlan(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/section-study-plan"),
      method: 'post',
      data: data
    });
  },

  /**
   * 设置课时图片
   * @param data todo
   */
  setOnlineCourseImg: function setOnlineCourseImg(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/course-image-update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 更新班级学习计划
   * @param data
   */
  updateOnlineClassStudyPlan: function updateOnlineClassStudyPlan(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-study-plan-update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 更新班级配置
   * @param data
   */
  updateOnlineClassItem: function updateOnlineClassItem(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 添加班级配置
   * @param data
   */
  addClassConfig: function addClassConfig(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-create"),
      method: 'post',
      data: data
    });
  },

  /**
   * 删除 班级-课程条目
   * @param data
   */
  delClassCourse: function delClassCourse(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/new-video-delete"),
      method: 'post',
      data: data
    });
  },

  /**
   * 更新班级信息
   * @param data
   */
  updateClassInfo: function updateClassInfo(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-info-update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 点评老师列表
   * @param data
   */
  configAddClassQueryreviewchargeList: function configAddClassQueryreviewchargeList(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/review-teacher-list"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取二维码
   * @param params
   */
  getClassQrcode: function getClassQrcode(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-qrcode"),
      method: 'get',
      params: params
    });
  },

  /**
   * 班主任列表
   * @param params
   */
  configAddClassQuerychargeList: function configAddClassQuerychargeList(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-teacher-list"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取班级内学生列表，添加学生时用
   * @param data
   */
  setOnlineClassaddStudent: function setOnlineClassaddStudent(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-student-add"),
      method: 'post',
      data: data
    });
  },

  /**
   * 学生进班，添加学生时用
   * @param params
   */
  getOnlineClassStudentsJoinList: function getOnlineClassStudentsJoinList(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/student-list"),
      method: 'get',
      params: params
    });
  },

  /**
   * 查询班级内学生列表
   * @param params
   */
  getOnlineClassStudentsList: function getOnlineClassStudentsList(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-student-list"),
      method: 'get',
      params: params
    });
  },

  /**
   * 删除班级-产品说暂时用不到
   * @param data
   */
  delOnlineClassItem: function delOnlineClassItem(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-delete"),
      method: 'post',
      data: data
    });
  },

  /**
   * 将学生移除班级
   * @param params
   */
  setOnlineClassDelStudent: function setOnlineClassDelStudent(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/class-student-del"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取课程列表
   * @param params
   */
  getOnlineCourseList: function getOnlineCourseList(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/new-video-list"),
      method: 'get',
      params: params
    });
  },

  /**
   * 编辑班级详情
   * @param params
   */
  setOnlineEditClassItem: function setOnlineEditClassItem(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/new-video-detail"),
      method: 'get',
      params: params
    });
  },

  /**
   * 更新课程详情
   * @param data
   */
  setOnlineEditCourseItem: function setOnlineEditCourseItem(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/new-video-update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 关联点播课程列表
   * @param params
   */
  getOnlineVodCourseList: function getOnlineVodCourseList(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API).concat(PROXY_ID, "/sale/video-list"),
      method: 'get',
      params: params
    });
  },

  /**
   * 课程对应的章节列表
   * @param params
   */
  getOnlineVidSectionList: function getOnlineVidSectionList(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/video-section-list"),
      method: 'get',
      params: params
    });
  },

  /**
   * 新增课程
   * @param data
   */
  insertOnlineCourse: function insertOnlineCourse(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/sale/new-video-create"),
      method: 'post',
      data: data
    });
  }
};
exports.default = _default;