"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasClassArr = exports.reviewStatusArr = exports.initData = void 0;
var initData = {
  class: {
    name: 'class',
    columns: [{
      label: '班级ID',
      width: '80',
      prop: 'id'
    }, {
      label: '班级名称',
      width: '100',
      prop: 'name'
    }, {
      label: '班主任',
      width: '100',
      prop: 'assistantName'
    }, {
      label: '工作状态',
      width: '100',
      prop: 'workStatusValue'
    }, {
      label: '手机号',
      width: '100',
      prop: 'phone'
    }, {
      label: '学员数',
      width: '80',
      prop: 'userNum'
    }, {
      label: '添加时间',
      width: '120',
      prop: 'createTime'
    }, {
      label: '操作',
      width: '120',
      fix: 'right'
    }]
  },
  myClass: {
    name: 'myClass',
    columns: [{
      label: '班级ID',
      width: '80',
      prop: 'id'
    }, {
      label: '班级课程',
      width: '100',
      prop: 'courseName'
    }, {
      label: '班级名称',
      width: '100',
      prop: 'name'
    }, {
      label: '班级学员数',
      width: '80',
      prop: 'userNum'
    }, {
      label: '未点评作业数',
      width: '80',
      prop: 'noReviewNum'
    }, {
      label: '添加时间',
      width: '120',
      prop: 'createTime'
    }, {
      label: '操作',
      width: '120',
      fix: 'right'
    }]
  },
  student: {
    name: 'student',
    columns: [{
      label: 'UID',
      width: '80',
      prop: 'uid'
    }, {
      label: '头像',
      width: '100',
      prop: 'avatar'
    }, {
      label: '昵称',
      width: '100',
      prop: 'uname'
    }, {
      label: '性别',
      width: '80',
      prop: 'sexText'
    }, {
      label: '生日',
      width: '100',
      prop: 'birthday'
    }, {
      label: '手机号',
      width: '120',
      prop: 'phone'
    }, {
      label: '地区',
      width: '120',
      prop: 'areaStr'
    }, {
      label: '进班时间',
      width: '120',
      prop: 'createTime'
    }, {
      label: '未点评作业数',
      width: '100',
      prop: 'noReviewNum'
    }, {
      label: '操作',
      width: '120',
      fix: 'right'
    }]
  },
  packageStudent: {
    name: 'packageStudent',
    columns: [{
      label: 'UID',
      width: '80',
      prop: 'uid'
    }, {
      label: '头像',
      width: '100',
      prop: 'avatar'
    }, {
      label: '昵称',
      width: '100',
      prop: 'uname'
    }, {
      label: '性别',
      width: '80',
      prop: 'sexText'
    }, {
      label: '生日',
      width: '100',
      prop: 'birthday'
    }, {
      label: '手机号',
      width: '120',
      prop: 'phone'
    }, {
      label: '地区',
      width: '120',
      prop: 'areaStr'
    }, {
      label: '进班时间',
      width: '120',
      prop: 'createTime'
    }, {
      label: '是否绑定主点评老师',
      width: '120',
      prop: 'teacherId'
    }, {
      label: '未点评作业数',
      width: '100',
      prop: 'noReviewNum'
    }, {
      label: '操作',
      width: '120',
      fix: 'right'
    }]
  },
  homework: {
    name: 'homework',
    columns: [{
      label: '作业ID',
      width: '80',
      prop: 'id'
    }, {
      label: '提交学员',
      width: '80'
    }, {
      label: '点评状态',
      width: '100',
      prop: 'reviewStatusText'
    }, {
      label: '课时名',
      width: '100',
      prop: 'lessonName'
    }, {
      label: '提交时间',
      width: '80',
      prop: 'createTime'
    }, {
      label: '点评时间',
      width: '100',
      prop: 'reviewTime'
    }, {
      label: '点评老师',
      width: '100',
      prop: 'teacherName'
    }, {
      label: '实际点评老师',
      width: '110',
      prop: 'reviewTeacherName'
    }, {
      label: '作业',
      width: '120'
    }, {
      label: '操作',
      width: '120',
      fix: 'right'
    }]
  },
  allHomework: {
    name: 'allHomework',
    columns: [{
      label: '作业ID',
      width: '80',
      prop: 'id'
    }, {
      label: '学员名称',
      width: '100',
      prop: 'uname'
    }, {
      label: '手机号',
      width: '100',
      prop: 'phone'
    }, {
      label: '是否有班级',
      width: '100'
    }, // {
    //     label: '班级名称',
    //     width: '100',
    //     prop: 'className'
    // },
    {
      label: '课程包班级名称',
      width: '100',
      prop: 'packageClassName'
    }, {
      label: '班主任名称',
      width: '100',
      prop: 'assistantName'
    }, {
      label: '点评老师',
      width: '100',
      prop: 'teacherName'
    }, {
      label: '实际点评老师',
      width: '110',
      prop: 'reviewTeacherName'
    }, {
      label: '点评状态',
      width: '100',
      prop: 'reviewStatusText'
    }, {
      label: '课时名',
      width: '100',
      prop: 'lessonName'
    }, {
      label: '提交时间',
      width: '120',
      prop: 'createTime'
    }, {
      label: '点评时间',
      width: '120',
      prop: 'reviewTime'
    }, {
      label: '作业',
      width: '80'
    }, {
      label: '操作',
      width: '120',
      fix: 'right'
    }]
  },
  myHomework: {
    name: 'myHomework',
    columns: [{
      label: '作业ID',
      width: '80',
      prop: 'id'
    }, {
      label: '学员名称',
      width: '100',
      prop: 'uname'
    }, // {
    //     label: '班级名称',
    //     width: '100',
    //     prop: 'className'
    // },
    {
      label: '课程包班级名称',
      width: '100',
      prop: 'packageClassName'
    }, {
      label: '班主任名称',
      width: '100',
      prop: 'assistantName'
    }, {
      label: '课程',
      width: '100',
      prop: 'courseName'
    }, {
      label: '课时名',
      width: '100',
      prop: 'lessonName'
    }, {
      label: '提交时间',
      width: '120',
      prop: 'createTime'
    }, {
      label: '超额分配',
      width: '120',
      prop: 'isOverValue'
    }, {
      label: '点评状态',
      width: '100',
      prop: 'reviewStatusText'
    }, {
      label: '点评时间',
      width: '120',
      prop: 'reviewTime'
    }, {
      label: '作业',
      width: '80'
    }, {
      label: '操作',
      width: '120',
      fix: 'right'
    }]
  },
  packageClass: {
    name: 'packageClass',
    columns: [{
      label: '班级ID',
      width: '60',
      prop: 'id'
    }, {
      label: '班级名称',
      width: '100',
      prop: 'name'
    }, {
      label: '包含课程',
      width: '100',
      prop: 'courseName'
    }, {
      label: '点评服务',
      width: '80',
      prop: 'hasCommentValue'
    }, {
      label: '主点评老师',
      width: '80',
      prop: 'teacherName'
    }, {
      label: '班级学员数',
      width: '80',
      prop: 'userNum'
    }, {
      label: '未点评作业数',
      width: '80',
      prop: 'noReviewNum'
    }, {
      label: '开班时间',
      width: '100',
      prop: 'startTime'
    }, {
      label: '操作',
      width: '150',
      fix: 'right'
    }]
  },
  allPackageClass: {
    name: 'allPackageClass',
    columns: [{
      label: '班级ID',
      width: '60',
      prop: 'id'
    }, {
      label: '班级名称',
      width: '100',
      prop: 'name'
    }, {
      label: '包含课程',
      width: '100',
      prop: 'courseName'
    }, {
      label: '班主任',
      width: '100',
      prop: 'assistantName'
    }, {
      label: '手机号',
      width: '100',
      prop: 'phone'
    }, {
      label: '点评服务',
      width: '80',
      prop: 'hasCommentValue'
    }, {
      label: '主点评老师',
      width: '80',
      prop: 'teacherName'
    }, {
      label: '班级学员数',
      width: '80',
      prop: 'userNum'
    }, {
      label: '未点评作业数',
      width: '80',
      prop: 'noReviewNum'
    }, {
      label: '开班时间',
      width: '100',
      prop: 'startTime'
    }, {
      label: '操作',
      width: '150',
      fix: 'right'
    }]
  }
};
/**
 * 点评状态
 * @type {*[]}
 */

exports.initData = initData;
var reviewStatusArr = [{
  value: 0,
  label: '未点评'
}, {
  value: 1,
  label: '已点评'
}];
/**
 * 是否有班级
 * @type {*[]}
 */

exports.reviewStatusArr = reviewStatusArr;
var hasClassArr = [{
  value: 0,
  label: '否'
}, {
  value: 1,
  label: '是'
}];
exports.hasClassArr = hasClassArr;