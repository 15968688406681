"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _class = require("./class");

var _bean = _interopRequireDefault(require("@/bean"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _UserDialog = _interopRequireDefault(require("@/components/Dialog/UserDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var selectHomeworkList = [];
var _default = {
  name: 'HomeworkList',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default,
    UserDialog: _UserDialog.default
  },
  data: function data() {
    return {
      createTime: '',
      tableData: null,
      listLoading: true,
      total: 0,
      initClassData: _class.initData,
      queryShowMore: false,
      columns: [],
      listQuery: {},
      hasClassArr: _class.hasClassArr,
      reviewStatusArr: _class.reviewStatusArr
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        if (route.name === 'HomeworkList' || route.name === 'PackageHomeworkList') {
          this.columns = _class.initData.homework.columns;
        } else if (route.name === 'AllHomeworkList') {
          this.columns = _class.initData.allHomework.columns;
        } else if (route.name === 'MyHomeworkList') {
          this.columns = _class.initData.myHomework.columns;
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getHomeworkList(this.listQuery).then(function (response) {
        var _response$data = response.data,
            infos = _response$data.infos,
            totalNum = _response$data.totalNum;
        _this.tableData = (infos || []).map(function (item) {
          return _bean.default.homeWorkBean(item);
        });
        _this.total = totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.listQuery.start_time = new Date(val[0]).getTime() / 1000;
        this.listQuery.end_time = new Date(val[1]).getTime() / 1000;
      } else {
        this.listQuery.start_time = '';
        this.listQuery.end_time = '';
      }
    },
    pageNoChange: function pageNoChange(val) {
      this.listQuery.page = val;
      this.fetchData();
    },
    reset: function reset() {
      var _this$$route = this.$route,
          params = _this$$route.params,
          name = _this$$route.name;
      var uid = params.uid,
          classId = params.classId,
          courseId = params.courseId,
          teacherId = params.teacherId,
          assistantId = params.assistantId;
      this.listQuery = {
        page: 1,
        page_size: 10,
        uid: uid,
        course_id: courseId,
        review_status: '',
        have_class: '',
        class_name: '',
        uname: '',
        start_time: '',
        end_time: '',
        teacher_id: teacherId,
        assistant_id: assistantId,
        point_scene: name === 'MyHomeworkList' ? 'my' : ''
      };

      if (name === 'PackageHomeworkList') {
        this.listQuery.packageclass_id = classId > 0 ? classId : '';
      } else {
        this.listQuery.class_id = classId > 0 ? classId : '';
      }

      this.createTime = '';
      this.fetchData();
    },
    jumpPage: function jumpPage(item, pageName) {
      this.$router.push({
        name: pageName,
        params: {
          classId: 0,
          wordId: item.id
        }
      });
    },
    doTap: function doTap(row) {
      var url = process.env.VUE_APP_REPORT_URL_H5;
      url = url.replace('UID', row.uid);
      url = url.replace('SECTIONID', row.sectionId);
      url = url.replace('LESSONID', row.lessonId);
      window.open(url);
    },
    onSubmit: function onSubmit() {
      this.listQuery.page = 1;
      this.fetchData();
    },
    exportData: function exportData() {
      _api.default.exportHomeworkList(this.listQuery).then(function (response) {
        window.location.href = response.data.url;
      });
    },
    selectionChange: function selectionChange(val) {
      selectHomeworkList = val;
    },
    showDialog: function showDialog() {
      if (!selectHomeworkList.length) {
        this.$message.warning('请选择要分配的作业~');
        return;
      }

      this.$refs.teacherDialog.showDialog();
    },
    confirm: function confirm(item) {
      var _this2 = this;

      var ids = [];
      selectHomeworkList.forEach(function (item) {
        ids.push({
          id: item.id,
          type: 1
        });
      });

      _api.default.dispachTeacherToHomework({
        teacher_id: item.backUserId,
        homework_info: ids
      }).then(function () {
        _this2.$message.success('操作成功~');

        selectHomeworkList = [];

        _this2.fetchData();
      });
    }
  }
};
exports.default = _default;