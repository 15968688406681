"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var smartPenRouter = {
  path: '/smartPen',
  component: _layout.default,
  redirect: '/smartPen/userManager/userList',
  name: 'SmartPen',
  meta: {
    title: '智能笔'
  },
  children: [{
    path: 'userManager',
    name: 'UserManager',
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/userManager'));
      });
    },
    meta: {
      title: '人员管理'
    },
    children: [{
      path: 'userList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/userManager/UserList.vue'));
        });
      },
      name: 'UserList',
      meta: {
        title: '用户列表'
      }
    }, {
      path: 'userCourse/:uid',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/userManager/UserCourse.vue'));
        });
      },
      name: 'UserCourse',
      hidden: true,
      meta: {
        title: '已购课程'
      }
    }, {
      path: 'assistantList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/userManager/AssistantList.vue'));
        });
      },
      name: 'AssistantList',
      meta: {
        title: '班主任列表'
      }
    }, {
      path: 'teacherList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/userManager/TeacherList.vue'));
        });
      },
      name: 'TeacherList',
      meta: {
        title: '点评师列表'
      }
    }]
  }, {
    path: 'courseManager',
    name: 'CourseManager',
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/index'));
      });
    },
    meta: {
      title: '课程管理'
    },
    children: [{
      path: 'courseList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/CourseList.vue'));
        });
      },
      name: 'SmartPenCourseList',
      meta: {
        title: '课程列表'
      }
    }, {
      path: 'sectionList/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/SectionList.vue'));
        });
      },
      name: 'SectionList',
      hidden: true,
      meta: {
        title: '章节列表'
      }
    }, {
      path: 'lessonList/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/LessonList.vue'));
        });
      },
      name: 'LessonList',
      hidden: true,
      meta: {
        title: '课时列表'
      }
    }, {
      path: 'handleLesson/:sectionId/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/handleLesson.vue'));
        });
      },
      name: 'handleLesson',
      hidden: true,
      meta: {
        title: '操作课时'
      }
    }, {
      path: 'coursewareEdit/:type/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/CoursewareEdit.vue'));
        });
      },
      name: 'CoursewareEdit',
      hidden: true,
      meta: {
        title: '课件编辑'
      }
    }, {
      path: 'assetsList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/vodCourseManager/CoursewareList.vue'));
        });
      },
      name: 'AssetsList',
      meta: {
        title: '资源库'
      }
    }, {
      path: 'typeSetting/:type/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/TypeSetting.vue'));
        });
      },
      name: 'TypeSetting',
      hidden: true,
      meta: {
        title: '点阵纸排版'
      }
    }, {
      path: 'classList/:courseId/:teacherId/:assistantId',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/ClassList.vue'));
        });
      },
      name: 'ClassList',
      hidden: true,
      meta: {
        title: '班级列表'
      }
    }, {
      path: 'studentList/:classId/:courseId',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/StudentList.vue'));
        });
      },
      name: 'StudentList',
      hidden: true,
      meta: {
        title: '学员列表'
      }
    }, {
      path: 'homeworkList/:classId/:uid/:courseId/:teacherId/:assistantId',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/HomeworkList.vue'));
        });
      },
      name: 'HomeworkList',
      hidden: true,
      meta: {
        title: '作业列表'
      }
    } // {
    //     path: 'commentWork/:classId/:wordId',
    //     component: () => import('@/views/smartPen/courseManager/CommentWork.vue'),
    //     name: 'CommentWork',
    //     hidden: true,
    //     meta: {
    //         title: '点评'
    //     }
    // }
    ]
  }, {
    path: 'assistantCenter',
    name: 'AssistantCenter',
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/assistantCenter/index'));
      });
    },
    meta: {
      title: '班主任中心'
    },
    children: [{
      path: 'allPackageClassList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/assistantCenter/PackageClassList.vue'));
        });
      },
      name: 'AllPackageClassList',
      meta: {
        title: '课程包班级管理'
      }
    }, {
      path: 'packageClassList/:courseId/:teacherId/:assistantId',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/assistantCenter/PackageClassList.vue'));
        });
      },
      name: 'PackageClassList',
      hidden: true,
      meta: {
        title: '课程包班级管理'
      }
    }, {
      path: 'allHomeworkList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/HomeworkList.vue'));
        });
      },
      name: 'AllHomeworkList',
      meta: {
        title: '作业列表'
      }
    }, {
      path: 'packageHomeworkList/:classId/:uid',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/HomeworkList.vue'));
        });
      },
      name: 'PackageHomeworkList',
      hidden: true,
      meta: {
        title: '作业列表'
      }
    }, {
      path: 'packageStudentList/:classId',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/assistantCenter/PackageStudentList.vue'));
        });
      },
      name: 'PackageStudentList',
      hidden: true,
      meta: {
        title: '课程包班级学员列表'
      }
    }, {
      path: 'myPackageStudentList/:classId',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/assistantCenter/PackageStudentList.vue'));
        });
      },
      name: 'MyPackageStudentList',
      hidden: true,
      meta: {
        title: '课程包班级学员列表'
      }
    }]
  }, {
    path: 'teacherCenter',
    name: 'TeacherCenter',
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/index'));
      });
    },
    meta: {
      title: '点评师中心'
    },
    children: [{
      path: 'myHomeworkList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/HomeworkList.vue'));
        });
      },
      name: 'MyHomeworkList',
      meta: {
        title: '我的作业'
      }
    }, {
      path: 'commentWork/:classId/:wordId',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/CommentWork.vue'));
        });
      },
      name: 'CommentWork',
      hidden: true,
      meta: {
        title: '点评'
      }
    }]
  }, {
    path: 'funManager',
    name: 'FunManager',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/funManager/index'));
      });
    },
    meta: {
      title: '功能列表'
    },
    children: [{
      path: 'feedbackList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/funManager/FeedbackList.vue'));
        });
      },
      name: 'FeedbackList',
      meta: {
        title: '反馈列表'
      }
    }, {
      path: 'versionList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/funManager/VersionList.vue'));
        });
      },
      name: 'VersionList',
      meta: {
        title: '版本号列表'
      }
    },
    /* {
        path: 'myClassList',
        component: () => import('@/views/smartPen/courseManager/ClassList.vue'),
        name: 'MyClassList',
        meta: { title: '我的班级' }
    }, */
    {
      path: 'myStudentList/:classId/:courseId',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/courseManager/StudentList.vue'));
        });
      },
      name: 'MyStudentList',
      hidden: true,
      meta: {
        title: '学员列表'
      }
    }, {
      path: 'realTimeWordData',
      name: 'RealTimeWordData',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/funManager/RealTimeWordData.vue'));
        });
      },
      meta: {
        title: '实时数据',
        noAuth: true
      }
    }]
  }, {
    path: 'ShipmentReview',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/smartPen/ShipmentReview.vue'));
      });
    },
    name: 'ShipmentReview',
    meta: {
      title: '发货审核'
    }
  }]
};
var _default = smartPenRouter;
exports.default = _default;