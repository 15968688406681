"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.regexp.to-string");

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _initData = require("../initData");

var _type = require("./type");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TypeSetting',
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      tableData: null,
      visibleModal: false,
      listLoading: true,
      total: 0,
      initSectionData: _initData.initData,
      funcType1: _initData.funcType,
      funcCategory1: [],
      dialogTitle: '新建点阵纸',
      moduleUrl: '//xsjy-1254153797.cos.ap-shanghai.myqcloud.com/smartpen/courseware/pc/2020/09/17/%E7%82%B9%E9%98%B5%E7%BA%B8.xlsx',
      ruleForm: {
        axis_code: '',
        type: '',
        category_id: '',
        lesson_id: 0,
        section_id: 0,
        sort: 0
      },
      rules: {
        axis_code: [{
          required: true,
          message: '请输入版编码',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择功能',
          trigger: 'change'
        }],
        category_id: [{
          required: true,
          message: '请选择教学环节分类',
          trigger: 'change'
        }]
      },
      searchParam: {
        page: 1,
        page_size: 10,
        lesson_id: 0,
        section_id: 0
      }
    };
  },
  created: function created() {
    var _this$$route$params = this.$route.params,
        type = _this$$route$params.type,
        id = _this$$route$params.id;

    if (type === 'section') {
      this.searchParam.section_id = id;
      this.ruleForm.section_id = id;
      this.funcCategory1 = _type.SECTION_CATE_ENUM;
    }

    if (type === 'lesson') {
      this.searchParam.lesson_id = id;
      this.ruleForm.lesson_id = id;
      this.funcCategory1 = _type.LESSON_CATE_ENUM;
    }

    this.getList();
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this = this;

      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (that.dialogTitle === '新建点阵纸') {
            // 新建点阵纸
            if (that.ruleForm.id) {
              delete that.searchParam.id;
            }

            _api.default.addLessonAxis(that.ruleForm).then(function (res) {
              _this.$message({
                message: res.msg,
                type: 'success'
              });

              that.getList();
              that.resetForm('ruleForm');
              that.visibleModal = false;
            });
          } else {
            // 编辑
            _api.default.updateLessonAxis(that.ruleForm).then(function (res) {
              _this.$message({
                message: res.msg,
                type: 'success'
              });

              that.getList();
              that.resetForm('ruleForm');
              that.visibleModal = false;
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getList: function getList() {
      var _this2 = this;

      _api.default.getLessonAxis(this.searchParam).then(function (res) {
        _this2.listLoading = false;
        _this2.tableData = res.data.infos;
        _this2.total = res.data.totalNum;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.getList();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    showDialog: function showDialog() {
      this.visibleModal = true;
      this.dialogTitle = '新建点阵纸';
    },
    editDialog: function editDialog(row) {
      this.ruleForm.id = row.id;
      this.ruleForm.axis_code = row.axis_code;
      this.ruleForm.type = row.type;
      this.ruleForm.category_id = row.category_id.toString();
      this.ruleForm.lesson_id = row.lesson_id;
      this.ruleForm.section_id = row.section_id;
      this.visibleModal = true;
      this.dialogTitle = '编辑点阵纸';
    },
    deleteItem: function deleteItem(row) {
      var _this3 = this;

      _api.default.deleteLessonAxis({
        id: row.id
      }).then(function (res) {
        _this3.$message.success('操作成功~');
      });
    },
    uploadRequest: function uploadRequest(params) {
      var _this4 = this;

      var _formData = new FormData();

      _formData.append('excel_file', params.file);

      _api.default.importLessonAxis(_formData).then(function () {
        _this4.$message.success('操作成功~');

        _this4.reset();
      });
    },
    onInputBlur: function onInputBlur(row, column) {
      var _this5 = this;

      var num = row[column.property];

      if (!num || num < 0) {
        this.$message({
          type: 'warning',
          message: '输入的值有误~'
        });
        return;
      }

      _api.default.updateLessonAxis({
        id: row.id,
        sort: row.sort
      }).then(function () {
        _this5.$message({
          type: 'success',
          message: '操作成功~'
        });

        _this5.fetchData();
      });
    }
  }
};
exports.default = _default;