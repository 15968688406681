"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _PreviewDialog = _interopRequireDefault(require("../../../../components/Dialog/PreviewDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'UploadItem',
  components: {
    PreviewDialog: _PreviewDialog.default
  },
  props: {
    itemInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    type: {
      type: String,
      default: ''
    },
    currentIndex: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    handleRemove: function handleRemove() {
      this.$emit('handleRemove', this.currentIndex);
    },
    handlePreview: function handlePreview() {
      this.$refs.previewDialog.showDialog(this.itemInfo);
    }
  }
};
exports.default = _default2;