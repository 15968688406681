"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.function.name");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.constructor");

var _type = require("../UploadPanel/type");

var _api = _interopRequireDefault(require("@/api"));

var _cosJsSdkV = _interopRequireDefault(require("cos-js-sdk-v5"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Index',
  props: {
    limit: {
      type: Number,
      default: 1
    },
    listType: {
      type: String,
      default: 'picture-card'
    },
    fileList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    lineType: {
      type: String,
      default: '',
      validator: function validator(value) {
        return [_type.PRODUCT_ENUM.TRAINING, _type.PRODUCT_ENUM.SMART_PEN, _type.PRODUCT_ENUM.TABLET, _type.PRODUCT_ENUM.EDU].includes(value);
      }
    }
  },
  data: function data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      cosOptions: {
        product: '',
        features: 'asset',
        terminal: 'pc',
        bucketName: ''
      }
    };
  },
  methods: {
    handleChange: function handleChange() {
      this.$refs.upload.clearFiles();
    },
    uploadRequest: function uploadRequest(params) {
      var _this = this;

      var file = params.file;
      this.cosOptions.product = this.lineType;
      this.cosOptions.bucketName = this.lineType === _type.PRODUCT_ENUM.TABLET ? _type.BUCKET_NAME_ENUM.TABLET : _type.BUCKET_NAME_ENUM.EDU;
      var cosOptions = this.cosOptions;

      _api.default.getTcCOSSignature(cosOptions).then(function (res) {
        var credentials = res.data;
        var cos = new _cosJsSdkV.default({
          getAuthorization: function getAuthorization(options, callback) {
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.token,
              StartTime: credentials.startTime,
              ExpiredTime: credentials.expiredTime,
              ScopeLimit: true // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用

            });
          }
        });
        cos.putObject({
          Bucket: cosOptions.bucketName,
          Region: 'ap-shanghai',
          Key: "".concat(credentials.pathKey, "/").concat(file.name),
          StorageClass: 'STANDARD',
          Body: file // 上传文件对象

        }, function (err, data) {
          if (data.statusCode !== 200) {
            _this.$message.error(err.message || '上传失败，请稍后重试');

            return;
          }

          _this.fileList.push({
            name: file.name,
            url: "https://".concat(data.Location)
          });

          _this.$emit('handleResult', _this.fileList);
        });
      });
    },
    _deleteFile: function _deleteFile(file) {
      var idx = this.fileList.findIndex(function (item) {
        return item.name === file.name;
      });

      if (idx >= 0) {
        this.fileList.splice(idx, 1);
      }
    },
    handleRemove: function handleRemove(file) {
      this._deleteFile(file);

      this.$emit('handleResult', this.fileList);
    },
    handlePreview: function handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E9 ".concat(this.limit, " \u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ").concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(files.length + fileList.length, " \u4E2A\u6587\u4EF6"));
    }
  }
};
exports.default = _default2;