"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PLATFORM = void 0;
var PLATFORM = {
  /** 碑帖 */
  TABLET: 1,

  /** 教培 */
  TRAINING: 2,

  /** 乐写字 */
  SMART_PEN: 3,

  /** 在线教育 */
  EDU: 4
};
exports.PLATFORM = PLATFORM;