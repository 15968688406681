"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _EditQrCodeDialog = _interopRequireDefault(require("./components/EditQrCodeDialog"));

var _ShiftDialog = _interopRequireDefault(require("./components/ShiftDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'QrCode',
  components: {
    PageTable: _PageTable.default,
    EditQrCodeDialog: _EditQrCodeDialog.default,
    ShiftDialog: _ShiftDialog.default
  },
  data: function data() {
    return {
      searchParam: {
        page: 1,
        page_size: 20
      },
      tableData: [],
      immutableTableData: [],
      yesterdayAll: 0,
      todayAll: 0,
      columns: [{
        label: 'ID',
        width: '80',
        prop: 'id'
      }, {
        label: '二维码',
        width: '120',
        prop: 'qrCodeUrl'
      }, {
        label: '微信昵称',
        width: '120',
        prop: 'weChatName'
      }, {
        label: '分类',
        width: '80',
        prop: 'lineType'
      }, {
        label: '销售人',
        width: '120',
        prop: 'saleManName'
      }, {
        label: '今日量',
        width: '80',
        prop: 'todayNum'
      }, {
        label: '单日上限',
        width: '100',
        prop: 'topLimit'
      }, {
        label: '是否开启',
        width: '100',
        prop: 'offName'
      }, {
        label: '是否添加至多客服',
        width: '100',
        prop: 'isDeleteName'
      }, {
        label: '总数',
        width: '80',
        prop: 'totalNum'
      }, {
        label: '微信类型',
        width: '80',
        prop: 'weChatTypeName'
      }, {
        label: '绑定微信号',
        width: '120',
        prop: 'weChatID'
      }, {
        label: '销售UID',
        width: '100',
        prop: 'uid'
      }, {
        label: '操作',
        width: '240',
        fix: 'right'
      }],
      listLoading: false,
      total: 0
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getSaleQrCodeList(this.searchParam).then(function (response) {
        _this.tableData = response.data.infos.map(function (item) {
          return _bean.default.qrCodeBean(item);
        });
        _this.immutableTableData = JSON.stringify(_this.tableData);
        _this.total = response.data.totalNum;
        _this.yesterdayAll = response.data.yesterdayAll || 0;
        _this.todayAll = response.data.todayAll || 0;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = [];
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      if (!this.$route.params.id) {
        this.$message({
          type: 'error',
          message: '网络异常，请稍后重试（msg：missing necessary parameters）'
        });
        return;
      }

      this.searchParam = {
        saleman_id: this.$route.params.id,
        page: 1,
        page_size: 20
      };
      this.fetchData();
    },
    showDialog: function showDialog(ref, item) {
      this.$refs[ref].showDialog(item);
    },
    doOption: function doOption(item, type) {
      var _this2 = this;

      var params = {
        id: item.id
      };

      if (type === 'toplimit') {
        var num = item.topLimit;

        if (num < 0) {
          this.$message({
            type: 'warning',
            message: '输入的值有误~'
          });
          return;
        }

        params.toplimit = item.topLimit;
        var list = JSON.parse(this.immutableTableData);
        var currentItem = list.filter(function (i) {
          return i.id === item.id;
        });

        if (currentItem[0].topLimit === item.topLimit) {
          return;
        }
      } else if (type === 'add') {
        params.is_delete = item.isDelete ? 0 : 1;
      } else if (type === 'status') {
        params.off = item.off ? 0 : 1;
      }

      _api.default.editSaleQrCode(params).then(function () {
        _this2.$message({
          type: 'success',
          message: '操作成功~'
        });

        _this2.fetchData();
      });
    }
  }
};
exports.default = _default;