"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _bean = _interopRequireDefault(require("@/bean"));

var _vue = _interopRequireDefault(require("vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
_vue.default.filter('checkType', function (value) {
  var data = {
    1: '定时推送',
    2: '点评推送'
  };
  return data[value];
});

var _default = {
  name: 'PushHistory',
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      listLoading: false,
      searchParam: {
        page: 1,
        per_page: 20
      },
      tableData: null,
      columns: [{
        label: '用户ID',
        width: '60',
        prop: 'uid'
      }, {
        label: '用户名',
        width: '60',
        prop: 'userName'
      }, {
        label: '推送类型',
        width: '60',
        prop: 'tid'
      }, {
        label: '课程名称',
        width: '120',
        prop: 'courseName'
      }, {
        label: '章节名称',
        width: '120',
        prop: 'lessonName'
      }, {
        label: '创建时间',
        width: '120',
        prop: 'createTime'
      }],
      total: 0
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getPushHistory(this.searchParam).then(function (response) {
        _this.tableData = response.data.data.map(function (item) {
          return _bean.default.pushRecordBean(item);
        });
        _this.total = response.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam = {
        page: val,
        per_page: 20
      };
      this.fetchData();
    }
  }
};
exports.default = _default;