"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'StudyPlan',
  data: function data() {
    return {
      total: 0,
      listLoading: false,
      postDateConfig: [],
      dynamicTags: ['以上课时时间配置为初始化配置。请修改'],
      tableData: [],
      studyConfigType: '',
      // 学习计划状态 init 为初始化配置 显示提示tag
      searchParam: {
        page: 1,
        per_page: 20,
        vid: ''
      }
    };
  },
  created: function created() {
    this.fetchDataDemo();
  },
  methods: {
    fetchDataDemo: function fetchDataDemo() {
      var _this = this;

      this.listLoading = true;
      var postData = {
        class_id: this.$route.params.id
      };

      _api.default.getOnlineClassStudyPlan(postData).then(function (response) {
        var tableArray = [];
        _this.studyConfigType = response.data.data_type; // 判断tag 状态，为init 则显示tag提示

        response.data.models.map(function (item) {
          return item.sections.map(function (i) {
            return tableArray.push(i);
          });
        });
        _this.tableData = tableArray.map(function (item) {
          return _bean.default.onlinePlanBean(item);
        });
        console.log(_this.tableData);
        _this.total = response.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleClose: function handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    dateChange: function dateChange(outidx, row, inneridx) {
      this.postDateConfig.push({
        classId: this.$route.params.id,
        courseId: row.old_id,
        date: (0, _utils.parseTime)(row.date)
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      var childList = [];
      var params = [];
      this.tableData.forEach(function (item) {
        childList = childList.concat(item.child);
      });
      childList.forEach(function (item) {
        params.push({
          classId: _this2.$route.params.id,
          courseId: item.old_id,
          date: (0, _utils.parseTime)(item.date)
        });
      }); // 提交修改

      this.listLoading = true;

      _api.default.updateOnlineClassStudyPlan(params).then(function (response) {
        _this2.$message.success('更新成功~');

        _this2.listLoading = false;
        _this2.postDateConfig = [];

        _this2.$router.back(-1);
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    fetchData: function fetchData() {
      var _this3 = this;

      this.listLoading = true;

      _api.default.getLessonList(this.searchParam).then(function (response) {
        _this3.tableData = response.data.data.map(function (item) {
          return _bean.default.onlineCourseBean(item);
        });
        _this3.total = response.data.total;
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    handlePageNoChange: function handlePageNoChange(val) {
      this.searchParam.page = val; // this.fetchData();
    },
    reset: function reset() {
      var id = this.$route.params.id;
      this.searchParam = {
        page: 1,
        per_page: 20,
        vid: id
      }; // this.fetchData();
    },
    changeRow: function changeRow(pIndex, sRow, sIndex) {
      if (sRow.day <= 0) {
        this.$message.warning('输入值必须大于0哦~');
        sRow.day = '';
        return;
      }

      var tableData = this.tableData;
      var pRow = tableData[pIndex];

      if (sIndex > 0) {
        var prevDay = pRow.child[sIndex - 1].day;

        if (prevDay !== '' && Number(sRow.day) < Number(prevDay)) {
          this.$message.warning('请输入上个课时更大的数字哦~');
          sRow.day = '';
        }
      } else if (pIndex > 0) {
        var prevItem = tableData[pIndex - 1].child;
        var _prevDay = prevItem[prevItem.length - 1].day;

        if (_prevDay !== '' && Number(sRow.day) < Number(_prevDay)) {
          this.$message.warning('请输入上个课时更大的数字哦~');
          sRow.day = '';
        }
      }

      if (sIndex < pRow.child.length - 1) {
        var nextDay = pRow.child[sIndex + 1].day;

        if (nextDay !== '' && Number(sRow.day) >= Number(nextDay)) {
          this.$message.warning('请输入下个课时更小的数字哦~');
          sRow.day = '';
        }
      } else if (pIndex < tableData.length - 1) {
        var nextItem = tableData[pIndex + 1].child;
        var _nextDay = nextItem[0].day;

        if (_nextDay !== '' && Number(sRow.day) >= Number(_nextDay)) {
          this.$message.warning('请输入下个课时更小的数字哦~');
          sRow.day = '';
        }
      }
    }
  }
};
exports.default = _default;