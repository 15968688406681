"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _api = _interopRequireDefault(require("@/api"));

var _UploadCos = _interopRequireDefault(require("@/components/UploadCos"));

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _initData = require("./initData");

var _utils = require("@/utils");

var _CourseDialog = _interopRequireDefault(require("./components/CourseDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'HandleLive',
  components: {
    Upload: _UploadCos.default,
    Tinymce: _Tinymce.default,
    CourseDialog: _CourseDialog.default
  },
  data: function data() {
    return {
      formData: {},
      rules: {
        title: [{
          required: true,
          message: '请输入直播名称',
          trigger: 'blur'
        }],
        category: [{
          required: true,
          message: '请选择直播分类',
          trigger: 'change'
        }],
        teacher_id: [{
          required: true,
          message: '请选择直播讲师',
          trigger: 'change'
        }],
        image: [{
          required: true,
          message: '请上传图片',
          trigger: 'change'
        }],
        qrcode: [{
          required: true,
          message: '请上传二维码',
          trigger: 'change'
        }],
        liveTime: [{
          required: true,
          message: '请选择直播时间',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '请输入价格',
          trigger: 'blur'
        }],
        reserve_num: [{
          required: true,
          message: '请输入初始预约人数',
          trigger: 'blur'
        }],
        video_title: [{
          required: true,
          message: '请选择关联课程',
          trigger: 'change'
        }],
        details: [{
          required: true,
          message: '请输入正文',
          trigger: 'blur'
        }]
      },
      teacherList: [],
      recommendOption: _initData.recommendOption,
      replayOption: _initData.replayOption,
      classifyOption: _initData.classifyOption,
      qrCodeList: [],
      imageList: [],
      loading: false
    };
  },
  created: function created() {
    this.initData();
  },
  activated: function activated() {
    this.resetForm();
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;

      _api.default.getAdultTeacherList().then(function (res) {
        _this.teacherList = res.data;
      });

      var params = this.$route.params;

      if (params.id && params.id > 0) {
        this.formData.id = params.id;
        this.getDetail();
      }
    },
    getDetail: function getDetail() {
      var _this2 = this;

      _api.default.getAdultLiveDetail({
        id: this.formData.id
      }).then(function (res) {
        console.log(res);
        var data = res.data;
        _this2.formData.title = data.title;
        _this2.formData.category = data.category || '';
        _this2.formData.teacher_id = data.teacher_id;
        _this2.formData.image = data.image;
        _this2.imageList = [{
          name: data.image.substring(data.image.lastIndexOf('/') + 1),
          url: data.image
        }];
        _this2.formData.qrcode = data.qrcode;
        _this2.qrCodeList = [];
        _this2.qrCodeList = [{
          name: data.qrcode.substring(data.qrcode.lastIndexOf('/') + 1),
          url: data.qrcode
        }];
        _this2.formData.liveTime = [data.start_time, data.end_time];
        _this2.formData.start_time = data.start_time;
        _this2.formData.end_time = data.end_time;
        _this2.formData.price = data.price;
        _this2.formData.reserve_num = data.reserve_num;
        _this2.formData.video_title = data.video_title;
        _this2.formData.video_id = data.video_id;
        _this2.formData.replay = data.replay;
        _this2.formData.recommend = data.recommend;
        _this2.formData.details = data.details;
      });
    },
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.formData.start_time = (0, _utils.parseTime)(val[0]);
        this.formData.end_time = (0, _utils.parseTime)(val[1]);
      } else {
        this.formData.start_time = '';
        this.formData.end_time = '';
      }
    },
    showDialog: function showDialog() {
      this.$refs.courseDialog.showDialog();
    },
    courseConfirm: function courseConfirm(data) {
      this.formData.video_id = data.id;
      this.formData.video_title = data.video_title;
    },
    cancel: function cancel() {
      this.$router.back(-1);
    },
    uploadResult: function uploadResult(data, type) {
      this.formData[type] = data[0] && data[0].url || '';
    },
    resetForm: function resetForm() {
      this.formData = {
        title: '',
        category: '',
        teacher_id: '',
        image: '',
        qrcode: '',
        liveTime: '',
        start_time: '',
        end_time: '',
        price: '',
        reserve_num: '',
        video_title: '',
        video_id: '',
        replay: 0,
        recommend: 0,
        details: ''
      };
      console.log(this.formData);
      this.qrCodeList = [];
      this.imageList = [];
      this.$refs.form.resetFields();
    },
    onSubmit: function onSubmit() {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this3.loading = true;

          _api.default.submitLiveInfo(_this3.formData).then(function (res) {
            _this3.$message.success('操作成功');

            _this3.loading = false;

            _this3.resetForm();

            setTimeout(function () {
              _this3.$router.replace({
                name: 'Live'
              });
            }, 2000);
          }).catch(function () {
            _this3.loading = false;
          });
        } else {
          console.warn('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;