"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actOptions = exports.sendOptions = exports.shopOptions = exports.orderOptions = exports.funcCategory = exports.funcType = exports.gradeList = exports.sexList = exports.learnObjList = exports.initData = void 0;
var commColumns = [{
  label: 'ID',
  width: '60',
  prop: 'id',
  hide: true
}, {
  label: '客户UID',
  width: '80',
  prop: 'uid',
  hide: false
}, {
  label: '头像',
  width: '100',
  prop: 'avatar',
  hide: false
}, {
  label: '客户昵称',
  width: '80',
  prop: 'uname',
  hide: false
}];
/* 学习对象类型 */

var learnObjList = [{
  value: 'adult',
  label: '成人'
}, {
  value: 'junior',
  label: '初中学生'
}, {
  value: 'topclass',
  label: '大班'
}, {
  value: 'gradeone',
  label: '一年级'
}, {
  value: 'gradetwo',
  label: '二年级'
}, {
  value: 'gradethree',
  label: '三年级'
}, {
  value: 'gradefour',
  label: '四年级及以上'
}, {
  value: 'senior',
  label: '高中学生'
}];
/* 性别 */

exports.learnObjList = learnObjList;
var sexList = [{
  value: '1',
  label: '小王子'
}, {
  value: '2',
  label: '小公主'
}]; // 年级

exports.sexList = sexList;
var gradeList = [{
  value: 'gradeone',
  label: '一年级'
}, {
  value: 'gradetwo',
  label: '二年级'
}, {
  value: 'gradethree',
  label: '三年级'
}, {
  value: 'gradefour',
  label: '四年级'
}, {
  value: 'gradefive',
  label: '五年级'
}, {
  value: 'gradesix',
  label: '六年级'
}];
exports.gradeList = gradeList;
var funcType = [{
  value: '1',
  label: '按钮(视频)'
}, {
  value: '2',
  label: '按钮(要点)'
}, {
  value: '3',
  label: '练字(描红)'
}, {
  value: '4',
  label: '练字(常规字)'
}, {
  value: '5',
  label: '练字(跟练)'
}, {
  value: '6',
  label: '练字(挑战赛)'
}, {
  value: '7',
  label: '练字(控笔)'
}];
exports.funcType = funcType;
var funcCategory = [{
  value: '1',
  label: '视频课堂'
}, {
  value: '2',
  label: '课堂测验'
}, {
  value: '3',
  label: '课后练习'
}, {
  value: '4',
  label: '小测验'
}, {
  value: '5',
  label: '练字挑战'
}];
exports.funcCategory = funcCategory;
var initData = {
  userlist: {
    name: 'userlist',
    columns: [].concat(commColumns, [{
      label: '性别',
      width: '80',
      prop: 'sexText'
    }, {
      label: '生日',
      width: '100',
      prop: 'birthday'
    }, {
      label: '年级',
      width: '100',
      prop: 'gradeValue'
    }, {
      label: '手机号',
      width: '100',
      prop: 'phone'
    }, {
      label: '地区',
      width: '120',
      prop: 'areaStr'
    }, {
      label: '创建时间',
      width: '120',
      prop: 'created_at'
    }, {
      label: '是否添加微信',
      width: '120',
      prop: 'wechat_added'
    }, {
      label: '课程数',
      width: '80',
      prop: 'courseNum'
    }, {
      label: '操作',
      width: '100',
      fix: 'right'
    }])
  },
  userCourse: {
    columns: [{
      label: 'ID',
      width: '60',
      prop: 'id'
    }, {
      label: '课程ID',
      width: '60',
      prop: 'course_id'
    }, {
      label: '课程名称',
      width: '100',
      prop: 'courseTitle'
    }, {
      label: '开通时间',
      width: '120',
      prop: 'created_at'
    }, {
      label: '开通渠道',
      width: '60',
      prop: 'channel'
    }]
  },
  courseList: {
    columns: [{
      label: '课程ID',
      width: '60',
      prop: 'course_id'
    }, {
      label: '课程名',
      width: '100',
      prop: 'video_title'
    }, {
      label: '课程封面',
      width: '100',
      prop: 'cover'
    }, {
      label: '用户数',
      width: '80',
      prop: 'userNum'
    }, {
      label: '关联时间',
      width: '120',
      prop: 'created_at'
    }, {
      label: '操作',
      width: '100',
      fix: 'right'
    }]
  },
  sectionList: {
    columns: [{
      label: '章节ID',
      width: '60',
      prop: 'id'
    }, {
      label: '章节名称',
      width: '80',
      prop: 'name'
    }, {
      label: '挑战赛时长(秒)',
      width: '60',
      prop: 'game_time'
    }, {
      label: '关联时间',
      width: '120',
      prop: 'created_at'
    }, {
      label: '排序',
      width: '60',
      prop: 'sort'
    }, {
      label: '是否上架',
      width: '60',
      prop: 'status'
    }, {
      label: '操作',
      width: '140',
      fix: 'right'
    }]
  },
  typeSetting: {
    columns: [{
      label: 'ID',
      width: '60',
      prop: 'id'
    }, {
      label: '编码',
      width: '80',
      prop: 'axis_code'
    }, {
      label: '功能',
      width: '100',
      prop: 'typeValue'
    }, {
      label: '排序',
      width: '80',
      prop: 'sort'
    }, {
      label: '操作',
      width: '200',
      fix: 'right'
    }]
  },
  lessonList: {
    columns: [{
      label: '课时ID',
      width: '80',
      prop: 'id'
    }, {
      label: '课时名称',
      width: '80',
      prop: 'name'
    }, {
      label: '课时类型',
      width: '80',
      prop: 'typeValue'
    }, {
      label: '练字时长',
      width: '80',
      prop: 'length'
    }, {
      label: '编码',
      width: '60',
      prop: 'page_code'
    }, {
      label: '映射编码',
      width: '80',
      prop: 'map_code'
    }, {
      label: '单字视频',
      width: '80',
      prop: 'videoFileid'
    }, {
      label: '书写要点',
      width: '80',
      prop: 'attentionResourceUrl'
    }, {
      label: '单字图',
      width: '80',
      prop: 'wordResourceUrl'
    }, {
      label: '笔顺图',
      width: '80',
      prop: 'strokeResourceUrl'
    }, {
      label: '排序',
      width: '80',
      prop: 'sort'
    }, {
      label: 'GIF图',
      width: '100',
      prop: 'gifResourceUrl'
    }, {
      label: '操作',
      width: '250',
      fix: 'right'
    }]
  },
  versionList: {
    columns: [{
      label: 'id',
      width: '30',
      prop: 'id'
    }, {
      label: '版本代码',
      width: '50',
      prop: 'code'
    }, {
      label: '版本号',
      width: '50',
      prop: 'version_number'
    }, {
      label: '下载URL',
      width: '120',
      prop: 'url'
    }, {
      label: '版本介绍',
      width: '60',
      prop: 'description'
    }, {
      label: '更新机制',
      width: '60',
      prop: 'forceUpdateValue'
    }, {
      label: '是否为稳定版本',
      width: '80',
      prop: 'isCurrentValue'
    }, {
      label: '创建时间',
      width: '90',
      prop: 'created_at'
    }, {
      label: '操作',
      width: '60',
      fix: 'right'
    }]
  },
  freebackList: {
    columns: [{
      label: 'id',
      width: '40',
      prop: 'id'
    }, {
      label: '时间',
      width: '80',
      prop: 'created_at'
    }, {
      label: 'UID/反馈用户',
      width: '80',
      prop: 'version_number'
    }, {
      label: '联系方式',
      width: '80',
      prop: 'contact'
    }, {
      label: '反馈内容',
      width: '100',
      prop: 'content'
    }, {
      label: '截图',
      width: '140',
      prop: 'images'
    }]
  },
  shipReviewList: {
    columns: [{
      label: '订单编号',
      width: '80',
      prop: 'id',
      hide: false
    }, {
      label: '中台订单号',
      width: '100',
      prop: 'shoporder',
      hide: false
    }, {
      label: '创建时间',
      width: '160',
      prop: 'created_at',
      hide: false
    }, {
      label: '用户ID',
      width: '80',
      prop: 'uid',
      hide: false
    }, {
      label: '用户名称',
      width: '100',
      prop: 'uname',
      hide: false
    }, {
      label: '销售ID',
      width: '100',
      prop: 'sale_uid',
      hide: false
    }, {
      label: '销售',
      width: '100',
      prop: 'sale_uid_uname',
      hide: false
    }, {
      label: '手机号(账号绑定)',
      width: '120',
      prop: 'phone',
      hide: false
    }, {
      label: '手机号',
      width: '120',
      prop: 'infophone',
      hide: false
    }, {
      label: '课程/课程包',
      width: '100',
      prop: 'title',
      hide: false
    }, {
      label: '订单金额',
      width: '80',
      prop: 'totalprice',
      hide: false
    }, {
      label: '收货地址',
      width: '160',
      prop: 'address',
      hide: false
    }, {
      label: '发货商品',
      width: '160',
      prop: 'product',
      hide: false
    }, {
      label: '订单类型',
      width: '80',
      prop: 'type',
      hide: false
    }, {
      label: '旺店通推送状态',
      width: '100',
      prop: 'wdt_state',
      hide: false
    }, {
      label: '发货状态',
      width: '80',
      prop: 'delivery_state',
      hide: false
    }, {
      label: '快递公司',
      width: '80',
      prop: 'delivery_company',
      hide: false
    }, {
      label: '快递单号',
      width: '140',
      prop: 'delivery_code',
      hide: false
    }, {
      label: '客户备注',
      width: '120',
      prop: 'remark',
      hide: false
    }, {
      label: '申请修改备注',
      width: '120',
      prop: 'act_remark',
      hide: false
    }, {
      label: '操作',
      width: '100',
      fix: 'right'
    }]
  },
  goodsInfo: {
    columns: [{
      label: '关联id',
      width: '60',
      prop: 'sm_id'
    }, {
      label: '商品id',
      width: '60',
      prop: 'pid'
    }, {
      label: '商品名称',
      width: '100',
      prop: 'title'
    }, {
      label: 'SKU',
      width: '60',
      prop: 'sku'
    }, {
      label: '数量',
      width: '60',
      prop: 'num'
    }, {
      label: '操作',
      width: '100',
      fix: 'right'
    }]
  },
  searchInfo: {
    columns: [{
      label: '关联id',
      width: '60',
      prop: 'sm_id'
    }, {
      label: '商品id',
      width: '60',
      prop: 'pid'
    }, {
      label: '商品名称',
      width: '100',
      prop: 'title'
    }, {
      label: 'SKU',
      width: '60',
      prop: 'sku'
    }, {
      label: '数量',
      width: '60',
      prop: 'num'
    }, {
      label: '操作',
      width: '100',
      fix: 'right'
    }]
  }
};
exports.initData = initData;
var orderOptions = [{
  value: 1,
  label: '课程包'
}, {
  value: 2,
  label: '课程'
}];
exports.orderOptions = orderOptions;
var shopOptions = [{
  value: 0,
  label: '-'
}, {
  value: 1,
  label: '推送成功'
}];
exports.shopOptions = shopOptions;
var sendOptions = [{
  value: 0,
  label: '未发货'
}, {
  value: 1,
  label: '已发货'
}, {
  value: 2,
  label: '已签收'
}];
exports.sendOptions = sendOptions;
var actOptions = [{
  value: 0,
  label: '全部'
}, {
  value: 1,
  label: '申请修改'
}, {
  value: 2,
  label: '未申请修改'
}];
exports.actOptions = actOptions;