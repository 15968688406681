"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var cLineWidth = 2;
var _default = {
  data: function data() {
    return {
      ctx: null,
      cWidth: 0,
      cHeight: 0,
      lastLoc: '' // 记录上一个帧数据

    };
  },
  methods: {
    initCanvas: function initCanvas() {
      var _this = this;

      this.$nextTick(function () {
        var canvas = document.getElementById('wordCanvas');
        _this.cWidth = canvas.width;
        _this.cHeight = canvas.height;
        _this.ctx = canvas.getContext('2d');
      });
    },
    moveStroke: function moveStroke(point) {
      if (this.lastLoc.id !== point.id) {
        this.ctx.beginPath();
        this.lastLoc = '';
      }

      var lineWidth = this._calcLineWidth(point.press); // 指定移动的位置


      this.ctx.lineTo(point.x, point.y); // 设置笔的粗细

      this.ctx.lineWidth = lineWidth;
      this.ctx.lineCap = 'round';
      this.ctx.lineJoin = 'round'; // 开始画线

      this.ctx.stroke();
      this.lastLoc = point;
      this.lastLoc.lineWidth = lineWidth;

      if (this.lastLoc.id !== point.id) {
        this.ctx.closePath();
      }
    },
    strToPointInfo: function strToPointInfo(obj) {
      var str = obj.pointStr;
      return str ? {
        id: str.substr(8, 2),
        gridId: str.substr(12, 16),
        press: Number(this._hex2int(str.substr(10, 2))) + Number(obj.size || 0),
        x: this._transRelativePoint(this._hex2int(this._reverse2Hex(str.substr(28, 4))), Number(obj.xOffset || 0), 'x'),
        y: this._transRelativePoint(this._hex2int(this._reverse2Hex(str.substr(32, 4))), Number(obj.yOffset || 0), 'y'),
        pointStr: str
      } : null;
    },
    clearCanvas: function clearCanvas() {
      this.ctx.clearRect(0, 0, this.cWidth, this.cHeight);
    },
    _calcLineWidth: function _calcLineWidth(val) {
      var PEN_PRESS_RELATIVE_VAL = 18;
      var WIDTH_DIFF_VAL = 0.8;
      var WIDTH_ADD_VAL = 0.2;
      var width = (val / PEN_PRESS_RELATIVE_VAL).toFixed(4);
      var currentWidth = this.lastLoc ? this.lastLoc.lineWidth : cLineWidth;
      var diff = (Number(currentWidth) - Number(width)).toFixed(4);

      if (Math.abs(Number(diff)) > WIDTH_DIFF_VAL) {
        return currentWidth > width ? currentWidth - WIDTH_ADD_VAL : currentWidth + WIDTH_ADD_VAL;
      }

      return width;
    },
    _transRelativePoint: function _transRelativePoint(num, offset, axis) {
      var ini = {
        x: 380,
        y: 386.3
      };
      return num * 0.0375 / 14 * this.cWidth - this.cWidth * (offset / ini[axis]);
    },
    _reverse2Hex: function _reverse2Hex(hex) {
      return hex.substr(2, 2) + hex.substr(0, 2);
    },
    _hex2int: function _hex2int(hex) {
      var len = hex.length;
      var a = new Array(len);
      var code;

      for (var i = 0; i < len; i++) {
        code = hex.charCodeAt(i);

        if (48 <= code && code < 58) {
          code -= 48;
        } else {
          code = (code & 0xdf) - 65 + 10;
        }

        a[i] = code;
      }

      return a.reduce(function (acc, c) {
        return 16 * acc + c;
      }, 0);
    }
  }
};
exports.default = _default;