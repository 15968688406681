var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课程名称：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "课程分类：", prop: "title" } },
            [
              _c("el-cascader", {
                staticStyle: { width: "300px" },
                attrs: { options: _vm.options },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.formData.classify,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "classify", $$v)
                  },
                  expression: "formData.classify"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所属机构：", prop: "title" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formData.organization,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "organization", $$v)
                    },
                    expression: "formData.organization"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "讲师选择：", prop: "title" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formData.teacher,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "teacher", $$v)
                    },
                    expression: "formData.teacher"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "课程状态：", prop: "title" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "更新中" } }),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "已完结" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "封面上传：", prop: "title" } },
            [
              _c("upload", {
                scopedSlots: _vm._u([
                  {
                    key: "tip",
                    fn: function() {
                      return [_c("div", [_vm._v("建议尺寸：580px*320px")])]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "详情页视频：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.formData.newCourseName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "newCourseName", $$v)
                  },
                  expression: "formData.newCourseName"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.choiceCourseware }
                },
                [_vm._v("选择")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "H5封面竖图：", prop: "title" } },
            [_c("upload")],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "营销图上传：", prop: "title" } },
            [
              _c("upload", {
                scopedSlots: _vm._u([
                  {
                    key: "tip",
                    fn: function() {
                      return [
                        _c("div", [_vm._v("建议尺寸：690px*300px【非绝对】")])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否推荐到首页：", prop: "title" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "否" } }),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "是" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "课程简介：", prop: "title" } },
            [
              _c("tinymce", {
                attrs: { height: 300 },
                model: {
                  value: _vm.formData.content,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "content", $$v)
                  },
                  expression: "formData.content"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "课程订单有效期：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form__tip" }, [
                _vm._v("单位：天，0为永久有效")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "上架时间：", prop: "title" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "300px" },
                attrs: { type: "date", placeholder: "选择日期" },
                model: {
                  value: _vm.formData.value,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "value", $$v)
                  },
                  expression: "formData.value"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "下架时间：", prop: "title" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "300px" },
                attrs: { type: "date", placeholder: "选择日期" },
                model: {
                  value: _vm.formData.value,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "value", $$v)
                  },
                  expression: "formData.value"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "原价（市场价格）：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "销售价格：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否体验：", prop: "title" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "否" } }),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "是" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "体验价格：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "学习人数（营销）：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "浏览人数（营销）：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "会员等级：", prop: "title" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "无" } }),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "月卡" } }),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "七天免费会员" } })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form__tip" }, [_vm._v("此vip模式免费")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "附件上传：", prop: "title" } },
            [
              _c("upload", {
                scopedSlots: _vm._u([
                  {
                    key: "tip",
                    fn: function() {
                      return [
                        _c("div", [
                          _vm._v("仅支持上传一个附件，多个附件请打包上传")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否精选：", prop: "title" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "否" } }),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "是" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "精选推荐排序：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form__tip" }, [_vm._v("越小越靠前")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否免费：", prop: "title" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "否" } }),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "是" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "顾问二维码：", prop: "title" } },
            [_c("upload")],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "二维码标题：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "顾问微信名称：", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否有礼包：", prop: "title" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "否" } }),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "是" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("courseware-dialog", {
        ref: "coursewareDialog",
        on: { confirm: _vm.confirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }