"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _AssetDialog = _interopRequireDefault(require("../../../../components/Dialog/AssetDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AssetDialog: _AssetDialog.default
  },
  data: function data() {
    return {
      dialogFormVisible: false,
      versionListVisible: false,
      dialogTitle: '新增版本配置',
      ruleForm: {},
      rules: {
        code: [{
          required: true,
          message: '请输入版本代码',
          trigger: 'change'
        }],
        url_resource: [{
          required: true,
          message: '请选择上传版本',
          trigger: 'change'
        }],
        version_number: [{
          required: true,
          message: '请输入版本号',
          trigger: 'change'
        }],
        force_update: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        is_current: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }]
      },
      loading: false
    };
  },
  watch: {
    dialogFormVisible: function dialogFormVisible(val) {
      if (!val) {
        this.$refs.ruleForm.resetFields();
      }
    }
  },
  created: function created() {
    this.resetModel();
  },
  deactivated: function deactivated() {
    this.dialogFormVisible = false;
  },
  methods: {
    init: function init(res, params) {
      if (res) {
        this.dialogFormVisible = true;

        if (res === 'edit') {
          this.dialogTitle = '编辑版本配置';
          this.ruleForm.id = params.id;
          this.ruleForm.code = params.code;
          this.ruleForm.version_number = params.version_number;
          this.ruleForm.url_resource_id = params.url_resource_id;
          this.ruleForm.description = params.description;
          this.ruleForm.url_resource = params.url;
          this.ruleForm.force_update = params.force_update;
          this.ruleForm.is_current = params.is_current;
        }
      }
    },
    resetModel: function resetModel() {
      this.ruleForm = {
        code: '',
        version_number: '',
        url_resource_id: '',
        description: '',
        force_update: '',
        url_resource: '',
        is_current: ''
      };
    },
    submitForm: function submitForm(formName) {
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          that.loading = true;

          if (that.dialogTitle === '新增版本配置') {
            if (that.ruleForm.id) {
              delete that.ruleForm.id;
            }

            _api.default.addVersion(that.ruleForm).then(function (res) {
              that.loading = false;
              that.$message({
                message: res.msg,
                type: 'success'
              });
              that.dialogFormVisible = false;
              that.$emit('refresh', 1);
            }).catch(function () {
              that.loading = false;
            });
          } else {
            if (that.ruleForm.description === '') {
              delete that.ruleForm.description;
            }

            _api.default.updateVersion(that.ruleForm).then(function (res) {
              that.loading = false;
              that.$message({
                message: res.msg,
                type: 'success'
              });
              that.dialogFormVisible = false;
              that.$emit('refresh', 1);
            }).catch(function () {
              that.loading = false;
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm: function resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    choiceVersion: function choiceVersion() {
      this.$refs.assetDialog.showDialog({
        fileType: 'apk',
        lineType: 3
      });
    },
    getFileList: function getFileList(data) {
      this.ruleForm.url_resource = data[0].fileUrl;
      this.ruleForm.url_resource_id = data[0].id;
    }
  }
};
exports.default = _default;