"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      listLoading: false,
      searchParam: {
        page: 1,
        page_size: 10
      },
      columns: [{
        label: '课程id',
        width: '60',
        prop: 'courseId'
      }, {
        label: '课程名称',
        width: '60',
        prop: 'courseName'
      }, {
        label: '购买时间',
        width: '60',
        prop: 'createTime'
      }],
      total: 0,
      tableData: [],
      dialogVisible: false
    };
  },
  methods: {
    showDialog: function showDialog(item) {
      this.searchParam.uid = item.uid;
      this.searchParam.saleman_id = '';
      this.dialogVisible = true;
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getOrderCourseList(this.searchParam).then(function (response) {
        _this.tableData = response.data.infos.map(function (item) {
          return _bean.default.saleCourseBean(item);
        });
        _this.total = response.data.totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    }
  }
};
exports.default = _default;