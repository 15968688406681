var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("search-box", {
        attrs: { columns: _vm.columns, "show-more": true },
        on: {
          search: _vm.onSubmit,
          reset: _vm.reset,
          handleFieldChange: _vm.handleFieldChange
        },
        scopedSlots: _vm._u([
          {
            key: "basic",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "用户名：" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入销售用户名",
                        clearable: true
                      },
                      model: {
                        value: _vm.searchParam.uname,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uname", $$v)
                        },
                        expression: "searchParam.uname"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "销售团队：" } },
                  [
                    _c("el-cascader", {
                      attrs: {
                        options: _vm.saleGroupArr,
                        clearable: true,
                        props: {
                          value: "sale_group_id",
                          label: "title",
                          children: "subInfos"
                        }
                      },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.saleGroup,
                        callback: function($$v) {
                          _vm.saleGroup = $$v
                        },
                        expression: "saleGroup"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "销售UID：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入销售UID", clearable: true },
                      model: {
                        value: _vm.searchParam.uid,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uid", $$v)
                        },
                        expression: "searchParam.uid"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "queryMore",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "手机号码：" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入销售手机号码",
                        clearable: true
                      },
                      model: {
                        value: _vm.searchParam.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "phone", $$v)
                        },
                        expression: "searchParam.phone"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "data-list": _vm.tableData,
          columns: _vm.columns,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size,
          "show-index": false,
          selection: false,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _vm.hasPerm("Staff:list", _vm.$route.name)
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                attrs: {
                                  "track-params": "销售-销售人员-二维码",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.jumpPage(props.row)
                                  }
                                }
                              },
                              [_vm._v("二维码")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : props.column.label === "状态"
                  ? _c("div", [
                      _c(
                        "span",
                        {
                          class:
                            props.row.status === "已禁用"
                              ? "text-danger"
                              : "text-success"
                        },
                        [_vm._v(_vm._s(props.row[props.column.property]))]
                      )
                    ])
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }