var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container student" },
    [
      _c("search-box", {
        attrs: { showMore: false },
        on: { search: _vm.onSubmit, reset: _vm.reset },
        scopedSlots: _vm._u([
          {
            key: "basic",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "UID：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "UID", clearable: true },
                      model: {
                        value: _vm.searchParam.uid,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uid", $$v)
                        },
                        expression: "searchParam.uid"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "学生昵称：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "学生昵称", clearable: true },
                      model: {
                        value: _vm.searchParam.uname,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uname", $$v)
                        },
                        expression: "searchParam.uname"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.showDialog }
                },
                [_vm._v("添加学生")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "show-index": false,
          "data-list": _vm.tableData,
          columns: _vm.columns,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.deleteItem(props.row)
                              }
                            }
                          },
                          [_vm._v("踢出班级")]
                        )
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("student-dialog", { ref: "studentDialog", on: { refresh: _vm.reset } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }