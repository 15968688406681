import { render, staticRenderFns } from "./ConfigUpload2.vue?vue&type=template&id=fe520128&"
import script from "./ConfigUpload2.vue?vue&type=script&lang=js&"
export * from "./ConfigUpload2.vue?vue&type=script&lang=js&"
import style0 from "./ConfigUpload2.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fe520128')) {
      api.createRecord('fe520128', component.options)
    } else {
      api.reload('fe520128', component.options)
    }
    module.hot.accept("./ConfigUpload2.vue?vue&type=template&id=fe520128&", function () {
      api.rerender('fe520128', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/classRoom/onlineBusiness/components/ConfigUpload2.vue"
export default component.exports