"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _EditCourseware = _interopRequireDefault(require("./components/EditCourseware"));

var _PublishDialog = _interopRequireDefault(require("./components/PublishDialog"));

var _initData = require("../initData");

var _UploadPanel = _interopRequireDefault(require("@/components/UploadPanel"));

var _PreviewDialog = _interopRequireDefault(require("../../../components/Dialog/PreviewDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CoursewareList',
  components: {
    PageTable: _PageTable.default,
    EditCourseware: _EditCourseware.default,
    PublishDialog: _PublishDialog.default,
    UploadPanel: _UploadPanel.default,
    PreviewDialog: _PreviewDialog.default
  },
  data: function data() {
    return {
      activeName: '1',
      createTime: '',
      searchParam: {},
      tableData: null,
      listLoading: true,
      total: 0,
      initCourseData: _initData.initData,
      illegalSelected: [],
      selectedItem: [],
      queryShowMore: false,
      lineType: 0,
      // 业务类型 1=在线教育 2=掌上碑帖 3=智能笔
      nodeEnv: ''
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.lineType = 1;

        if (route.name === 'Resource') {
          this.lineType = 2;
        } else if (route.name === 'AssetsList') {
          this.lineType = 3;
        }
      },
      immediate: true
    }
  },
  created: function created() {
    // console.log(process.env.NODE_ENV);
    this.nodeEnv = process.env.NODE_ENV;
    this.reset();
  },
  methods: {
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.searchParam.start_time = new Date(val[0]).getTime() / 1000;
        this.searchParam.end_time = new Date(val[1]).getTime() / 1000;
      } else {
        this.searchParam.start_time = '';
        this.searchParam.end_time = '';
      }
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      var url = Number(this.activeName) === 1 ? 'getCoursewareList' : 'getRecycleList';

      _api.default[url](this.searchParam).then(function (response) {
        _this.tableData = response.data.data.map(function (item) {
          return _bean.default.coursewareBean(item);
        });
        _this.total = response.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectedItem = val;
    },
    reset: function reset() {
      this.searchParam = {
        name: '',
        file_type: '',
        page: 1,
        per_page: 20,
        is_release: '',
        is_transcoding: '',
        status: '',
        uname: '',
        start_time: '',
        end_time: '',
        line_type: this.lineType
      };
      this.createTime = '';
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    showDialog: function showDialog(ref, item, type) {
      if (ref === 'previewDialog') {
        this.$refs[ref].showDialog({
          fileType: item.fileType,
          fileUrl: item.videoUrl,
          fileId: item.fileId,
          name: item.name
        });
      } else {
        this.$refs[ref].showDialog(item, type);
      }
    },
    transCode: function transCode(item) {
      var _this2 = this;

      var fileId = item.fileId;

      if (!fileId) {
        this.$message({
          message: '暂无视频需要转码~',
          type: 'warning'
        });
        return;
      }

      _api.default.transcodeTask({
        fileId: fileId
      }).then(function () {
        _this2.$message({
          message: '转码中，请稍后查看~',
          type: 'success'
        });
      });
    },
    showPublishDialog: function showPublishDialog(ref, item) {
      var selectedItem = this.selectedItem;
      var selectIds = [];

      if (item) {
        if ([0, 3].includes(item.isRelease)) {
          selectIds.push(item.fileId);
        }
      } else {
        if (!selectedItem.length) {
          this.$message({
            message: '请选择要发布的课件~',
            type: 'warning'
          });
          return;
        }

        selectedItem.forEach(function (item) {
          if ([0, 3].includes(item.isRelease)) {
            selectIds.push(item.fileId);
          }
        });
      }

      this.$refs[ref].showDialog(selectIds);
    },
    batchCourseware: function batchCourseware(type, item) {
      var _this3 = this;

      var options = {
        recover: {
          url: 'recoverCourseware',
          desc: '恢复'
        },
        delete: {
          url: 'deleteCourseware',
          desc: '删除'
        }
      };
      var selectedItem = this.selectedItem;
      var selectIds = [];
      var mOpt = options[type];

      if (item) {
        selectIds.push(item.id);
      } else {
        if (!selectedItem.length) {
          this.$message({
            message: "\u8BF7\u9009\u62E9\u8981".concat(mOpt.desc, "\u7684\u8BFE\u4EF6~"),
            type: 'warning'
          });
          return;
        }

        selectedItem.forEach(function (item) {
          selectIds.push(item.id);
        });
      }

      this.$confirm("\u786E\u5B9A\u8981".concat(mOpt.desc, "\u8BFE\u4EF6\u5417\uFF1F")).then(function (_) {
        _api.default[mOpt.url]({
          id: selectIds
        }).then(function () {
          _this3.$message({
            message: "".concat(mOpt.desc, "\u6210\u529F~"),
            type: 'success'
          });

          _this3.reset();
        });
      }).catch(function (_) {});
    },
    handleClick: function handleClick() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    exportData: function exportData() {
      var _this4 = this;

      this.searchParam.export = 1;

      _api.default.getCoursewareList(this.searchParam).then(function (response) {
        window.location.href = response.data;
        _this4.searchParam.export = 0;
      });
    },
    exportVideoData: function exportVideoData(name) {
      var _this5 = this;

      var url = name === 'duration' ? 'syncVideoInfo' : 'syncMediaSnapshot';

      _api.default[url]().then(function (response) {
        _this5.$message({
          type: 'success',
          message: response.msg
        });
      });
    },
    uploadFile: function uploadFile(e) {
      var files = e.target.files;
      this.$refs.uploadPanel.init(files);
    },
    handleReset: function handleReset() {
      this.$refs.fileForm.reset();
      this.reset();
    },
    handleResult: function handleResult(result) {
      var _this6 = this;

      var params = {
        name: result.name,
        url: result.fileUrl,
        fileId: result.fileId,
        line_type: this.lineType,
        file_type: result.type
      };

      _api.default.addUploadInfo(params).then(function () {
        _this6.$message({
          type: 'success',
          message: "\u3010".concat(result.name, "\u3011\u4E0A\u4F20\u6210\u529F~")
        });

        if (result.type === 'video') {
          _this6.transcoding(result.fileId);
        }
      });
    },
    transcoding: function transcoding(fileId) {
      _api.default.transcodeTask({
        fileId: fileId
      }).then(function () {});
    }
  }
};
exports.default = _default;