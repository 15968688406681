"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/*
 * @Author: your name
 * @Date: 2021-06-03 21:05:33
 * @LastEditTime: 2021-06-10 11:23:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt-online-edu-admin/src/api/user.js
 */
var BASE_URL = "".concat(process.env.VUE_APP_BASE_API);
var PROXY_ID = '/userCenter/api/v1';
var _default = {
  login: function login(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/login/backLogin"),
      method: 'post',
      data: data
    });
  },
  logout: function logout() {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sale/logout"),
      method: 'post'
    });
  },
  getMenuInfo: function getMenuInfo(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/menu/tree"),
      method: 'get',
      params: params
    });
  },
  getInfo: function getInfo(token) {
    return (0, _request.default)({
      url: '/vue-admin-template/user/info',
      method: 'get',
      params: {
        token: token
      }
    });
  },
  getBtnLimits: function getBtnLimits() {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/func/list"),
      method: 'get'
    });
  },
  trackInfo: function trackInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL, "/dataCollector/api/v1/behavior/collect"),
      method: 'post',
      data: data
    });
  },
  resetPwd: function resetPwd(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/userBack/password"),
      method: 'put',
      data: data
    });
  }
};
exports.default = _default;