"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _CoursewareData = _interopRequireDefault(require("./CoursewareData"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditClassHourInfo',
  components: {
    CoursewareData: _CoursewareData.default
  },
  data: function data() {
    return {
      formData: {
        title: '',
        newCourseName: '',
        zy_video_section_id: '',
        is_free: '',
        is_experience: '',
        tcvideo_id: ''
      },
      formLabelWidth: '100px',
      drawer: false,
      loading: false,
      rules: {
        title: [{
          required: true,
          message: '请输入课时名称',
          trigger: 'change'
        }],
        newCourseName: [{
          required: true,
          message: '请选择新的课程文件',
          trigger: 'change'
        }]
      },
      pItemData: ''
    };
  },
  methods: {
    showDialog: function showDialog(pItem, sItem) {
      this.pItemData = pItem;
      this.drawer = true;
      this.formData.title = sItem.title;
      this.formData.newCourseName = sItem.newCourseName;
      this.formData.zy_video_section_id = sItem.id;
      this.formData.is_free = sItem.isFree;
      this.formData.is_experience = sItem.isExperience;
      this.formData.tcvideo_id = sItem.newCourseId;
    },
    cancelForm: function cancelForm() {
      this.loading = false;
      this.drawer = false;
    },
    handleClose: function handleClose(done) {
      var _this = this;

      if (this.loading) {
        return;
      }

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.$confirm('确定要提交数据吗？').then(function (_) {
            _this.loading = true;

            _api.default.bindCourseInfo(_this.formData).then(function () {
              _this.loading = false;
              done();

              _this.$emit('refresh', _this.pItemData, _this.formData);

              _this.$refs.form.resetFields();
            }).catch(function () {
              _this.loading = false;
            });
          }).catch(function (_) {});
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    confirm: function confirm(item) {
      this.formData.newCourseName = item.name;
      this.formData.tcvideo_id = item.id;
    }
  }
};
exports.default = _default;