"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.assetBean = assetBean;

require("core-js/modules/es6.function.name");

var _utils = require("@/utils");

function assetBean(r) {
  return {
    id: r.id,
    name: r.name,
    fileType: r.file_type,
    fileTypeText: r.fileTypeValue,
    fileId: r.fileid,
    fileUrl: r.original_url,
    createTime: r.create_time ? (0, _utils.parseTime)(r.create_time) : ''
  };
}