"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'RightPanel',
  props: {},
  data: function data() {
    return {
      show: false,
      buttonRight: 0
    };
  },
  watch: {
    show: function show(val) {
      if (val) {
        this.buttonRight = 400;
      } else {
        this.buttonRight = 0;
      }
    }
  },
  methods: {
    close: function close() {
      this.$emit('handleClose');
    }
  }
};
exports.default = _default;