"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _initData = require("../initData");

var _QrCodeDialog = _interopRequireDefault(require("./components/QrCodeDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ClassList',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default,
    QrCodeDialog: _QrCodeDialog.default
  },
  data: function data() {
    return {
      createTime: '',
      searchParam: {},
      tableData: [],
      listLoading: false,
      total: 0,
      classStatusArr: _initData.classStatusOptions,
      columns: _initData.initData.classList.columns,
      loading: false,
      courseArr: []
    };
  },
  created: function created() {},
  // mounted() {
  //     this.reset();
  // },
  activated: function activated() {
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      var url = 'getOnlineClassList';

      _api.default[url](this.searchParam).then(function (response) {
        var _response$data = response.data,
            data = _response$data.data,
            totalNum = _response$data.totalNum;
        _this.tableData = (data || []).map(function (item) {
          return _bean.default.middleUserBean(item);
        });
        _this.total = totalNum || 0;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    remoteMethod: function remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        this.getCourseList(query);
      } else {
        this.courseArr = [];
      }
    },
    getCourseList: function getCourseList(val) {
      var _this2 = this;

      _api.default.getOnlineCourseList({
        name: val
      }).then(function (response) {
        _this2.courseArr = response.data.data || [];
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.curPage = val;
      this.fetchData();
    },
    reset: function reset() {
      this.searchParam = {
        className: '',
        startTime: '',
        endTime: '',
        curPage: 1,
        pageSize: 10
      };
      this.createTime = '';
      this.fetchData();
    },
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.searchParam.startTime = new Date(val[0]).getTime() / 1000;
        this.searchParam.endTime = new Date(val[1]).getTime() / 1000;
      } else {
        this.searchParam.startTime = '';
        this.searchParam.endTime = '';
      }
    },
    onSubmit: function onSubmit() {
      this.searchParam.curPage = 1;
      this.fetchData();
    },
    handleEditClassInfo: function handleEditClassInfo(type, item) {
      // 编辑班级配置
      var id = item.id;
      this.$router.push({
        name: 'HandleClass',
        params: {
          id: id,
          type: type
        }
      });
    },
    handleClassInfo: function handleClassInfo(type, item) {
      var id = type === 'add' ? 0 : item.id;
      this.$router.push({
        name: 'HandleClass',
        params: {
          id: id
        }
      });
    },
    handlerefreshItemData: function handlerefreshItemData(rowData) {
      var _this3 = this;

      this.listLoading = true;
      var url = 'updateClassInfo';
      var postData = {
        class_id: rowData.id
      };

      _api.default[url](postData).then(function (response) {
        _this3.listLoading = false;

        _this3.tableData.filter(function (item, index) {
          if (response.data.id === item.id) {
            _this3.tableData[index].classFinishedPercent = response.data.classFinishedPercent;
            _this3.tableData[index].renewPercent = response.data.renewPercent;
          }

          return item;
        });

        _this3.$message.success('更新成功');
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    jumpPage: function jumpPage(configObj) {
      this.$router.push({
        name: 'ClassStudentList',
        params: {
          id: configObj.id
        }
      });
    },
    jumpStudyPlanPage: function jumpStudyPlanPage(rowData) {
      this.$router.push({
        name: 'StudyPlan',
        params: {
          id: rowData.id
        }
      });
    },
    handleQrCode: function handleQrCode(rowData) {
      this.$refs.qrCodeDialog.showDialog(rowData);
    }
  }
};
exports.default = _default;