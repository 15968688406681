import { render, staticRenderFns } from "./StudentList.vue?vue&type=template&id=47a1c837&"
import script from "./StudentList.vue?vue&type=script&lang=js&"
export * from "./StudentList.vue?vue&type=script&lang=js&"
import style0 from "./StudentList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47a1c837')) {
      api.createRecord('47a1c837', component.options)
    } else {
      api.reload('47a1c837', component.options)
    }
    module.hot.accept("./StudentList.vue?vue&type=template&id=47a1c837&", function () {
      api.rerender('47a1c837', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/smartPen/courseManager/StudentList.vue"
export default component.exports