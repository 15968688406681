"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  data: function data() {
    return {
      loginForm: {
        userAccount: '',
        password: '',
        platId: 4
      },
      loginRules: {
        userAccount: [{
          required: true,
          trigger: 'blur',
          message: '请输入用户名'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: '请输入密码'
        }]
      },
      optionPlats: [{
        label: '碑帖',
        value: 1
      }, {
        label: '教培',
        value: 2
      }, {
        label: '乐写字',
        value: 3
      }, {
        label: '在线教育',
        value: 4
      }, {
        label: '智能测评项目',
        value: 5
      }],
      loading: false,
      passwordType: 'password',
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    showPwd: function showPwd() {
      var _this = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;

          _this2.$store.dispatch('user/login', _this2.loginForm).then(function () {
            _this2.$router.push({
              path: _this2.redirect || '/'
            }, function () {});

            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;