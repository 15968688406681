"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _path = _interopRequireDefault(require("path"));

var _validate = require("@/utils/validate");

var _Link = _interopRequireDefault(require("./Link"));

var _Item = _interopRequireDefault(require("./Item"));

var _FixiOSBug = _interopRequireDefault(require("./FixiOSBug"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'SidebarItem',
  components: {
    AppLink: _Link.default,
    Item: _Item.default
  },
  mixins: [_FixiOSBug.default],
  props: {
    menus: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    resolvePath: function resolvePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }

      if ((0, _validate.isExternal)(this.basePath)) {
        return this.basePath;
      }

      return _path.default.resolve(this.basePath, routePath);
    }
  }
};
exports.default = _default2;