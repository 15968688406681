var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "直播名称：", prop: "title", required: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "直播分类：", prop: "category", required: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "400px" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formData.category,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "category", $$v)
                    },
                    expression: "formData.category"
                  }
                },
                _vm._l(_vm.classifyOption, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "直播讲师：", prop: "teacher_id", required: "" }
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "400px" },
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.formData.teacher_id,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "teacher_id", $$v)
                    },
                    expression: "formData.teacher_id"
                  }
                },
                _vm._l(_vm.teacherList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "图片：", prop: "image", required: "" } },
            [
              _c("el-input", {
                staticStyle: { display: "none" },
                attrs: { autocomplete: "off", disabled: true },
                model: {
                  value: _vm.formData.image,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "image", $$v)
                  },
                  expression: "formData.image"
                }
              }),
              _vm._v(" "),
              _c("upload", {
                attrs: { lineType: "edu", "file-list": _vm.imageList },
                on: {
                  handleResult: function(val) {
                    return _vm.uploadResult(val, "image")
                  }
                }
              }),
              _vm._v(" "),
              _c("div", [_vm._v("建议图片宽高比：1：0.55")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "二维码：", prop: "qrcode", required: "" } },
            [
              _c("el-input", {
                staticStyle: { display: "none" },
                attrs: { autocomplete: "off", disabled: true },
                model: {
                  value: _vm.formData.qrcode,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "qrcode", $$v)
                  },
                  expression: "formData.qrcode"
                }
              }),
              _vm._v(" "),
              _c("upload", {
                attrs: { lineType: "edu", "file-list": _vm.qrCodeList },
                on: {
                  handleResult: function(val) {
                    return _vm.uploadResult(val, "qrcode")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "直播时间：", prop: "liveTime", required: "" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "400px" },
                attrs: {
                  type: "datetimerange",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                on: { change: _vm.dateRangeChange },
                model: {
                  value: _vm.formData.liveTime,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "liveTime", $$v)
                  },
                  expression: "formData.liveTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "价格：", prop: "price", required: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                model: {
                  value: _vm.formData.price,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "price", $$v)
                  },
                  expression: "formData.price"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "初始预约人数：",
                prop: "reserve_num",
                required: ""
              }
            },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                model: {
                  value: _vm.formData.reserve_num,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "reserve_num", $$v)
                  },
                  expression: "formData.reserve_num"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "关联课程：", prop: "video_title", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { disabled: true },
                model: {
                  value: _vm.formData.video_title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "video_title", $$v)
                  },
                  expression: "formData.video_title"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.showDialog } },
                [_vm._v("选择")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "直播回放：", prop: "replay" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "400px" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formData.replay,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "replay", $$v)
                    },
                    expression: "formData.replay"
                  }
                },
                _vm._l(_vm.replayOption, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "设为推荐：", prop: "recommend" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "400px" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formData.recommend,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "recommend", $$v)
                    },
                    expression: "formData.recommend"
                  }
                },
                _vm._l(_vm.recommendOption, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "正文：", prop: "details", required: "" } },
            [
              _c("tinymce", {
                attrs: { height: 300 },
                model: {
                  value: _vm.formData.details,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "details", $$v)
                  },
                  expression: "formData.details"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("保存提交")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("course-dialog", {
        ref: "courseDialog",
        on: { confirm: _vm.courseConfirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }