"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'UserDialog',
  components: {
    PageTable: _PageTable.default
  },
  props: {
    type: {
      type: String,
      required: true
    },
    isConfirmHide: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formLabelWidth: '70px',
      searchParam: {
        business_type: undefined,
        // 业务类型(1-软笔、2-硬笔、3-少儿、4-智能笔)
        userName: undefined,
        video_id: undefined,
        phone: undefined,
        page: 1,
        page_size: 10
      },
      eventType: 0,
      // 点击类型0 关联课程 1点评师 2班主任
      tableData: [],
      postData: {
        business_type: null
      },
      total: 0,
      selectList: [],
      columns: [],
      selectItem: '',
      dialogType: null,
      // 弹窗类型 0关联课程 1点评师 2班主任
      jsonItem: {},
      listLoading: false
    };
  },
  methods: {
    showDialog: function showDialog(dialogType, eventType) {
      var postData = {};

      switch (dialogType) {
        case 'associatedCLass':
          // 关联课程
          this.dialogVisible = true;
          this.selectItem = '';
          this.jsonItem = this._formatViewInfo('classConfig');
          this.columns = this.jsonItem.column;
          this.dialogType = 1; // todo

          this.searchParam.business_type = eventType;
          this.fetchData(1, postData);
          break;

        case 'teacher':
          // 点评老师
          this.dialogVisible = true;
          this.selectItem = '';
          this.jsonItem = this._formatViewInfo('teacher');
          this.columns = this.jsonItem.column;
          this.dialogType = 2;

          if (Number(this.$route.params.id) !== 0) {
            // 编辑
            this.searchParam.video_id = eventType.video_id || this.$route.params.id;
          } else {
            this.searchParam.video_id = this.$parent.formData.id;
          }

          this.fetchData(2, postData);
          break;

        case 'teachercharge':
          // 班主任
          this.dialogVisible = true;
          this.selectItem = '';
          this.dialogType = 3;
          this.jsonItem = this._formatViewInfo('teachercharge');
          this.columns = this.jsonItem.column;
          postData.video_id = 1;
          this.fetchData(3, postData);
          break;

        default:
          break;
      }
    },
    fetchData: function fetchData(eventType) {
      var _this = this;

      this.listLoading = true;
      this.eventType = eventType; // eventType 1 课程列表 1点评师 2 班主任

      var url = eventType === 1 ? 'getOnlineCourseList' : eventType === 2 ? 'configAddClassQueryreviewchargeList' : 'configAddClassQuerychargeList';

      if (eventType === 2) {
        // 点评老师 必填video_id
        // this.$route.params.id;
        this.searchParam.business_type = undefined;

        if (Number(this.$route.params.id) !== 0 && !this.$parent.formData.id) {
          // 没修改默认接口返回的
          this.searchParam.video_id = this.$parent.formData.courseId;
        } else {
          // 修改后的就取修改的ID
          this.searchParam.video_id = this.$parent.formData.video_id;
        }
      }

      _api.default[url](this.searchParam).then(function (response) {
        var data = response.data.data;

        if (url === 'configAddClassQuerychargeList') {
          _this.total = response.data.totalNum || 0;
        } else {
          _this.total = response.data.total || 0;
        }

        _this.tableData = (data || []).map(function (item) {
          return _bean.default.middleUserBean(item);
        });
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    _formatViewInfo: function _formatViewInfo(type) {
      var json = {
        classConfig: {
          url: 'getOriginalUserList',
          bean: 'userBean',
          column: [{
            label: '课程id',
            width: '120',
            prop: 'id'
          }, {
            label: '关联课程名称',
            width: '140',
            prop: 'classConfigTitle'
          }],
          query: [{
            label: '课程id',
            prop: 'vid'
          }, {
            label: '课程名称',
            prop: 'name'
          }]
        },
        teacher: {
          url: 'getTeacherList',
          bean: 'teacherBean',
          column: [{
            label: 'id',
            width: '80',
            prop: 'id'
          }, {
            label: '昵称',
            width: '100',
            prop: 'nickname'
          }]
        },
        teachercharge: {
          url: 'getUserList',
          bean: 'userBean',
          column: [{
            label: 'id',
            width: '80',
            prop: 'backUserId'
          }, {
            label: '昵称',
            width: '100',
            prop: 'userName'
          }, {
            label: '手机号',
            width: '100',
            prop: 'phone'
          }],
          query: [{
            label: '昵称',
            prop: 'userName'
          }, {
            label: '手机号',
            prop: 'phone'
          }]
        }
      };
      return json[type] || {};
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData(this.dialogType);
    },
    searchData: function searchData() {
      // this.searchParam.page = 1;
      this.fetchData(this.dialogType);
    },
    currentChange: function currentChange(val) {
      this.selectItem = val;
    },
    confirm: function confirm() {
      if (!this.selectItem) {
        this.$message.warning('请选择数据~');
        return;
      }

      this.$emit('confirm', this.selectItem, this.eventType);
      if (this.isConfirmHide) this.hideDialog();
    },
    hideDialog: function hideDialog() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;