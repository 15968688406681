var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "课程", visible: _vm.dialogVisible },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("page-table", {
        attrs: {
          loading: _vm.listLoading,
          "data-list": _vm.tableData,
          columns: _vm.columns,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size,
          "show-index": false,
          selection: false,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                _c("span", [_vm._v(_vm._s(props.row[props.column.property]))])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }