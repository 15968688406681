var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-button", { attrs: { type: "primary" } }, [
        _vm._v("\n        选择视频\n        "),
        _c("form", { ref: "fileForm", staticClass: "form" }, [
          _c("input", {
            ref: "videoFile",
            staticClass: "input",
            attrs: { type: "file" },
            on: { change: _vm.upload }
          })
        ])
      ]),
      _vm._v(" "),
      _vm.uploaderInfo.videoInfo
        ? _c("div", [
            _c("div", [
              _vm._v(
                "\n            视频名称：" +
                  _vm._s(
                    _vm.uploaderInfo.videoInfo.name +
                      "." +
                      _vm.uploaderInfo.videoInfo.type
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "display-flex" },
              [
                _vm._v("\n            上传进度："),
                _vm._v(" "),
                _c("el-progress", {
                  staticClass: "progress",
                  attrs: {
                    percentage: Math.floor(_vm.uploaderInfo.progress * 100)
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n            上传结果：" +
                  _vm._s(
                    _vm.uploaderInfo.isVideoUploadCancel
                      ? "已取消"
                      : _vm.uploaderInfo.isVideoUploadSuccess
                      ? "上传成功"
                      : "上传中"
                  ) +
                  "\n        "
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }