var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("search-box", {
        attrs: {
          showMore: true,
          columns: _vm.shipReviewData.shipReviewList.columns
        },
        on: {
          search: _vm.onSubmit,
          reset: _vm.reset,
          handleFieldChange: _vm.handleFieldChange
        },
        scopedSlots: _vm._u([
          {
            key: "basic",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "订单编号：" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.searchParam.id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "id", $$v)
                        },
                        expression: "searchParam.id"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "创建时间：" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        "range-separator": "~",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateRangeChange },
                      model: {
                        value: _vm.createTime,
                        callback: function($$v) {
                          _vm.createTime = $$v
                        },
                        expression: "createTime"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "用户ID：" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.searchParam.uid,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uid", $$v)
                        },
                        expression: "searchParam.uid"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "手机号：" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.searchParam.infophone,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "infophone", $$v)
                        },
                        expression: "searchParam.infophone"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "queryMore",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "绑定手机号：" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.searchParam.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "phone", $$v)
                        },
                        expression: "searchParam.phone"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "销售ID：" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.searchParam.sale_uid,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "sale_uid", $$v)
                        },
                        expression: "searchParam.sale_uid"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "用户名称：" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.searchParam.uname,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uname", $$v)
                        },
                        expression: "searchParam.uname"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "订单类型：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchParam.type,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "type", $$v)
                          },
                          expression: "searchParam.type"
                        }
                      },
                      _vm._l(_vm.orderOptionsArr, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "旺店通：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchParam.wdt_state,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "wdt_state", $$v)
                          },
                          expression: "searchParam.wdt_state"
                        }
                      },
                      _vm._l(_vm.shopOptionsArr, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "发货状态：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchParam.delivery_state,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "delivery_state", $$v)
                          },
                          expression: "searchParam.delivery_state"
                        }
                      },
                      _vm._l(_vm.sendOptionsArr, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "申请修改：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchParam.act_status,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "act_status", $$v)
                          },
                          expression: "searchParam.act_status"
                        }
                      },
                      _vm._l(_vm.actOptions, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("el-form", { attrs: { inline: true } }),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "data-list": _vm.tableData,
          columns: _vm.shipReviewData.shipReviewList.columns,
          "show-index": false,
          selection: false,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        props.row.wdt_state === 0
                          ? [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track:click",
                                      arg: "click"
                                    }
                                  ],
                                  attrs: {
                                    "track-params": "智能笔-发货审核-修改",
                                    size: "small",
                                    type: "text"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleGoods(props.row)
                                    }
                                  }
                                },
                                [_vm._v("修改")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track:click",
                                      arg: "click"
                                    }
                                  ],
                                  attrs: {
                                    "track-params":
                                      "智能笔-发货审核-旺店通建单",
                                    size: "small",
                                    type: "text"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.showDialog(props.row)
                                    }
                                  }
                                },
                                [_vm._v("旺店通建单")]
                              )
                            ]
                          : [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track:click",
                                      arg: "click"
                                    }
                                  ],
                                  staticClass: "copy",
                                  attrs: {
                                    "track-params": "智能笔-发货审核-复制",
                                    size: "small",
                                    type: "text",
                                    "data-clipboard-text": _vm.copyData
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.copy(props.row)
                                    }
                                  }
                                },
                                [_vm._v("复制\n                    ")]
                              )
                            ]
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                props.column.label === "中台订单号"
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("fomatteShopOrder")(props.row.shoporder)
                          ) +
                          "\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                props.column.label === "发货商品"
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("fomatteProduct")(props.row.product)) +
                          "\n            "
                      )
                    ])
                  : props.column.label === "订单类型"
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("ordertypeText")(props.row.type)) +
                          "\n            "
                      )
                    ])
                  : props.column.label === "旺店通推送状态"
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("wdt_stateText")(props.row.wdt_state)) +
                          "\n            "
                      )
                    ])
                  : props.column.label === "发货状态"
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("sendStatusText")(props.row.delivery_state)
                          ) +
                          "\n            "
                      )
                    ])
                  : props.column.label === "收货地址"
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("fomatteAddr")(props.row.address)) +
                          "\n            "
                      )
                    ])
                  : props.column.label === "快递单号"
                  ? _c(
                      "div",
                      [
                        props.row.delivery_state > 0
                          ? _c("block", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(props.row.delivery_code)
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : props.column.label === "快递公司"
                  ? _c(
                      "div",
                      [
                        props.row.delivery_state > 0
                          ? _c("block", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(props.row.delivery_company)
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("build-order-dialog", {
        ref: "buildOrderDialog",
        on: { refresh: _vm.reset }
      }),
      _vm._v(" "),
      _c("handle-goods-dialog", {
        ref: "handleGoodsDialog",
        on: { refresh: _vm.reset }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }