"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ImageConfig',
  components: {
    PageTable: _PageTable.default
  },
  props: {
    isConfirmHide: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formLabelWidth: '70px',
      searchParam: {
        file_type: 'image',
        line_type: 1,
        page: 1,
        per_page: 10,
        name: ''
      },
      eventType: 0,
      // 点击类型0 关联课程 1点评师 2班主任
      tableData: [],
      total: 0,
      selectList: [],
      columns: [],
      propsItem: {},
      selectItem: '',
      // 选择了哪个内容
      jsonItem: {},
      videosectionid: '',
      // 选中的子章节id
      listLoading: false
    };
  },
  methods: {
    showDialog: function showDialog(propsItem, eventType) {
      var postData = {};
      this.dialogVisible = true;
      this.selectItem = '';
      this.jsonItem = this._formatViewInfo('classConfig');
      this.columns = this.jsonItem.column;
      postData.business_type = eventType;
      this.videosectionid = eventType.id;
      this.propsItem = propsItem;
      this.fetchData(1, postData);
    },
    fetchData: function fetchData(eventType) {
      var _this = this;

      this.listLoading = true;
      this.eventType = eventType;
      var url = 'getCoursewareListNew';

      _api.default[url](this.searchParam).then(function (response) {
        var _response$data = response.data,
            data = _response$data.data,
            total = _response$data.total;
        _this.tableData = (data || []).map(function (item) {
          return _bean.default.classImageGroup(item);
        });
        _this.total = total || 0;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    _formatViewInfo: function _formatViewInfo(type) {
      var json = {
        classConfig: {
          bean: 'userBean',
          column: [{
            label: 'id',
            width: '80',
            prop: 'id'
          }, {
            label: '图片名',
            width: '120',
            prop: 'imageName'
          }, {
            label: '图片',
            width: '260',
            prop: 'imageUrl'
          }]
        }
      };
      return json[type] || {};
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    currentChange: function currentChange(val) {
      this.selectItem = val;
    },
    confirm: function confirm() {
      if (!this.selectItem) {
        this.$message.warning('请选择要设置的图片~');
        return;
      } // 没有zid


      this.$emit('refresh', this.selectItem, this.videosectionid);
      this.$emit('confirm', this.selectItem, this.propsItem.id);
      if (this.isConfirmHide) this.hideDialog();
    },
    hideDialog: function hideDialog() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;