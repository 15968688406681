var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        visible: _vm.centerDialogVisible,
        width: "30%",
        center: ""
      },
      on: {
        "update:visible": function($event) {
          _vm.centerDialogVisible = $event
        },
        close: _vm.closeDialog
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "span",
                { staticClass: "dialog-footer" },
                [
                  _c("el-button", { on: { click: _vm.closeDialog } }, [
                    _vm._v("取 消")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.buildOrder }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("el-input", {
        attrs: {
          type: "textarea",
          placeholder: "请输入内容",
          maxlength: "200",
          "show-word-limit": ""
        },
        on: { change: _vm.changeVal },
        model: {
          value: _vm.textarea,
          callback: function($$v) {
            _vm.textarea = $$v
          },
          expression: "textarea"
        }
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { margin: "20px 0" } }),
      _vm._v(" "),
      _c(
        "el-checkbox",
        {
          model: {
            value: _vm.checked,
            callback: function($$v) {
              _vm.checked = $$v
            },
            expression: "checked"
          }
        },
        [_vm._v("添加课程包关联的教材商品")]
      ),
      _vm._v(" "),
      _vm.checked === true
        ? _c(
            "div",
            { staticClass: "typeList" },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.typeData },
                  model: {
                    value: _vm.radio,
                    callback: function($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio"
                  }
                },
                _vm._l(_vm.typeList, function(item) {
                  return _c(
                    "el-radio-button",
                    { key: item.bus_id, attrs: { label: item, border: "" } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.checked === true
        ? _c(
            "div",
            { staticClass: "typeList" },
            [
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.addInputData },
                  model: {
                    value: _vm.checkboxGroup,
                    callback: function($$v) {
                      _vm.checkboxGroup = $$v
                    },
                    expression: "checkboxGroup"
                  }
                },
                _vm._l(_vm.classBao_list, function(item) {
                  return _c(
                    "el-checkbox-button",
                    { key: item.id, attrs: { label: item } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }