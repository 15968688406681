"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'UserDialog',
  components: {
    PageTable: _PageTable.default
  },
  props: {
    type: {
      type: String,
      required: true
    },
    isConfirmHide: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formLabelWidth: '70px',
      searchParam: {
        page: 1,
        page_size: 10,
        per_page: 10,
        uname: '',
        phone: ''
      },
      tableData: [],
      total: 0,
      selectList: [],
      columns: [],
      selectItem: '',
      jsonItem: {},
      listLoading: false
    };
  },
  methods: {
    showDialog: function showDialog() {
      this.dialogVisible = true;
      this.selectItem = '';
      this.jsonItem = this._formatViewInfo(this.type);
      this.columns = this.jsonItem.column;
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default[this.jsonItem.url](this.searchParam).then(function (response) {
        var _response$data = response.data,
            infos = _response$data.infos,
            totalNum = _response$data.totalNum,
            data = _response$data.data,
            total = _response$data.total;
        _this.tableData = (infos || data || []).map(function (item) {
          return _bean.default[_this.jsonItem.bean](item);
        });
        _this.total = totalNum || total || 0;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });
    },
    _formatViewInfo: function _formatViewInfo(type) {
      var json = {
        original: {
          url: 'getOriginalUserList',
          bean: 'userBean',
          column: [{
            label: 'UID',
            width: '80',
            prop: 'uid'
          }, {
            label: '昵称',
            width: '100',
            prop: 'uname'
          }, {
            label: '手机号',
            width: '100',
            prop: 'phone'
          }],
          query: [{
            label: '昵称',
            prop: 'uname'
          }, {
            label: '手机号',
            prop: 'phone'
          }]
        },
        teacher: {
          url: 'getTeacherList',
          bean: 'teacherBean',
          column: [{
            label: 'ID',
            width: '80',
            prop: 'id'
          }, {
            label: '昵称',
            width: '100',
            prop: 'uname'
          }, {
            label: '手机号',
            width: '100',
            prop: 'phone'
          }],
          query: [{
            label: '昵称',
            prop: 'nickname'
          }, {
            label: '手机号',
            prop: 'mobile'
          }]
        },
        student: {
          url: 'getUserList',
          bean: 'userBean',
          column: [{
            label: 'UID',
            width: '80',
            prop: 'uid'
          }, {
            label: '昵称',
            width: '100',
            prop: 'uname'
          }, {
            label: '手机号',
            width: '100',
            prop: 'phone'
          }],
          query: [{
            label: '昵称',
            prop: 'uname'
          }, {
            label: '手机号',
            prop: 'phone'
          }]
        },
        assistant: {
          url: 'getAssistantList',
          bean: 'assistantBean',
          column: [{
            label: 'UID',
            width: '80',
            prop: 'uid'
          }, {
            label: '名称',
            width: '100',
            prop: 'name'
          }, {
            label: '昵称',
            width: '100',
            prop: 'uname'
          }, {
            label: '手机号',
            width: '100',
            prop: 'phone'
          }],
          query: [{
            label: '名称',
            prop: 'name'
          }, {
            label: '手机号',
            prop: 'phone'
          }]
        }
      };
      return json[type] || {};
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    currentChange: function currentChange(val) {
      this.selectItem = val;
    },
    confirm: function confirm() {
      if (!this.selectItem) {
        this.$message.warning('请选择数据~');
        return;
      }

      this.$emit('confirm', this.selectItem);
      if (this.isConfirmHide) this.hideDialog();
    },
    hideDialog: function hideDialog() {
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;