var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        { staticStyle: { display: "inline-block" } },
        [
          [
            _vm.item.meta
              ? _c(
                  "app-link",
                  { attrs: { to: _vm.item.path } },
                  [
                    _c("el-menu-item", { attrs: { index: _vm.item.path } }, [
                      _c("span", [_vm._v(_vm._s(_vm.item.meta.title))])
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }