"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _initData = require("../initData");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CourseList',
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          var curDate = new Date().getTime();
          var one = 30 * 24 * 3600 * 1000;
          var oneMonths = curDate - one;
          return time.getTime() > Date.now() || time.getTime() < oneMonths;
        }
      },
      searchParam: {},
      tableData: null,
      listLoading: true,
      total: 0,
      putAwayTime: '',
      options: [{
        value: '0',
        label: '全部'
      }, {
        value: '1',
        label: '是'
      }, {
        value: '2',
        label: '否'
      }],
      initCourseData: _initData.initData,
      queryShowMore: false
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        // eslint-disable-next-line
        this.searchParam.start_time = val[0] + ' 00:00:00'; // eslint-disable-next-line

        this.searchParam.end_time = val[1] + ' 23:59:59';
      } else {
        this.searchParam.start_time = '';
        this.searchParam.end_time = '';
      }

      console.log(this.searchParam);
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getbookUser(this.searchParam).then(function (response) {
        console.log(response);
        _this.tableData = response.data;
        _this.total = response.count;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.searchParam = {
        name: '',
        uid: '',
        page: 1,
        phone: '',
        // is_clue: '',
        start_time: '',
        end_time: '',
        per_page: 20
      };
      this.putAwayTime = '';
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    jumpPage: function jumpPage(item) {
      this.$router.push({
        name: 'ClassHourList',
        params: {
          id: item.id
        }
      });
    },
    exportExcel: function exportExcel() {
      if (this.putAwayTime === '') {
        this.$message({
          message: '请选择需要导出日期',
          type: 'warning'
        });
      } else {
        _api.default.exportExcel(this.searchParam).then(function (res) {
          window.location.href = res.data;
        });
      }
    },
    handleCourse: function handleCourse(item) {
      console.log(item);
      var id = item.uid;
      this.$router.push({
        name: 'Testuser',
        params: {
          id: id
        }
      });
    }
  }
};
exports.default = _default;