"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _initData = require("../initData");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CourseList',
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      searchParam: {},
      tableData: null,
      listLoading: true,
      total: 0,
      initCourseData: _initData.initData,
      queryShowMore: false
    };
  },
  created: function created() {
    this.uid = this.$route.params.id;
    this.reset();
  },
  methods: {
    fomatte: function fomatte(e) {
      var arr = ['无', '一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '自由练习', 'diy7*10', '笔画偏旁', '一年级同步', '二年级同步', 'diy5*5', '一年级上册期末', '二年级上册期末'];
      return arr[e];
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.gettestUser(this.searchParam, this.uid).then(function (response) {
        console.log(response);
        _this.tableData = response.data;
        _this.total = response.count;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.searchParam = {
        page: 1,
        per_page: 20
      };
      console.log(this.searchParam);
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    }
  }
};
exports.default = _default;