"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.array.find");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

var _api = _interopRequireDefault(require("@/api"));

var _initData = require("../../initData");

var _Upload = _interopRequireDefault(require("@/components/Upload"));

var _cache = require("@/utils/cache");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Upload: _Upload.default
  },
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        qrcode: '',
        wechat: '',
        wechat_type: '1',
        name: '',
        cid: '',
        toplimit: '',
        saleman_id: ''
      },
      formLabelWidth: '120px',
      serviceLineArr: _initData.serviceLine,
      itemData: null,
      rules: {
        qrcode: [{
          required: true,
          message: '请上传二维码',
          trigger: 'blur'
        }],
        wechat_type: [{
          required: true,
          message: '请选择微信类型',
          trigger: 'change'
        }],
        wechat: [{
          required: true,
          message: '请填写微信号',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请填写微信昵称',
          trigger: 'blur'
        }],
        cid: [{
          required: true,
          message: '请选择分类',
          trigger: 'change'
        }],
        toplimit: [{
          required: true,
          message: '请填写上限',
          trigger: 'blur'
        }]
      },
      fileList: [],
      saleManInfo: {},
      disabled: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (!val) {
        this.form = {
          qrcode: '',
          wechat: '',
          wechat_type: '1',
          name: '',
          cid: '',
          toplimit: '',
          saleman_id: ''
        };
        this.$refs.ruleForm.resetFields();
        this.fileList = [];
      }
    }
  },
  methods: {
    showDialog: function showDialog(item) {
      this.itemData = item;
      this.dialogVisible = true;

      if (item) {
        this.form.id = item.id;
        this.form.qrcode = item.qrCodeId;
        this.form.wechat_type = item.weChatType;
        this.form.wechat = item.weChatID;
        this.form.name = item.weChatName;
        this.form.cid = item.lineId.toString();
        this.form.toplimit = item.topLimit;
        this.form.saleman_id = item.saleManId;
        this.fileList = [{
          url: item.qrCodeUrl,
          attach_id: item.qrCodeId
        }];
        this.saleManInfo = {
          uid: item.saleManId,
          userName: item.saleManName
        };
        this.disabled = true;
      } else {
        this.saleManInfo = (0, _cache.find)('saleManInfo');
        this.form.saleman_id = this.saleManInfo.id;
        this.disabled = false;
      }
    },
    submitForm: function submitForm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.loading = true;
          var url = _this.itemData ? 'editSaleQrCode' : 'addSaleQrCode';

          _api.default[url](_this.form).then(function (response) {
            _this.loading = false;
            _this.dialogVisible = false;

            _this.$emit('refresh');
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    uploadResult: function uploadResult(data) {
      this.form.qrcode = data[0] && data[0].attach_id || '';
    }
  }
};
exports.default = _default;