"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.middleUserBean = middleUserBean;
exports.classImageGroup = classImageGroup;

require("core-js/modules/es6.function.name");

/**
 * 中台：用户数据实体对象
 * @param r
 * @returns {{workStatus: boolean, classCount: (*|number), platId: number, phone: *, nickname, backUserId, workStatusTxt: string, id: *, userName: string}}
 */
function middleUserBean(r) {
  var className = r.className,
      commentName = r.commentName,
      classTeacherName = r.classTeacherName,
      surplusTime = r.surplusTime,
      studentNum = r.studentNum,
      status = r.status,
      startTime = r.startTime,
      courseName = r.courseName,
      userName = r.userName,
      lastDay = r.lastDay,
      nickname = r.nickname,
      vid = r.vid,
      dkNum = r.dkNum,
      dkNotComment = r.dkNotComment,
      name = r.name,
      classFinishedPercent = r.classFinishedPercent,
      renewPercent = r.renewPercent,
      uid = r.uid;
  return {
    id: r.id,
    platId: r.platId,
    backUserId: r.backUserId,
    nickname: nickname,
    // 点评老师姓名
    userName: userName,
    // 班主任用户名
    classCount: r.classCount || 0,
    phone: r.phone,
    vid: vid,
    lastDay: lastDay,
    // 剩余结课时间
    dkNum: dkNum,
    // 打卡数
    dkNotComment: dkNotComment,
    // 打卡未点评数
    workStatus: r.status === 1,
    // 在职状态（1-在职， 2-离职）
    workStatusTxt: r.status === 1 ? '在职' : '离职',
    studentNum: studentNum,
    // 学生数
    className: className,
    // 班级名称
    commentName: commentName,
    // 点评老师 取commentName
    classTeacherName: classTeacherName,
    // 班主任
    surplusTime: surplusTime,
    // 剩余结课时间
    startTime: startTime,
    // 开班时间
    courseName: courseName,
    // 课程类型
    classFinishedPercent: classFinishedPercent,
    // 完课率
    renewPercent: renewPercent,
    // 续费率
    classConfigTitle: name,
    // 课程名称
    classStatus: status === 0 ? '未开班' : status === 1 ? '已开班' : '已结课',
    // 剩余结课时间
    uid: uid
  };
}
/**
 * 课时图片配置对象
 * @param r
 * @returns {{isFree: (string|*), isExperience: (string|*), pid: number, id: (string|*), title: *, child: {oldCourseName: (*|string), isFree: (string|*), isExperience: (string|*), newCourseId: (*|string), pid: number, isActivity: *, id: (string|*), oldCourseId: (*|string), title: *, newCourseName: (*|string), fileId: (*|string)}[]}}
 */


function classImageGroup(r) {
  return {
    id: r.id,
    // 图片id
    imageName: r.name,
    // 图片名
    imageUrl: r.original_url // 图片地址

  };
}