"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _request = _interopRequireDefault(require("@/utils/request"));

var BASE_URL = process.env.VUE_APP_BASE_API;
var PROXY_ID = '/smartpen/api/admin';
var PROXY_MIDDLE_ID = '/userCenter/api';
var PROXY_ID2 = ''; // const BASE_URL = '';
// const PROXY_ID = '/api';

var _default = {
  /**
   * 用户列表数据
   * @param params
   */
  getUserList: function getUserList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/user/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 更新用户信息
   * @param data
   */
  updateUserInfo: function updateUserInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/user/update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 用户课程数据
   * @param params
   */
  getUserCourseList: function getUserCourseList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/user/courses"),
      method: 'get',
      params: params
    });
  },

  /**
   * 课程列表数据 course/lists
   * @param params
   */
  getCourseList_2: function getCourseList_2(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/lists"),
      // url: `${TEST_URL}/course/lists`,
      method: 'get',
      params: params
    });
  },

  /**
   * 章节列表数据
   * @param params
   */
  getSectionList: function getSectionList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course-section/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 新增章节
   * @param data
   */
  addSectionList: function addSectionList(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course-section/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 删除章节
   * @param data
   */
  delSectionList: function delSectionList(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course-section/delete"),
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑章节
   * @param data
   */
  updateSectionList: function updateSectionList(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course-section/update"),
      // url: `${TEST_URL}/course-section/update`,
      method: 'post',
      data: data
    });
  },

  /**
   * 课时列表数据
   * @param params
   */
  getSmartPenLessonList: function getSmartPenLessonList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course-lesson/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 删除课时
   * @param data
   */
  delLessonList: function delLessonList(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course-lesson/delete"),
      method: 'post',
      data: data
    });
  },

  /**
   * 查询gif
   * @param params
   */
  getCoursewareGif: function getCoursewareGif(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/resource/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 版本列表
   * @param params
   */
  getVersionList: function getVersionList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/version/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 用户反馈列表
   * @param params
   */
  getFreebackList: function getFreebackList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/feedback/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取课时点阵纸列表
   * @param params
   */
  getLessonAxis: function getLessonAxis(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/lesson-axis/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 新建点阵纸
   * @param data
   */
  addLessonAxis: function addLessonAxis(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/lesson-axis/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑点阵纸
   * @param data
   */
  updateLessonAxis: function updateLessonAxis(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/lesson-axis/update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 删除点阵纸
   * @param data
   */
  deleteLessonAxis: function deleteLessonAxis(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/lesson-axis/delete"),
      method: 'post',
      data: data
    });
  },

  /**
   * 添加版本
   * @param data
   */
  addVersion: function addVersion(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/version/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 添加版本
   * @param data
   */
  updateVersion: function updateVersion(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/version/update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 批量添加课时/章节资源
   * @param data
   */
  submitCoursewareResources: function submitCoursewareResources(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/create-resources"),
      method: 'post',
      data: data
    });
  },

  /**
   * 添加单个课时数据
   * @param data
   */
  addCourseLessonInfo: function addCourseLessonInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course-lesson/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑单个课时数据
   * @param data
   */
  editCourseLessonInfo: function editCourseLessonInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course-lesson/update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 导入章节信息
   * @param data
   */
  importSectionList: function importSectionList(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course-section/import"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取课时详情
   * @param data
   */
  getCourseLessonDetail: function getCourseLessonDetail(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course-lesson/view"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取课件详情信息
   */
  getCoursewareDetail: function getCoursewareDetail(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/fetch-resources"),
      method: 'get',
      params: params
    });
  },

  /**
   * 导入课时信息
   * @param data
   */
  importLessonList: function importLessonList(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course-lesson/import"),
      method: 'post',
      data: data
    });
  },

  /**
   * 导入课时信息
   * @param data
   */
  importLessonAxis: function importLessonAxis(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/lesson-axis/import"),
      method: 'post',
      data: data
    });
  },

  /**
   * 点评中台：获取点评师列表信息
   * @param data
   */
  getTeacherList: function getTeacherList(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL, "/comment/admin/teacher/list"),
      method: 'post',
      data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        plat_id: 3
      })
    });
  },

  /**
   * 点评中台：手动分配点评师
   * @param data
   */
  dispachTeacherToHomework: function dispachTeacherToHomework(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL, "/comment/admin/homework/appoint"),
      method: 'post',
      data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        plat_id: 3
      })
    });
  },

  /**
   * 点评中台：添加点评师
   * @param data
   */
  addTeacherInfo: function addTeacherInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_MIDDLE_ID, "/v1/userBack/addUser"),
      method: 'post',
      data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        platId: 3,
        role: 1
      })
    });
  },

  /**
   * 点评中台：编辑点评师
   * @param data
   */
  updateTeacherInfo: function updateTeacherInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_MIDDLE_ID, "/v1/userBack/updateUser"),
      method: 'post',
      data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        platId: 3,
        role: 1
      })
    });
  },

  /**
   * 点评中台：删除点评师
   * @param data
   */
  deleteTeacherInfo: function deleteTeacherInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_MIDDLE_ID, "/v1/userBack/deleteUser"),
      method: 'post',
      data: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        platId: 3,
        role: 1
      })
    });
  },

  /**
   * 获取系统用户列表信息
   * @param params
   */
  getOriginalUserList: function getOriginalUserList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/sysuser/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取班级列表
   * @param params
   */
  getClassRoomList: function getClassRoomList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/class/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 添加班级信息
   * @param data
   */
  addClassRoomInfo: function addClassRoomInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/class/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑班级信息
   * @param data
   */
  editClassRoomInfo: function editClassRoomInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/class/update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 删除班级
   * @param data
   */
  deleteClassRoom: function deleteClassRoom(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/class/delete"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取学员列表
   * @param params
   */
  getStudentList: function getStudentList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/class-user/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 删除班级用户
   * @param data
   */
  deleteClassRoomStudent: function deleteClassRoomStudent(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/class-user/delete"),
      method: 'post',
      data: data
    });
  },

  /**
   * 添加班级用户
   * @param data
   */
  addClassRoomStudent: function addClassRoomStudent(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/class-user/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 批量导入班级用户
   * @param data
   */
  importClassRoomStudent: function importClassRoomStudent(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/class-user/import"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取作业列表数据
   * @param params
   */
  getHomeworkList: function getHomeworkList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/homework/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取作业详情数据
   * @param params
   */
  getHomeworkDetail: function getHomeworkDetail(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/homework/view"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取点评详情列表数据
   * @param params
   */
  getReviewDetailList: function getReviewDetailList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/review-detail/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 删除点评详情
   * @param data
   */
  deleteReviewDetail: function deleteReviewDetail(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/review-detail/delete"),
      method: 'post',
      data: data
    });
  },

  /**
   * 合成语音
   * @param data
   */
  setTextToVoice: function setTextToVoice(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/review-detail/tts"),
      method: 'post',
      data: data
    });
  },

  /**
   * 生成点评记录
   * @param data
   */
  submitReviewInfo: function submitReviewInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/review/create"),
      method: 'post',
      data: data
    });
  },

  /**
   * 智能笔少儿发货审核列表
   * @param params
   */
  getShipmentReviewListPen: function getShipmentReviewListPen(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID2, "/sale/childa/shoporder-reviewlist"),
      // url: `/api/sale/childa/shoporder-reviewlist`,
      method: 'get',
      params: params
    });
  },

  /**
   * 旺店通确定建单接口
   * @param data
   */
  buildOrder: function buildOrder(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID2, "/sale/childa/shoporder-createOrder2center"),
      // url: `/api/sale/childa/shoporder-createOrder2center`,
      method: 'post',
      data: data
    });
  },

  /**
   * 修改商品接口
   * @param params
   */
  changeGoodsPen: function changeGoodsPen(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID2, "/sale/childa/shoporder-orderinfo"),
      // url: `/api/sale/childa/shoporder-orderinfo`,
      method: 'get',
      params: params
    });
  },

  /**
   * 保存商品接口
   * @param data
   */
  saveGoods: function saveGoods(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID2, "/sale/childa/shoporder-editPreOrder"),
      // url: `/api/sale/childa/shoporder-editPreOrder`,
      method: 'post',
      data: data
    });
  },

  /**
   * 建单弹窗判断接口
   * @param params
   */
  buildPop: function buildPop(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID2, "/sale/childa/shoporder-preOrderMsg"),
      // url: `/api/sale/childa/shoporder-preOrderMsg`,
      method: 'get',
      params: params
    });
  },

  /**
   * 班主任列表
   * @param params
   */
  getAssistantList: function getAssistantList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/assistant/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 添加班主任
   * @param data
   */
  addAssistantInfo: function addAssistantInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/assistant/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑班主任
   * @param data
   */
  updateAssistantInfo: function updateAssistantInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/assistant/update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取课程包班级列表
   * @param params
   */
  getPackageClassRoomList: function getPackageClassRoomList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/packageclass/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 添加课程包班级信息
   * @param data
   */
  addPackageClassRoomInfo: function addPackageClassRoomInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/packageclass/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑课程包班级信息
   * @param data
   */
  editPackageClassRoomInfo: function editPackageClassRoomInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/packageclass/update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 删除课程包班级信息
   * @param data
   */
  deletePackageClassRoomInfo: function deletePackageClassRoomInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/packageclass/delete"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取课程包班级学员列表
   * @param params
   */
  getPackageStudentList: function getPackageStudentList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/packageclass-user/lists"),
      method: 'get',
      params: params
    });
  },

  /**
   * 添加课程包班级用户
   * @param data
   */
  addPackageClassRoomStudent: function addPackageClassRoomStudent(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/packageclass-user/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 更新课程包班级用户信息
   * @param data
   */
  updatePackageClassRoomStudentInfo: function updatePackageClassRoomStudentInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/packageclass-user/update"),
      method: 'post',
      data: data
    });
  },

  /**
   * 删除课程包班级用户
   * @param data
   */
  deletePackageClassRoomStudent: function deletePackageClassRoomStudent(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/packageclass-user/delete"),
      method: 'post',
      data: data
    });
  },

  /**
   * 批量导入课程包班级用户
   * @param data
   */
  importPackageClassRoomStudent: function importPackageClassRoomStudent(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/packageclass-user/import"),
      method: 'post',
      data: data
    });
  },

  /**
   * 导出作业列表数据
   * @param params
   */
  exportHomeworkList: function exportHomeworkList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/homework/download"),
      method: 'get',
      params: params
    }, true);
  },

  /**
   * 修复单字生成gif数据
   * @param params
   */
  fixedWordCompoundGif: function fixedWordCompoundGif(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL, "/smartpenService/api/v1/smartPen/gif"),
      method: 'get',
      params: params
    }, true);
  },

  /**
   * WebSocket评分结果实时通知
   */
  URL_SOCKET_SCORE: "".concat(process.env.VUE_APP_SOCKET_API, "/smartpenService/ws/smartPen/trainingMate")
};
exports.default = _default;