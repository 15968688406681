var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData }
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { "show-header": false, data: props.row.child }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "", width: "200" }
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "title", width: "300" }
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "操作" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("el-date-picker", {
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期时间"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.dateChange(
                                            props.$index,
                                            scope.row,
                                            scope.$index
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.date,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "date", $$v)
                                        },
                                        expression: "scope.row.date"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "章节名称", prop: "title", width: "200" }
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "课时名称", width: "300" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { label: "操作" } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "submitBox" },
        [
          _vm.tableData.length
            ? _c(
                "el-button",
                {
                  staticClass: "submitBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("完成")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.studyConfigType === "init"
            ? _c(
                "div",
                _vm._l(_vm.dynamicTags, function(tag) {
                  return _c(
                    "el-tag",
                    {
                      key: tag,
                      staticStyle: { "margin-left": "50px" },
                      attrs: {
                        closable: "",
                        "disable-transitions": false,
                        type: "success"
                      },
                      on: {
                        close: function($event) {
                          return _vm.handleClose(tag)
                        }
                      }
                    },
                    [_vm._v("\n                " + _vm._s(tag))]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }