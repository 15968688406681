"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var onlineBusinessRouter = {
  path: '/ob',
  component: _layout.default,
  redirect: '/ob/bookuser',
  name: 'Ob',
  meta: {
    title: '电商'
  },
  children: [{
    path: 'bookuser',
    name: 'Bookuser',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/onlineBusiness/bookUser.vue'));
      });
    },
    meta: {
      title: '字帖用户'
    }
  }, {
    path: 'testuser/:id',
    name: 'Testuser',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/onlineBusiness/testUser.vue'));
      });
    },
    meta: {
      title: '测评用户',
      noCache: true
    }
  }, {
    path: 'contconfig',
    name: 'Contconfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/onlineBusiness/contConfig.vue'));
      });
    },
    meta: {
      title: '内容配置'
    }
  }]
};
var _default = onlineBusinessRouter;
exports.default = _default;