var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("search-box", {
        attrs: { showMore: false },
        on: { search: _vm.onSubmit, reset: _vm.reset },
        scopedSlots: _vm._u([
          {
            key: "basic",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "课程名称：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "班级名称", clearable: true },
                      model: {
                        value: _vm.searchParam.name,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "name", $$v)
                        },
                        expression: "searchParam.name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "关联课程：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "关联课程", clearable: true },
                      model: {
                        value: _vm.searchParam.vid,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "vid", $$v)
                        },
                        expression: "searchParam.vid"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _vm.hasPerm("ClassCourseList:add")
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track:click",
                          arg: "click"
                        }
                      ],
                      attrs: {
                        "track-params": "在线-课程-新增",
                        type: "primary",
                        icon: "el-icon-plus"
                      },
                      on: {
                        click: function($event) {
                          return _vm.jumpPage("add")
                        }
                      }
                    },
                    [_vm._v("新增")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "show-index": false,
          "data-list": _vm.tableData,
          columns: _vm.columns,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _vm.hasPerm("ClassCourseList:del")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                attrs: {
                                  "track-params": "在线-课程-删除",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelClassItem(props.row)
                                  }
                                }
                              },
                              [_vm._v("删除\n                ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPerm("ClassCourseList:edit")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                attrs: {
                                  "track-params": "在线-课程-编辑",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.jumpPage("edit", props.row)
                                  }
                                }
                              },
                              [_vm._v("编辑\n                ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }