"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.array.find");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _clipboard = _interopRequireDefault(require("clipboard"));

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _BuildOrderDialog = _interopRequireDefault(require("./components/BuildOrderDialog"));

var _ChangeOrder = _interopRequireDefault(require("./components/ChangeOrder"));

var _utils = require("@/utils");

var _initData = require("../initData");

var _cache = require("@/utils/cache");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: '',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default,
    BuildOrderDialog: _BuildOrderDialog.default,
    ChangeOrder: _ChangeOrder.default
  },
  filters: {
    ordertypeText: function ordertypeText(val) {
      var item = _initData.orderOptions.filter(function (item) {
        return Number(item.value) === Number(val);
      });

      return item[0] && item[0].label || '';
    },
    wdt_stateText: function wdt_stateText(val) {
      var item = _initData.shopOptions.filter(function (item) {
        return Number(item.value) === Number(val);
      });

      return item[0] && item[0].label || '';
    },
    sendStatusText: function sendStatusText(val) {
      var item = _initData.sendOptions.filter(function (item) {
        return Number(item.value) === Number(val);
      });

      return item[0] && item[0].label || '';
    },
    fomatteAddr: function fomatteAddr(val) {
      // console.log(val);
      // console.log(JSON.parse(val));
      if (val) {
        var addr = JSON.parse(val);
        var phone = addr.phone || '';
        var contacts = addr.contacts || '';
        var province = addr.province || '';
        var city = addr.city || '';
        var region = addr.region || '';
        var detail = addr.detail || '';
        var addrDtail = contacts + ' ' + phone + ' ' + province + ' ' + city + ' ' + region + ' ' + detail; // let addrDtail = `${contacts}
        // ${phone}`;

        return addrDtail;
      } //    console.log(JSON.parse(e));

    },
    fomatteProduct: function fomatteProduct(val) {
      // 兜底
      if (val !== 'null' && val) {
        var product = JSON.parse(val);
        var proTitle = '';
        product.forEach(function (item, index) {
          proTitle += index + 1 + '、' + item.title + '（' + item.sku + '）';
        });
        return proTitle;
      }
    }
  },
  data: function data() {
    return {
      createTime: '',
      shipReviewData: _initData.initData,
      total: 0,
      tableData: [],
      listLoading: false,
      searchParam: {},
      orderOptionsArr: [{
        value: '',
        label: '全部'
      }].concat(_initData.orderOptions),
      shopOptionsArr: [{
        value: '',
        label: '全部'
      }].concat(_initData.shopOptions),
      sendOptionsArr: [{
        value: '',
        label: '全部'
      }].concat(_initData.sendOptions),
      selectedItem: [],
      copyData: '',
      actOptions: _initData.actOptions // uid: ''

    };
  },
  created: function created() {
    // this.getUid();
    var fieldJson = (0, _cache.find)('fieldJson');

    if (fieldJson && fieldJson[this.$route.name]) {
      _initData.initData.shipReviewList.columns = fieldJson[this.$route.name];
    }

    this.reset();
  },
  methods: {
    // getUid() {
    //     let userInfo = localStorage.getItem('userInfo');
    //     let userInfo_json = JSON.parse(userInfo);
    //     let uid = userInfo_json.data.myinfo.uid;
    //     console.log(uid);
    //     if (uid == '569369') {
    //         uid = '';
    //     }
    //     this.searchParam.sale_uid = uid;
    // },
    reset: function reset() {
      var userInfo = localStorage.getItem('userInfo');
      var userInfo_json = JSON.parse(userInfo);
      var uid = userInfo_json.data.backUserInfo.uid;
      var roleCode = userInfo_json.data.backUserInfo.roleCode;

      if (uid === '569369') {
        uid = '';
      }

      if (roleCode === 'admin') {
        uid = '';
      }

      this.searchParam = {
        id: '',
        sale_uid: uid,
        sale_uid_uname: '',
        phone: '',
        infophone: '',
        uname: '',
        type: 1,
        wdt_state: '',
        delivery_state: '',
        act_status: '',
        start_time: '',
        end_time: '',
        page: 1,
        page_size: 10
      };
      this.createTime = '';
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getShipmentReviewList(this.searchParam).then(function (res) {
        console.log(res.data);
        _this.tableData = res.data.data;
        _this.total = res.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    handleFieldChange: function handleFieldChange(val) {
      this.shipReviewData.shipReviewList.columns = val;
    },
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.searchParam.start_time = (0, _utils.parseTime)(val[0]);
        this.searchParam.end_time = (0, _utils.parseTime)(val[1]);
      } else {
        this.searchParam.start_time = '';
        this.searchParam.end_time = '';
      }
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    showDialog: function showDialog(item) {
      console.log(item);
      this.$refs.buildOrderDialog.showDialog(item.id);
    },
    changeGoods: function changeGoods(item) {
      console.log(item);
      this.$refs.changeOrder.showDialog(item.id);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      this.selectedItem = val;
    },
    copy: function copy(item) {
      var clipboard = new _clipboard.default('.copy');
      var time = item.created_at;
      var uname = item.uname;
      var phone = item.phone;
      var title = item.title; // 地址

      var addr = JSON.parse(item.address);
      var contacts = addr.contacts;
      var tel = addr.phone;
      var province = addr.province || '';
      var city = addr.city || '';
      var region = addr.region || '';
      var detail = addr.detail || '';
      var addrDtail = contacts + ' ' + tel + ' ' + province + ' ' + city + ' ' + region + ' ' + detail; // 发货

      var product = JSON.parse(item.product);
      var proTitle = '';
      product.forEach(function (item, index) {
        proTitle += index + 1 + '.' + item.title + ' ';
      });
      this.copyData = '订单时间：' + time + ' 用户昵称：' + uname + ' 手机号：' + phone + ' 课程：' + title + ' 收货地址：' + addrDtail + ' 发货商品：' + proTitle; // let clipboard = new Clipboard('.copy', {
      //     text: function (trigger) {
      //         return copyData;
      //     }
      // });

      clipboard.on('success', function () {
        console.log('复制成功，粘贴给好友吧~');
      });
      clipboard.on('error', function (error) {
        console.log('复制失败，请稍后再试');
      });
    }
  }
};
exports.default = _default;