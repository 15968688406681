"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueAplayer = _interopRequireDefault(require("vue-aplayer"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PreviewDialog',
  components: {
    Aplayer: _vueAplayer.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      player: null,
      previewData: ''
    };
  },
  destroyed: function destroyed() {
    this.clear();
  },
  methods: {
    showDialog: function showDialog(data) {
      var _this = this;

      this.previewData = data;
      this.dialogVisible = true;

      if (data.fileType === 'video') {
        var options = {
          fileID: data.fileId,
          appID: '1254153797',
          poster: data.coverImg,
          plugins: {
            ContinuePlay: {
              auto: false,
              text: '上次看到',
              btnText: '恢复播放'
            }
          }
        };
        this.$nextTick(function () {
          // eslint-disable-next-line
          _this.player = TCPlayer("player-container-id-".concat(data.fileId), options);
        });
      }
    },
    handleClose: function handleClose() {
      this.clear();
    },
    clear: function clear() {
      this.player && this.player.dispose();
      this.player = '';
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;