var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改发货信息",
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.sendPageData }
                        },
                        [_vm._v("保 存")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "addrInfo" }, [
            _c("span", [_vm._v(_vm._s(_vm.addressinfo.contacts))]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.addressinfo.phone))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.addressinfo.province) +
                  " " +
                  _vm._s(_vm.addressinfo.city) +
                  "\n                " +
                  _vm._s(_vm.addressinfo.region)
              )
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.addressinfo.detail))]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "changeAddr",
                on: {
                  click: function($event) {
                    return _vm.showAddrDialog(_vm.addressinfo)
                  }
                }
              },
              [_vm._v("修改")]
            )
          ]),
          _vm._v(" "),
          _c("page-table", {
            ref: "pageTable",
            attrs: {
              loading: _vm.listLoading,
              "data-list": _vm.tableData,
              columns: _vm.gridData.goodsInfo.columns,
              "page-no": _vm.searchParam.page,
              "page-size": _vm.searchParam.per_page,
              total: _vm.total
            },
            on: { pageNoChange: _vm.pageNoChange },
            scopedSlots: _vm._u([
              {
                key: "cell",
                fn: function(props) {
                  return [
                    props.column.label === "操作"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.delGoods(props, props.row)
                                  }
                                }
                              },
                              [_vm._v("删除\n                    ")]
                            )
                          ],
                          1
                        )
                      : props.column.label === "SKU"
                      ? _c(
                          "div",
                          [
                            _c("el-input", {
                              model: {
                                value: props.row.sku,
                                callback: function($$v) {
                                  _vm.$set(
                                    props.row,
                                    "sku",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "props.row.sku"
                              }
                            })
                          ],
                          1
                        )
                      : props.column.label === "数量"
                      ? _c(
                          "div",
                          [
                            _c("el-input", {
                              model: {
                                value: props.row.num,
                                callback: function($$v) {
                                  _vm.$set(props.row, "num", $$v)
                                },
                                expression: "props.row.num"
                              }
                            })
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(_vm._s(props.row[props.column.property]))
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "add_search" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showSearch = true
                    }
                  }
                },
                [_vm._v("添加商品")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSearch,
                      expression: "showSearch"
                    }
                  ]
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "id" },
                    model: {
                      value: _vm.searchParam.id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParam, "id", $$v)
                      },
                      expression: "searchParam.id"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { placeholder: "商品名称" },
                    model: {
                      value: _vm.searchParam.name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParam, "name", $$v)
                      },
                      expression: "searchParam.name"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onSearch(_vm.searchParam)
                        }
                      }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("search-list", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSearchModal,
                expression: "showSearchModal"
              }
            ],
            ref: "searchFuc",
            on: { getSearchData: _vm.getSearchData }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("build-addr", { ref: "buildAddr", on: { addrInfo: _vm.addrInfo } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }