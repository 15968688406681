"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.array.find");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _auth = require("@/utils/auth");

var _cache = require("@/utils/cache");

var _router = require("@/router");

var _elementUi = require("element-ui");

// import { set } from 'nprogress';
var getDefaultState = function getDefaultState() {
  return {
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    roles: [],
    btnLimts: [],
    cachedViews: [],
    visitedViews: []
  };
};

var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_BTN_LIMITS: function SET_BTN_LIMITS(state, btnLimts) {
    state.btnLimts = btnLimts;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _api.default.login(userInfo).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.tokenInfo.token);
        (0, _cache.save)('userInfo', data);
        (0, _auth.setToken)(data.tokenInfo.token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get menu info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      var _find = (0, _cache.find)('userInfo'),
          backUserInfo = _find.backUserInfo;

      commit('SET_NAME', backUserInfo.userName);
      commit('SET_AVATAR', backUserInfo.userPic);

      _api.default.getMenuInfo().then(function (res) {
        var roles = res.data;

        if (roles && roles.length) {
          commit('SET_ROLES', roles);
          resolve(res.data);
        } else {
          reject(new Error('getInfo: roles must be a non-null array!'));
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get button limits
  getBtnLimits: function getBtnLimits(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      _api.default.getBtnLimits().then(function (res) {
        var btnLimts = res.data;

        if (btnLimts && btnLimts.length) {
          commit('SET_BTN_LIMITS', btnLimts);
          resolve(btnLimts);
        } else {
          reject(new Error('按钮权限列表为空'));
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref4) {
    var commit = _ref4.commit,
        state = _ref4.state;
    return new Promise(function (resolve, reject) {
      (0, _auth.removeToken)();
      (0, _router.resetRouter)();
      commit('RESET_STATE');
      resolve();
    });
  },
  // remove token
  resetToken: function resetToken(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      (0, _auth.removeToken)();
      commit('RESET_STATE');
      resolve();
    });
  },
  // reset password
  changePassword: function changePassword(_ref6, password) {
    var commit = _ref6.commit;
    return new Promise(function (resolve, reject) {
      _api.default.resetPwd(password).then(function (response) {
        if (response.status === 0) {
          (0, _elementUi.Message)({
            message: '修改密码成功',
            type: 'success'
          });
          (0, _auth.removeToken)();
          (0, _router.resetRouter)();
          commit('RESET_STATE');
          setTimeout(function () {
            resolve();
          }, 1500);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;