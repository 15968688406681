"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ServiceGroup',
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      listLoading: false,
      searchParam: {
        page: 1,
        page_size: 10
      },
      columns: [{
        label: 'id',
        width: '60',
        prop: 'id'
      }, {
        label: '客服组名称',
        width: '60',
        prop: 'groupName'
      }, {
        label: '昨日统计',
        width: '60',
        prop: 'yesterdayNum'
      }, {
        label: '总统计',
        width: '60',
        prop: 'totalNum'
      }, {
        label: '操作',
        width: '60',
        fix: 'right'
      }],
      total: 0,
      tableData: []
    };
  },
  mounted: function mounted() {
    console.log(this.$route.meta);
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getServiceGroupList().then(function (response) {
        _this.tableData = response.data.infos.map(function (item) {
          return _bean.default.serviceGroupBean(item);
        });
        _this.total = response.data.totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = [];
        _this.listLoading = false;
      });
    },
    jumpPage: function jumpPage(item) {
      this.$router.push({
        name: 'ServiceCodeList',
        params: {
          id: item.id,
          lineId: item.lineId
        }
      });
    }
  }
};
exports.default = _default;