"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userBean = userBean;
exports.userCourseBean = userCourseBean;
exports.courseListBean = courseListBean;
exports.sectionListBean = sectionListBean;
exports.lessonListBean = lessonListBean;
exports.teacherBean = teacherBean;
exports.assistantBean = assistantBean;
exports.classRoomBean = classRoomBean;
exports.studentBean = studentBean;
exports.homeWorkBean = homeWorkBean;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _utils = require("@/utils");

function baseUserInfo(r) {
  return {
    id: r.id,
    uid: r.uid,
    uname: r.uname,
    // 客户昵称
    avatar: r.avatar,
    // 客户头像
    phone: r.phone,
    // 手机号
    sex: r.sex,
    // 性别
    sexText: r.sexValue,
    birthday: r.birthday,
    // 生日
    areaStr: r.areaStr,
    // 地区
    courseNum: r.courseNum,
    // 课程数
    created_at: r.created_at,
    teacherId: r.teacherId,
    teacherName: r.teacherName
  };
}
/**
 * 用户列表
 * @param r
 * @returns {{uid, uname, avatar,phone, sex,birthday,areaStr,courseNum,created_at}
 */


function userBean(r) {
  return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, baseUserInfo(r)), {}, {
    grade: r.grade,
    gradeValue: r.gradeValue,
    wechat_added: !!r.wechat_added,
    wechatAddedValue: r.wechatAddedValue
  });
}
/**
 * 用户已购课程列表
 * @param r
 * @returns {{id, course_id, course_title, created_at,channel}
 */


function userCourseBean(r) {
  return {
    id: r.id,
    course_id: r.course_id,
    course_title: r.course_title,
    created_at: r.created_at,
    channel: r.channel
  };
}
/**
 * 用户课程列表
 * @param r
 * @returns {{id, course_id, video_title,cover, created_at,userNum}
 */


function courseListBean(r) {
  return {
    id: r.id,
    course_id: r.course_id,
    video_title: r.video_title,
    created_at: r.created_at ? (0, _utils.parseTime)(r.created_at) : '',
    cover: r.cover,
    userNum: r.userNum
  };
}
/**
 * 用户章节列表
 * @param r
 * @returns {{id,course_id,courseName, name,created_at}
 */


function sectionListBean(r) {
  return {
    id: r.id,
    course_id: r.course_id,
    courseName: r.courseName,
    name: r.name,
    game_time: r.game_time,
    created_at: r.created_at,
    sort: r.sort,
    status: !!r.status
  };
}
/**
 * 课时列表列表
 * @param r
 * @returns {{id,name,type,typeValue,length,page_code,video_resource_id,videoResourceUrl,content,sort,gif_resource_id,gifResourceUrl}
 */


function lessonListBean(r) {
  return {
    id: r.id,
    name: r.name,
    type: r.type,
    typeValue: r.typeValue,
    length: r.length,
    page_code: r.page_code,
    map_code: r.map_code,
    video_resource_id: r.video_resource_id,
    videoResourceUrl: r.videoResourceUrl,
    sort: r.sort,
    gif_resource_id: r.gif_resource_id,
    gifResourceUrl: r.gifResourceUrl,
    attentionResourceName: r.attentionResourceName,
    attentionResourceUrl: r.attentionResourceUrl,
    attention_resource_id: r.attention_resource_id,
    defaultPicResource: r.defaultPicResource,
    defaultPicResourceUrl: r.defaultPicResourceUrl,
    default_pic_resource_id: r.default_pic_resource_id,
    gifResourceName: r.gifResourceName,
    redPicResource: r.redPicResource,
    redPicResourceUrl: r.redPicResourceUrl,
    red_pic_resource_id: r.red_pic_resource_id,
    strokeResourceName: r.strokeResourceName,
    strokeResourceUrl: r.strokeResourceUrl,
    stroke_resource_id: r.stroke_resource_id,
    videoFileid: r.videoFileid,
    videoName: r.videoName,
    wordResourceName: r.wordResourceName,
    wordResourceUrl: r.wordResourceUrl,
    word_resource_id: r.word_resource_id
  };
}
/**
 * 老师数据实体对象
 * @param r
 * @returns {{phone: *, createTime: (string|*), name: *, id: *, avatar: *, classRoomNum: *, noReviewNum: *}}
 */


function teacherBean(r) {
  return {
    id: r.back_user_id,
    backUserId: r.back_user_id,
    platId: r.plat_id,
    uid: r.uid,
    name: r.name,
    uname: r.nickname,
    createTime: r.created_at,
    isLimit: r.is_limit,
    // 分配作业是否有上限
    topNum: r.limit_num,
    // 分配作业上限数量
    topNumText: Number(r.is_limit) === 1 ? '无上限' : r.limit_num,
    // 分配作业上限数量值
    workStatusValue: r.status_name,
    // 工作状态
    workStatus: r.status,
    atWork: Number(r.at_work) === 1,
    // 是否在岗
    atWorkValue: r.at_work,
    // 是否在岗
    atWorkName: r.at_work_name,
    phone: r.mobile,
    classId: r.class_id,
    classNum: r.class_num,
    // 负责班级数
    courseIds: r.course_id,
    classRoomNum: r.classroomNum,
    // 课程班数
    isMasterValue: r.isMasterValue,
    isMaster: r.is_master,
    reviewedNum: r.homework_done,
    // 已点评作业数
    noReviewNum: r.homework_not,
    // 未点评作业数
    outTimeNoNum: r.homework_late,
    // 未及时点评作业数
    reviewedRate: r.comment_percent,
    // 点评率
    timelyRate: r.comment_percent_timely,
    // 点评及时率
    userAccount: r.user_account
  };
}

function assistantBean(r) {
  return {
    id: r.id,
    uid: r.uid,
    name: r.name,
    uname: r.uname,
    avatar: r.headResourceUrl,
    phone: r.phone,
    createTime: r.created_at,
    classRoomNum: r.classroomNum,
    // 带班数
    packageClassNum: r.packageclassNum,
    // 课程包班级数
    noReviewNum: r.noReviewNum,
    // 未点评作业数
    workStatusValue: r.workStatusValue,
    // 工作状态
    workStatus: r.work_status,
    wechatImg: r.wechat_img
  };
}
/**
 * 班级数据实体对象
 * @param r
 * @returns {{id: *}}
 */


function classRoomBean(r) {
  return {
    id: r.id,
    name: r.name,
    // 班级名称
    teacherId: r.teacher_id,
    // 点评师ID
    teacherName: r.teacherName,
    // 点评师姓名
    assistantId: r.assistant_id,
    // 班主任ID
    assistantName: r.assistantName,
    // 班主任姓名
    courseId: r.course_id,
    // 课程ID
    courseName: r.courseName,
    // 课程名称
    phone: r.phone,
    // 手机号
    userNum: r.userNum,
    // 学员数
    noReviewNum: r.noReviewNum,
    // 未点评作业数
    createTime: r.created_at,
    // 添加时间
    startTime: r.start_time,
    // 开班时间
    hasComment: r.has_comment,
    hasCommentValue: r.hasCommentValue,
    // 点评服务值
    courseIds: r.course_id,
    workStatusValue: r.workStatusValue,
    workStatus: r.work_status
  };
}
/**
 * 学生数据实体对象
 * @param r
 * @returns {{birthday, uid, uname, phone, areaStr, courseNum, createTime: (string|*), sex, created_at, avatar, sexText, noReviewNum: *}}
 */


function studentBean(r) {
  return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, baseUserInfo(r)), {}, {
    noReviewNum: r.noReviewNum,
    // 未点评作业数
    createTime: r.created_at,
    bindTeacher: !!r.bind_teacher,
    bindTeacherValue: r.bindTeacherValue
  });
}
/**
 * 作业数据实体对象
 * @param r
 * @returns {{uname: *, teacherName: (string|rules.teacherName|{trigger, message, required}|*), lessonId: (number|*), className: *, reviewStatusText: *, sectionId: (number|*), lessonName: *, uid: *, classId, teacherId: (string|*), phone: *, createTime: (string|*), reviewStatus: *, id: *, reviewTime: *}}
 */


function homeWorkBean(r) {
  return {
    id: r.id,
    uid: r.uid,
    uname: r.uname,
    phone: r.phone,
    classId: r.class_id,
    className: r.className,
    packageClassName: r.packageclassName,
    // 课程包班级名称
    courseName: r.courseName,
    lessonName: r.lessonName,
    sectionId: r.section_id,
    lessonId: r.lesson_id,
    assistantName: r.assistantName,
    teacherId: r.teacher_id,
    teacherName: r.teacherName,
    reviewTeacherName: r.reviewTeacherName,
    reviewTime: r.reviewAt,
    reviewStatus: r.review_status,
    reviewStatusText: r.reviewStatus,
    createTime: r.created_at,
    isOverValue: r.isOverValue
  };
}