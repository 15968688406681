var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _vm.hasPerm("VersionList:add")
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track:click",
                          arg: "click"
                        }
                      ],
                      attrs: {
                        "track-params": "智能笔-版本号列表-添加",
                        type: "primary",
                        icon: "el-icon-plus"
                      },
                      on: { click: _vm.add }
                    },
                    [_vm._v("添加")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          "page-no": _vm.listQuery.page,
          "page-size": _vm.listQuery.page_size,
          loading: _vm.listLoading,
          "show-index": false,
          "data-list": _vm.tableData,
          columns: _vm.initCourseData.versionList.columns,
          total: _vm.total,
          selection: true
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _vm.hasPerm("VersionList:edit")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                attrs: {
                                  "track-params": "智能笔-版本号列表-编辑",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.jumpPage(props.row)
                                  }
                                }
                              },
                              [_vm._v("编辑\n                ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("VersionEdit", { ref: "editVersion", on: { refresh: _vm.reset } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }