"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _base = _interopRequireDefault(require("./base"));

var _classRoom = _interopRequireDefault(require("./classRoom"));

var _sales = _interopRequireDefault(require("./sales"));

var _role = _interopRequireDefault(require("./role"));

var _user = _interopRequireDefault(require("./user"));

var _smartPen = _interopRequireDefault(require("./smartPen"));

var _child = _interopRequireDefault(require("./child"));

var _adult = _interopRequireDefault(require("./adult"));

var _middle = _interopRequireDefault(require("./middle"));

var _online = _interopRequireDefault(require("./online"));

var _default = (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _middle.default), _adult.default), _smartPen.default), _base.default), _classRoom.default), _sales.default), _user.default), _role.default), _child.default), _middle.default), _online.default);

exports.default = _default;