"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actOptions = exports.sendOptions = exports.shopOptions = exports.orderOptions = exports.initData = exports.classifyOption = exports.replayOption = exports.recommendOption = void 0;
var recommendOption = [{
  value: 1,
  label: '是'
}, {
  value: 0,
  label: '否'
}];
exports.recommendOption = recommendOption;
var replayOption = [{
  value: 1,
  label: '开放'
}, {
  value: 0,
  label: '不开放'
}];
exports.replayOption = replayOption;
var classifyOption = [{
  value: 1,
  label: '正楷'
}, {
  value: 2,
  label: '行楷'
}, {
  value: 3,
  label: '软笔'
}];
exports.classifyOption = classifyOption;
var orderOptions = [{
  value: 1,
  label: '课程包'
}, {
  value: 2,
  label: '课程'
}];
exports.orderOptions = orderOptions;
var shopOptions = [{
  value: 0,
  label: '-'
}, {
  value: 1,
  label: '推送成功'
}];
exports.shopOptions = shopOptions;
var sendOptions = [{
  value: 0,
  label: '未发货'
}, {
  value: 1,
  label: '已发货'
}, {
  value: 2,
  label: '已签收'
}];
exports.sendOptions = sendOptions;
var actOptions = [{
  value: 0,
  label: '全部'
}, {
  value: 1,
  label: '申请修改'
}, {
  value: 2,
  label: '未申请修改'
}];
exports.actOptions = actOptions;
var initData = {
  shipReviewList: {
    columns: [{
      label: '订单编号',
      width: '80',
      prop: 'id',
      hide: false
    }, {
      label: '中台订单号',
      width: '100',
      prop: 'shoporder',
      hide: false
    }, {
      label: '创建时间',
      width: '160',
      prop: 'created_at',
      hide: false
    }, {
      label: '用户ID',
      width: '80',
      prop: 'uid',
      hide: false
    }, {
      label: '用户名称',
      width: '100',
      prop: 'uname',
      hide: false
    }, {
      label: '销售ID',
      width: '100',
      prop: 'sale_uid',
      hide: false
    }, {
      label: '销售',
      width: '100',
      prop: 'sale_uid_uname',
      hide: false
    }, {
      label: '手机号(账号绑定)',
      width: '120',
      prop: 'phone',
      hide: false
    }, {
      label: '手机号',
      width: '120',
      prop: 'infophone',
      hide: false
    }, {
      label: '课程/课程包',
      width: '100',
      prop: 'title',
      hide: false
    }, {
      label: '订单金额',
      width: '80',
      prop: 'totalprice',
      hide: false
    }, {
      label: '收货地址',
      width: '160',
      prop: 'address',
      hide: false
    }, {
      label: '发货商品',
      width: '160',
      prop: 'product',
      hide: false
    }, {
      label: '订单类型',
      width: '80',
      prop: 'type',
      hide: false
    }, {
      label: '旺店通推送状态',
      width: '100',
      prop: 'wdt_state',
      hide: false
    }, {
      label: '发货状态',
      width: '80',
      prop: 'delivery_state',
      hide: false
    }, {
      label: '快递公司',
      width: '80',
      prop: 'delivery_company',
      hide: false
    }, {
      label: '快递单号',
      width: '140',
      prop: 'delivery_code',
      hide: false
    }, {
      label: '客户备注',
      width: '120',
      prop: 'remark',
      hide: false
    }, {
      label: '申请修改备注',
      width: '120',
      prop: 'act_remark',
      hide: false
    }, {
      label: '操作',
      width: '100',
      fix: 'right'
    }]
  },
  goodsInfo: {
    columns: [{
      label: '关联id',
      width: '60',
      prop: 'sm_id'
    }, {
      label: '商品id',
      width: '60',
      prop: 'pid'
    }, {
      label: '商品名称',
      width: '100',
      prop: 'title'
    }, {
      label: 'SKU',
      width: '60',
      prop: 'sku'
    }, {
      label: '数量',
      width: '60',
      prop: 'num'
    }, {
      label: '操作',
      width: '100',
      fix: 'right'
    }]
  },
  searchInfo: {
    columns: [{
      label: '关联id',
      width: '60',
      prop: 'sm_id'
    }, {
      label: '商品id',
      width: '60',
      prop: 'pid'
    }, {
      label: '商品名称',
      width: '100',
      prop: 'title'
    }, {
      label: 'SKU',
      width: '60',
      prop: 'sku'
    }, {
      label: '数量',
      width: '60',
      prop: 'num'
    }, {
      label: '操作',
      width: '100',
      fix: 'right'
    }]
  }
};
exports.initData = initData;