var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _vm.homeworkList.length
      ? _c("div", { staticClass: "homework-list-content" }, [
          _c("div", { staticClass: "arrow prev", on: { click: _vm.prev } }, [
            _c("i", { staticClass: "el-icon-arrow-left" })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { ref: "slideWrap", staticClass: "slide-wrap" },
            _vm._l(_vm.homeworkList, function(item, index) {
              return _c("div", { key: index, staticClass: "slide-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "slide-item-inner",
                    class: _vm.selectHomeworkIndex === index ? "active" : "",
                    on: {
                      click: function($event) {
                        return _vm.handleHomework(item, index)
                      }
                    }
                  },
                  [
                    _c("p", [_vm._v("用户名" + _vm._s(item.uname))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(item.lessonName))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(item.reviewStatusText))])
                  ]
                )
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "arrow next", on: { click: _vm.next } }, [
            _c("i", { staticClass: "el-icon-arrow-right" })
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.homeworkDetail
      ? _c("div", { staticClass: "identify-content" }, [
          _c("div", { staticClass: "identify-product-tool" }, [
            _c(
              "div",
              { staticClass: "word-list" },
              _vm._l(_vm.homeworkDetail.scoreRecords, function(item, index) {
                return _c("div", { key: index, staticClass: "word-item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "word-item-inner",
                      on: {
                        click: function($event) {
                          return _vm.doWordTap(item, index)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "word-item-img" }, [
                        _c("img", {
                          staticClass: "word-bg-img",
                          attrs: { src: require("@/assets/znb_bg_word@1x.png") }
                        }),
                        _vm._v(" "),
                        item.word_pic
                          ? _c("img", {
                              staticClass: "word-img",
                              attrs: { src: item.word_pic }
                            })
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "word-radio" },
                        [
                          _c("el-radio", {
                            attrs: {
                              disabled: !item.word_pic || !item.word_gif,
                              label: index + 1
                            },
                            model: {
                              value: _vm.selectWordIndex,
                              callback: function($$v) {
                                _vm.selectWordIndex = $$v
                              },
                              expression: "selectWordIndex"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "word-detail-info" }, [
              _c("div", { staticClass: "basic-info" }, [
                _vm.selectStrokeIndex > 1
                  ? _c("img", {
                      attrs: {
                        src:
                          _vm.strokeList[_vm.selectStrokeIndex - 2].stroke_gif
                      }
                    })
                  : _c("img", {
                      attrs: {
                        src:
                          _vm.homeworkDetail.scoreRecords &&
                          _vm.homeworkDetail.scoreRecords[
                            _vm.selectWordIndex - 1
                          ].word_gif
                      }
                    }),
                _vm._v(" "),
                _c("img", {
                  attrs: { src: _vm.homeworkDetail.attentionResourceUrl }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sort-info" }, [
                _c("p", [_vm._v("笔顺")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "sort-info-img" },
                  [
                    _c("div", { staticClass: "sort-info-img-item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "word-item-inner",
                          on: {
                            click: function($event) {
                              _vm.selectStrokeIndex = 1
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "word-bg-img",
                            attrs: {
                              src: require("@/assets/znb_bg_word@1x.png")
                            }
                          }),
                          _vm._v(" "),
                          _vm.homeworkDetail.scoreRecords &&
                          _vm.homeworkDetail.scoreRecords[
                            _vm.selectWordIndex - 1
                          ].word_gif
                            ? _c("img", {
                                staticClass: "word-img",
                                attrs: {
                                  src:
                                    _vm.homeworkDetail.scoreRecords &&
                                    _vm.homeworkDetail.scoreRecords[
                                      _vm.selectWordIndex - 1
                                    ].word_gif
                                }
                              })
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "word-radio" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.selectStrokeIndex,
                                callback: function($$v) {
                                  _vm.selectStrokeIndex = $$v
                                },
                                expression: "selectStrokeIndex"
                              }
                            },
                            [
                              _vm._v(
                                "\n                                    单字\n                                "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.strokeList, function(subItem, subIndex) {
                      return _c(
                        "div",
                        {
                          key: "sub_" + subIndex,
                          staticClass: "sort-info-img-item"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "word-item-inner",
                              on: {
                                click: function($event) {
                                  _vm.selectStrokeIndex = subIndex + 2
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "word-bg-img",
                                attrs: {
                                  src: require("@/assets/znb_bg_word@1x.png")
                                }
                              }),
                              _vm._v(" "),
                              subItem.stroke_gif
                                ? _c("img", {
                                    staticClass: "word-img",
                                    attrs: { src: subItem.stroke_gif }
                                  })
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "word-radio" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: subIndex + 2 },
                                  model: {
                                    value: _vm.selectStrokeIndex,
                                    callback: function($$v) {
                                      _vm.selectStrokeIndex = $$v
                                    },
                                    expression: "selectStrokeIndex"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    笔顺" +
                                      _vm._s(subIndex + 1) +
                                      "\n                                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "comment-word-box" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "请输入点评~",
                    maxlength: "100",
                    "show-word-limit": "",
                    rows: "5"
                  },
                  model: {
                    value: _vm.commentValue,
                    callback: function($$v) {
                      _vm.commentValue = $$v
                    },
                    expression: "commentValue"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticStyle: { height: "20px" } }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: _vm.synBtnDisabled,
                      type: "primary",
                      loading: _vm.loading
                    },
                    on: { click: _vm.synVoice }
                  },
                  [_vm._v("合成语音")]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "identify-product-text" }, [
            _c("div", { staticClass: "audio-list" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("点评记录")]),
              _vm._v(" "),
              _vm.reviewDetailList.length
                ? _c(
                    "div",
                    { staticClass: "audio-list-inner" },
                    _vm._l(_vm.reviewDetailList, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "audio-item" },
                        [
                          _c("div", { staticClass: "word-img-item" }, [
                            _c("img", {
                              staticClass: "word-bg-img",
                              attrs: {
                                src: require("@/assets/znb_bg_word@1x.png")
                              }
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "word-img",
                              attrs: { src: item.gif }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "audio-cont" }, [
                            _c(
                              "div",
                              {
                                staticClass: "audio-cont-top",
                                on: {
                                  click: function($event) {
                                    return _vm.handlePlayAudio(item, index, 0)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "audio-cont-icon" }, [
                                  _c("img", {
                                    staticClass: "icon-audio",
                                    attrs: {
                                      src: require("@/assets/znb_icon_audio.png")
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.selectAudioIndex === index && _vm.isPlay
                                    ? _c("img", {
                                        staticClass: "icon-audio-dot",
                                        attrs: {
                                          src: require("@/assets/znb_icon_audio_dot.png")
                                        }
                                      })
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "audio-cont-text" },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(item.content) +
                                        "\n                                    "
                                    ),
                                    item.offLine
                                      ? _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [_vm._v("new")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "audio-cont-bottom" }, [
                              item.wordIndex > -1
                                ? _c("span", [
                                    _vm._v(_vm._s(item.wordIndex + 1)),
                                    item.strokeIndex > -1
                                      ? _c("i", [
                                          _vm._v(
                                            "- 笔顺" +
                                              _vm._s(item.strokeIndex + 1)
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                : _c("span"),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "btn-delete",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteAudio(item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.offLine ? "重新点评" : "删除")
                                  )
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", [_vm._v("暂无数据")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "audio-list" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("历史记录")]),
              _vm._v(" "),
              _vm.deleteReviewDetailList.length
                ? _c(
                    "div",
                    { staticClass: "audio-list-inner" },
                    _vm._l(_vm.deleteReviewDetailList, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "audio-item" },
                        [
                          _c("div", { staticClass: "word-img-item" }, [
                            _c("img", {
                              staticClass: "word-bg-img",
                              attrs: {
                                src: require("@/assets/znb_bg_word@1x.png")
                              }
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "word-img",
                              attrs: { src: item.gif }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "audio-cont" }, [
                            _c(
                              "div",
                              {
                                staticClass: "audio-cont-top",
                                on: {
                                  click: function($event) {
                                    return _vm.handlePlayAudio(item, index, 1)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "audio-cont-icon" }, [
                                  _c("img", {
                                    staticClass: "icon-audio",
                                    attrs: {
                                      src: require("@/assets/znb_icon_audio.png")
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.selectDeleteAudioIndex === index &&
                                  _vm.isPlay
                                    ? _c("img", {
                                        staticClass: "icon-audio-dot",
                                        attrs: {
                                          src: require("@/assets/znb_icon_audio_dot.png")
                                        }
                                      })
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "audio-cont-text" }, [
                                  _vm._v(_vm._s(item.content))
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "audio-cont-bottom" }, [
                              _c("span", [
                                _vm._v("删除人：" + _vm._s(item.teacherName))
                              ])
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", [_vm._v("暂无数据")])
            ])
          ])
        ])
      : _c("div", [_vm._v("暂无数据")]),
    _vm._v(" "),
    _vm.homeworkDetail
      ? _c(
          "div",
          { staticClass: "btn-wrap" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", loading: _vm.reviewLoading },
                on: { click: _vm.submit }
              },
              [_vm._v("提交点评")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("audio", {
      ref: "audio",
      on: { play: _vm.handleAudioPlay, ended: _vm.handleAudioEnd }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }