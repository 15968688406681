var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        "destroy-on-close": true,
        "before-close": _vm.handleClose,
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.previewData.fileType === "image"
        ? _c("img", {
            attrs: { width: "100%", src: _vm.previewData.fileUrl, alt: "" }
          })
        : _vm.previewData.fileType === "video"
        ? _c("video", {
            staticClass: "player-container",
            attrs: {
              id: "player-container-id-" + _vm.previewData.fileId,
              preload: "auto",
              playsinline: "",
              "webkit-playsinline": "",
              "x5-playsinline": ""
            }
          })
        : _vm.previewData.fileType === "audio"
        ? _c("aplayer", {
            attrs: {
              autoplay: "",
              music: {
                title: _vm.previewData.name,
                artist: " ",
                src: _vm.previewData.fileUrl,
                pic:
                  "https://cn-east-17-aplayer-35525609.oss.dogecdn.com/secretbase.jpg"
              }
            }
          })
        : _c(
            "a",
            {
              staticClass: "preview-href",
              attrs: { href: _vm.previewData.fileUrl }
            },
            [_vm._v(_vm._s(_vm.previewData.fileUrl))]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }