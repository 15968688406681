"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  loading: false
};
var mutations = {
  CHANGE_LOADING: function CHANGE_LOADING(state, val) {
    state.loading = val;
  }
};
var actions = {
  changeLoading: function changeLoading(_ref, val) {
    var commit = _ref.commit;
    commit('CHANGE_LOADING', val);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;