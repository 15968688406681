var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("search-box", {
        attrs: { "show-more": false },
        on: { search: _vm.onSubmit, reset: _vm.reset },
        scopedSlots: _vm._u([
          {
            key: "basic",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "客户UID：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "客户UID", clearable: true },
                      model: {
                        value: _vm.searchParam.uid,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uid", $$v)
                        },
                        expression: "searchParam.uid"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "客户昵称：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "客户昵称", clearable: true },
                      model: {
                        value: _vm.searchParam.uname,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uname", $$v)
                        },
                        expression: "searchParam.uname"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "手机号：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "手机号", clearable: true },
                      model: {
                        value: _vm.searchParam.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "phone", $$v)
                        },
                        expression: "searchParam.phone"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "data-list": _vm.tableData,
          columns: _vm.initSaleData.orderClient.columns,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size,
          "show-index": false,
          selection: false,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _vm.hasPerm("OrderClient:class")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  "track-params": "销售-我的成单客户-课程",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showDialog(
                                      "courseDialog",
                                      props.row
                                    )
                                  }
                                }
                              },
                              [_vm._v("课程")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPerm("OrderClient:follow")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  "track-params": "销售-我的成单客户-跟进客户",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showDialog(
                                      "followDialog",
                                      props.row
                                    )
                                  }
                                }
                              },
                              [_vm._v("跟进客户")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : props.column.label === "头像"
                  ? _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "80px", height: "60px" },
                          attrs: {
                            src: props.row.userAvatar,
                            "preview-src-list": [props.row.userAvatar]
                          }
                        })
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("follow-dialog", {
        ref: "followDialog",
        attrs: { "page-name": _vm.initSaleData.orderClient.name }
      }),
      _vm._v(" "),
      _c("course-dialog", { ref: "courseDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }