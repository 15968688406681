var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c("el-tab-pane", { attrs: { label: "课件", name: "1" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "回收站", name: "2" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      +_vm.activeName === 1
        ? _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "pageForm",
                  staticClass: "form-inline",
                  attrs: { inline: true }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "课件名称：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "课件名称" },
                        model: {
                          value: _vm.searchParam.name,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "name", $$v)
                          },
                          expression: "searchParam.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "转码状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.searchParam.is_transcoding,
                            callback: function($$v) {
                              _vm.$set(_vm.searchParam, "is_transcoding", $$v)
                            },
                            expression: "searchParam.is_transcoding"
                          }
                        },
                        _vm._l(
                          _vm.initCourseData.coursewareList.transcodeList,
                          function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }
                        ),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发布状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.searchParam.is_release,
                            callback: function($$v) {
                              _vm.$set(_vm.searchParam, "is_release", $$v)
                            },
                            expression: "searchParam.is_release"
                          }
                        },
                        _vm._l(
                          _vm.initCourseData.coursewareList.releaseList,
                          function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }
                        ),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button-group",
                    { staticStyle: { float: "right" } },
                    [
                      _c("el-button", {
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: { click: _vm.onSubmit }
                      }),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: { content: "重置查询条件", placement: "top" }
                        },
                        [
                          _c("el-button", {
                            attrs: { icon: "el-icon-refresh" },
                            on: { click: _vm.reset }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "显示更多查询条件",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.queryShowMore = !_vm.queryShowMore
                                }
                              }
                            },
                            [
                              _c("i", {
                                class: {
                                  "el-icon-arrow-up": _vm.queryShowMore,
                                  "el-icon-arrow-down": !_vm.queryShowMore
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.queryShowMore
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建人：" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "创建人" },
                                model: {
                                  value: _vm.searchParam.uname,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchParam, "uname", $$v)
                                  },
                                  expression: "searchParam.uname"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "课件类型：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.searchParam.file_type,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.searchParam,
                                        "file_type",
                                        $$v
                                      )
                                    },
                                    expression: "searchParam.file_type"
                                  }
                                },
                                _vm._l(
                                  _vm.initCourseData.coursewareList.typeList,
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建时间：" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  "range-separator": "~",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期"
                                },
                                on: { change: _vm.dateRangeChange },
                                model: {
                                  value: _vm.createTime,
                                  callback: function($$v) {
                                    _vm.createTime = $$v
                                  },
                                  expression: "createTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    [
                      _vm.hasPerm("CoursewareList:upload", _vm.$route.name) ||
                      _vm.hasPerm("Resource:upload", _vm.$route.name) ||
                      _vm.hasPerm("AssetsList:upload", _vm.$route.name)
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track:click",
                                  arg: "click"
                                }
                              ],
                              attrs: {
                                "track-params":
                                  _vm.$route.name === "CoursewareList"
                                    ? "课程-课件库-上传"
                                    : _vm.$route.name === "Resource"
                                    ? "碑帖-视频管理-上传"
                                    : _vm.$route.name === "AssetsList"
                                    ? "智能笔-资源库-上传"
                                    : "",
                                type: "primary",
                                icon: "el-icon-upload2"
                              }
                            },
                            [
                              _vm._v("上传\n                    "),
                              _c(
                                "form",
                                { ref: "fileForm", staticClass: "file-form" },
                                [
                                  _c("input", {
                                    ref: "videoFile",
                                    staticClass: "input",
                                    attrs: { multiple: "", type: "file" },
                                    on: { change: _vm.uploadFile }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _vm.hasPerm("CoursewareList:del", _vm.$route.name) ||
                      _vm.hasPerm("Resource:del", _vm.$route.name) ||
                      _vm.hasPerm("AssetsList:del", _vm.$route.name)
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track:click",
                                  arg: "click"
                                }
                              ],
                              attrs: {
                                "track-params":
                                  _vm.$route.name === "CoursewareList"
                                    ? "课程-课件库-删除"
                                    : _vm.$route.name === "Resource"
                                    ? "碑帖-视频管理-删除"
                                    : _vm.$route.name === "AssetsList"
                                    ? "智能笔-资源库-删除"
                                    : "",
                                type: "primary",
                                icon: "el-icon-delete"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.batchCourseware("delete")
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _vm.hasPerm(
                        "CoursewareList:wxPublish",
                        _vm.$route.name
                      ) ||
                      _vm.hasPerm("Resource:wxPublish", _vm.$route.name) ||
                      _vm.hasPerm("AssetsList:wxPublish", _vm.$route.name)
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track:click",
                                  arg: "click"
                                }
                              ],
                              attrs: {
                                "track-params":
                                  _vm.$route.name === "CoursewareList"
                                    ? "课程-课件库-微信小程序发布"
                                    : _vm.$route.name === "Resource"
                                    ? "碑帖-视频管理-微信小程序发布"
                                    : _vm.$route.name === "AssetsList"
                                    ? "智能笔-资源库-微信小程序发布"
                                    : "",
                                type: "primary",
                                icon: "el-icon-upload"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showPublishDialog("publishDialog")
                                }
                              }
                            },
                            [_vm._v("微信小程序发布")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _vm.hasPerm("CoursewareList:export", _vm.$route.name) ||
                      _vm.hasPerm("Resource:export", _vm.$route.name) ||
                      _vm.hasPerm("AssetsList:export", _vm.$route.name)
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track:click",
                                  arg: "click"
                                }
                              ],
                              attrs: {
                                "track-params":
                                  _vm.$route.name === "CoursewareList"
                                    ? "课程-课件库-导出"
                                    : _vm.$route.name === "Resource"
                                    ? "碑帖-视频管理-导出"
                                    : _vm.$route.name === "AssetsList"
                                    ? "智能笔-资源库-导出"
                                    : "",
                                type: "primary",
                                icon: "el-icon-download"
                              },
                              on: { click: _vm.exportData }
                            },
                            [_vm._v("导出")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.nodeEnv === "production"
                    ? [
                        _c(
                          "el-form-item",
                          [
                            _vm.hasPerm(
                              "CoursewareList:getTimeInfo",
                              _vm.$route.name
                            ) ||
                            _vm.hasPerm(
                              "Resource:getTimeInfo",
                              _vm.$route.name
                            ) ||
                            _vm.hasPerm(
                              "AssetsList:getTimeInfo",
                              _vm.$route.name
                            )
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "track",
                                        rawName: "v-track:click",
                                        arg: "click"
                                      }
                                    ],
                                    attrs: {
                                      "track-params":
                                        _vm.$route.name === "CoursewareList"
                                          ? "课程-课件库-获取时长信息"
                                          : _vm.$route.name === "Resource"
                                          ? "碑帖-视频管理-获取时长信息"
                                          : _vm.$route.name === "AssetsList"
                                          ? "智能笔-资源库-获取时长信息"
                                          : "",
                                      type: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.exportVideoData("duration")
                                      }
                                    }
                                  },
                                  [_vm._v("获取时长信息")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          [
                            _vm.hasPerm(
                              "CoursewareList:getVideoShot",
                              _vm.$route.name
                            ) ||
                            _vm.hasPerm(
                              "Resource:getVideoShot",
                              _vm.$route.name
                            ) ||
                            _vm.hasPerm(
                              "AssetsList:getVideoShot",
                              _vm.$route.name
                            )
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "track",
                                        rawName: "v-track:click",
                                        arg: "click"
                                      }
                                    ],
                                    attrs: {
                                      "track-params":
                                        _vm.$route.name === "CoursewareList"
                                          ? "课程-课件库-获取视频截图"
                                          : _vm.$route.name === "Resource"
                                          ? "碑帖-视频管理-获取视频截图"
                                          : _vm.$route.name === "AssetsList"
                                          ? "智能笔-资源库-获取视频截图"
                                          : "",
                                      type: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.exportVideoData("screenshot")
                                      }
                                    }
                                  },
                                  [_vm._v("获取视频截图")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      +_vm.activeName === 2
        ? _c(
            "div",
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    [
                      _vm.hasPerm("CoursewareList:Recover", _vm.$route.name) ||
                      _vm.hasPerm("Resource:recover", _vm.$route.name) ||
                      _vm.hasPerm("AssetsList:recover", _vm.$route.name)
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.batchCourseware("recover")
                                }
                              }
                            },
                            [_vm._v("恢复")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "data-list": _vm.tableData,
          columns: _vm.initCourseData.coursewareList.columns,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.per_page,
          "show-index": false,
          selection: true,
          total: _vm.total
        },
        on: {
          pageNoChange: _vm.pageNoChange,
          selectionChange: _vm.handleSelectionChange
        },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _vm.hasPerm(
                          "CoursewareList:preview",
                          _vm.$route.name
                        ) ||
                        _vm.hasPerm("Resource:preview", _vm.$route.name) ||
                        _vm.hasPerm("AssetsList:preview", _vm.$route.name)
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  "track-params":
                                    _vm.$route.name === "CoursewareList"
                                      ? "课程-课件库-预览"
                                      : _vm.$route.name === "Resource"
                                      ? "碑帖-视频管理-预览"
                                      : _vm.$route.name === "AssetsList"
                                      ? "智能笔-资源库-预览"
                                      : "",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showDialog(
                                      "previewDialog",
                                      props.row
                                    )
                                  }
                                }
                              },
                              [_vm._v("预览")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        +_vm.activeName === 1
                          ? _c(
                              "span",
                              [
                                props.row.fileType === "video" &&
                                props.row.isTranscoding === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "track",
                                            rawName: "v-track:click",
                                            arg: "click"
                                          }
                                        ],
                                        attrs: {
                                          "track-params":
                                            _vm.$route.name === "CoursewareList"
                                              ? "课程-课件库-转码"
                                              : _vm.$route.name === "Resource"
                                              ? "碑帖-视频管理-转码"
                                              : _vm.$route.name === "AssetsList"
                                              ? "智能笔-资源库-转码"
                                              : "",
                                          size: "small",
                                          type: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.transCode(props.row)
                                          }
                                        }
                                      },
                                      [_vm._v("转码")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                props.row.fileType === "video" &&
                                (_vm.hasPerm(
                                  "CoursewareList:edit",
                                  _vm.$route.name
                                ) ||
                                  _vm.hasPerm(
                                    "AssetsList:edit",
                                    _vm.$route.name
                                  ))
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "track",
                                            rawName: "v-track:click",
                                            arg: "click"
                                          }
                                        ],
                                        attrs: {
                                          "track-params":
                                            _vm.$route.name === "CoursewareList"
                                              ? "课程-课件库-编辑"
                                              : _vm.$route.name === "Resource"
                                              ? "碑帖-视频管理-编辑"
                                              : _vm.$route.name === "AssetsList"
                                              ? "智能笔-资源库-编辑"
                                              : "",
                                          size: "small",
                                          type: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.showDialog(
                                              "editCourseware",
                                              props.row,
                                              "edit"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("编辑")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasPerm(
                                  "CoursewareList:singleDel",
                                  _vm.$route.name
                                ) ||
                                _vm.hasPerm(
                                  "Resource:singleDel",
                                  _vm.$route.name
                                ) ||
                                _vm.hasPerm(
                                  "AssetsList:singleDel",
                                  _vm.$route.name
                                )
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "track",
                                            rawName: "v-track:click",
                                            arg: "click"
                                          }
                                        ],
                                        attrs: {
                                          "track-params":
                                            _vm.$route.name === "CoursewareList"
                                              ? "课程-课件库-删除"
                                              : _vm.$route.name === "Resource"
                                              ? "碑帖-视频管理-删除"
                                              : _vm.$route.name === "AssetsList"
                                              ? "智能笔-资源库-删除"
                                              : "",
                                          size: "small",
                                          type: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.batchCourseware(
                                              "delete",
                                              props.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                props.row.fileType === "video" &&
                                [0, 3].includes(props.row.isRelease) &&
                                _vm.hasPerm(
                                  "CoursewareList:publish",
                                  _vm.$route.name
                                )
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "track",
                                            rawName: "v-track:click",
                                            arg: "click"
                                          }
                                        ],
                                        attrs: {
                                          "track-params":
                                            _vm.$route.name === "CoursewareList"
                                              ? "课程-课件库-发布"
                                              : _vm.$route.name === "Resource"
                                              ? "碑帖-视频管理-发布"
                                              : _vm.$route.name === "AssetsList"
                                              ? "智能笔-资源库-发布"
                                              : "",
                                          size: "small",
                                          type: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.showPublishDialog(
                                              "publishDialog",
                                              props.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("发布")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _c(
                              "span",
                              [
                                _vm.hasPerm(
                                  "CoursewareList:singleRecover",
                                  _vm.$route.name
                                ) ||
                                _vm.hasPerm(
                                  "Resource:singleRecover",
                                  _vm.$route.name
                                ) ||
                                _vm.hasPerm(
                                  "AssetsList:singleRecover",
                                  _vm.$route.name
                                )
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "track",
                                            rawName: "v-track:click",
                                            arg: "click"
                                          }
                                        ],
                                        attrs: {
                                          "track-params":
                                            _vm.$route.name === "CoursewareList"
                                              ? "课程-课件库-恢复"
                                              : _vm.$route.name === "Resource"
                                              ? "碑帖-视频管理-恢复"
                                              : _vm.$route.name === "AssetsList"
                                              ? "智能笔-资源库-恢复"
                                              : "",
                                          size: "small",
                                          type: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.batchCourseware(
                                              "recover",
                                              props.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("恢复")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("edit-courseware", {
        ref: "editCourseware",
        on: { refresh: _vm.reset }
      }),
      _vm._v(" "),
      _c("publish-dialog", { ref: "publishDialog" }),
      _vm._v(" "),
      _c("upload-panel", {
        ref: "uploadPanel",
        on: { handleReset: _vm.handleReset, handleResult: _vm.handleResult }
      }),
      _vm._v(" "),
      _c("preview-dialog", { ref: "previewDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }