"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditLearnModule',
  data: function data() {
    return {
      dialogFormVisible: false,
      form: {
        vid: 0,
        name: ''
      },
      dialogType: '',
      // 学习模块状态
      videoId: 0,
      formLabelWidth: '120px',
      selIndex: '',
      // 选择了哪个模块
      rules: {
        name: [{
          required: true,
          message: '请输入模块名称',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    editLearnDialog: function editLearnDialog(dialogType, dialogData, selIndex) {
      this.dialogType = 'edit';

      if (dialogType === 'edit') {
        // this.form.vid = dialogData.vid;
        this.form.name = dialogData.id; // 编辑学习模块标题回填
      }

      this.selIndex = selIndex;
      this.dialogFormVisible = true;
    },
    showLearnModuleDialog: function showLearnModuleDialog(dialogData) {
      this.dialogType = 'add'; // 添加学习模块

      this.form.name = '';
      this.form.vid = dialogData;
      this.dialogFormVisible = true;
    },
    // 保存编辑 或 添加
    save: function save(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.dialogFormVisible = false;

          _this.$emit('confirm', _this.form, _this.dialogType, _this.selIndex);
        } else {
          return false;
        }
      }); // setTimeout(() => {
      //     this.form.name = ''; //清空form内容
      // }, 200);
    }
  }
};
exports.default = _default;