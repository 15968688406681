"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditSectionDialog',
  data: function data() {
    return {
      dialogFormVisible: false,
      form: {
        name: '',
        game_time: '',
        sort: ''
      },
      formLabelWidth: '120px',
      dialogType: '',
      loading: false
    };
  },
  methods: {
    showDialog: function showDialog(type, data) {
      this.dialogType = type;
      this.form.course_id = data.course_id;

      if (type === 'edit') {
        this.form.id = data.id;
        this.form.name = data.name;
        this.form.game_time = data.game_time;
      }

      this.dialogFormVisible = true;
    },
    // 保存编辑 或 添加
    save: function save() {
      var _this = this;

      var url = this.dialogType === 'add' ? 'addSectionList' : 'updateSectionList';
      this.loading = true;

      _api.default[url](this.form).then(function (res) {
        _this.loading = false;

        if (res) {
          _this.$message.success('操作成功');

          _this.dialogFormVisible = false;

          _this.$refs.editSectionForm.resetFields();

          _this.$emit('refresh');
        }
      }).catch(function () {
        _this.loading = false;
      });
    }
  }
};
exports.default = _default;