"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var BASE_URL = process.env.VUE_APP_BASE_API;
console.log(BASE_URL); // const PROXY_ID = '/smartpen/api/admin';

var _default = {
  /**
   * 用户列表数据
   * @param params
   */
  childUserList: function childUserList(params) {
    return (0, _request.default)({
      url: BASE_URL + "/sale/childa/ucenter-listinfo",
      method: 'get',
      params: params
    });
  },

  /**
  * 用户作业数据
  * @param params
  */
  childHwList: function childHwList(params) {
    return (0, _request.default)({
      url: BASE_URL + "/sale/childa/ucenter-homeworklist",
      method: 'get',
      params: params
    });
  },

  /**
  * 用户已购课程数据
  * @param params
  */
  childCourseList: function childCourseList(params) {
    return (0, _request.default)({
      url: BASE_URL + "/sale/childa/ucenter-courselist",
      method: 'get',
      params: params
    });
  },

  /**
  * 单个用户评测数据
  * @param params
  */
  childEvaList: function childEvaList(params) {
    return (0, _request.default)({
      url: BASE_URL + "/sale/childa/ucenter-evaluationuser",
      method: 'get',
      params: params
    });
  },

  /**
  * 单个用户评测数据
  * @param params
  */
  evaList: function evaList(params) {
    return (0, _request.default)({
      url: BASE_URL + "/sale/childa/ucenter-evaluationlist",
      method: 'get',
      params: params
    });
  }
};
exports.default = _default;