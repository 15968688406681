"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _Logo = _interopRequireDefault(require("./Logo"));

var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));

var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['permission_routes', 'sidebar'])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path;

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    },
    modulePath: function modulePath() {
      return this.$route.matched.length === 0 ? this.$route.path : this.$route.matched[0].path || '/';
    }
  })
};
exports.default = _default;