"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRoutes = getRoutes;
exports.getRoles = getRoles;
exports.addRole = addRole;
exports.updateRole = updateRole;
exports.deleteRole = deleteRole;

var _request = _interopRequireDefault(require("@/utils/request"));

function getRoutes() {
  return (0, _request.default)({
    url: '/permission/routes',
    method: 'get'
  });
}

function getRoles() {
  return (0, _request.default)({
    url: '/permission/roles',
    method: 'get'
  });
}

function addRole(data) {
  return (0, _request.default)({
    url: '/permission/role',
    method: 'post',
    data: data
  });
}

function updateRole(id, data) {
  return (0, _request.default)({
    url: "/permission/role/".concat(id),
    method: 'put',
    data: data
  });
}

function deleteRole(id) {
  return (0, _request.default)({
    url: "/permission/role/".concat(id),
    method: 'delete'
  });
}