"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _EditSectionDialog = _interopRequireDefault(require("./components/EditSectionDialog"));

var _initData = require("../initData");

var _bean = _interopRequireDefault(require("@/bean"));

var _PreviewDialog = _interopRequireDefault(require("../../../components/Dialog/PreviewDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CourseList',
  components: {
    PageTable: _PageTable.default,
    EditSectionDialog: _EditSectionDialog.default,
    PreviewDialog: _PreviewDialog.default
  },
  data: function data() {
    return {
      tableData: null,
      listLoading: true,
      total: 0,
      initLessonData: _initData.initData,
      searchParam: {
        page: 1,
        page_size: 10,
        vid: ''
      },
      moduleUrl: '//xsjy-1254153797.cos.ap-shanghai.myqcloud.com/smartpen/courseware/pc/2020/09/17/%E8%AF%BE%E6%97%B6.xlsx'
    };
  },
  activated: function activated() {
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getSmartPenLessonList(this.searchParam).then(function (response) {
        var _response$data = response.data,
            infos = _response$data.infos,
            totalNum = _response$data.totalNum;
        _this.tableData = (infos || []).map(function (item) {
          return _bean.default.lessonListBean(item);
        });
        _this.total = totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      var id = this.$route.params.id;
      this.searchParam = {
        page: 1,
        page_size: 10,
        section_id: id
      };
      this.fetchData();
    },
    jumpPage: function jumpPage(name, params) {
      this.$router.push({
        name: name,
        params: params
      });
    },
    handleLesson: function handleLesson(type, item) {
      var id = type === 'add' ? '-1' : item.id;
      this.$router.push({
        name: 'handleLesson',
        params: {
          sectionId: this.searchParam.section_id,
          id: id
        }
      });
    },
    // 删除课时
    del: function del(row) {
      var _this2 = this;

      this.$confirm('确定要删除该记录吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _api.default.delLessonList({
          id: row.id
        }).then(function () {
          _this2.$message.success('操作成功~');

          _this2.fetchData();
        });
      });
    },
    handlePreview: function handlePreview(data) {
      this.$refs.previewDialog.showDialog({
        fileId: data.videoFileid,
        fileType: 'video'
      });
    },
    uploadRequest: function uploadRequest(params) {
      var _this3 = this;

      var _formData = new FormData();

      _formData.append('excel_file', params.file);

      _api.default.importLessonList(_formData).then(function () {
        _this3.$message.success('操作成功~');

        _this3.reset();
      });
    },
    onInputBlur: function onInputBlur(row, column) {
      var _this4 = this;

      var num = row[column.property];

      if (!num || num < 0) {
        this.$message({
          type: 'warning',
          message: '输入的值有误~'
        });
        return;
      }

      _api.default.editCourseLessonInfo({
        id: row.id,
        sort: row.sort
      }).then(function () {
        _this4.$message({
          type: 'success',
          message: '操作成功~'
        });

        _this4.fetchData();
      });
    }
  }
};
exports.default = _default;