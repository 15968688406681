"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _UploadCos = _interopRequireDefault(require("@/components/UploadCos"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'HandleUserOpus',
  components: {
    Upload: _UploadCos.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      dialogType: 'add',
      formData: {},
      rules: {
        name: [{
          required: true,
          message: '请输入学员姓名',
          trigger: 'blur'
        }],
        testimonials: [{
          required: true,
          message: '请输入结业感言',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请上传图片',
          trigger: 'change'
        }],
        graduate_time: [{
          required: true,
          message: '请选择时间',
          trigger: 'blur'
        }]
      },
      imageList: [],
      loading: false,
      deleteLoading: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (!val) {
        this.imageList = [];
      }
    }
  },
  methods: {
    showDialog: function showDialog(type, item) {
      var _this = this;

      this.dialogType = type;
      this.dialogVisible = true;
      this.formData = {
        id: '',
        name: '',
        testimonials: '',
        image: '',
        graduate_time: '',
        work_sort: ''
      };

      if (type === 'edit') {
        this.formData.id = item.id;
        this.formData.name = item.name;
        this.formData.testimonials = item.testimonials;
        this.formData.image = item.image;
        this.imageList = [{
          name: item.image.substring(item.image.lastIndexOf('/') + 1),
          url: item.image
        }];
        this.formData.graduate_time = item.graduate_time;
        this.formData.work_sort = item.work_sort;
      } else {
        this.$nextTick(function () {
          _this.$refs.userOpusForm.resetFields();
        });
      }
    },
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.formData.graduate_time = (0, _utils.parseTime)(val);
      } else {
        this.formData.graduate_time = '';
      }
    },
    uploadResult: function uploadResult(data, type) {
      this.formData[type] = data[0] && data[0].url || '';
    },
    cancel: function cancel() {
      this.dialogVisible = false;
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      this.$refs.userOpusForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;

          _api.default.submitUserOpus(_this2.formData).then(function (res) {
            _this2.$message.success('操作成功');

            _this2.loading = false;
            _this2.dialogVisible = false;

            _this2.$refs.userOpusForm.resetFields();

            _this2.$emit('refresh');
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.warn('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;