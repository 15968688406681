"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditPackageStudentDialog',
  data: function data() {
    return {
      formLabelWidth: '120px',
      dialogFormVisible: false,
      form: {
        packageclass_id: ''
      },
      rules: {
        packageclass_id: [{
          required: true,
          message: '请选择要更换的班级',
          trigger: 'change'
        }]
      },
      loading: false,
      classList: []
    };
  },
  methods: {
    showDialog: function showDialog() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.dialogFormVisible = true;
      this.form.id = data.id;
      this.form.packageclass_id = Number(this.$route.params.classId);
      this.initData();
    },
    initData: function initData() {
      var _this = this;

      _api.default.getPackageClassRoomList({
        packageclass_id: this.form.packageclass_id
      }).then(function (res) {
        _this.classList = res.data.infos;
      });
    },
    save: function save(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.loading = true;

          _api.default.updatePackageClassRoomStudentInfo(_this2.form).then(function (res) {
            if (res) {
              _this2.$message.success('操作成功');

              _this2.dialogFormVisible = false;

              _this2.$refs[formName].resetFields();

              _this2.$emit('refresh');
            }

            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;