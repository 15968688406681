"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var BASE_URL = "".concat(process.env.VUE_APP_BASE_API);
var IMG_HOST = process.env.VUE_APP_IMG_HOST;
var PROXY_ID = '/curricula';
var _default = {
  /**
   * 获取腾讯云上传签名
   * @param data
   */
  getSignature: function getSignature(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/tcvideo/sign"),
      method: 'post',
      data: data
    });
  },

  /**
   * 云点播视频转码
   * @param data
   */
  transcodeTask: function transcodeTask(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/tcvideo/processMedia"),
      method: 'post',
      data: data
    });
  },

  /**
   * 微信小程序视频发布
   * @param data
   */
  miniProgramPublish: function miniProgramPublish(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/tcvideo/batchPublish"),
      method: 'post',
      data: data
    });
  },

  /**
   * 图片上传
   * @param data
   */
  uploadImg: function uploadImg(data) {
    return (0, _request.default)({
      url: "".concat(IMG_HOST, "/index.php?app=widget&mod=Upload&act=save&attach_type=feed_image&upload_type=image&thumb=1"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取腾讯云cos上传临时密钥
   */
  getTcCOSSignature: function getTcCOSSignature(data) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/fileService/api/v1/file/sts"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取资源库列表数据
   * @param params
   */
  getAssetsList: function getAssetsList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL, "/smartpen/api/admin/resource/lists"),
      method: 'get',
      params: params
    });
  }
};
exports.default = _default;