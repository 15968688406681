"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditPackageClassDialog',
  data: function data() {
    return {
      uidInputDisable: false,
      // uid 输入框是否可输入
      dialogFormVisible: false,
      form: {
        name: '',
        uid: ''
      },
      formLabelWidth: '120px',
      dialogType: '',
      rules: {
        name: [{
          required: true,
          message: '请输入班主任名称',
          trigger: 'blur'
        }],
        uid: [{
          required: true,
          message: '请输入UID',
          trigger: 'blur'
        }]
      },
      loading: false,
      courseArr: []
    };
  },
  methods: {
    showDialog: function showDialog(type, formData) {
      this.dialogType = type;
      console.log(formData);

      if (type === 'update') {
        this.form.name = formData.userName;
        this.form.uid = formData.uid;
        this.form.backUserId = formData.backUserId;
        this.uidInputDisable = true;
      } else {
        this.form.name = '';
        this.form.uid = '';
        this.form.backUserId = '';
        this.uidInputDisable = false;
      }

      this.dialogFormVisible = true;
    },
    // 保存编辑 或 添加  addClassTeacher
    save: function save() {
      var _this = this;

      this.listLoading = true;
      this.loading = true;
      var url = this.dialogType === 'update' ? 'updateClassTeacher' : 'addClassTeacher';
      var postData = {
        userName: this.form.name,
        backUserId: this.form.backUserId,
        uid: this.form.uid
      };

      _api.default[url](postData).then(function (response) {
        _this.$message.success('添加班主任成功');

        _this.dialogFormVisible = false;

        _this.$emit('confirmadd');

        _this.listLoading = false;
        _this.loading = false;
      }).catch(function () {
        _this.listLoading = false;
        _this.loading = false;
      });
    }
  }
};
exports.default = _default;