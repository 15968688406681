var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: (_vm.dialogType === "edit" ? "编辑" : "添加") + "用户作品",
        visible: _vm.dialogVisible,
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "userOpusForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "学员姓名：", prop: "name", required: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "结业感言：", prop: "testimonials", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "textarea",
                  rows: 3,
                  maxlength: "200",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.formData.testimonials,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "testimonials", $$v)
                  },
                  expression: "formData.testimonials"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "图片：", prop: "image", required: "" } },
            [
              _c("el-input", {
                staticStyle: { display: "none" },
                attrs: { autocomplete: "off", disabled: true },
                model: {
                  value: _vm.formData.image,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "image", $$v)
                  },
                  expression: "formData.image"
                }
              }),
              _vm._v(" "),
              _c("upload", {
                attrs: { lineType: "edu", "file-list": _vm.imageList },
                on: {
                  handleResult: function(val) {
                    return _vm.uploadResult(val, "image")
                  }
                }
              }),
              _vm._v(" "),
              _c("div", [_vm._v("建议图片宽高比：1：0.75")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "时间：", prop: "graduate_time", required: "" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: { type: "date", placeholder: "选择日期" },
                on: { change: _vm.dateRangeChange },
                model: {
                  value: _vm.formData.graduate_time,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "graduate_time", $$v)
                  },
                  expression: "formData.graduate_time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序：", prop: "work_sort" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                model: {
                  value: _vm.formData.work_sort,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "work_sort", $$v)
                  },
                  expression: "formData.work_sort"
                }
              }),
              _vm._v(" "),
              _c("div", [_vm._v("（数字越小，官网显示越靠前）")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("保存提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }