"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _EditClassHourInfo = _interopRequireDefault(require("./components/EditClassHourInfo"));

var _ImageConfig = _interopRequireDefault(require("./components/ImageConfig"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ClassHourList',
  components: {
    EditClassHourInfo: _EditClassHourInfo.default,
    ImageDialog: _ImageConfig.default
  },
  data: function data() {
    return {
      total: 0,
      listLoading: false,
      tableData: [],
      sessionvid: '',
      // 选中的章节id
      searchParam: {
        page: 1,
        per_page: 20,
        vid: ''
      }
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getLessonList(this.searchParam).then(function (response) {
        _this.tableData = response.data.data.map(function (item) {
          return _bean.default.lessonBean(item);
        });
        _this.total = response.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    editChapter: function editChapter(item) {
      this.$message({
        message: '该功能暂未开放~',
        type: 'warning'
      });
    },
    deleteChapter: function deleteChapter() {
      this.$message({
        message: '该功能暂未开放~',
        type: 'warning'
      });
    },
    confirm: function confirm(selectItem, propsItem) {
      var _this2 = this;

      // 图片dialog 更新图片
      var postData = {
        course_id: this.sessionvid || '',
        image_id: selectItem.id
      };

      _api.default.setOnlineCourseImg(postData).then(function (res) {
        _this2.$message.success('操作成功~');

        _this2.tableData.map(function (item) {
          if (item.id === propsItem) {
            item.child.map(function (cItem) {
              if (cItem.id === _this2.sessionvid) {
                cItem.image = selectItem.imageUrl;
              }

              return cItem;
            });
          }

          return item;
        });
      });
    },
    handlePageNoChange: function handlePageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    showImgDialog: function showImgDialog(ref, pItem, sItem) {
      this.$refs[ref].showDialog(pItem, sItem);
    },
    editClassHourInfo: function editClassHourInfo(ref, pItem, sItem) {
      this.$refs[ref].showDialog(pItem, sItem);
    },
    reset: function reset() {
      var id = this.$route.params.id;
      this.searchParam = {
        page: 1,
        per_page: 20,
        vid: id
      };
      this.fetchData();
    },
    updateTableImageData: function updateTableImageData(row, form) {
      this.sessionvid = form;
    },
    updateTableData: function updateTableData(row, form) {
      this.tableData.map(function (item) {
        if (item.id === row.id) {
          item.child.map(function (cItem) {
            if (cItem.id === form.zy_video_section_id) {
              cItem.newCourseName = form.newCourseName;
            }

            return cItem;
          });
        }

        return item;
      });
    }
  }
};
exports.default = _default;