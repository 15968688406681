"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _FieldDialog = _interopRequireDefault(require("./FieldDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    FieldDialog: _FieldDialog.default
  },
  props: {
    showMore: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      queryShowMore: false
    };
  },
  methods: {
    onSubmit: function onSubmit() {
      this.$emit('search');
    },
    reset: function reset() {
      this.$emit('reset');
    },
    handleField: function handleField() {
      this.$refs.fieldDialog.showDialog();
    },
    handleFieldChange: function handleFieldChange(val) {
      this.$emit('handleFieldChange', val);
    }
  }
};
exports.default = _default2;