var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container type-setting" },
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.showDialog }
                },
                [_vm._v("添加")]
              ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "inline-block", attrs: { href: _vm.moduleUrl } },
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("下载模板")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload",
                  attrs: {
                    action: "",
                    accept:
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "http-request": _vm.uploadRequest,
                    "with-credentials": true,
                    "auto-upload": true
                  }
                },
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("批量导入")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "show-index": false,
          "data-list": _vm.tableData,
          columns: _vm.initSectionData.typeSetting.columns,
          total: _vm.total,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "排序"
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: { type: "number", size: "mini", min: "0" },
                          on: {
                            blur: function($event) {
                              return _vm.onInputBlur(props.row, props.column)
                            }
                          },
                          model: {
                            value: props.row[props.column.property],
                            callback: function($$v) {
                              _vm.$set(props.row, props.column.property, $$v)
                            },
                            expression: "props.row[props.column.property]"
                          }
                        })
                      ],
                      1
                    )
                  : props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.editDialog(props.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.deleteItem(props.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.visibleModal },
          on: {
            "update:visible": function($event) {
              _vm.visibleModal = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "功能", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择活动区域" },
                      model: {
                        value: _vm.ruleForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "type", $$v)
                        },
                        expression: "ruleForm.type"
                      }
                    },
                    _vm._l(_vm.funcType1, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "教学环节分类", prop: "category_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择活动区域" },
                      model: {
                        value: _vm.ruleForm.category_id,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "category_id", $$v)
                        },
                        expression: "ruleForm.category_id"
                      }
                    },
                    _vm._l(_vm.funcCategory1, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { value: index, label: item }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "编码", prop: "axis_code" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.axis_code,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "axis_code", $$v)
                      },
                      expression: "ruleForm.axis_code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "sort", $$v)
                      },
                      expression: "ruleForm.sort"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("立即创建")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.resetForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }