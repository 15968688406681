"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cosAuth_ = cosAuth_;

var _cosJsSdkV = _interopRequireDefault(require("cos-js-sdk-v5"));

var _api = _interopRequireDefault(require("@/api"));

var _store = _interopRequireDefault(require("@/store"));

// var COS = require('@/utils/cos-js-sdk-v5.js')
var keyName;
/**
 * Remove class from element
 * @param {File} file_
 * @param {Name} name_
 */

function cosAuth_(file_, name_) {
  return new Promise(function (resolve, reject) {
    var data = {
      product: 'edu',
      features: 'jumpLink',
      terminal: 'pc',
      bucketName: 'xsjy-1254153797'
    }; // console.log('loasing',store.dispatch('loading/changeLoading', true))

    _store.default.dispatch('loading/changeLoading', true); // debugger


    _api.default.uploadCos(data).then(function (res) {
      var credentials = res.data;
      console.log(credentials);
      keyName = credentials.pathKey + '/' + name_;
      console.log(keyName);
      var cos = new _cosJsSdkV.default({
        getAuthorization: function getAuthorization(options, callback) {
          // debugger
          console.log('------123-------');
          callback({
            TmpSecretId: credentials.tmpSecretId,
            TmpSecretKey: credentials.tmpSecretKey,
            XCosSecurityToken: credentials.token,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: credentials.startTime,
            // 时间戳，单位秒，如：1580000000
            ExpiredTime: credentials.expiredTime // 时间戳，单位秒，如：1580000900

          });
        }
      });
      cos.putObject({
        Bucket: 'xsjy-1254153797',

        /* 必须 */
        Region: 'ap-shanghai',

        /* 存储桶所在地域，必须字段 */
        Key: keyName,

        /* 必须 */
        StorageClass: 'STANDARD',
        Body: file_,
        // 上传文件对象
        onProgress: function onProgress(progressData) {
          console.log(JSON.stringify(progressData));
        }
      }, function (err, data) {
        console.log(err || data);
        var links = 'https://' + data.Location;
        console.log(links);

        _store.default.dispatch('loading/changeLoading', false);

        resolve(links);
      });
    });
  });
}