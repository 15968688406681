"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vodJsSdkV = _interopRequireDefault(require("vod-js-sdk-v6"));

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
function getSignature() {
  return _api.default.getSignature().then(function (res) {
    return res.data;
  });
}

var _default = {
  name: 'Index',
  data: function data() {
    return {
      uploaderInfo: {},
      tcVod: null
    };
  },
  created: function created() {
    this.tcVod = new _vodJsSdkV.default({
      getSignature: getSignature
    });
  },
  methods: {
    upload: function upload() {
      var _this = this;

      var mediaFile = this.$refs.videoFile.files[0];
      var uploader = this.tcVod.upload({
        mediaFile: mediaFile
      });
      var uploaderInfo = {
        videoInfo: uploader.videoInfo,
        isVideoUploadSuccess: false,
        isVideoUploadCancel: false,
        progress: 0,
        fileId: '',
        videoUrl: '',
        cancel: function cancel() {
          uploaderInfo.isVideoUploadCancel = true;
          uploader.cancel();
        }
      };
      this.uploaderInfo = uploaderInfo;
      uploader.on('media_progress', function (info) {
        uploaderInfo.progress = info.percent;
      });
      uploader.on('media_upload', function () {
        uploaderInfo.isVideoUploadSuccess = true;
      });
      uploader.done().then(function (doneResult) {
        uploaderInfo.fileId = doneResult.fileId;
        uploaderInfo.videoUrl = doneResult.video.url;

        _this.$refs.fileForm.reset();

        _this.$emit('result', uploaderInfo);
      });
    }
  }
};
exports.default = _default;