"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Index',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    needPagination: {
      type: Boolean,
      default: true
    },
    showIndex: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      required: true
    },
    pageNo: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default: function _default() {
        return [10, 20, 50];
      }
    },
    total: {
      type: Number,
      required: true
    },
    selection: {
      type: Boolean,
      required: false
    },
    layout: {
      type: String,
      default: 'total, prev, pager, next, jumper'
    },
    align: {
      type: String,
      default: function _default() {
        return 'center';
      }
    }
  },
  data: function data() {
    return {
      name: 'pageTable'
    };
  },
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      this.$emit('pageSizeChange', val);
    },
    handlePageNoChange: function handlePageNoChange(val) {
      this.$emit('pageNoChange', val);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.$emit('selectionChange', val);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.$emit('currentChange', val);
    },
    handleSortChange: function handleSortChange(val) {
      this.$emit('sortChange', val);
    }
  }
};
exports.default = _default2;