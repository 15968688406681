"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'PublishDialog',
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        video_type: 2
      }
    };
  },
  methods: {
    showDialog: function showDialog(selelctIds) {
      this.dialogVisible = true;
      this.formData.fileId = selelctIds;
    },
    confirm: function confirm() {
      var _this = this;

      _api.default.miniProgramPublish(this.formData).then(function (res) {
        _this.$message({
          message: '发布中，请稍后查看~',
          type: 'success'
        });

        _this.dialogVisible = false;

        _this.$emit('refresh');
      });
    }
  }
};
exports.default = _default;