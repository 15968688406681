"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _UserInfoDialog = _interopRequireDefault(require("./components/UserInfoDialog"));

var _initData = require("./initData");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Live',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default,
    UserInfoDialog: _UserInfoDialog.default
  },
  filters: {
    recommendText: function recommendText(val) {
      var item = _initData.recommendOption.filter(function (item) {
        return Number(item.value) === Number(val);
      });

      return item[0] && item[0].label || '';
    }
  },
  data: function data() {
    return {
      createTime: '',
      columns: [{
        label: 'ID',
        width: '60',
        prop: 'id'
      }, {
        label: '直播名称',
        width: '80',
        prop: 'title'
      }, {
        label: '直播讲师',
        width: '100',
        prop: 'teacher_name'
      }, {
        label: '直播时间',
        width: '80',
        prop: 'start_time'
      }, {
        label: '价格',
        width: '60',
        prop: 'price'
      }, {
        label: '学习人数',
        width: '80',
        prop: 'live_orders_count'
      }, {
        label: '关联点播课',
        width: '100',
        prop: 'video_title'
      }, {
        label: '设为推荐',
        width: '80',
        prop: 'recommend'
      }, {
        label: '直播回放',
        width: '80',
        prop: 'live_status'
      }, {
        label: '操作',
        width: '100',
        fix: 'right'
      }],
      options: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '是'
      }, {
        value: 2,
        label: '否'
      }],
      total: 0,
      tableData: [],
      listLoading: false,
      searchParam: {},
      webSiteUrl: process.env.VUE_APP_PC_SITE
    };
  },
  created: function created() {
    this.reset();
  },
  activated: function activated() {
    this.reset();
  },
  methods: {
    reset: function reset() {
      this.searchParam = {
        title: '',
        recommend: '',
        start_time: '',
        end_time: '',
        page: 1,
        page_size: 10
      };
      this.createTime = '';
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getLiveList(this.searchParam).then(function (res) {
        _this.tableData = res.data;
        _this.total = res.count;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.searchParam.start_time = (0, _utils.parseTime)(val[0]);
        this.searchParam.end_time = (0, _utils.parseTime)(val[1]);
      } else {
        this.searchParam.start_time = '';
        this.searchParam.end_time = '';
      }
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    showDialog: function showDialog(item) {
      this.$refs.userInfoDialog.showDialog(item.id);
    },
    handleLive: function handleLive(type, item) {
      this.$router.push({
        name: 'HandleLive',
        params: {
          id: item && item.id || 0
        }
      });
    }
  }
};
exports.default = _default;