var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData }
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { "show-header": false, data: props.row.child }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "title", width: "200" }
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "oldCourseName", width: "300" }
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "newCourseName", width: "300" }
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { prop: "操作" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(inProps) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.editClassHourInfo(
                                              "editClassHourInfo",
                                              props.row,
                                              inProps.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: { click: _vm.editChapter }
                                      },
                                      [_vm._v("添加作业")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: { click: _vm.editChapter }
                                      },
                                      [_vm._v("删除")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.showImgDialog(
                                              "ImageDialog",
                                              props.row,
                                              inProps.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("图片")]
                                    ),
                                    _vm._v(" "),
                                    inProps.row.image
                                      ? _c("el-image", {
                                          staticStyle: {
                                            width: "80px",
                                            height: "60px",
                                            margin: "0 30px"
                                          },
                                          attrs: {
                                            src:
                                              inProps.row.image &&
                                              inProps.row.image.indexOf(
                                                "http"
                                              ) > -1
                                                ? inProps.row.image
                                                : inProps.row.image
                                                ? "https://" + inProps.row.image
                                                : ""
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "章节名称", prop: "title", width: "200" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "原始课程文件", width: "300" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "新课程文件", width: "300" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.editChapter("", props.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: { click: _vm.editChapter }
                      },
                      [_vm._v("添加课时")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: { click: _vm.deleteChapter }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        staticStyle: { "margin-top": "15px" },
        attrs: {
          "current-page": _vm.searchParam.page,
          "page-size": _vm.searchParam.per_page,
          total: _vm.total
        },
        on: { "current-change": _vm.handlePageNoChange }
      }),
      _vm._v(" "),
      _c("edit-class-hour-info", {
        ref: "editClassHourInfo",
        on: { refresh: _vm.updateTableData }
      }),
      _vm._v(" "),
      _c("image-dialog", {
        ref: "ImageDialog",
        attrs: { type: "classConfig" },
        on: { refresh: _vm.updateTableImageData, confirm: _vm.confirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }