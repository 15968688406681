"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      createTime: '',
      listLoading: false,
      searchParam: {
        page: 1,
        page_size: 10
      },
      columns: [{
        label: '学生ID',
        width: '60',
        prop: 'uid'
      }, {
        label: '学生昵称',
        width: '60',
        prop: 'uname'
      }, {
        label: '购课时间',
        width: '60',
        prop: 'buy_time'
      }, {
        label: '操作',
        width: '60',
        prop: 'status'
      }],
      total: 0,
      tableData: [],
      dialogVisible: false,
      selectItem: ''
    };
  },
  methods: {
    showDialog: function showDialog() {
      this.dialogVisible = true;
      this.fetchData();
    },
    addStudent: function addStudent(rowData) {
      var _this = this;

      this.listLoading = true;
      var url = 'setOnlineClassaddStudent';
      var postData = {
        uid: rowData.uid,
        class_id: this.$route.params.id
      };

      _api.default[url](postData).then(function (response) {
        _this.$message.success('操作成功~');

        _this.$emit('refresh');

        _this.listLoading = false;

        _this.fetchData();
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    fetchData: function fetchData() {
      var _this2 = this;

      this.listLoading = true;
      var url = 'getOnlineClassStudentsJoinList';
      var postData = (0, _objectSpread2.default)({
        class_id: this.$route.params.id
      }, this.searchParam);

      _api.default[url](postData).then(function (response) {
        var _response$data = response.data,
            data = _response$data.data,
            total = _response$data.total;
        _this2.tableData = data;
        _this2.total = total || 0;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.searchParam.start_time = new Date(val[0]).getTime() / 1000;
        this.searchParam.end_time = new Date(val[1]).getTime() / 1000;
      } else {
        this.searchParam.start_time = '';
        this.searchParam.end_time = '';
      }
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    currentChange: function currentChange(val) {
      this.selectItem = val;
    },
    confirm: function confirm() {
      if (!this.selectItem) {
        this.$message.warning('请选择数据~');
        return;
      }

      this.$emit('confirm', this.selectItem);
      this.dialogVisible = false;
    }
  }
};
exports.default = _default;