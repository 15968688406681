var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    _vm._l(_vm.menus, function(item, index) {
      return _c("div", { key: index, attrs: { index: item.meta.fullPath } }, [
        !item.hidden
          ? _c(
              "div",
              [
                typeof item.children === "undefined" ||
                item.children.length === 0
                  ? [
                      _c(
                        "app-link",
                        { attrs: { to: _vm.resolvePath(item.path) } },
                        [
                          _c(
                            "el-menu-item",
                            {
                              class: {
                                "submenu-title-noDropdown": !_vm.isNest
                              },
                              attrs: { index: _vm.resolvePath(item.path) }
                            },
                            [_c("item", { attrs: { meta: item.meta } })],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _c(
                      "el-submenu",
                      {
                        ref: "subMenu",
                        refInFor: true,
                        attrs: { index: _vm.resolvePath(item.path) },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [_vm._v(_vm._s(item.meta.title))]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("sidebar-item", {
                          staticClass: "nest-menu",
                          attrs: {
                            "is-nest": true,
                            menus: item.children,
                            "base-path": _vm.resolvePath(item.path)
                          }
                        })
                      ],
                      1
                    )
              ],
              2
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }