"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'UserInfoDialog',
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      searchParam: {
        page: 1,
        per_page: 10
      },
      listLoading: false,
      tableData: [],
      total: 0,
      columns: [{
        label: 'UID',
        width: '100',
        prop: 'uid'
      }, {
        label: '用户昵称',
        width: '100',
        prop: 'uname'
      }, {
        label: '时间',
        width: '100',
        prop: 'ptime'
      }]
    };
  },
  methods: {
    showDialog: function showDialog(id) {
      this.dialogVisible = true;
      this.fetchData(id);
    },
    fetchData: function fetchData(id) {
      var _this = this;

      this.listLoading = true;

      _api.default.getLiveUserList({
        id: id
      }).then(function (res) {
        _this.tableData = res.data;
        _this.total = res.count;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    }
  }
};
exports.default = _default;