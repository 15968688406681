var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课时名称：", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "课时类型：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formData.type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type"
                  }
                },
                _vm._l(_vm.lessonType, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "练字时长：", prop: "length" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { type: "number" },
                model: {
                  value: _vm.formData.length,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "length", $$v)
                  },
                  expression: "formData.length"
                }
              }),
              _vm._v(" "),
              _c("span", [_vm._v("分钟")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "编码：", prop: "page_code" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.page_code,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "page_code", $$v)
                  },
                  expression: "formData.page_code"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "映射编码：", prop: "map_code" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.map_code,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "map_code", $$v)
                  },
                  expression: "formData.map_code"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "单字图（黑）：", prop: "word_resource_id" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.formData.wordResourceName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "wordResourceName", $$v)
                  },
                  expression: "formData.wordResourceName"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.choiceCourseware(
                        "image",
                        "word_resource_id",
                        "wordResourceName"
                      )
                    }
                  }
                },
                [_vm._v("选择")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "单字图（灰）：", prop: "gray_word_resource_id" }
            },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.formData.grayWordResourceName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "grayWordResourceName", $$v)
                  },
                  expression: "formData.grayWordResourceName"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.choiceCourseware(
                        "image",
                        "gray_word_resource_id",
                        "grayWordResourceName"
                      )
                    }
                  }
                },
                [_vm._v("选择")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "笔顺图：", prop: "stroke_resource_id" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.formData.strokeResourceName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "strokeResourceName", $$v)
                  },
                  expression: "formData.strokeResourceName"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.choiceCourseware(
                        "image",
                        "stroke_resource_id",
                        "strokeResourceName"
                      )
                    }
                  }
                },
                [_vm._v("选择")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "GIF图：", prop: "gif_resource_id" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.formData.gifResourceName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "gifResourceName", $$v)
                  },
                  expression: "formData.gifResourceName"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.choiceCourseware(
                        "image",
                        "gif_resource_id",
                        "gifResourceName"
                      )
                    }
                  }
                },
                [_vm._v("选择")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "单字视频：", prop: "video_resource_id" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.formData.videoName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "videoName", $$v)
                  },
                  expression: "formData.videoName"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.choiceCourseware(
                        "video",
                        "video_resource_id",
                        "videoName"
                      )
                    }
                  }
                },
                [_vm._v("选择")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "书写要点：", prop: "attention_resource_id" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.formData.attentionResourceName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "attentionResourceName", $$v)
                  },
                  expression: "formData.attentionResourceName"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.choiceCourseware(
                        "image",
                        "attention_resource_id",
                        "attentionResourceName"
                      )
                    }
                  }
                },
                [_vm._v("选择")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.formData.type === 2
            ? _c(
                "el-form-item",
                {
                  attrs: { label: "天地格（红)：", prop: "red_pic_resource_id" }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.formData.redPicResourceName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "redPicResourceName", $$v)
                      },
                      expression: "formData.redPicResourceName"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.choiceCourseware(
                            "image",
                            "red_pic_resource_id",
                            "redPicResourceName"
                          )
                        }
                      }
                    },
                    [_vm._v("选择")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "天地格：", prop: "default_pic_resource_id" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.formData.defaultPicResourceName,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "defaultPicResourceName", $$v)
                  },
                  expression: "formData.defaultPicResourceName"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.choiceCourseware(
                        "image",
                        "default_pic_resource_id",
                        "defaultPicResourceName"
                      )
                    }
                  }
                },
                [_vm._v("选择")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序：", prop: "sort" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "sort", $$v)
                  },
                  expression: "formData.sort"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("asset-dialog", {
        ref: "assetDialog",
        attrs: { isOnly: true },
        on: { confirm: _vm.getFileList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }