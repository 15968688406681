var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          "data-list": _vm.tableData,
          loading: _vm.listLoading,
          columns: _vm.initUserData.childEvaList.columns,
          "show-index": false,
          selection: false,
          total: _vm.total,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "评测原图"
                  ? _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "80px", height: "60px" },
                          attrs: {
                            src: props.row.img_url,
                            "preview-src-list": [props.row.img_url]
                          }
                        })
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }