var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c("el-tab-pane", { attrs: { label: "已审", name: "1" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { disabled: "", label: "待审", name: "2" }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "pageForm",
          staticClass: "form-inline",
          attrs: { inline: true }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课程ID：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "课程ID" },
                model: {
                  value: _vm.searchParam.id,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParam, "id", $$v)
                  },
                  expression: "searchParam.id"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "课程名称：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "课程名称" },
                model: {
                  value: _vm.searchParam.video_title,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParam, "video_title", $$v)
                  },
                  expression: "searchParam.video_title"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button-group",
            { staticStyle: { float: "right" } },
            [
              _c("el-button", {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.onSubmit }
              }),
              _vm._v(" "),
              _c(
                "el-tooltip",
                { attrs: { content: "重置查询条件", placement: "top" } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-refresh" },
                    on: { click: _vm.reset }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tooltip",
                { attrs: { content: "显示更多查询条件", placement: "top" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.queryShowMore = !_vm.queryShowMore
                        }
                      }
                    },
                    [
                      _c("i", {
                        class: {
                          "el-icon-arrow-up": _vm.queryShowMore,
                          "el-icon-arrow-down": !_vm.queryShowMore
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.queryShowMore
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传用户UID：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "上传用户UID" },
                        model: {
                          value: _vm.searchParam.uid,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "uid", $$v)
                          },
                          expression: "searchParam.uid"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "教师ID：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "教师ID" },
                        model: {
                          value: _vm.searchParam.teacherId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "teacherId", $$v)
                          },
                          expression: "searchParam.teacherId"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否免费：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.searchParam.isFree,
                            callback: function($$v) {
                              _vm.$set(_vm.searchParam, "isFree", $$v)
                            },
                            expression: "searchParam.isFree"
                          }
                        },
                        _vm._l(_vm.initCourseData.courseList.options, function(
                          item
                        ) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否精选：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.searchParam.isBest,
                            callback: function($$v) {
                              _vm.$set(_vm.searchParam, "isBest", $$v)
                            },
                            expression: "searchParam.isBest"
                          }
                        },
                        _vm._l(_vm.initCourseData.courseList.options, function(
                          item
                        ) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上架时间：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "~",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        on: { change: _vm.dateRangeChange },
                        model: {
                          value: _vm.putAwayTime,
                          callback: function($$v) {
                            _vm.putAwayTime = $$v
                          },
                          expression: "putAwayTime"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下架时间：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "~",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        on: { change: _vm.dateRangeChange },
                        model: {
                          value: _vm.soldOutTime,
                          callback: function($$v) {
                            _vm.soldOutTime = $$v
                          },
                          expression: "soldOutTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _vm.hasPerm("CourseList:add")
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track:click",
                          arg: "click"
                        }
                      ],
                      attrs: {
                        "track-params": "课程-点播课程-添加",
                        type: "primary",
                        icon: "el-icon-plus"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleCourse("add")
                        }
                      }
                    },
                    [_vm._v("添加")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "show-index": false,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.per_page,
          "data-list": _vm.tableData,
          columns: _vm.initCourseData.courseList.columns,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "课程封面"
                  ? _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "80px", height: "60px" },
                          attrs: {
                            src: props.row.coverImg,
                            "preview-src-list": [props.row.coverImg]
                          }
                        })
                      ],
                      1
                    )
                  : props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              "track-params": "课程-点播课程-课时管理",
                              size: "small",
                              type: "text"
                            },
                            on: {
                              click: function($event) {
                                return _vm.jumpPage(props.row, "ClassHourList")
                              }
                            }
                          },
                          [_vm._v("课时管理")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              "track-params": "课程-点播课程-编辑",
                              size: "small",
                              type: "text"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleCourse("edit", props.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.jumpPage(props.row, "StudyLearn")
                              }
                            }
                          },
                          [_vm._v("学习计划")]
                        )
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }