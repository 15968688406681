"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _elementChinaAreaData = require("element-china-area-data");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Api from '@/api';
var _default = {
  data: function data() {
    var validatePhone = function validatePhone(rule, value, callback) {
      var regPhone = /^1[3456789]\d{9}$/;

      if (!regPhone.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };

    return {
      options: _elementChinaAreaData.regionData,
      selectedOptions: [],
      dialogAddr: false,
      ruleForm: {
        contacts: '',
        phone: '',
        detail: '',
        province: '',
        city: '',
        region: '',
        street: ''
      },
      rules: {
        phone: [{
          validator: validatePhone,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    showDialog: function showDialog(item) {
      console.log(item);
      this.dialogAddr = true;
      this.ruleForm.contacts = item.contacts;
      this.ruleForm.phone = item.phone;
      this.ruleForm.detail = item.detail;
    },
    submitForm: function submitForm(formName) {
      var _this = this;

      console.log(formName);
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.$emit('addrInfo', _this.ruleForm);

          _this.dialogAddr = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleChange: function handleChange(value) {
      console.log(value);
      this.ruleForm.province = _elementChinaAreaData.CodeToText[value[0]];
      this.ruleForm.city = _elementChinaAreaData.CodeToText[value[1]];
      this.ruleForm.region = _elementChinaAreaData.CodeToText[value[2]]; //    this.ruleForm.location=CodeToText[value[0]]+' '+CodeToText[value[1]]+' '+CodeToText[value[2]];
    }
  }
};
exports.default = _default;