"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.array.find");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.string.starts-with");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _auth = require("@/utils/auth");

var _cache = require("@/utils/cache");

var _store = _interopRequireDefault(require("@/store"));

// create an axios instance
var service = _axios.default.create({
  withCredentials: false,
  // send cookies when cross-domain requests
  timeout: 20000 // request timeout

}); // request interceptor


service.interceptors.request.use(function (config) {
  config.headers['Accept'] = 'application/json';
  config.headers['Authorization'] = (0, _auth.getToken)();
  config.headers['System'] = 'eduOnline';
  config.headers['user-role-type'] = (0, _cache.find)('userInfo') && (0, _cache.find)('userInfo').backUserInfo && (0, _cache.find)('userInfo').backUserInfo.roleCode ? (0, _cache.find)('userInfo').backUserInfo.roleCode : '';
  return config;
}, function (error) {
  // do something with request error
  error.errorCode = '';
  error.errorMessage = '网络异常，请稍后重试~';
  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(function (response) {
  var res = response.data;
  var middleFilter = ['smartpen', 'fileService', 'userCenter', 'comment'].filter(function (item) {
    return response.config.url.includes(item);
  });
  var isMiddle = middleFilter && middleFilter.length;

  if (response.status === 200 && (Number(res.code) === 200 || Number(res.status) === 1 && !isMiddle || Number(res.status) === 0 && isMiddle) || Number(res.status) === 0) {
    return res;
  } else {
    console.warn(response);

    try {
      res.errorMessage = res.msg;
    } catch (e) {
      res.errorMessage = 'type error';
      return Promise.reject(res);
    }

    if (Number(res.code) === 405 || Number(res.status) === 401 || Number(res.code) === 401) {
      reLogin();
    } else {
      res.errorCode = res.code || res.status || '';

      if (res.code && res.code.toString().startsWith('4') || res.status && res.status.toString().startsWith('4')) {
        res.errorMessage = res.msg || res.message || '客户端异常，请稍后重试~';
      } else if (res.code && res.code.toString().startsWith('5') || res.status && res.status.toString().startsWith('5')) {
        res.errorMessage = res.msg || res.message || '服务端出现了问题，请稍后重试~';
      }

      return Promise.reject(res);
    }
  }
}, function (error) {
  console.error('rejected err' + error);

  if (error.response) {
    error.errorCode = error.response.status || '';

    if (Number(error.errorCode) === 401) {
      reLogin();
    } else {
      if (error.errorCode.toString().startsWith('4')) {
        error.errorMessage = '资源未找到，请稍后重试~';
      } else if (error.errorCode.toString().startsWith('5')) {
        error.errorMessage = '服务器内部错误，请稍后重试~';
      }

      return Promise.reject(error);
    }
  } else {
    error.errorCode = '';
    error.errorMessage = error;
    return Promise.reject(error);
  }
});

var reLogin = function reLogin() {
  _elementUi.MessageBox.confirm('您已经登出，您可以取消以停留在此页面，或再次登录', '确认登出', {
    confirmButtonText: '重新登录',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(function () {
    _store.default.dispatch('user/resetToken').then(function () {
      location.reload();
    });
  });
};

var request = function request(options) {
  var isHideError = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var isHideLoading = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return new Promise(function (resolve, reject) {
    var loadingInstance = null;

    if (!isHideLoading) {
      loadingInstance = _elementUi.Loading.service({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    }

    service(options).then(function (res) {
      loadingInstance && loadingInstance.close();
      resolve(res);
    }).catch(function (err) {
      console.error(err);
      loadingInstance && loadingInstance.close();

      if (!isHideError) {
        (0, _elementUi.Message)({
          message: err.errorMessage ? "".concat(err.errorMessage, "\uFF08").concat(err.errorCode, "\uFF09") : 'Error',
          type: err.errorCode && err.errorCode.toString().startsWith('4') ? 'warning' : 'error',
          duration: 3 * 1000
        });
      }

      reject(err);
    });
  });
};

var _default = request;
exports.default = _default;