var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { ref: "chatBox", staticClass: "res-data-wrap" },
        [
          !_vm.socketConnected
            ? _c(
                "div",
                {
                  staticClass: "refresh-btn",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleRefresh($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-refresh" }),
                  _vm._v("网络连接异常，点击重试\n        ")
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("DynamicScroller", {
            ref: "scroller",
            staticClass: "scroller",
            attrs: {
              items: _vm.wordList,
              "min-item-size": 10,
              keyField: "wordId"
            },
            on: { resize: _vm.scrollToBottom },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  var active = ref.active
                  return [
                    _c(
                      "DynamicScrollerItem",
                      {
                        attrs: {
                          item: item,
                          active: active,
                          "data-index": index
                        }
                      },
                      [
                        _c("div", { staticClass: "res-item" }, [
                          _c(
                            "div",
                            {
                              staticClass: "res-item-l",
                              on: {
                                click: function($event) {
                                  return _vm.handleItem(item)
                                }
                              }
                            },
                            [
                              _c("el-image", {
                                staticClass: "avatar",
                                attrs: { src: item.userPic, fit: "cover" }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.userName))
                              ]),
                              _vm._v(" "),
                              item.strokeCorrect && item.dataType === 2
                                ? _c("div", { staticClass: "score" }, [
                                    _vm._v(
                                      "\n                                分数：" +
                                        _vm._s(item.totalScore) +
                                        "\n                            "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "res-item-r" }, [
                            _c(
                              "div",
                              {
                                staticClass: "img-b",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.handleImg(item)
                                  }
                                }
                              },
                              [
                                item.dataType === 2
                                  ? _c("img", {
                                      staticClass: "word-bg-img",
                                      attrs: {
                                        src: require("@/assets/znb_bg_word_yellow.png")
                                      }
                                    })
                                  : _c("img", {
                                      staticClass: "word-bg-img",
                                      attrs: {
                                        src: require("@/assets/znb_bg_word_gray.png")
                                      }
                                    }),
                                _vm._v(" "),
                                item.wordPic
                                  ? _c("el-image", {
                                      staticClass: "word-img",
                                      attrs: { src: item.wordPic, fit: "cover" }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.status === "wait"
                                  ? _c("div", { staticClass: "word-mask" }, [
                                      _c("div", { staticClass: "loading" })
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          !_vm.autoScroll && !_vm.isBottom
            ? _c(
                "div",
                { staticClass: "more-btn", on: { click: _vm.handleMore } },
                [
                  _c("i", { staticClass: "el-icon-bottom" }),
                  _vm._v("点击查看更多\n        ")
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-btn", on: { click: _vm.handleScreen } },
        [
          _c("el-button", {
            attrs: { type: "primary", icon: "el-icon-edit", circle: "" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "res-dialog",
          attrs: { visible: _vm.resFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.resFormVisible = $event
            }
          }
        },
        [
          _c("div", [
            _vm._v(
              "\n            " + _vm._s(_vm.curItem.userName) + "\n        "
            )
          ]),
          _vm._v(" "),
          _c("iframe", { attrs: { src: _vm.curItem.freePracticeUrl } })
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "word-dialog",
          attrs: { visible: _vm.wordFormVisible, width: "600px" },
          on: {
            "update:visible": function($event) {
              _vm.wordFormVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "word-img" }, [
            _c("img", {
              staticClass: "word-bg-img",
              attrs: { src: require("@/assets/znb_bg_word_yellow.png") }
            }),
            _vm._v(" "),
            _c("canvas", {
              attrs: { id: "wordCanvas", width: "350", height: "350" }
            })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }