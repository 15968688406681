var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        visible: _vm.centerDialogVisible,
        width: "20%",
        center: ""
      },
      on: {
        "update:visible": function($event) {
          _vm.centerDialogVisible = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _vm.status === 1
                ? _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.centerDialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.buildOrder }
                        },
                        [_vm._v("确 定")]
                      )
                    ],
                    1
                  )
                : _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.centerDialogVisible = false
                            }
                          }
                        },
                        [_vm._v("好的")]
                      )
                    ],
                    1
                  )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.prompt))]),
      _vm._v(" "),
      _vm.status === 2
        ? _c(
            "block",
            _vm._l(_vm.infoData, function(item, index) {
              return _c("div", { key: index }, [
                _c("p", [
                  _vm._v(
                    _vm._s(item.title) +
                      "(" +
                      _vm._s(item.sku) +
                      ") " +
                      _vm._s(item.msg)
                  )
                ])
              ])
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }