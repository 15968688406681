"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var classRoomRouter = {
  path: '/bt',
  component: _layout.default,
  redirect: '/bt/resource',
  name: 'Bt',
  meta: {
    title: '碑帖'
  },
  children: [{
    path: 'resource',
    name: 'Resource',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/classRoom/vodCourseManager/CoursewareList.vue'));
      });
    },
    meta: {
      title: '视频管理'
    }
  }]
};
var _default = classRoomRouter;
exports.default = _default;