var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "upload-demo",
          attrs: {
            action: "",
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            headers: { "content-type": "multipart/form-data" },
            multiple: "",
            disabled: _vm.disabled,
            accept: "image/png, image/jpeg",
            limit: _vm.limit,
            "http-request": _vm.uploadRequest,
            "on-exceed": _vm.handleExceed,
            "file-list": _vm.fileList,
            "list-type": _vm.listType
          }
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, "append-to-body": true },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }