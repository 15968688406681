"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actOptions = exports.sendOptions = exports.shopOptions = exports.orderOptions = exports.clientTypeList = exports.recommendCourseList = exports.learnObjList = exports.intentionList = exports.typeList = exports.scheduleList = exports.serviceLine = exports.initData = void 0;
var commColumns = [{
  label: 'ID',
  width: '60',
  prop: 'id',
  hide: true
}, {
  label: '客户UID',
  width: '80',
  prop: 'uid',
  hide: false
}, {
  label: '客户昵称',
  width: '80',
  prop: 'userName',
  hide: false
}, {
  label: '头像',
  width: '100',
  prop: 'userAvatar',
  hide: false
}];
var initData = {
  clue: {
    name: 'clue',
    columns: [].concat(commColumns, [{
      label: '类型',
      width: '100',
      prop: 'typeName',
      hide: false
    }, {
      label: '体验课',
      width: '120',
      prop: 'course',
      hide: false
    }, {
      label: '课程领取时间',
      width: '120',
      prop: 'courseDrawTime',
      hide: false
    }, {
      label: '注册手机号',
      width: '120',
      prop: 'mobile',
      hide: false
    }, {
      label: '手机号',
      width: '120',
      prop: 'infoPhone',
      hide: false
    }, {
      label: '渠道来源',
      width: '80',
      prop: 'channel',
      hide: true
    }, {
      label: '客户端',
      width: '80',
      prop: 'clientTypeName',
      hide: true
    }, {
      label: '业务线',
      width: '80',
      prop: 'lineType',
      hide: true
    }, {
      label: '销售微信',
      width: '80',
      prop: 'weChatName',
      hide: false
    }, {
      label: '销售',
      width: '80',
      prop: 'saleManId',
      hide: true
    }, {
      label: '操作',
      width: '200',
      fix: 'right'
    }])
  },
  chance: {
    name: 'chance',
    columns: [].concat(commColumns, [{
      label: '类型',
      width: '100',
      prop: 'typeName',
      hide: false
    }, {
      label: '体验课',
      width: '100',
      prop: 'course',
      hide: false
    }, {
      label: '课程领取时间',
      width: '110',
      prop: 'courseDrawTime',
      hide: false
    }, {
      label: '最近作品提交',
      width: '120',
      prop: 'lastHomeworkTime',
      hide: true
    }, {
      label: '点评',
      width: '80',
      prop: 'reviewStatus',
      hide: true
    }, {
      label: '提交次数',
      width: '80',
      prop: 'homeworkNum',
      hide: true
    }, {
      label: '注册手机号',
      width: '120',
      prop: 'mobile',
      hide: false
    }, {
      label: '手机号',
      width: '120',
      prop: 'infoPhone',
      hide: false
    }, {
      label: '渠道来源',
      width: '80',
      prop: 'channel',
      hide: true
    }, {
      label: '客户端',
      width: '80',
      prop: 'clientTypeName',
      hide: true
    }, {
      label: '业务线',
      width: '80',
      prop: 'lineType',
      hide: true
    }, {
      label: '销售微信',
      width: '80',
      prop: 'weChatName',
      hide: false
    }, {
      label: '销售',
      width: '80',
      prop: 'saleManId',
      hide: true
    }, {
      label: '操作',
      width: '160',
      fix: 'right'
    }])
  },
  orderClient: {
    name: 'orderClient',
    columns: [].concat(commColumns, [{
      label: '手机号',
      width: '80',
      prop: 'mobile',
      hide: false
    }, {
      label: '销售微信',
      width: '80',
      prop: 'weChatName',
      hide: false
    }, {
      label: '销售',
      width: '80',
      prop: 'saleManId',
      hide: false
    }, {
      label: '最近购买课程时间',
      width: '100',
      prop: 'lastOrder',
      hide: false
    }, {
      label: '操作',
      width: '160',
      fix: 'right'
    }])
  },
  shipReviewList: {
    columns: [{
      label: '订单编号',
      width: '80',
      prop: 'id',
      hide: false
    }, {
      label: '创建时间',
      width: '160',
      prop: 'created_at',
      hide: false
    }, {
      label: '用户ID',
      width: '80',
      prop: 'uid',
      hide: false
    }, {
      label: '用户名称',
      width: '100',
      prop: 'uname',
      hide: false
    }, {
      label: '销售ID',
      width: '100',
      prop: 'sale_uid',
      hide: false
    }, {
      label: '销售',
      width: '100',
      prop: 'sale_uid_uname',
      hide: false
    }, {
      label: '手机号(账号绑定)',
      width: '120',
      prop: 'phone',
      hide: false
    }, {
      label: '手机号',
      width: '120',
      prop: 'infophone',
      hide: false
    }, {
      label: '课程/课程包',
      width: '100',
      prop: 'title',
      hide: false
    }, {
      label: '订单金额',
      width: '80',
      prop: 'totalprice',
      hide: false
    }, {
      label: '收货地址',
      width: '160',
      prop: 'address',
      hide: false
    }, {
      label: '发货商品',
      width: '160',
      prop: 'product',
      hide: false
    }, {
      label: '订单类型',
      width: '80',
      prop: 'type',
      hide: false
    }, {
      label: '旺店通推送状态',
      width: '100',
      prop: 'wdt_state',
      hide: false
    }, {
      label: '发货状态',
      width: '80',
      prop: 'delivery_state',
      hide: false
    }, {
      label: '快递公司',
      width: '80',
      prop: 'delivery_company',
      hide: false
    }, {
      label: '快递单号',
      width: '140',
      prop: 'delivery_code',
      hide: false
    }, {
      label: '客户备注',
      width: '120',
      prop: 'remark',
      hide: false
    }, {
      label: '申请修改备注',
      width: '120',
      prop: 'act_remark',
      hide: false
    }, {
      label: '操作',
      width: '100',
      fix: 'right'
    }]
  }
};
/* 业务线 */

exports.initData = initData;
var serviceLine = [{
  value: '3',
  label: '少儿'
}, {
  value: '1',
  label: '软笔'
}, {
  value: '2',
  label: '硬笔'
}, {
  value: '4',
  label: '智能笔'
}];
/* 进度 */

exports.serviceLine = serviceLine;
var scheduleList = [{
  value: 'phone',
  label: '未添加微信，已电话联系'
}, {
  value: 'nocontact',
  label: '未添加微信，无手机号码，无法联系'
}, {
  value: 'order',
  label: '该业务线已成单'
}, {
  value: 'other',
  label: '其他'
}];
/* 用户意向 */

exports.scheduleList = scheduleList;
var intentionList = [{
  value: 'high',
  label: '高意向'
}, {
  value: 'middle',
  label: '中等意向，可跟进'
}, {
  value: 'low',
  label: '低意向，可跟进'
}, {
  value: 'nocallback',
  label: '一直未回复任何消息'
}, {
  value: 'reject',
  label: '明确拒绝买课'
}];
/* 学习对象类型 */

exports.intentionList = intentionList;
var learnObjList = [{
  value: 'adult',
  label: '成人'
}, {
  value: 'junior',
  label: '初中学生'
}, {
  value: 'topclass',
  label: '大班'
}, {
  value: 'gradeone',
  label: '一年级'
}, {
  value: 'gradetwo',
  label: '二年级'
}, {
  value: 'gradethree',
  label: '三年级'
}, {
  value: 'gradefour',
  label: '四年级及以上'
}, {
  value: 'senior',
  label: '高中学生'
}];
/* 推荐的课程 */

exports.learnObjList = learnObjList;
var recommendCourseList = [{
  value: 'children',
  label: '儿童课'
}, {
  value: 'hardregular',
  label: '硬笔楷书'
}, {
  value: 'hardrungular',
  label: '硬笔行楷'
}, {
  value: 'hardrun',
  label: '硬笔行书'
}, {
  value: 'soft',
  label: '软笔'
}];
exports.recommendCourseList = recommendCourseList;
var typeList = [{
  value: 0,
  label: '未分类'
}, {
  value: 1,
  label: 'A类'
}, {
  value: 2,
  label: 'B类'
}, {
  value: 3,
  label: 'C类'
}];
/* 端来源类型 */

exports.typeList = typeList;
var clientTypeList = [{
  value: 'pc',
  label: 'PC'
}, {
  value: 'h5',
  label: 'H5'
}, {
  value: 'miniprogram',
  label: '小程序'
}, {
  value: 'pad',
  label: 'PAD'
}];
exports.clientTypeList = clientTypeList;
var orderOptions = [{
  value: 1,
  label: '课程包'
}, {
  value: 2,
  label: '课程'
}];
exports.orderOptions = orderOptions;
var shopOptions = [{
  value: 0,
  label: '-'
}, {
  value: 1,
  label: '推送成功'
}];
exports.shopOptions = shopOptions;
var sendOptions = [{
  value: 0,
  label: '未发货'
}, {
  value: 1,
  label: '已发货'
}, {
  value: 2,
  label: '已签收'
}];
exports.sendOptions = sendOptions;
var actOptions = [{
  value: 0,
  label: '全部'
}, {
  value: 1,
  label: '申请修改'
}, {
  value: 2,
  label: '未申请修改'
}];
exports.actOptions = actOptions;