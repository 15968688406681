"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

var _api = _interopRequireDefault(require("@/api"));

var _initData = require("../../initData");

var _FollowInfo = _interopRequireDefault(require("./FollowInfo"));

var _WorksInfo = _interopRequireDefault(require("./WorksInfo"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'FollowDialog',
  components: {
    FollowInfo: _FollowInfo.default,
    WorksInfo: _WorksInfo.default
  },
  props: {
    pageName: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    var _this = this;

    var validate = function validate(rule, value, callback) {
      var err = {
        tag_schedule: '请选择进度',
        tag_intention: '请选择用户意向',
        tag_object: '请选择学习对象的类型',
        tag_course: '请选择推荐的课程',
        content: '请填写备注信息'
      };
      var condition = true;

      for (var i in err) {
        if (i === rule.field) {
          condition = condition && !value;
        } else {
          condition = condition && _this.formData[i] === '';
        }
      }

      if (condition) {
        callback(new Error(err[rule.field]));
      } else {
        callback();
      }
    };

    return {
      formData: {},
      recordList: [],
      activeName: '',
      lineActiveName: '',
      drawer: false,
      loading: false,
      serviceLineArr: _initData.serviceLine,
      scheduleList: _initData.scheduleList,
      selectJson: [{
        prop: 'tag_schedule',
        list: _initData.scheduleList,
        placeholder: '选择进度'
      }, {
        prop: 'tag_intention',
        list: _initData.intentionList,
        placeholder: '选择用户意向'
      }, {
        prop: 'tag_object',
        list: _initData.learnObjList,
        placeholder: '选择学习对象的类型'
      }, {
        prop: 'tag_course',
        list: _initData.recommendCourseList,
        placeholder: '选择推荐的课程'
      }],
      itemData: {},
      rules: {
        tag_schedule: [{
          trigger: 'change',
          validator: validate
        }],
        tag_intention: [{
          trigger: 'change',
          validator: validate
        }],
        tag_object: [{
          trigger: 'change',
          validator: validate
        }],
        tag_course: [{
          trigger: 'change',
          validator: validate
        }],
        content: [{
          trigger: 'blur',
          validator: validate
        }]
      }
    };
  },
  watch: {
    activeName: function activeName(val) {
      if (val === 'works') {
        this.$refs.worksInfo.fetchData({
          uid: this.itemData.uid,
          customer_id: this.itemData.id
        });
      }
    }
  },
  created: function created() {},
  methods: {
    init: function init() {
      var itemData = this.itemData,
          pageName = this.pageName;
      this.formData = {
        cate_id: itemData.lineId ? itemData.lineId.toString() : '3',
        tag_schedule: '',
        tag_intention: '',
        tag_object: '',
        tag_course: '',
        content: ''
      };

      if (pageName === 'chance') {
        this.formData.customer_id = itemData.id;
      } else {
        this.formData.buyer_id = itemData.id;
      }

      this.lineActiveName = itemData.lineId ? itemData.lineId.toString() : '3';
    },
    showDialog: function showDialog(item) {
      var _this2 = this;

      this.activeName = 'action';
      this.itemData = item;
      this.init();
      this.drawer = true;
      this.$nextTick(function () {
        _this2.resetRecord();
      });
    },
    handleClose: function handleClose(done) {
      this.$confirm('确认关闭？').then(function (_) {
        done();
      }).catch(function (_) {});
    },
    onSubmit: function onSubmit(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _api.default.addFollowRecord(_this3.formData).then(function () {
            _this3.$message({
              type: 'success',
              message: '操作成功'
            });

            _this3.resetRecord();

            _this3.$refs[formName].resetFields();
          }).catch(function () {});
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetRecord: function resetRecord() {
      this.$refs.followInfo.fetchData({
        uid: this.itemData.uid
      });
    }
  }
};
exports.default = _default;