"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var BASE_URL = "".concat(process.env.VUE_APP_BASE_API, "/curricula");
var BASE_URL_OB = "".concat(process.env.VUE_APP_BASE_API);
var PROXY_ID = ''; // const BASE_URL = '';
// const PROXY_ID = '/tapi';

var _default = {
  /**
   * 获取课件列表
   * @param params
   */
  getCoursewareList: function getCoursewareList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/tcvideo/videoList"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取课件列表 测试先使用新的
   * @param params
   */
  getCoursewareListNew: function getCoursewareListNew(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/curricula/course/tcvideo/videoList"),
      method: 'get',
      params: params
    });
  },

  /**
   * 新增课件数据
   * @param data
   */
  addUploadInfo: function addUploadInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/tcvideo/doneUpload"),
      method: 'post',
      data: data
    });
  },

  /**
   * 编辑课件数据
   * @param data
   */
  editUploadInfo: function editUploadInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/tcvideo/videoUpdate"),
      method: 'post',
      data: data
    });
  },

  /**
   * 删除课件
   * @param data
   */
  deleteCourseware: function deleteCourseware(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/tcvideo/videoDel"),
      method: 'post',
      data: data
    });
  },

  /**
   * 课件删除恢复
   * @param data
   */
  recoverCourseware: function recoverCourseware(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/tcvideo/videoRestore"),
      method: 'post',
      data: data
    });
  },

  /**
   * 课件回收站列表
   * @param params
   */
  getRecycleList: function getRecycleList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/tcvideo/recycleList"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取点播课列表
   * @param params
   */
  getCourseList: function getCourseList(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/admin_video/index"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取点播课课时列表
   * @param params
   */
  getLessonList: function getLessonList(params) {
    return (0, _request.default)({
      url: "".concat(process.env.VUE_APP_COMMON_API, "/curricula/course/admin_video/lesson"),
      method: 'get',
      params: params
    });
  },

  /**
   * 点播课课时信息修改
   * @param data
   */
  bindCourseInfo: function bindCourseInfo(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/admin_video/bindVideo"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取推送历史列表
   * @param params
   */
  getPushHistory: function getPushHistory(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/admin_notice/listIndex"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取字帖用户
   * @param params
   */
  getbookUser: function getbookUser(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL_OB, "/sale/api/e-commerce/users"),
      method: 'get',
      params: params
    });
  },

  /**
   * 导出字帖用户
   * @param params
   */
  exportExcel: function exportExcel(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL_OB, "/sale/api/e-commerce/export_users"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取某一用户评测作品
   * @param params
   */
  gettestUser: function gettestUser(params, uid) {
    return (0, _request.default)({
      url: "".concat(BASE_URL_OB, "/sale/api/e-commerce/users/").concat(uid, "/photos"),
      method: 'get',
      params: params
    });
  },

  /**
   * 获取腾讯云上传签名
   * @param data
   */
  uploadCos: function uploadCos(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL_OB, "/fileService/api/v1/file/sts"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取配置信息
   * @param params
   */
  getContConfig: function getContConfig(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL_OB, "/sale/api/e-commerce/settings"),
      method: 'get',
      params: params
    });
  },

  /**
   * 保存配置
   * @param data
   */
  saveContConfig: function saveContConfig(data) {
    return (0, _request.default)({
      url: "".concat(BASE_URL_OB, "/sale/api/e-commerce/settings/store"),
      method: 'post',
      data: data
    });
  },

  /**
   * 获取资源时长信息
   * @param params
   */
  syncVideoInfo: function syncVideoInfo(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/console/syncVideoInfo"),
      method: 'get',
      params: params
    });
  },

  /**
   *  获取视频截图
   * @param params
   */
  syncMediaSnapshot: function syncMediaSnapshot(params) {
    return (0, _request.default)({
      url: "".concat(BASE_URL).concat(PROXY_ID, "/course/console/syncMediaSnapshot"),
      method: 'get',
      params: params
    });
  }
};
exports.default = _default;