import { render, staticRenderFns } from "./TypeSetting.vue?vue&type=template&id=e58a9c18&"
import script from "./TypeSetting.vue?vue&type=script&lang=js&"
export * from "./TypeSetting.vue?vue&type=script&lang=js&"
import style0 from "./TypeSetting.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e58a9c18')) {
      api.createRecord('e58a9c18', component.options)
    } else {
      api.reload('e58a9c18', component.options)
    }
    module.hot.accept("./TypeSetting.vue?vue&type=template&id=e58a9c18&", function () {
      api.rerender('e58a9c18', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/smartPen/courseManager/TypeSetting.vue"
export default component.exports