var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("search-box", {
        on: { search: _vm.onSubmit, reset: _vm.reset },
        scopedSlots: _vm._u([
          {
            key: "basic",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "客户UID：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "客户UID", clearable: true },
                      model: {
                        value: _vm.searchParam.uid,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uid", $$v)
                        },
                        expression: "searchParam.uid"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "昵称：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "昵称", clearable: true },
                      model: {
                        value: _vm.searchParam.uname,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uname", $$v)
                        },
                        expression: "searchParam.uname"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "手机号：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "手机号", clearable: true },
                      model: {
                        value: _vm.searchParam.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "phone", $$v)
                        },
                        expression: "searchParam.phone"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "queryMore",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "日期时间：" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        "range-separator": "~",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        clearable: true
                      },
                      on: { change: _vm.dateRangeChange },
                      model: {
                        value: _vm.createTime,
                        callback: function($$v) {
                          _vm.createTime = $$v
                        },
                        expression: "createTime"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "性别：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchParam.sex,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "sex", $$v)
                          },
                          expression: "searchParam.sex"
                        }
                      },
                      _vm._l(_vm.sexTypeArr, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "课程：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchParam.course_id,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "course_id", $$v)
                          },
                          expression: "searchParam.course_id"
                        }
                      },
                      _vm._l(_vm.courseArr, function(item) {
                        return _c("el-option", {
                          key: item.course_id,
                          attrs: {
                            label: item.video_title,
                            value: item.course_id
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          "data-list": _vm.tableData,
          loading: _vm.listLoading,
          columns: _vm.initUserData.userlist.columns,
          "show-index": false,
          selection: false,
          total: _vm.total,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _vm.hasPerm("UserList:alreadyBoughtClass")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                attrs: {
                                  "track-params": "智能笔-用户列表-已购课程",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.jumpPage(props.row)
                                  }
                                }
                              },
                              [_vm._v("已购课程\n                ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : props.column.label === "头像"
                  ? _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "80px", height: "60px" },
                          attrs: {
                            src: props.row.avatar,
                            "preview-src-list": [props.row.avatar]
                          }
                        })
                      ],
                      1
                    )
                  : props.column.label === "是否添加微信"
                  ? _c(
                      "div",
                      [
                        _c("span", [
                          _vm._v(_vm._s(props.row.wechatAddedValue) + " ")
                        ]),
                        _vm._v(" "),
                        _c("el-switch", {
                          on: {
                            change: function($event) {
                              return _vm.changeRow($event, props.row)
                            }
                          },
                          model: {
                            value: props.row.wechat_added,
                            callback: function($$v) {
                              _vm.$set(props.row, "wechat_added", $$v)
                            },
                            expression: "props.row.wechat_added"
                          }
                        })
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }