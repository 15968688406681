"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _initData = require("../initData");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CourseList',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default
  },
  data: function data() {
    return {
      tableData: null,
      listLoading: false,
      total: 0,
      columns: _initData.initData.courseList.columns,
      searchParam: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  activated: function activated() {
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      var url = 'getOnlineCourseList';

      _api.default[url](this.searchParam).then(function (response) {
        var data = response.data;
        _this.tableData = (data.data || []).map(function (item) {
          return _bean.default.onlineCourseBean(item);
        });
        _this.total = data.total || 0;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.searchParam = {
        page: 1,
        page_size: 10,
        name: '',
        vid: ''
      };
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    jumpPage: function jumpPage(type, item) {
      var id = type === 'add' ? 0 : item.id;
      this.$router.push({
        name: 'HandleClassCourse',
        params: {
          type: type,
          id: id
        }
      });
    },
    handleDelClassItem: function handleDelClassItem(rowData) {
      var _this2 = this;

      this.listLoading = true;
      var postData = {
        video_id: rowData.id
      };
      var url = 'delClassCourse';

      _api.default[url](postData).then(function (response) {
        _this2.$message.success('课程删除成功～');

        _this2.reset();

        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;