var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page-table", {
    ref: "pageTable",
    attrs: {
      loading: _vm.listLoading,
      "data-list": _vm.tableData,
      columns: _vm.gridData.searchInfo.columns,
      "page-no": _vm.searchParam.page,
      "page-size": _vm.searchParam.per_page,
      total: _vm.total
    },
    on: { pageNoChange: _vm.pageNoChange },
    scopedSlots: _vm._u([
      {
        key: "cell",
        fn: function(props) {
          return [
            props.column.label === "操作"
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.addGoods(props, props.row)
                          }
                        }
                      },
                      [_vm._v("添加\n            ")]
                    )
                  ],
                  1
                )
              : props.column.label === "SKU"
              ? _c(
                  "div",
                  [
                    _c("el-input", {
                      model: {
                        value: props.row.sku,
                        callback: function($$v) {
                          _vm.$set(
                            props.row,
                            "sku",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "props.row.sku"
                      }
                    })
                  ],
                  1
                )
              : props.column.label === "数量"
              ? _c(
                  "div",
                  [
                    _c("el-input", {
                      model: {
                        value: props.row.num,
                        callback: function($$v) {
                          _vm.$set(props.row, "num", $$v)
                        },
                        expression: "props.row.num"
                      }
                    })
                  ],
                  1
                )
              : _c("span", [_vm._v(_vm._s(props.row[props.column.property]))])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }