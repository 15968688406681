"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var onlineRouter = {
  path: '/online',
  component: _layout.default,
  redirect: '/online/website/live',
  name: 'Online',
  meta: {
    title: '在线'
  },
  children: [{
    path: 'website',
    name: 'Website',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/adult/website/index'));
      });
    },
    meta: {
      title: '成人官网'
    },
    children: [{
      path: 'live',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/adult/website/Live.vue'));
        });
      },
      name: 'Live',
      meta: {
        title: '直播'
      }
    }, {
      path: 'handleLive/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/adult/website/HandleLive.vue'));
        });
      },
      hidden: true,
      name: 'HandleLive',
      meta: {
        title: '编辑直播'
      }
    }, {
      path: 'userOpus',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/adult/website/UserOpus.vue'));
        });
      },
      name: 'UserOpus',
      meta: {
        title: '用户作品'
      }
    }, {
      path: 'friendsUrl',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/adult/website/FriendsUrl.vue'));
        });
      },
      name: 'FriendsUrl',
      meta: {
        title: '友情链接'
      }
    }]
  }, {
    path: 'audit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/adult/website/ShipmentReview.vue'));
      });
    },
    name: 'Audit',
    meta: {
      title: '审核'
    },
    children: [{
      path: 'shipmentAudit',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/adult/website/ShipmentReview.vue'));
        });
      },
      name: 'ShipmentAudit',
      meta: {
        title: '发货审核'
      }
    }]
  }, {
    path: 'class',
    name: 'Class',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/online/class/index'));
      });
    },
    meta: {
      title: '班级'
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/online/class/ClassList.vue'));
        });
      },
      name: 'List',
      meta: {
        title: '班级'
      }
    }, {
      path: 'handleClass/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/online/class/HandleClass.vue'));
        });
      },
      name: 'HandleClass',
      meta: {
        title: '编辑班级'
      },
      hidden: true
    }, {
      path: 'student/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/online/class/StudentList.vue'));
        });
      },
      name: 'ClassStudentList',
      meta: {
        title: '学生列表'
      },
      hidden: true
    }, {
      path: 'studyPlan/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/online/class/StudyPlan.vue'));
        });
      },
      name: 'StudyPlan',
      meta: {
        title: '学习计划'
      },
      hidden: true
    }, {
      path: 'courseList',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/online/class/CourseList.vue'));
        });
      },
      name: 'ClassCourseList',
      meta: {
        title: '课程'
      }
    }, {
      path: 'handleCourse/:id/:type',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/online/class/HandleCourse.vue'));
        });
      },
      name: 'HandleClassCourse',
      meta: {
        title: '配置课程'
      },
      hidden: true
    }, {
      path: 'instructor',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/online/class/InstructorList.vue'));
        });
      },
      name: 'InstructorList',
      meta: {
        title: '班主任'
      }
    }]
  }, {
    path: 'child',
    name: 'Child',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/child/childModal/index'));
      });
    },
    meta: {
      title: '少儿'
    },
    children: [{
      path: 'childUser',
      name: 'ChildUser',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/child/childModal/ChildUser.vue'));
        });
      },
      meta: {
        title: '少儿用户'
      }
    }, {
      path: 'userHw/:id',
      name: 'UserHw',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/child/childModal/UserHw.vue'));
        });
      },
      meta: {
        title: '用户作业',
        noCache: true
      },
      hidden: true
    }, {
      path: 'childCourse/:id',
      name: 'ChildCourse',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/child/childModal/childCourse.vue'));
        });
      },
      meta: {
        title: '用户已购课程',
        noCache: true
      },
      hidden: true
    }, {
      path: 'childEva/:id',
      name: 'childEva',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/child/childModal/childEvaluation.vue'));
        });
      },
      meta: {
        title: '用户AI评测作品',
        noCache: true
      },
      hidden: true
    }, {
      path: 'evaluation',
      name: 'evaluation',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/child/Evaluation/Evaluation.vue'));
        });
      },
      meta: {
        title: '全部AI评测作品'
      }
    }]
  }]
};
var _default = onlineRouter;
exports.default = _default;