"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _UserDialog = _interopRequireDefault(require("@/components/Dialog/UserDialog"));

var _validate = require("@/utils/validate");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditClassDialog',
  components: {
    UserDialog: _UserDialog.default
  },
  data: function data() {
    var checkPhone = function checkPhone(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入手机号'));
      }

      if (!(0, _validate.isPoneAvailable)(value)) {
        callback('请输入正确的手机号');
      } else {
        callback();
      }
    };

    return {
      dialogFormVisible: false,
      form: {
        course_id: '',
        name: '',
        phone: '',
        assistant_id: '',
        assistantName: ''
      },
      formLabelWidth: '120px',
      dialogType: '',
      rules: {
        name: [{
          required: true,
          message: '请输入班级名称',
          trigger: 'blur'
        }],
        assistantName: [{
          required: true,
          message: '请选择班主任',
          trigger: 'change'
        }],
        phone: [{
          required: true,
          validator: checkPhone,
          trigger: 'blur'
        }]
      },
      loading: false
    };
  },
  methods: {
    showDialog: function showDialog(type, data) {
      this.dialogType = type;
      this.form.course_id = this.$route.params.courseId;

      if (type === 'edit') {
        this.form.id = data.id;
        this.form.name = data.name;
        this.form.assistant_id = data.assistantId;
        this.form.assistantName = data.assistantName;
        this.form.phone = data.phone;
      }

      this.dialogFormVisible = true;
    },
    // 保存编辑 或 添加
    save: function save(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.loading = true;
          var url = _this.dialogType === 'add' ? 'addClassRoomInfo' : 'editClassRoomInfo';

          _api.default[url](_this.form).then(function (res) {
            if (res) {
              _this.$message.success('操作成功');

              _this.dialogFormVisible = false;

              _this.$refs.editClassForm.resetFields();

              _this.$emit('refresh');
            }

            _this.loading = false;
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    choiceTeacher: function choiceTeacher() {
      this.$refs.userDialog.showDialog();
    },
    confirm: function confirm(item) {
      this.form.assistant_id = item.id;
      this.form.assistantName = item.uname;
    }
  }
};
exports.default = _default;