"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _ConfigUpload = _interopRequireDefault(require("./components/ConfigUpload1.vue"));

var _ConfigUpload2 = _interopRequireDefault(require("./components/ConfigUpload2.vue"));

var _ConfigUpload3 = _interopRequireDefault(require("./components/ConfigUpload3.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    configUpload1: _ConfigUpload.default,
    configUpload2: _ConfigUpload2.default,
    configUpload3: _ConfigUpload3.default
  },
  data: function data() {
    return {
      // loading: false,
      query: {
        home: {
          image: '',
          redirect_url: ''
        },
        result: {
          image: '',
          redirect_url: ''
        },
        compare: {
          image: '',
          redirect_url: ''
        }
      }
    };
  },
  computed: {
    loading: function loading() {
      return this.$store.getters.loading;
    }
  },
  created: function created() {
    this.getContConfig();
  },
  methods: {
    getLink1_: function getLink1_(res) {
      if (res) {
        this.query.home.image = res;
      } else {
        this.query.home.image = '';
      }
    },
    getLink2_: function getLink2_(res) {
      if (res) {
        this.query.result.image = res;
      } else {
        this.query.result.image = '';
      }
    },
    getLink3_: function getLink3_(res) {
      if (res) {
        this.query.compare.image = res;
      } else {
        this.query.compare.image = '';
      }
    },
    getContConfig: function getContConfig() {
      var _this = this;

      _api.default.getContConfig({}).then(function (res) {
        console.log(res);

        _this.$nextTick(function () {
          _this.$refs.default1.init(res.data.home.image);

          _this.$refs.default2.init(res.data.result.image);

          _this.$refs.default3.init(res.data.compare.image);
        });

        _this.query.home.redirect_url = res.data.home.redirect_url;
        _this.query.home.image = res.data.home.image;
        _this.query.result.redirect_url = res.data.result.redirect_url;
        _this.query.result.image = res.data.result.image;
        _this.query.compare.redirect_url = res.data.compare.redirect_url;
        _this.query.compare.image = res.data.compare.image;
      });
    },
    save: function save() {
      var _this2 = this;

      console.log(this.query);
      var isOver;

      for (var i in this.query) {
        if (this.query[i].image === '' || this.query[i].redirect_url === '') {
          this.$message({
            message: '您有信息未填写',
            type: 'warning'
          });
          isOver = false;
          break;
        } else {
          isOver = true;
        }
      }

      if (isOver) {
        _api.default.saveContConfig(this.query).then(function (res) {
          _this2.$message({
            message: '保存成功',
            type: 'success'
          });
        });
      }
    }
  }
};
exports.default = _default;