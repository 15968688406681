"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    lineType: {
      type: [String, Number],
      default: ''
    }
  },
  data: function data() {
    return {
      workList: {}
    };
  },
  methods: {
    fetchData: function fetchData(params) {
      var _this = this;

      _api.default.getWorksList(params).then(function (response) {
        _this.workList = (response.data.infos || []).map(function (item) {
          return _bean.default.worksBean(item);
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;