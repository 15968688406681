"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _initData = require("../initData");

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CourseList',
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      tableData: null,
      listLoading: true,
      total: 0,
      initCourseData: _initData.initData,
      queryShowMore: false,
      listQuery: {}
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getCourseList_2(this.listQuery).then(function (response) {
        var _response$data = response.data,
            infos = _response$data.infos,
            totalNum = _response$data.totalNum;
        _this.tableData = (infos || []).map(function (item) {
          return _bean.default.courseListBean(item);
        });
        _this.total = totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.listQuery.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.listQuery = {
        page: 1,
        page_size: 10
      };
      this.fetchData();
    },
    jumpPage: function jumpPage(pageName, params, item) {
      this.$router.push({
        name: pageName,
        params: params
      });
    }
  }
};
exports.default = _default;