"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _bean = _interopRequireDefault(require("@/bean"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _initData = require("../initData");

var _EditInstructorDialog = _interopRequireDefault(require("./components/EditInstructorDialog"));

var _constants = require("@/constants");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'InstructorList',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default,
    EditInstructorDialog: _EditInstructorDialog.default
  },
  data: function data() {
    return {
      tableData: [],
      listLoading: false,
      total: 0,
      columns: _initData.initData.instructorList.columns,
      searchParam: {}
    };
  },
  created: function created() {},
  activated: function activated() {
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      var url = 'configAddClassQuerychargeList';

      _api.default[url](this.searchParam).then(function (response) {
        var _response$data = response.data,
            data = _response$data.data,
            totalNum = _response$data.totalNum;
        _this.tableData = (data || []).map(function (item) {
          return _bean.default.middleUserBean(item);
        });
        _this.total = totalNum || 0;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.curPage = val;
      this.fetchData();
    },
    reset: function reset() {
      this.searchParam = {
        uid: '',
        curPage: 1,
        pageSize: 20,
        phone: '',
        userName: '',
        platId: _constants.PLATFORM.EDU
      };
      this.fetchData();
    },
    confirmadd: function confirmadd() {
      // 添加班主任成功
      this.reset(); // 刷新
    },
    showDialog: function showDialog(ref, type, row) {
      this.$refs[ref].showDialog(type, row);
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    handleConfigItem: function handleConfigItem(item) {
      // 编辑班主任
      var teacherInfo = {
        userName: item.userName,
        uid: item.uid,
        backUserId: item.backUserId
      };
      this.showDialog('editInstructorDialog', 'update', teacherInfo);
    },
    changeRow: function changeRow(val, item) {
      var _this2 = this;

      this.$confirm('确定要执行该操作吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this2.listLoading = true;

        _api.default.updateClassTeacher({
          backUserId: item.backUserId,
          platId: _constants.PLATFORM.EDU,
          role: 2,
          map: {
            status: val ? 1 : 2
          }
        }).then(function (res) {
          item.workStatusTxt = val ? '在职' : '离职';

          if (res.msg === 'success') {
            _this2.$message.success('操作成功~');

            _this2.listLoading = false;
          }
        }).catch(function (err) {
          item.workStatus = !val;
        });
      }).catch(function () {
        item.workStatus = !val;
      });
    }
  }
};
exports.default = _default;