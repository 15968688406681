var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.dialogTitle, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "版本代码", prop: "code" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.code,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "code", $$v)
                      },
                      expression: "ruleForm.code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "版本号", prop: "version_number" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.version_number,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "version_number", $$v)
                      },
                      expression: "ruleForm.version_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "版本上传", prop: "url_resource" } },
                [
                  _c("el-input", {
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm.url_resource,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "url_resource", $$v)
                      },
                      expression: "ruleForm.url_resource"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.choiceVersion }
                    },
                    [_vm._v("选择")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "版本介绍", prop: "description" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.description,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "description", $$v)
                      },
                      expression: "ruleForm.description"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否强制更新", prop: "force_update" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.force_update,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "force_update", $$v)
                        },
                        expression: "ruleForm.force_update"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("强制更新")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("不强制更新")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否为稳定版本", prop: "is_current" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.is_current,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "is_current", $$v)
                        },
                        expression: "ruleForm.is_current"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.loading, type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.resetForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("asset-dialog", {
        ref: "assetDialog",
        attrs: { isOnly: true },
        on: { confirm: _vm.getFileList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }