"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _initData = require("../../initData");

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      title: '',
      type: '',
      loading: false,
      formData: {},
      dialogVisible: false,
      typeArr: _initData.typeList,
      itemData: {},
      rules: {
        status_clue: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        status_chance: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    showDialog: function showDialog(item, title, type) {
      console.log(type);
      this.type = type;
      this.title = title;
      this.dialogVisible = true;

      if (type === 'Clue') {
        this.formData = {
          saleman_customer_id: item.id,
          status_clue: item.type
        };
      } else {
        this.formData = {
          saleman_customer_id: item.id,
          status_chance: item.type
        };
      }

      this.itemData = item;
    },
    confirm: function confirm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.loading = true;

          _api.default.setCustomerType(_this.formData).then(function () {
            _this.$message({
              type: 'success',
              message: '操作成功'
            });

            _this.$refs[formName].resetFields();

            _this.loading = false;
            _this.dialogVisible = false;

            _this.$emit('refresh', _this.itemData);
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;