"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      centerDialogVisible: false,
      textarea: '',
      oldTextarea: '',
      goodsId: '',
      checked: false,
      // typeList:['硬笔','软笔','少儿','智能笔'],
      typeList: [{
        bus_id: 1,
        name: '软笔'
      }, {
        bus_id: 2,
        name: '硬笔'
      }, {
        bus_id: 3,
        name: '少儿'
      }, {
        bus_id: 4,
        name: '智能笔'
      }],
      classBao_list: [],
      if_gift: 0,
      selArr: [],
      radio: '1',
      checkboxGroup: [],
      nameVal: ''
    };
  },
  methods: {
    showDialog: function showDialog(item) {
      console.log(item);
      this.goodsId = item;
      this.centerDialogVisible = true;
    },
    closeDialog: function closeDialog() {
      this.centerDialogVisible = false;
      this.checked = false;
      this.textarea = '';
    },
    buildOrder: function buildOrder() {
      var _this = this;

      var changeData = {
        id: this.goodsId,
        act_remark: this.textarea,
        if_gift: this.checked === false ? 0 : 1,
        bao_id: this.selArr
      };
      this.centerDialogVisible = false;

      _api.default.changeGoods(changeData).then(function (res) {
        // console.log(res.data);
        _this.$emit('refresh');

        _this.checked = false;
        _this.textarea = '';
      }).catch(function () {});
    },
    typeData: function typeData(val) {
      var _this2 = this;

      console.log(val);
      this.checkboxGroup = [];
      this.selArr = [];

      if (this.oldTextarea) {
        this.textarea = this.oldTextarea;
      } else {
        this.textarea = '';
      }

      _api.default.classBaoList(val.bus_id).then(function (res) {
        console.log(res.data);
        _this2.classBao_list = res.data;
      }).catch(function () {});
    },
    addInputData: function addInputData(val) {
      console.log(val); // let prevTextarea=this.textarea;

      var nameVal = ' 添加';
      var idArr = [];
      val.forEach(function (item) {
        nameVal += ' ' + item.name;
        idArr.push(item.id);
      });
      console.log(nameVal);

      if (val.length === 0) {
        this.textarea = this.oldTextarea;
      } else {
        this.textarea = this.oldTextarea + nameVal + '关联的教材商品';
      }

      this.selArr = idArr;
    },
    changeVal: function changeVal(val) {
      console.log(val);
      this.oldTextarea = val;
    }
  }
};
exports.default = _default;