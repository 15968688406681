"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CoursewareData',
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      formLabelWidth: '100px',
      searchParam: {
        page: 1,
        per_page: 10,
        name: ''
      },
      tableData: [],
      total: 0,
      columns: [{
        label: '文件名',
        width: '100',
        prop: 'name'
      }, {
        label: '文件类型',
        width: '60',
        prop: 'typeText'
      }, {
        label: '上传用户',
        width: '60',
        prop: 'uName'
      }, {
        label: '发布状态',
        width: '60',
        prop: 'isReleaseText'
      }, {
        label: '上传时间',
        width: '120',
        prop: 'createTime'
      }, {
        label: '操作',
        width: '100',
        fix: 'right'
      }]
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      _api.default.getCoursewareList(this.searchParam).then(function (response) {
        _this.tableData = response.data.data.map(function (item) {
          return _bean.default.coursewareBean(item);
        });
        _this.total = response.data.total;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    choice: function choice(item) {
      this.$emit('confirm', item);
    }
  }
};
exports.default = _default;