"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onlineCourseBean = onlineCourseBean;
exports.onlinePlanBean = onlinePlanBean;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.function.name");

/**
 * 课程实体对象
 * @param r
 * @returns {{createTime: string, lineType: string, name: *, id: *, lineTypeText: *, videoTitle: (string|rules.video_title|{trigger, message, required}|*)}}
 */
function onlineCourseBean(r) {
  return {
    id: r.id,
    lineType: r.business_type,
    lineTypeText: r.business_name,
    name: r.name,
    classNum: r.startClassCount,
    // 开班总数
    nowClickNum: r.totalClassCount,
    // 现有班级数
    videoTitle: r.video_title,
    createTime: r.created_at
  };
}
/**
 * 课时实体对象
 * @param r
 * @returns {{isFree: (string|*), isExperience: (string|*), pid: number, id: (string|*), title: *, child: {oldCourseName: (*|string), isFree: (string|*), isExperience: (string|*), newCourseId: (*|string), pid: number, isActivity: *, id: (string|*), oldCourseId: (*|string), title: *, newCourseName: (*|string), fileId: (*|string)}[]}}
 */


function onlinePlanBean(r) {
  return {
    id: r.id,
    // 章节id
    pid: r.old_id,
    title: r.title,
    isFree: r.sort,
    child: (r.courses || []).map(function (c) {
      return {
        pid: c.pid,
        title: c.title,
        date: c.date,
        is_activity: c.is_activity,
        plan_day: c.plan_day,
        old_id: c.old_id,
        sort: c.sort
      };
    })
  };
}