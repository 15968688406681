"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.array.find");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _initData = require("../initData");

var _TypeDialog = _interopRequireDefault(require("./components/TypeDialog"));

var _cache = require("@/utils/cache");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Clue',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default,
    TypeDialog: _TypeDialog.default
  },
  data: function data() {
    return {
      createTime: '',
      searchParam: {},
      tableData: [],
      listLoading: false,
      total: 0,
      initSaleData: _initData.initData,
      typeArr: [{
        value: '',
        label: '全部'
      }].concat(_initData.typeList),
      serviceLineArr: [{
        value: '',
        label: '全部'
      }].concat(_initData.serviceLine),
      clientTypeArr: [{
        value: '',
        label: '全部'
      }].concat(_initData.clientTypeList)
    };
  },
  created: function created() {
    var fieldJson = (0, _cache.find)('fieldJson');

    if (fieldJson && fieldJson[this.$route.name]) {
      _initData.initData.clue.columns = fieldJson[this.$route.name];
    }

    this.initSaleData = _initData.initData;
    this.reset();
  },
  methods: {
    dateRangeChange: function dateRangeChange(val) {
      if (val) {
        this.searchParam.start_time = new Date(val[0]).getTime() / 1000;
        this.searchParam.end_time = new Date(val[1]).getTime() / 1000;
      } else {
        this.searchParam.start_time = '';
        this.searchParam.end_time = '';
      }
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getClueList(this.searchParam).then(function (response) {
        var _response$data = response.data,
            infos = _response$data.infos,
            totalNum = _response$data.totalNum;
        _this.tableData = (infos || []).map(function (item) {
          return _bean.default.clueBean(item);
        });
        _this.total = totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.searchParam = {
        uid: '',
        cate_id: '',
        uname: '',
        course: '',
        start_time: '',
        end_time: '',
        channel: '',
        status_clue: '',
        client_type: '',
        page: 1,
        page_size: 20
      };
      this.createTime = '';
      this.fetchData();
    },
    handleFieldChange: function handleFieldChange(val) {
      this.initSaleData.clue.columns = val;
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    // 开通体验课
    open: function open(item) {
      var _this2 = this;

      _api.default.grantCourse({
        saleman_customer_id: item.id
      }).then(function (response) {
        _this2.$message({
          type: 'success',
          message: '操作成功'
        });

        _this2.reset();
      });
    },
    // 线索数据设置为机会数据
    chance: function chance(item) {
      var uid = item.uid,
          userName = item.userName,
          lineType = item.lineType;
      this.$refs.typeChanceDialog.showDialog(item, "\u786E\u5B9A\u5C06\u3010".concat(uid, "\u3011").concat(userName, "\u7684").concat(lineType, "\u4E1A\u52A1\u4ECE\u7EBF\u7D22\u53D8\u4E3A\u673A\u4F1A\uFF1F"), 'Chance');
    },
    typeChange: function typeChange(item) {
      var _this3 = this;

      _api.default.setChance({
        saleman_customer_id: item.id
      }).then(function () {
        _this3.reset();
      });
    },
    // 类型操作
    doType: function doType(item) {
      this.$refs.typeDialog.showDialog(item, "\u3010".concat(item.uid, "\u3011").concat(item.userName), 'Clue');
    }
  }
};
exports.default = _default;