var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.cateList, function(item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { name: index, label: item }
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.resourceList.length
        ? [
            _vm._l(_vm.resourceList, function(item, index) {
              return [
                _vm.activeName === item.categoryId
                  ? _c(
                      "el-form",
                      {
                        key: index,
                        ref: "form",
                        refInFor: true,
                        attrs: { "label-width": "110px" }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "选择课件类型：" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择课件类型",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.type,
                                  callback: function($$v) {
                                    _vm.type = $$v
                                  },
                                  expression: "type"
                                }
                              },
                              _vm._l(_vm.topicList, function(item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item, value: index }
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "el-icon-circle-plus-outline",
                              on: {
                                click: function($event) {
                                  return _vm.handleAdd(index, item)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        item.list.length
                          ? _c(
                              "Draggable",
                              {
                                model: {
                                  value: item.list,
                                  callback: function($$v) {
                                    _vm.$set(item, "list", $$v)
                                  },
                                  expression: "item.list"
                                }
                              },
                              [
                                _vm._l(item.list, function(subItem, subIndex) {
                                  return _c(
                                    "el-form-item",
                                    {
                                      key: subIndex,
                                      attrs: {
                                        label:
                                          _vm.topicList[subItem.type] + "："
                                      }
                                    },
                                    [
                                      _c("upload", {
                                        attrs: {
                                          current: subIndex,
                                          uploadInfo: subItem,
                                          typeIndex: index
                                        },
                                        on: { handleDelete: _vm.handleDelete }
                                      })
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.confirm }
            },
            [_vm._v("保 存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }