var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogFormVisible },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "editClassForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": _vm.formLabelWidth
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "班级名称：", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入班级名称", autocomplete: "off" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "班主任：", prop: "assistantName" } },
            [
              _c("el-input", {
                staticStyle: { width: "83%", "margin-right": "1%" },
                attrs: {
                  placeholder: "请选择班主任",
                  autocomplete: "off",
                  disabled: ""
                },
                model: {
                  value: _vm.form.assistantName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "assistantName", $$v)
                  },
                  expression: "form.assistantName"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "14%" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.choiceUser("assistant")
                    }
                  }
                },
                [_vm._v("选择")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "手机号：", prop: "phone" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入手机号",
                  maxlength: "11",
                  autocomplete: "off"
                },
                model: {
                  value: _vm.form.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "选择关联课程：", prop: "course_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    multiple: "",
                    placeholder: "请选择关联课程",
                    clearable: ""
                  },
                  model: {
                    value: _vm.form.course_id,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "course_id", $$v)
                    },
                    expression: "form.course_id"
                  }
                },
                _vm._l(_vm.courseArr, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.video_title, value: item.course_id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "点评服务：", prop: "has_comment", required: "" }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.has_comment,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "has_comment", $$v)
                    },
                    expression: "form.has_comment"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "0" } }, [_vm._v("没有")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "1" } }, [_vm._v("有")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          +_vm.form.has_comment === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "点评师：",
                    prop: "teacherName",
                    required: ""
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "83%", "margin-right": "1%" },
                    attrs: {
                      placeholder: "请选择主点评老师",
                      autocomplete: "off",
                      disabled: ""
                    },
                    model: {
                      value: _vm.form.teacherName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "teacherName", $$v)
                      },
                      expression: "form.teacherName"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "14%" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.choiceUser("teacher")
                        }
                      }
                    },
                    [_vm._v("选择")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "开班时间：", prop: "start_time" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: { type: "date", placeholder: "选择日期" },
                on: { change: _vm.dateRangeChange },
                model: {
                  value: _vm.form.start_time,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "start_time", $$v)
                  },
                  expression: "form.start_time"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialog-footer text-center" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogFormVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: {
                click: function($event) {
                  return _vm.save("editClassForm")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("user-dialog", {
        ref: "assistantDialog",
        attrs: { type: "assistant" },
        on: { confirm: _vm.confirm }
      }),
      _vm._v(" "),
      _c("user-dialog", {
        ref: "teacherDialog",
        attrs: { type: "teacher" },
        on: { confirm: _vm.confirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }