"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/web.dom.iterable");

var _vue = _interopRequireDefault(require("vue"));

var _click = _interopRequireDefault(require("./click"));

/*
 * @Author: lz
 * @Date: 2021-06-04 15:08:58
 * @LastEditTime: 2021-06-04 16:01:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt-online-edu-admin/src/directives/track.js
 */
var cli = new _click.default();

_vue.default.directive('track', {
  bind: function bind(el, binding) {
    var arg = binding.arg; // 同一元素对应多个事件，用 ｜ 分隔

    arg.split('|').forEach(function (item) {
      if (item === 'click') {
        cli.add({
          el: el
        });
      }
    });
  }
});