var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "选择显示的列表字段",
        visible: _vm.dialogVisible,
        width: "40%"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "field-list" },
        [
          _vm._l(_vm.fieldList, function(item, index) {
            return [
              item.label !== "操作"
                ? _c(
                    "el-button",
                    {
                      key: index,
                      staticClass: "field-btn",
                      attrs: { type: item.hide ? "" : "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleTap(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.label))]
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }