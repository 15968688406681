"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initData = void 0;
var commColumns = [{
  label: 'ID',
  width: '60',
  prop: 'id',
  hide: true
}, {
  label: '学号',
  width: '80',
  prop: 'uid',
  hide: false
}, {
  label: '头像',
  width: '100',
  prop: 'avatar',
  hide: false
}, {
  label: '客户昵称',
  width: '80',
  prop: 'uname',
  hide: false
}];
var initData = {
  userlist: {
    name: 'userlist',
    columns: [].concat(commColumns, [{
      label: '手机号',
      width: '100',
      prop: 'phone'
    }, {
      label: '生日',
      width: '100',
      prop: 'birthday'
    }, {
      label: '注册时间',
      width: '120',
      prop: 'ctime_d'
    }, {
      label: '课程数',
      width: '80',
      prop: 'course_num'
    }, {
      label: '提交作业次数',
      width: '80',
      prop: 'submit_num'
    }, {
      label: 'AI评测次数',
      width: '80',
      prop: 'ai_submit_num'
    }, {
      label: '操作',
      width: '100',
      fix: 'right'
    }])
  },
  hwList: {
    name: 'hwlist',
    columns: [{
      label: '作业ID',
      width: '80',
      prop: 'id'
    }, {
      label: '头像',
      width: '100',
      prop: 'avatar'
    }, {
      label: '昵称',
      width: '80',
      prop: 'uname'
    }, {
      label: '提交时间',
      width: '120',
      prop: 'atime_d'
    }, {
      label: '打卡',
      width: '100',
      prop: 'imageindex'
    }]
  },
  courseList: {
    name: 'courselist',
    columns: [{
      label: '课程id',
      width: '60',
      prop: 'video_id'
    }, {
      label: '课程名称',
      width: '100',
      prop: 'video_title'
    }, {
      label: '买课时间',
      width: '120',
      prop: 'ptime_d'
    }, {
      label: '绑定点评师ID',
      width: '60',
      prop: 'teacherid'
    }, {
      label: '绑定点评师',
      width: '80',
      prop: 'bind_teacher'
    }]
  },
  childEvaList: {
    name: 'childevalist',
    columns: [{
      label: '作品id',
      width: '60',
      prop: 'score_id'
    }, {
      label: '用户',
      width: '100',
      prop: 'uname'
    }, {
      label: '课程',
      width: '100',
      prop: 'video_title'
    }, {
      label: '课时',
      width: '100',
      prop: 'section'
    }, {
      label: '差评数',
      width: '60',
      prop: 'bad_word_count'
    }, {
      label: '好评数',
      width: '60',
      prop: 'good_word_count'
    }, {
      label: '评价一般',
      width: '60',
      prop: 'medium_word_count'
    }, {
      label: '空字数',
      width: '60',
      prop: 'empty_word_count'
    }, {
      label: '失败数',
      width: '60',
      prop: 'error_word_count'
    }, {
      label: '评测时间',
      width: '120',
      prop: 'score_date'
    }, {
      label: '评测原图',
      width: '100',
      prop: 'img_url'
    }]
  },
  evaList: {
    name: 'evaList',
    columns: [{
      label: '作品id',
      width: '60',
      prop: 'score_id'
    }, {
      label: '用户',
      width: '100',
      prop: 'uname'
    }, {
      label: '课程',
      width: '100',
      prop: 'video_title'
    }, {
      label: '课时',
      width: '100',
      prop: 'section'
    }, {
      label: '差评数',
      width: '60',
      prop: 'bad_word_count'
    }, {
      label: '好评数',
      width: '60',
      prop: 'good_word_count'
    }, {
      label: '评价一般',
      width: '60',
      prop: 'medium_word_count'
    }, {
      label: '空字数',
      width: '60',
      prop: 'empty_word_count'
    }, {
      label: '失败数',
      width: '60',
      prop: 'error_word_count'
    }, {
      label: '评测时间',
      width: '120',
      prop: 'score_date'
    }, {
      label: '评测原图',
      width: '100',
      prop: 'img_url'
    }]
  }
};
exports.initData = initData;