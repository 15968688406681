"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _SaleCodeDialog = _interopRequireDefault(require("./components/SaleCodeDialog"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ServiceCodeList',
  components: {
    SearchBox: _SearchBox.default,
    PageTable: _PageTable.default,
    SaleCodeDialog: _SaleCodeDialog.default
  },
  data: function data() {
    return {
      searchParam: {},
      tableData: [],
      immutableTableData: [],
      columns: [{
        label: 'ID',
        width: '80',
        prop: 'id'
      }, {
        label: '二维码',
        width: '120',
        prop: 'qrCodeUrl'
      }, {
        label: '微信昵称',
        width: '120',
        prop: 'weChatName'
      }, {
        label: '分类',
        width: '120',
        prop: 'lineType'
      }, {
        label: '销售人',
        width: '120',
        prop: 'saleManName'
      }, {
        label: '昨日量',
        width: '120',
        prop: 'yesterdayNum',
        sortable: 'custom'
      }, {
        label: '今日量',
        width: '120',
        prop: 'todayNum'
      }, {
        label: '单日上限',
        width: '120',
        prop: 'topLimit',
        sortable: 'custom'
      }, {
        label: '是否开启',
        width: '120',
        prop: 'offName'
      }, {
        label: '总数',
        width: '120',
        prop: 'totalNum'
      }, {
        label: '微信类型',
        width: '80',
        prop: 'weChatTypeName'
      }, {
        label: '绑定微信号',
        width: '120',
        prop: 'weChatID'
      }, {
        label: '销售UID',
        width: '120',
        prop: 'uid'
      }, {
        label: '操作',
        width: '120',
        fix: 'right'
      }],
      listLoading: false,
      total: 0,
      yesterdayAll: 0,
      formData: {
        id: ''
      }
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    // 请求表格数据
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getServiceCodeList(this.searchParam).then(function (response) {
        _this.tableData = response.data.infos.map(function (item) {
          return _bean.default.qrCodeBean(item);
        });
        _this.immutableTableData = JSON.stringify(_this.tableData);
        _this.total = response.data.totalNum;
        _this.yesterdayAll = response.data.yesterdayAll;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = [];
        _this.listLoading = false;
      });
    },
    // 分页
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    // 排序
    sortChange: function sortChange(val) {
      var filter = {
        topLimit: 'toplimit',
        yesterdayNum: 'yesterday_num'
      };
      var prop = val.prop,
          order = val.order;

      if (order === 'ascending') {
        // 升序
        this.searchParam.order_type = 'asc';
      } else if (order === 'descending') {
        // 降序
        this.searchParam.order_type = 'desc';
      }

      this.searchParam.order_field = filter[prop];
      this.searchParam.page = 1;
      this.fetchData();
    },
    reset: function reset() {
      var _this$$route$params = this.$route.params,
          id = _this$$route$params.id,
          lineId = _this$$route$params.lineId;

      if (!id || !lineId) {
        this.$message({
          type: 'error',
          message: '网络异常，请稍后重试（msg：missing necessary parameters）'
        });
        return;
      }

      this.searchParam = {
        team_id: id,
        cate_id: lineId,
        order_type: '',
        order_field: '',
        name: '',
        saleman_name: '',
        page: 1,
        page_size: 20
      };
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    // 添加二维码的点击事件
    showDialog: function showDialog(ref) {
      this.$refs[ref].showDialog();
    },
    // 开启、删除操作事件
    doOption: function doOption(item, type) {
      var _this2 = this;

      var params = {
        id: item.id
      };

      if (type === 'delete') {
        this.$confirm('确定要删除该记录吗？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(function () {
          _api.default.deleteServiceCode(params).then(function () {
            _this2.$message({
              type: 'success',
              message: '操作成功~'
            });

            _this2.reset();
          });
        });
      } else {
        params.off = item.off ? 0 : 1;

        _api.default.editSaleQrCode(params).then(function () {
          _this2.$message({
            type: 'success',
            message: '操作成功~'
          });

          _this2.fetchData();
        });
      }
    },
    // 表格内容编辑
    onInputBlur: function onInputBlur(item) {
      var _this3 = this;

      var num = item.topLimit;

      if (num < 0) {
        this.$message({
          type: 'warning',
          message: '输入的值有误~'
        });
        return;
      }

      var params = {
        id: item.id,
        toplimit: item.topLimit
      };
      var list = JSON.parse(this.immutableTableData);
      var currentItem = list.filter(function (i) {
        return i.id === item.id;
      });

      if (currentItem[0].topLimit === item.topLimit) {
        return;
      }

      _api.default.editSaleQrCode(params).then(function () {
        _this3.$message({
          type: 'success',
          message: '操作成功~'
        });

        _this3.fetchData();
      });
    }
  }
};
exports.default = _default;