"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Item',
  filters: {
    statusText: function statusText(val) {
      var data = {
        wait: '等待中',
        uploading: '上传中',
        cancel: '已暂停',
        success: '上传成功'
      };
      return data[val] || '';
    }
  },
  props: {
    uploaderInfo: {
      default: function _default() {
        return {};
      },
      type: Object
    },
    uploader: {
      default: function _default() {
        return {};
      },
      type: Object
    }
  },
  methods: {
    continueItem: function continueItem() {
      this.uploaderInfo.upload(this.uploaderInfo.file);
    },
    pauseItem: function pauseItem() {
      this.uploaderInfo.cancel();
    },
    deleteItem: function deleteItem() {
      this.$emit('handleDelete', this.uploaderInfo);
    }
  }
};
exports.default = _default2;