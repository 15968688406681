"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.array.find-index");

var _toConsumableArray2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'StudyLearn',
  data: function data() {
    return {
      total: 0,
      listLoading: false,
      tableData: [],
      tableChildData: [],
      searchParam: {
        page: 1,
        per_page: 20,
        vid: ''
      },
      formData: {
        plan: []
      }
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this = this;

      var formData = this.formData;

      if (!formData.plan.length) {
        this.$message.warning('请先设置课时~');
        return;
      }

      this.listLoading = true;

      _api.default.updateOnlineCourseStudyPlan(formData).then(function () {
        _this.$message.success('课时设置成功~');

        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    fetchData: function fetchData() {
      var _this2 = this;

      var searchParam = this.searchParam,
          formData = this.formData;
      this.listLoading = true;

      _api.default.getLessonList(searchParam).then(function (response) {
        _this2.tableData = response.data.data.map(function (item) {
          return _bean.default.lessonBean(item);
        });
        formData.plan.length && formData.plan.forEach(function (item) {
          _this2.tableData.map(function (tItem) {
            if (tItem.id === item.pId) {
              tItem.child[item.sIndex].planDay = item.day;
            }

            return tItem;
          });
        });

        _this2.tableChildData = function (arr) {
          var _ref;

          return (_ref = []).concat.apply(_ref, (0, _toConsumableArray2.default)(arr.map(function (item) {
            return [].concat(item.child);
          })));
        }(_this2.tableData);

        _this2.total = response.data.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    handlePageNoChange: function handlePageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      var id = this.$route.params.id;
      this.searchParam = {
        page: 1,
        per_page: 20,
        vid: id
      };
      this.fetchData();
    },
    changeRow: function changeRow(pIndex, sRow, sIndex) {
      var tableData = this.tableData;
      var pRow = tableData[pIndex];

      if (sRow.planDay <= 0) {
        this.$message.warning('输入值必须大于0哦~');
        sRow.planDay = '';
      } else {
        var tableChildData = this.tableChildData;
        var childIndex = tableChildData.findIndex(function (item) {
          return item.id === sRow.id;
        });

        if (childIndex > 0) {
          var prevDay = tableChildData[childIndex - 1].planDay;

          if (prevDay !== '' && Number(sRow.planDay) < Number(prevDay)) {
            this.$message.warning('请输入上个课时更大的数字哦~');
            sRow.planDay = '';
          }
        }

        if (childIndex < tableChildData.length - 1) {
          var nextDay = tableChildData[childIndex + 1].planDay;

          if (nextDay !== '' && Number(sRow.planDay) > Number(nextDay)) {
            this.$message.warning('请输入下个课时更小的数字哦~');
            sRow.planDay = '';
          }
        }
      }

      var cIndex = this.formData.plan.findIndex(function (item) {
        return item.id === sRow.id;
      });

      if (cIndex < 0) {
        sRow.planDay && this.formData.plan.push({
          id: sRow.id,
          day: sRow.planDay,
          pId: pRow.id,
          sIndex: sIndex
        });
      } else {
        if (sRow.planDay) {
          this.formData.plan[cIndex].day = sRow.planDay;
        } else {
          this.formData.plan.splice(cIndex, 1);
        }
      }
    }
  }
};
exports.default = _default;