"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.coursewareBean = coursewareBean;
exports.courseBean = courseBean;
exports.lessonBean = lessonBean;
exports.pushRecordBean = pushRecordBean;

require("core-js/modules/es6.function.name");

var _utils = require("@/utils");

/**
 * 课件库实体对象
 * @param r
 * @returns {{isReleaseText: *, isTranscoding: string, type: *, isRelease: string, uId: number | * | string, videoUrl: *, createTime: *, uName: number | string, typeText: *, statusText: *, appId: *, name: *, transcodingText: *, id: *, status: *, fileId: *}}
 */
function coursewareBean(r) {
  return {
    id: r.id,
    name: r.name,
    type: r.type,
    status: r.status,
    isTranscoding: r.is_transcoding,
    // 0:未转码 1:转码完成 2:转码中
    fileId: r.fileid,
    createTime: r.create_time,
    transcodingText: r.file_type === 'video' ? r.transcoding_text : '-',
    typeText: r.type_text,
    fileType: r.file_type,
    fileTypeText: r.file_type_value,
    statusText: r.status_text,
    uName: r.user && r.user.uname || '管理员',
    uId: r.user && r.user.uid || '',
    appId: r.app_id,
    isRelease: r.is_release,
    // 0 未发布 1 发布成功 2 发布中 3 发布失败
    isReleaseText: r.file_type === 'video' ? r.release_text : '-',
    videoUrl: r.original_url,
    coverImg: r.img_url
  };
}
/**
 * 课程实体对象
 * @param r
 * @returns {{teacherName: *, coverImg: (*|string), isUpdateText: string, count: *, vPrice: *, title: string, isBest: *, uId: *, score: *, ePrice: *, countMark: *, isActivityText: string, createTime: *, isActivity: boolean, isCharge: *, isChargeText: string, id: *, tPrice: *, category: *, isBestText: string, isUpdate: boolean, status: string}}
 */


function courseBean(r) {
  return {
    id: r.id,
    uId: r.id,
    status: '',
    title: r.video_title,
    category: r.video_category,
    teacherName: r.teacher_name,
    coverImg: r.cover_img || '',
    vPrice: r.v_price,
    tPrice: r.t_price,
    ePrice: r.e_price,
    count: r.video_order_count,
    countMark: r.video_order_count_mark,
    isUpdate: r.is_update === 1,
    isUpdateText: r.is_update === 1 ? '更新中' : '完结',
    isActivity: r.is_activity === 1,
    isActivityText: r.is_activity === 1 ? '已审核' : '未审核',
    isBest: r.is_best,
    isBestText: r.is_best === 1 ? '是' : '否',
    isCharge: r.is_charge,
    isChargeText: r.is_charge === 1 ? '是' : '否',
    score: r.video_score,
    createTime: (0, _utils.parseTime)(r.ctime)
  };
}
/**
 * 课时实体对象
 * @param r
 * @returns {{isFree: (string|*), isExperience: (string|*), pid: number, id: (string|*), title: *, child: {oldCourseName: (*|string), isFree: (string|*), isExperience: (string|*), newCourseId: (*|string), pid: number, isActivity: *, id: (string|*), oldCourseId: (*|string), title: *, newCourseName: (*|string), fileId: (*|string)}[]}}
 */


function lessonBean(r) {
  return {
    id: r.zy_video_section_id,
    pid: r.pid,
    title: r.title,
    isFree: r.is_free,
    // [是否免费] 1是 0否
    isExperience: r.is_experience,
    // [是否体验] 1是 0否
    child: (r.child || []).map(function (c) {
      return {
        id: c.zy_video_section_id,
        // 章节id
        pid: c.pid,
        title: c.title,
        isFree: c.is_free,
        // [是否免费] 1是 0否
        isExperience: c.is_experience,
        // [是否体验] 1是 0否
        isActivity: c.is_activity,
        // [是否审核] 1是 0否
        image: c.image && c.image.original_url,
        // 课时配置显示图片
        newCourseName: c.tcvideo && c.tcvideo.name || '',
        newCourseId: c.tcvideo && c.tcvideo.id || '',
        fileId: c.tcvideo && c.tcvideo.fileid || '',
        oldCourseName: c.videod && c.videod.title || '',
        planDay: c.plan_day || '',
        // 计划第几天学
        oldCourseId: c.videod && c.videod.id || ''
      };
    })
  };
}

function pushRecordBean(r) {
  return {
    id: r.id,
    uid: r.uid,
    tid: r.tid,
    courseName: r.videotitle && r.videotitle.video_title || '',
    userName: r.user && r.user.uname || '',
    lessonName: r.vstitle && r.vstitle.title || '',
    createTime: (0, _utils.parseTime)(r.addtime)
  };
}