var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "box-card" }, [
    _c("div", { staticClass: "file-item-inner" }, [
      _c("div", { staticClass: "title" }, [
        _c("i", { staticClass: "el-icon-video-camera" }),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.uploaderInfo.file.name))])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "options" },
        [
          _c("el-progress", {
            staticStyle: { flex: "1" },
            attrs: { percentage: Math.floor(_vm.uploaderInfo.progress * 100) }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "status" }, [
            _vm._v(_vm._s(_vm._f("statusText")(_vm.uploaderInfo.status)))
          ]),
          _vm._v(" "),
          _vm.uploaderInfo.type === "video"
            ? [
                _vm.uploaderInfo.status === "cancel"
                  ? _c("i", {
                      staticClass: "icon el-icon-video-play",
                      on: { click: _vm.continueItem }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.uploaderInfo.status === "uploading"
                  ? _c("i", {
                      staticClass: "icon el-icon-video-pause",
                      on: { click: _vm.pauseItem }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("i", {
                  staticClass: "icon el-icon-close",
                  on: { click: _vm.deleteItem }
                })
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }