"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _UploadCos = _interopRequireDefault(require("@/components/UploadCos"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditAssistantInfoDialog',
  components: {
    Upload: _UploadCos.default
  },
  data: function data() {
    return {
      formLabelWidth: '150px',
      title: '',
      loading: false,
      formData: {},
      dialogVisible: false,
      itemData: {},
      qrCodeList: [],
      rules: {
        name: [{
          required: true,
          message: '请输入班主任姓名',
          trigger: 'blur'
        }],
        wechat_img: [{
          required: true,
          message: '请上传企微二维码',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    showDialog: function showDialog(item, title) {
      this.title = title;
      this.dialogVisible = true;
      this.itemData = item;
      this.formData = {
        id: item.id,
        name: item.name,
        wechat_img: item.wechatImg
      };

      if (item.wechatImg) {
        this.qrCodeList = [{
          name: item.wechatImg.substring(item.wechatImg.lastIndexOf('/') + 1),
          url: item.wechatImg
        }];
      } else {
        this.qrCodeList = [];
      }
    },
    uploadResult: function uploadResult(data) {
      this.formData.wechat_img = data[0] && data[0].url || '';
    },
    confirm: function confirm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.loading = true;

          _api.default.updateAssistantInfo(_this.formData).then(function () {
            _this.$message({
              type: 'success',
              message: '操作成功'
            });

            _this.$refs[formName].resetFields();

            _this.loading = false;
            _this.dialogVisible = false;

            _this.$emit('refresh', _this.itemData);
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;