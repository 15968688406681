"use strict";

require("core-js/modules/es6.array.find");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _cache = require("@/utils/cache");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  data: function data() {
    return {
      rpwdForm: {
        oldPassword: '',
        newPassword: ''
      },
      rpwdRules: {
        oldPassword: [{
          required: true,
          trigger: 'blur',
          message: '请输入旧密码'
        }],
        newPassword: [{
          required: true,
          trigger: 'blur',
          message: '请输入新密码'
        }]
      },
      loading: false,
      oldPasswordType: 'password',
      newPasswordType: 'password',
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    showPwd: function showPwd(idx) {
      var _this = this;

      switch (idx) {
        case 1:
          this.oldPasswordType = this.oldPasswordType === 'password' ? '' : 'password';
          break;

        case 2:
          this.newPasswordType = this.newPasswordType === 'password' ? '' : 'password';
          break;
      }

      this.$nextTick(function () {
        if (idx * 1 === 1) {
          _this.$refs.oldPassword.focus();
        } else if (idx * 1 === 2) {
          _this.$refs.newPassword.focus();
        }
      });
    },
    resetPwd: function resetPwd() {
      var _this2 = this;

      this.$refs.rpwdForm.validate(function (valid) {
        if (valid) {
          var params = {
            userAccount: (0, _cache.find)('userInfo') && (0, _cache.find)('userInfo').backUserInfo && (0, _cache.find)('userInfo').backUserInfo.phone ? (0, _cache.find)('userInfo').backUserInfo.phone : '',
            platId: (0, _cache.find)('userInfo') && (0, _cache.find)('userInfo').backUserInfo && (0, _cache.find)('userInfo').backUserInfo.platId ? (0, _cache.find)('userInfo').backUserInfo.platId : ''
          };
          Object.assign(_this2.rpwdForm, params);
          _this2.loading = true;

          _this2.$store.dispatch('user/changePassword', _this2.rpwdForm).then(function () {
            // this.$router.push({ path: this.redirect || '/' });
            _this2.$router.push("/login?redirect=".concat(_this2.redirect));

            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;