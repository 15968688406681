"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _cosAuth = require("./cosAuth.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      hideUpload: false,
      // fileList: [{
      //     name: 'food.jpeg',
      //     url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      // }, {
      //     name: 'food2.jpeg',
      //     url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      // }],
      fileList: [],
      limitCount: 1
    };
  },
  methods: {
    init: function init(res) {
      console.log(res);

      if (res !== '') {
        this.fileList = [{
          name: 'name',
          url: res
        }];
        console.log(this.fileList);
      } else {
        this.fileList = [];
      }

      this.hideUpload = this.fileList.length >= this.limitCount;
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
      this.hideUpload = fileList.length >= this.limitCount;
      this.$emit('getLink1', false);
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange: function handleChange(file, fileList) {
      var that = this;
      console.log(fileList); // console.log(file)

      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(file.name)) {
        this.$message.error('请确认图片格式');
        this.fileList = [];
      } else {
        // debugger
        this.hideUpload = fileList.length >= this.limitCount;
        console.log(file);
        var file_ = file.raw;
        var name_ = file.raw.lastModified + '.jpg';
        console.log(file_, name_);
        (0, _cosAuth.cosAuth_)(file_, name_).then(function (res) {
          console.log(res);
          that.dialogImageUrl = file.url;
          that.fileList[0] = {
            name: name_,
            url: res
          };
          that.$emit('getLink1', res);
        });
      }
    }
  }
};
exports.default = _default;