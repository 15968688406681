var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogFormVisible },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        { ref: "editSectionForm", attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: "章节名称：", "label-width": _vm.formLabelWidth }
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "挑战赛时长(秒)：",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                attrs: { type: "number", autocomplete: "off" },
                model: {
                  value: _vm.form.game_time,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "game_time", $$v)
                  },
                  expression: "form.game_time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序：", "label-width": _vm.formLabelWidth } },
            [
              _c("el-input", {
                attrs: { type: "number", autocomplete: "off" },
                model: {
                  value: _vm.form.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialog-footer text-center" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogFormVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.save }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }