"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clueBean = clueBean;
exports.changeBean = changeBean;
exports.buyerBean = buyerBean;
exports.saleCourseBean = saleCourseBean;
exports.followRecordBean = followRecordBean;
exports.worksBean = worksBean;
exports.saleOrderBean = saleOrderBean;
exports.salesStaffBean = salesStaffBean;
exports.qrCodeBean = qrCodeBean;
exports.serviceGroupBean = serviceGroupBean;
exports.wxStatisticsBean = wxStatisticsBean;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _utils = require("@/utils");

function baseUserInfo(r) {
  return {
    uid: r.uid,
    userName: r.uname,
    // 客户昵称
    userAvatar: r.avatar,
    // 客户头像
    mobile: r.mobile,
    // 手机号
    createTime: r.created_time ? (0, _utils.parseTime)(r.created_time * 1000) : ''
  };
}

function baseCustomerInfo(r) {
  return {
    id: r.id,
    course: r.course,
    // 体验课
    channel: r.channel,
    // 渠道来源
    lineType: r.cate_name,
    // 业务线
    lineId: r.cate_id,
    weChatName: r.wechat,
    // 销售微信
    saleManId: r.saleman_id // 销售

  };
}
/**
 * 我的线索数据实体对象
 * @param r
 * @returns {{userAvatar, mobile, channel, typeName: *, lineId, userName, type: (string|rules.status_clue|{trigger, message, required}), uid, createTime, weChatName, lineType, course, id, saleManId}}
 */


function clueBean(r) {
  return (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, baseUserInfo(r)), baseCustomerInfo(r)), {}, {
    courseDrawTime: r.course_time ? (0, _utils.parseTime)(r.course_time * 1000) : '',
    // 课程领取时间
    type: r.status_clue,
    // 类型
    typeName: r.statusClueValue,
    // 类型描述
    clientType: r.client_type,
    // 客户端类型
    clientTypeName: r.clientTypeValue,
    // 客户端类型名称
    infoPhone: r.infophone // 手机号

  });
}
/**
 * 我的机会数据实体对象
 * @param r
 * @returns {{userAvatar, courseDrawTime: string, mobile, channel, typeName: *, lineId, userName, type: (string|number), uid, homeworkNum: string, createTime, weChatName, lastHomeworkTime: string, lineType, course, reviewStatus: *, id, saleManId}}
 */


function changeBean(r) {
  return (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, baseUserInfo(r)), baseCustomerInfo(r)), {}, {
    lastHomeworkTime: r.last_homework ? (0, _utils.parseTime)(r.last_homework * 1000) : '',
    // 最近作品提交时间
    reviewStatus: r.review_status,
    // 点评
    homeworkNum: r.homework_num,
    // 提交次数
    courseDrawTime: r.course_time ? (0, _utils.parseTime)(r.course_time * 1000) : '',
    // 课程领取时间
    type: r.status_chance,
    // 类型
    typeName: r.statusChanceValue,
    // 类型描述
    clientType: r.client_type,
    // 客户端类型
    clientTypeName: r.clientTypeValue,
    // 客户端类型名称
    infoPhone: r.infophone // 手机号

  });
}
/**
 * 我的成单客户数据实体对象
 * @param r
 * @returns {{lastOrder: string, userAvatar, mobile, channel, lineId, userName, uid, createTime, weChatName, lineType, course, id, saleManId}}
 */


function buyerBean(r) {
  return (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, baseUserInfo(r)), baseCustomerInfo(r)), {}, {
    lastOrder: r.last_order ? (0, _utils.parseTime)(r.last_order * 1000) : '' // 最近购买时间

  });
}
/**
 * 销售课程数据实体对象
 * @param r
 * @returns {{saleManName: *, courseName: *, createTime: string, price: *, saleManId: (string|*), courseId: *}}
 */


function saleCourseBean(r) {
  return {
    courseId: r.video_id,
    // 课程ID
    courseName: r.video_name,
    // 课程名称
    price: r.price,
    // 购买价格
    createTime: r.ctime ? (0, _utils.parseTime)(r.ctime * 1000) : '',
    // 购买时间
    saleManId: r.saleman_id,
    // 销售ID
    saleManName: r.saleman_uname // 销售

  };
}
/**
 * 回访记录数据实体对象
 * @param r
 * @returns {{saleManName: *, createTime: *, lineType: (string), quickNote: string, id: *, content: *}}
 */


function followRecordBean(r) {
  return {
    id: r.id,
    lineType: r.cate_id,
    quickNote: r.quick_note,
    content: r.content,
    createTime: r.created_time ? (0, _utils.parseTime)(r.created_time * 1000) : '',
    saleManName: r.saleman_uname,
    recordUid: r.record_uid,
    recordUserName: r.record_uname
  };
}
/**
 * 客户作品数据实体类
 * @param r
 * @returns {{chapter: {count: *, section: {createTime: string, id: *, title: *, status: *}[], id: *, title: *}[], id: string, title: *}}
 */


function worksBean(r) {
  return {
    id: r.vid,
    title: r.vTitle,
    chapter: (r.chapter || []).map(function (c) {
      return {
        id: r.cId,
        title: c.title,
        count: c.count,
        section: (c.section || []).map(function (s) {
          return {
            id: s.id,
            title: s.section_name,
            createTime: s.atime ? (0, _utils.parseTime)(s.atime * 1000) : '',
            status: s.review_status
          };
        })
      };
    })
  };
}
/**
 * 我的销售订单数据实体对象
 * @param r
 * @returns {{saleManName: *, oldCourseName: *, courseName: *, totalPrice: *, createTime: *, mobile: (y.mobile|mobile), id: *, saleManId: *, buyerId: *, userName: string}}
 */


function saleOrderBean(r) {
  return {
    id: r.id,
    saleManId: r.sale_id,
    // 销售ID
    saleManName: r.saleman_uname,
    // 销售
    courseName: r.video_id,
    // 课程名称
    oldCourseName: r.sale_video_id,
    // 原关联课程
    totalPrice: r.totalprice,
    // 订单金额
    buyerId: r.buyer_id,
    // 用户ID
    userName: r.uname,
    // 用户名
    mobile: r.mobile,
    // 用户手机号
    createTime: (0, _utils.parseTime)(r.create_time) // 购买时间

  };
}
/**
 * 销售人员实体对象
 * @param r
 * @returns {{uid: *, totalPrice: *, teamId: *, groupId: *, mobile: (y.mobile|mobile), totalOrder: *, id: *, userName: string, type: *}}
 */


function salesStaffBean(r) {
  return {
    id: r.id,
    uid: r.uid,
    userName: r.uname,
    // 销售人
    type: r.type,
    // 销售人员类型
    mobile: r.mobile,
    // 销售人手机号
    teamId: r.team_id,
    // 销售团队
    groupId: r.group_id,
    // 销售组
    totalOrder: r.totalorder,
    // 订单量
    totalPrice: r.totalprice,
    // 订单金额
    status: r.is_disable,
    yesterdayNum: r.yesterday_num,
    // 昨日量
    todayNum: r.today_num // 今日量

  };
}
/**
 * 二维码信息实体对象
 * @param r
 * @returns {{uid: *, totalNum: *, weChatName: *, qrCodeUrl: *, weChatType: *, todayNum: *, id: *, saleManId: *, weChatID: *, topLimit: string, off: *}}
 */


function qrCodeBean(r) {
  return {
    id: r.id,
    qrCodeId: r.qrcode,
    qrCodeUrl: r.qrcode_img,
    // 二维码
    weChatType: r.wechat_type,
    // 微信类型
    weChatTypeName: r.wechat_typename,
    // 微信类型
    weChatID: r.wechat,
    // 绑定微信号
    weChatName: r.name,
    // 微信昵称
    saleManId: r.saleman_id,
    // 销售人
    saleManName: r.saleman_uname,
    // 销售人
    uid: r.uid,
    // 销售UID
    totalNum: r.total_num,
    // 总数
    todayNum: r.today_num,
    // 今日量
    topLimit: r.toplimit,
    // 上限
    lineType: r.cate_name,
    // 分类
    lineId: r.cid,
    yesterdayNum: r.yesterday_num,
    // 昨日量
    off: r.off,
    // 是否开启
    offName: r.offValue,
    isDelete: r.is_delete,
    isDeleteName: r.isDeleteValue
  };
}
/**
 * 客服组信息实体对象
 * @param r
 * @returns {{totalNum: *, yesterdayNum: *, lineType: string, id: *, title: *}}
 */


function serviceGroupBean(r) {
  return {
    id: r.id,
    groupName: r.title,
    // 客服组名称
    lineId: r.cate_id,
    // 业务类型
    yesterdayNum: r.yesterday_num,
    // 昨日统计
    totalNum: r.total_num // 总统计

  };
}
/**
 * 微信统计信息实体对象
 * @param r
 * @returns {{saleManName: *, weChatName: *, qrCodeUrl: *, lineType: *, lineId: (string|rules.cid|{trigger, message, required}|*), id: *, saleManId: (string|*), weChatID: (string|rules.wechat|{trigger, message, required}|*)}}
 */


function wxStatisticsBean(r) {
  var monthObj = {};

  if (r.wechatNums) {
    try {
      for (var _i = 0, _Object$keys = Object.keys(r.wechatNums); _i < _Object$keys.length; _i++) {
        var i = _Object$keys[_i];
        monthObj["".concat(i, "month")] = r.wechatNums[i];
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (0, _objectSpread2.default)({
    id: r.id,
    qrCodeUrl: r.qrcode_img,
    // 二维码
    weChatID: r.wechat,
    // 绑定微信号
    weChatName: r.name,
    // 微信昵称
    saleManId: r.saleman_id,
    // 销售人
    saleManName: r.saleman_uname,
    // 销售人
    lineType: r.cate_name,
    // 分类
    lineId: r.cid,
    saleManGroup: r.saleman_group
  }, monthObj);
}