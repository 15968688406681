"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'Index',
  props: {
    limit: {
      type: Number,
      default: 1
    },
    listType: {
      type: String,
      default: 'picture-card'
    },
    fileList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false
    };
  },
  methods: {
    uploadRequest: function uploadRequest(params) {
      var _this = this;

      var _formData = new FormData();

      _formData.append('file', params.file);

      _api.default.uploadImg(_formData).then(function (response) {
        var data = response.data;

        _this.fileList.push({
          name: data.name,
          url: data.src,
          attach_id: data.attach_id
        });

        _this.$emit('myEvent', _this.fileList);
      });
    },
    handleRemove: function handleRemove(file) {
      var idx = this.fileList.findIndex(function (item) {
        return item.attach_id === file.attach_id;
      });

      if (idx >= 0) {
        this.fileList.splice(idx, 1);
      }

      this.$emit('myEvent', this.fileList);
    },
    handlePreview: function handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E9 ".concat(this.limit, " \u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ").concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(files.length + fileList.length, " \u4E2A\u6587\u4EF6"));
    }
  }
};
exports.default = _default2;