"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _router = require("@/router");

/*
 * @Author: your name
 * @Date: 2021-06-03 21:05:33
 * @LastEditTime: 2021-06-10 15:08:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt-online-edu-admin/src/store/modules/permission.js
 */
// import role from 'mock/role';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.hidden) {
    return true;
  }

  if (route.meta && route.name) {
    if (route.meta.noAuth) return true;
    return roles.some(function (role) {
      return route.name === role.menuCode;
    });
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */


function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);

    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }

      res.push(tmp);
    }
  });
  return res;
}

var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var flat = function flat(arr) {
        var _ref2;

        return (_ref2 = []).concat.apply(_ref2, (0, _toConsumableArray2.default)(arr.map(function (item) {
          var _ref3;

          return (_ref3 = []).concat.apply(_ref3, [item].concat((0, _toConsumableArray2.default)(flat(item.childMenus))));
        })));
      };

      var accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, flat(roles));
      accessedRoutes.map(function (item) {
        var curPath = '';

        var pathStr = function pathStr(v) {
          curPath = curPath || v.path;

          if (v.children) {
            curPath = "".concat(curPath, "/").concat(v.children[0].path);
            pathStr(v.children[0]);
          }
        };

        pathStr(item);
        item.redirect = curPath;
        return item;
      });
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;