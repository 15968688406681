var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c("el-tab-pane", { attrs: { label: "全部机会", name: "1" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "未跟进", name: "2" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("search-box", {
        attrs: { columns: _vm.initSaleData.chance.columns },
        on: {
          search: _vm.onSubmit,
          reset: _vm.reset,
          handleFieldChange: _vm.handleFieldChange
        },
        scopedSlots: _vm._u([
          {
            key: "basic",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "客户UID：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "客户UID", clearable: true },
                      model: {
                        value: _vm.searchParam.uid,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uid", $$v)
                        },
                        expression: "searchParam.uid"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "客户昵称：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "客户昵称", clearable: true },
                      model: {
                        value: _vm.searchParam.uname,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "uname", $$v)
                        },
                        expression: "searchParam.uname"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "类型：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchParam.status_chance,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "status_chance", $$v)
                          },
                          expression: "searchParam.status_chance"
                        }
                      },
                      _vm._l(_vm.typeArr, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "queryMore",
            fn: function() {
              return [
                _c(
                  "el-form-item",
                  { attrs: { label: "体验课：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "体验课", clearable: true },
                      model: {
                        value: _vm.searchParam.course,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "course", $$v)
                        },
                        expression: "searchParam.course"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "手机号：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "手机号", clearable: true },
                      model: {
                        value: _vm.searchParam.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "phone", $$v)
                        },
                        expression: "searchParam.phone"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "渠道来源：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "渠道来源", clearable: true },
                      model: {
                        value: _vm.searchParam.channel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "channel", $$v)
                        },
                        expression: "searchParam.channel"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "客户端：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchParam.client_type,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "client_type", $$v)
                          },
                          expression: "searchParam.client_type"
                        }
                      },
                      _vm._l(_vm.clientTypeArr, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "业务线：" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchParam.cate_id,
                          callback: function($$v) {
                            _vm.$set(_vm.searchParam, "cate_id", $$v)
                          },
                          expression: "searchParam.cate_id"
                        }
                      },
                      _vm._l(_vm.serviceLineArr, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "课程领取时间：" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetimerange",
                        "range-separator": "~",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        clearable: true
                      },
                      on: { change: _vm.dateRangeChange },
                      model: {
                        value: _vm.courseDrawTime,
                        callback: function($$v) {
                          _vm.courseDrawTime = $$v
                        },
                        expression: "courseDrawTime"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "作品提交次数：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "作品提交次数", clearable: true },
                      model: {
                        value: _vm.searchParam.homework_num,
                        callback: function($$v) {
                          _vm.$set(_vm.searchParam, "homework_num", $$v)
                        },
                        expression: "searchParam.homework_num"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "data-list": _vm.tableData,
          columns: _vm.initSaleData.chance.columns,
          "page-no": _vm.searchParam.page,
          "page-size": _vm.searchParam.page_size,
          "show-index": false,
          selection: false,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _vm.hasPerm("Chance:change")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  "track-params": "销售-我的机会-跟进客户",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showDialog(props.row)
                                  }
                                }
                              },
                              [_vm._v("跟进客户\n                ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPerm("Chance:type")
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track:click",
                                    arg: "click"
                                  }
                                ],
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  "track-params": "销售-我的机会-类型",
                                  size: "small",
                                  type: "text"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.doType(props.row)
                                  }
                                }
                              },
                              [_vm._v("类型\n                ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : props.column.label === "头像"
                  ? _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "80px", height: "60px" },
                          attrs: {
                            src: props.row.userAvatar,
                            "preview-src-list": [props.row.userAvatar]
                          }
                        })
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("follow-dialog", {
        ref: "followDialog",
        attrs: { "page-name": _vm.initSaleData.chance.name }
      }),
      _vm._v(" "),
      _c("type-dialog", { ref: "typeDialog", on: { refresh: _vm.fetchData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }