var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          class: { hide: _vm.hideUpload },
          attrs: {
            action: "https://jsonplaceholder.typicode.com/posts/",
            "list-type": "picture-card",
            "file-list": _vm.fileList,
            "auto-upload": false,
            "on-change": _vm.handleChange,
            "on-preview": _vm.handlePictureCardPreview,
            "on-remove": _vm.handleRemove
          }
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, size: "tiny" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }