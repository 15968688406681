"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initData = void 0;
var initData = {
  courseList: {
    columns: [{
      label: '编号',
      width: '60',
      prop: 'id'
    }, {
      label: '课程名称',
      width: '120',
      prop: 'title'
    }, {
      label: '课程状态',
      width: '80',
      prop: 'isUpdateText'
    }, {
      label: '课程封面',
      width: '100',
      prop: 'coverImg'
    }, {
      label: '市场价',
      width: '80',
      prop: 'vPrice'
    }, {
      label: '销售价',
      width: '80',
      prop: 'tPrice'
    }, {
      label: '体验价',
      width: '80',
      prop: 'ePrice'
    }, {
      label: '上传用户',
      width: '80',
      prop: 'dutyPerson'
    }, {
      label: '教师名称',
      width: '80',
      prop: 'teacherName'
    }, {
      label: '课程评分',
      width: '80',
      prop: 'score'
    }, {
      label: '学习人数',
      width: '80',
      prop: 'count'
    }, {
      label: '学习人数（营销）',
      width: '80',
      prop: 'countMark'
    }, {
      label: '是否免费',
      width: '80',
      prop: 'isChargeText'
    }, {
      label: '是否审核',
      width: '80',
      prop: 'isActivityText'
    }, {
      label: '是否精选',
      width: '80',
      prop: 'isBestText'
    }, {
      label: '创建时间',
      width: '130',
      prop: 'createTime'
    }, {
      label: '操作',
      width: '160',
      fix: 'right'
    }],
    options: [{
      value: '1',
      label: '不限'
    }, {
      value: '2',
      label: '否'
    }, {
      value: '3',
      label: '是'
    }]
  },
  coursewareList: {
    columns: [{
      label: 'id',
      width: '60',
      prop: 'id'
    }, {
      label: '创建人',
      width: '80',
      prop: 'uName'
    }, {
      label: '课件名称',
      width: '100',
      prop: 'name'
    }, {
      label: '类型',
      width: '60',
      prop: 'fileTypeText'
    }, {
      label: '创建时间',
      width: '100',
      prop: 'createTime'
    }, {
      label: '转码状态',
      width: '60',
      prop: 'transcodingText'
    }, {
      label: '发布状态',
      width: '60',
      prop: 'isReleaseText'
    },
    /* {
        label: '状态',
        width: '60',
        prop: 'statusText'
    }, */
    {
      label: '操作',
      width: '160',
      fix: 'right'
    }],
    typeList: [{
      value: '',
      label: '不限'
    }, {
      value: 'video',
      label: '视频'
    }, {
      value: 'audio',
      label: '音频'
    }, {
      value: 'image',
      label: '图片'
    }, {
      value: 'document',
      label: '文档'
    }, {
      value: '应用',
      label: 'apk'
    }, {
      value: 'other',
      label: '其他'
    }],
    status: [{
      value: '',
      label: '不限'
    }, {
      value: 1,
      label: '禁用'
    }, {
      value: 0,
      label: '正常'
    }],
    transcodeList: [{
      value: -1,
      label: '未转码'
    }, {
      value: 1,
      label: '转码完成'
    }, {
      value: 2,
      label: '转码中'
    }],
    releaseList: [{
      value: -1,
      label: '未发布'
    }, {
      value: 1,
      label: '发布完成'
    }, {
      value: 2,
      label: '发布中'
    }, {
      value: 3,
      label: '发布失败'
    }]
  },
  bookUser: {
    columns: [{
      label: 'UID',
      width: '60',
      prop: 'uid'
    }, {
      label: '用户昵称',
      width: '80',
      prop: 'name'
    }, {
      label: '手机号',
      width: '60',
      prop: 'phone'
    }, {
      label: '头像',
      width: '60' // prop: 'name'

    }, {
      label: '最近评测的字帖年级类型',
      width: '40',
      prop: 'grade'
    }, {
      label: '作品数',
      width: '30',
      prop: 'exist_photos'
    }, {
      label: '评测数',
      width: '30',
      prop: 'totoal_photos'
    }, {
      label: '最近评测时间',
      width: '70',
      prop: 'created_at'
    }, {
      label: '少儿线索生成的时间',
      width: '70' // prop: 'created_time'

    }, {
      label: '操作',
      width: '60',
      fix: 'right'
    }]
  },
  testUser: {
    columns: [{
      label: '作品ID',
      width: '60',
      prop: 'id'
    }, {
      label: '用户',
      width: '80',
      prop: 'name'
    }, {
      label: '字帖年级类型',
      width: '50' // prop: 'grade'

    }, {
      label: '差评数',
      width: '50',
      prop: 'poor'
    }, {
      label: '好评数',
      width: '60',
      prop: 'good'
    }, {
      label: '空字数',
      width: '100',
      prop: 'missing'
    }, {
      label: '时间',
      width: '100',
      prop: 'created_at'
    }, {
      label: '操作',
      width: '160' // fix: 'right'

    }]
  }
};
exports.initData = initData;