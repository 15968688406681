"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/web.dom.iterable");

var _type = require("./type");

var _Upload = _interopRequireDefault(require("./components/Upload"));

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CoursewareEdit',
  components: {
    Upload: _Upload.default,
    Draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      type: '',
      topicList: _type.TOPIC_ENUM,
      cateList: _type.LESSON_CATE_ENUM,
      activeName: '1',
      // 视频课堂
      formData: {
        lesson_id: 0,
        section_id: 0
      },
      resourceList: [],
      coursewareList: [],
      loading: false
    };
  },
  activated: function activated() {
    this.initView();
    this.initData();
  },
  methods: {
    initView: function initView() {
      var _this$$route$params = this.$route.params,
          type = _this$$route$params.type,
          id = _this$$route$params.id;

      if (type === 'lesson') {
        this.activeName = '1';
        this.cateList = _type.LESSON_CATE_ENUM;
        this.formData.lesson_id = id;
      } else {
        this.activeName = '5';
        this.cateList = _type.SECTION_CATE_ENUM;
        this.formData.section_id = id;
      }

      this.resourceList = []; // eslint-disable-next-line

      for (var value in this.cateList) {
        this.resourceList.push({
          categoryId: value,
          list: []
        });
      }
    },
    initData: function initData() {
      var _this = this;

      var _this$formData = this.formData,
          lesson_id = _this$formData.lesson_id,
          section_id = _this$formData.section_id;

      _api.default.getCoursewareDetail({
        lesson_id: lesson_id,
        section_id: section_id
      }).then(function (res) {
        var data = res.data;

        if (data && data.length) {
          _this.resourceList.map(function (item) {
            data.forEach(function (subItem) {
              if (Number(item.categoryId) === Number(subItem.categoryId)) {
                item.categoryId = subItem.categoryId.toString();
                item.list = subItem.list;
              }
            });
            return item;
          });
        }
      });
    },
    handleAdd: function handleAdd(index) {
      if (!this.type) {
        this.$message.warning('请先选择课件类型~');
        return;
      }

      this.resourceList[index].list.push({
        type: this.type,
        selectId: '',
        action: '',
        audioResource: '',
        fileList: []
      });
      this.type = '';
    },
    handleDelete: function handleDelete(typeIndex, index) {
      this.resourceList[typeIndex].list.splice(index, 1);
    },
    isVerify: function isVerify() {
      /* eslint-disable */
      var resourceList = this.resourceList,
          cateList = this.cateList;

      for (var i = 0; i < resourceList.length; i++) {
        var item = resourceList[i];

        if (!['3', '6'].includes(item.categoryId)) {
          if (!item.list || !item.list.length) {
            this.$message.warning("\u8BF7\u5B8C\u5584".concat(cateList[item.categoryId], "~"));
            return false;
            break;
          } else {
            for (var j = 0; j < item.list.length; j++) {
              var subItem = item.list[j];

              if (!subItem.fileList || !subItem.fileList.length) {
                this.$message.warning("\u8BF7\u5B8C\u5584".concat(cateList[item.categoryId], "~"));
                return false;
                break;
              }

              if (item.categoryId === '2' && subItem.type === 'video' && !subItem.action) {
                this.$message.warning("\u8BF7\u5B8C\u5584".concat(cateList[item.categoryId], "\u89C6\u9891\u7C7B\u578B~"));
                return false;
                break;
              }
            }
          }
        }
      }

      return true;
      /* eslint-disable */
    },
    confirm: function confirm() {
      var _this2 = this;

      if (this.loading) {
        return;
      }

      if (!this.isVerify()) {
        return;
      }

      var resourceList = this.resourceList,
          formData = this.formData;
      this.loading = true;
      this.formData.resource_data = JSON.stringify(resourceList);

      _api.default.submitCoursewareResources(formData).then(function () {
        _this2.$message.success('操作成功');

        _this2.loading = false;
        setTimeout(function () {
          _this2.$router.go(-1);
        }, 2000);
      }).catch(function () {
        _this2.loading = false;
      });
    }
  }
};
exports.default = _default;