var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-panel" },
    [
      _c(
        "right-panel",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          ref: "rightPanel",
          attrs: { title: "上传列表" },
          on: { handleClose: _vm.handleClose }
        },
        [
          _c("div", { staticClass: "panel-content" }, [
            _c(
              "div",
              { staticClass: "file-list-wrap" },
              _vm._l(_vm.uploaderList, function(item, index) {
                return _c("item", {
                  key: index,
                  attrs: {
                    uploader: item.uploader,
                    uploaderInfo: item.uploaderInfo
                  },
                  on: { handleDelete: _vm.handleDelete }
                })
              }),
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }