var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.itemData ? "编辑二维码" : "添加二维码",
        visible: _vm.dialogVisible
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.loading ? "提交中 ..." : "确 定"))]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            "label-width": _vm.formLabelWidth,
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "二维码图片：", prop: "qrcode", required: "" } },
            [
              _c("el-input", {
                staticStyle: { display: "none" },
                attrs: { autocomplete: "off", disabled: _vm.disabled },
                model: {
                  value: _vm.form.qrcode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "qrcode", $$v)
                  },
                  expression: "form.qrcode"
                }
              }),
              _vm._v(" "),
              _c("upload", {
                attrs: { "file-list": _vm.fileList, disabled: _vm.disabled },
                on: { myEvent: _vm.uploadResult }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "微信类型：", prop: "wechat_type", required: "" }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.wechat_type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "wechat_type", $$v)
                    },
                    expression: "form.wechat_type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("企业微信")
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "2" } }, [_vm._v("微信")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "绑定微信号：", prop: "wechat", required: "" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", clearable: true },
                model: {
                  value: _vm.form.wechat,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "wechat", $$v)
                  },
                  expression: "form.wechat"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "微信昵称：", prop: "name", required: "" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", clearable: true },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "分类：", prop: "cid", required: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { disabled: _vm.disabled, placeholder: "请选择" },
                  model: {
                    value: _vm.form.cid,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "cid", $$v)
                    },
                    expression: "form.cid"
                  }
                },
                _vm._l(_vm.serviceLineArr, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "上限：", prop: "toplimit", required: "" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", clearable: true },
                model: {
                  value: _vm.form.toplimit,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "toplimit", $$v)
                  },
                  expression: "form.toplimit"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "销售：" } },
            [
              _c("el-input", {
                attrs: {
                  value:
                    "【" +
                    _vm.saleManInfo.uid +
                    "】" +
                    _vm.saleManInfo.userName,
                  autocomplete: "off",
                  disabled: ""
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }