"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.array.find");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _cache = require("@/utils/cache");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Staff',
  components: {
    SearchBox: _SearchBox.default,
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      saleGroup: [],
      searchParam: {},
      tableData: [],
      columns: [{
        label: 'ID',
        width: '80',
        prop: 'id',
        hide: false
      }, {
        label: 'UID',
        width: '100',
        prop: 'uid',
        hide: false
      }, {
        label: '销售人',
        width: '80',
        prop: 'userName',
        hide: false
      }, {
        label: '销售人员类型',
        width: '120',
        prop: 'type',
        hide: false
      }, {
        label: '销售人手机号',
        width: '120',
        prop: 'mobile',
        hide: false
      }, {
        label: '销售团队',
        width: '120',
        prop: 'teamId',
        hide: false
      }, {
        label: '销售组',
        width: '120',
        prop: 'groupId',
        hide: false
      }, {
        label: '昨日总量',
        width: '80',
        prop: 'yesterdayNum',
        hide: false
      }, {
        label: '今日总量',
        width: '80',
        prop: 'todayNum',
        hide: false
      }, {
        label: '订单量',
        width: '80',
        prop: 'totalOrder',
        hide: false
      }, {
        label: '订单金额',
        width: '80',
        prop: 'totalPrice',
        hide: false
      }, {
        label: '状态',
        width: '120',
        prop: 'status',
        hide: false
      }, {
        label: '操作',
        width: '120',
        fix: 'right'
      }],
      listLoading: false,
      saleGroupArr: [],
      total: 0
    };
  },
  created: function created() {
    var fieldJson = (0, _cache.find)('fieldJson');

    if (fieldJson && fieldJson[this.$route.name]) {
      this.columns = fieldJson[this.$route.name];
    }

    this.reset();
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;

      _api.default.getSaleGroupList().then(function (res) {
        _this.saleGroupArr = (res.data || []).map(function (item) {
          item.subInfos = [{
            sale_group_id: '',
            title: '所有组'
          }].concat(item.subInfos || []);
          return item;
        });
      });
    },
    handleChange: function handleChange(val) {
      this.searchParam.team_id = val[0] || '';
      this.searchParam.group_id = val[1] || '';
    },
    handleFieldChange: function handleFieldChange(val) {
      this.columns = val;
    },
    fetchData: function fetchData() {
      var _this2 = this;

      this.listLoading = true;

      _api.default.getSaleStaffList(this.searchParam).then(function (response) {
        _this2.tableData = response.data.infos.map(function (item) {
          return _bean.default.salesStaffBean(item);
        });
        _this2.total = response.data.totalNum;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.tableData = [];
        _this2.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.searchParam = {
        uname: '',
        phone: '',
        uid: '',
        team_id: '',
        group_id: '',
        page: 1,
        page_size: 20
      };
      this.saleGroup = [];
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    jumpPage: function jumpPage(item) {
      (0, _cache.save)('saleManInfo', item);
      this.$router.push({
        name: 'QrCode',
        params: {
          id: item.id
        }
      });
    }
  }
};
exports.default = _default;