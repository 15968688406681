"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _PreviewDialog = _interopRequireDefault(require("@/components/Dialog/PreviewDialog"));

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AssetDialog',
  components: {
    PageTable: _PageTable.default,
    PreviewDialog: _PreviewDialog.default
  },
  props: {
    isOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formLabelWidth: '60px',
      searchParam: {
        page: 1,
        page_size: 10,
        id: '',
        name: ''
      },
      tableData: [],
      total: 0,
      selectList: [],
      columns: [{
        label: '资源ID',
        width: '100',
        prop: 'id'
      }, {
        label: '资源名称',
        width: '100',
        prop: 'name'
      }, {
        label: '文件类型',
        width: '80',
        prop: 'fileTypeText'
      }, {
        label: '上传时间',
        width: '120',
        prop: 'createTime'
      }]
    };
  },
  created: function created() {},
  methods: {
    showDialog: function showDialog(params) {
      var fileType = params.fileType,
          lineType = params.lineType;
      this.dialogVisible = true; // file_type: 资源类型['video' => '视频', 'audio' => '音频', 'apk' => '应用', 'image' => '图片', 'document' => '文档', 'other' => '其他']

      this.searchParam.file_type = fileType; // line_type: 业务线([1 => 线上教育, 2 => 碑帖, 3 => 智能笔]

      this.searchParam.line_type = lineType;
      this.selectList = [];
      this.fetchData();
    },
    confirm: function confirm() {
      if (!this.selectList.length) {
        this.$message.warning('请选择资源~');
        return;
      }

      this.$emit('confirm', this.selectList);
      this.dialogVisible = false;
    },
    fetchData: function fetchData() {
      var _this = this;

      _api.default.getAssetsList(this.searchParam).then(function (response) {
        _this.tableData = response.data.infos.map(function (item) {
          return _bean.default.assetBean(item);
        });
        _this.total = response.data.totalNum;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    selectionChange: function selectionChange(val) {
      this.selectList = val;
    },
    currentChange: function currentChange(val) {
      if (this.isOnly && val) {
        this.selectList = [val];
      }
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    jumpPage: function jumpPage() {
      this.dialogVisible = false;
      var line_type = this.searchParam.line_type;

      if (Number(line_type) === 1) {
        this.$router.push({
          name: 'CoursewareList'
        });
      } else if (Number(line_type) === 2) {
        this.$router.push({
          name: 'Resource'
        });
      } else if (Number(line_type) === 3) {
        this.$router.push({
          name: 'AssetsList'
        });
      }
    },
    showPreviewDialog: function showPreviewDialog(item) {
      this.$refs.previewDialog.showDialog({
        fileType: item.fileType,
        fileUrl: item.fileUrl,
        fileId: item.fileId,
        name: item.name
      });
    }
  }
};
exports.default = _default;