var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "follow-list" },
    [
      _vm.recordList.length
        ? _vm._l(_vm.recordList, function(item) {
            return _c("div", { key: item.id }, [
              +_vm.lineType === +item.lineType
                ? _c("div", { staticClass: "follow-item" }, [
                    _c("p", { staticClass: "name" }, [
                      _vm._v(
                        "【" +
                          _vm._s(item.recordUid) +
                          "】" +
                          _vm._s(item.recordUserName)
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      item.content
                        ? _c("span", [_vm._v(_vm._s(item.content))])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "time" }, [
                      _vm._v(_vm._s(item.createTime))
                    ])
                  ])
                : _vm._e()
            ])
          })
        : _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }