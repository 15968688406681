var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container student" },
    [
      !_vm.isMyStudent
        ? _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.showDialog }
                    },
                    [_vm._v("添加学员")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "a",
                    {
                      staticClass: "inline-block",
                      attrs: { href: _vm.moduleUrl }
                    },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("下载模板")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload",
                      attrs: {
                        action: "",
                        accept:
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "http-request": _vm.uploadRequest,
                        "with-credentials": true,
                        "auto-upload": true
                      }
                    },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("批量导入学员")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("page-table", {
        ref: "pageTable",
        attrs: {
          loading: _vm.listLoading,
          "show-index": false,
          "data-list": _vm.tableData,
          columns: _vm.initClassData.student.columns,
          "page-no": _vm.listQuery.page,
          "page-size": _vm.listQuery.page_size,
          total: _vm.total
        },
        on: { pageNoChange: _vm.pageNoChange },
        scopedSlots: _vm._u([
          {
            key: "cell",
            fn: function(props) {
              return [
                props.column.label === "头像"
                  ? _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "80px", height: "60px" },
                          attrs: {
                            src: props.row.avatar,
                            "preview-src-list": [props.row.avatar]
                          }
                        })
                      ],
                      1
                    )
                  : props.column.label === "操作"
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.jumpPage(props.row, "HomeworkList")
                              }
                            }
                          },
                          [_vm._v("作业列表")]
                        ),
                        _vm._v(" "),
                        !_vm.isMyStudent
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteItem(props.row)
                                  }
                                }
                              },
                              [_vm._v("删除\n                ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(props.row[props.column.property]))
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("user-dialog", {
        ref: "userDialog",
        attrs: { type: "student" },
        on: { confirm: _vm.confirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }