var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogFormVisible },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "code-content", attrs: { id: "canvas" } }, [
        _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.classTitle))]),
        _vm._v(" "),
        _c("img", {
          staticClass: "bg-img",
          attrs: { src: require("@/assets/edu_bg_share_poster.png") }
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "qr-code-img",
          attrs: {
            src:
              "https://route.liupinyike.com/sale/class-qrcode?class_id=" +
              _vm.imageClass_id
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialog-footer text-center" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogFormVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.download }
            },
            [_vm._v("下 载")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }