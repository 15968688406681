"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _html2canvas = _interopRequireDefault(require("html2canvas"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'QrCodeDialog',
  data: function data() {
    return {
      dialogFormVisible: false,
      loading: false,
      classTitle: '',
      imgUri: '',
      imageClass_id: '',
      qrcodeImg: ''
    };
  },
  methods: {
    getQrcode: function getQrcode(rowData) {
      this.listLoading = true;
      this.imageClass_id = rowData.id;
    },
    showDialog: function showDialog(refData) {
      this.classTitle = refData.className;
      this.dialogFormVisible = true;
      this.getQrcode(refData);
    },
    download: function download() {
      var _this = this;

      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.loading = true;
      var scale = window.devicePixelRatio;
      var dom = document.getElementById('canvas');
      var canvas = document.createElement('canvas');
      canvas.width = dom.offsetWidth * scale;
      canvas.height = dom.offsetHeight * scale;
      (0, _html2canvas.default)(dom, {
        scale: scale,
        canvas: canvas,
        taintTest: true,
        backgroundColor: null,
        useCORS: true
      }).then(function (data) {
        var a = document.createElement('a');
        a.href = data.toDataURL('image/png');
        a.download = '活动页';
        a.click();
        _this.loading = false;
      }).catch(function () {
        _this.$message.error('图片生成失败，请稍后重试');

        _this.loading = false;
      });
    }
  }
};
exports.default = _default;