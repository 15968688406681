"use strict";

var _interopRequireWildcard = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var base = _interopRequireWildcard(require("./base"));

var classRoom = _interopRequireWildcard(require("./classRoom"));

var sales = _interopRequireWildcard(require("./sales"));

var smartPen = _interopRequireWildcard(require("./smartPen"));

var child = _interopRequireWildcard(require("./child"));

var middle = _interopRequireWildcard(require("./middle"));

var online = _interopRequireWildcard(require("./online"));

var _default = (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, base), classRoom), sales), smartPen), child), middle), online);

exports.default = _default;