var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "upload-wrap" },
        [
          _vm.uploadInfo.type === "radio"
            ? _c("div", [
                !_vm.uploadInfo.audioResource
                  ? _c(
                      "div",
                      {
                        staticClass: "upload-list--btn",
                        on: { click: _vm.handleChangeAudioFile }
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "upload-list-btn-tip" }, [
                          _vm._v("题干录音")
                        ])
                      ]
                    )
                  : _c(
                      "ul",
                      { staticClass: "upload-list" },
                      [
                        _c("upload-item", {
                          attrs: {
                            type: "audio",
                            itemInfo: _vm.uploadInfo.audioResource
                          },
                          on: { handleRemove: _vm.handleAudioRemove }
                        })
                      ],
                      1
                    )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              attrs: { size: "mini" },
              model: {
                value: _vm.uploadInfo.selectId,
                callback: function($$v) {
                  _vm.$set(_vm.uploadInfo, "selectId", $$v)
                },
                expression: "uploadInfo.selectId"
              }
            },
            [
              _vm.uploadInfo.fileList.length
                ? _c(
                    "ul",
                    { staticClass: "upload-list" },
                    _vm._l(_vm.uploadInfo.fileList, function(item, index) {
                      return _c("upload-item", {
                        key: index,
                        attrs: {
                          currentIndex: index,
                          itemInfo: item,
                          type: _vm.uploadInfo.type
                        },
                        on: { handleRemove: _vm.handleRemove }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "upload-list--btn",
              on: { click: _vm.handleChangeFile }
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.uploadInfo.action,
                callback: function($$v) {
                  _vm.$set(_vm.uploadInfo, "action", $$v)
                },
                expression: "uploadInfo.action"
              }
            },
            _vm._l(_vm.options, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("i", {
            staticClass: "el-icon-delete",
            on: { click: _vm.handleDelete }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("asset-dialog", {
        ref: "assetDialog",
        on: { confirm: _vm.getFileList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }