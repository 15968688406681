"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _initData = require("../initData");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CourseList',
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      tableData: [],
      listLoading: false,
      total: 0,
      initUserData: _initData.initData,
      uid: ''
    };
  },
  created: function created() {
    console.log(this.$route.params.id);
    this.reset();
    this.searchParam.uid = this.$route.params.id;
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.childCourseList(this.searchParam).then(function (response) {
        console.log(response); // const { infos, totalNum } = response.data;

        var infos = response.data.data;
        var totalNum = response.data.total;
        console.log(infos);
        _this.tableData = (infos || []).map(function (item) {
          return _bean.default.childCourseListBean(item);
        });
        _this.total = totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });
    },
    reset: function reset() {
      this.searchParam = {
        uid: '',
        page: 1,
        page_size: 10
      };
      this.createTime = '';
      this.fetchData();
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    }
  }
};
exports.default = _default;