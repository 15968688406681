"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _UserDialog = _interopRequireDefault(require("@/components/Dialog/UserDialog"));

var _EditAssistantInfoDialog = _interopRequireDefault(require("./components/EditAssistantInfoDialog"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AssistantList',
  components: {
    PageTable: _PageTable.default,
    UserDialog: _UserDialog.default,
    EditAssistantInfoDialog: _EditAssistantInfoDialog.default
  },
  filters: {
    workStatusText: function workStatusText(val) {
      var map = {
        injob: '请假',
        outjob: '在职',
        leave: '在职'
      };
      return map[val];
    }
  },
  data: function data() {
    return {
      tableData: [],
      listLoading: true,
      total: 0,
      columns: [{
        label: 'ID',
        width: '40',
        prop: 'id'
      }, {
        label: 'UID',
        width: '60',
        prop: 'uid'
      }, {
        label: '微信二维码',
        width: '100',
        prop: 'wechatImg'
      }, {
        label: '昵称',
        width: '100',
        prop: 'name'
      }, {
        label: '工作状态',
        width: '80',
        prop: 'workStatusValue'
      }, {
        label: '手机号',
        width: '100',
        prop: 'phone'
      }, {
        label: '添加时间',
        width: '100',
        prop: 'createTime'
      }, {
        label: '课程班级数',
        width: '80',
        prop: 'classRoomNum'
      }, {
        label: '课程包班级数',
        width: '80',
        prop: 'packageClassNum'
      }, {
        label: '未点评作业数',
        width: '80',
        prop: 'noReviewNum'
      }, {
        label: '操作',
        width: '130',
        fix: 'right'
      }],
      listQuery: {},
      dialogType: ''
    };
  },
  created: function created() {
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getAssistantList(this.listQuery).then(function (response) {
        var _response$data = response.data,
            infos = _response$data.infos,
            totalNum = _response$data.totalNum;
        _this.tableData = (infos || []).map(function (item) {
          return _bean.default.assistantBean(item);
        });
        _this.total = totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.listQuery.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.listQuery = {
        page: 1,
        page_size: 20
      };
      this.fetchData();
    },
    jumpPage: function jumpPage(item, pageName, params) {
      this.$router.push({
        name: pageName,
        params: params
      });
    },
    showDialog: function showDialog(ref, type, row) {
      this.dialogType = type;
      this.dialogData = row;
      this.$refs[ref].showDialog(row, '编辑');
    },
    handleItem: function handleItem(type, row) {
      var _this2 = this;

      console.log(row);

      if (type === 'workStatus') {
        // 工作状态 ['': 未知; injob: 在职; outjob: 离职；leave: 请假]
        var status = {
          injob: 'leave',
          leave: 'injob'
        };

        _api.default.updateAssistantInfo({
          id: row.id,
          work_status: status[row.workStatus]
        }).then(function () {
          _this2.$message.success('操作成功');

          _this2.reset();
        });
      }
    },
    confirm: function confirm(item) {
      var _this3 = this;

      var url = 'addAssistantInfo';
      var params = {
        uid: item.uid
      };

      if (this.dialogType === 'edit') {
        params.id = this.dialogData.id;
        url = 'updateAssistantInfo';
      }

      _api.default[url](params).then(function () {
        _this3.$message.success('操作成功');

        _this3.reset();
      });
    },
    exportData: function exportData() {}
  }
};
exports.default = _default;