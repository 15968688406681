"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.childUserBean = childUserBean;
exports.childHwBean = childHwBean;
exports.childCourseListBean = childCourseListBean;
exports.childEvaListBean = childEvaListBean;
exports.evaListBean = evaListBean;

// import { parseTime } from '@/utils';

/**
 * 用户列表
 * @param r
 * @returns {{uid, avatar,uname, phone, birthday,ctime_d,course_num,submit_num,ai_submit_num}
 */
function childUserBean(r) {
  return {
    uid: r.uid,
    avatar: r.avatar,
    // 客户头像
    uname: r.uname,
    // 客户昵称
    phone: r.phone,
    // 手机号
    birthday: r.birthday,
    // 生日
    ctime_d: r.ctime_d,
    course_num: r.course_num,
    // 课程数
    submit_num: r.submit_num,
    ai_submit_num: r.ai_submit_num
  };
}
/**
 * 用户作业列表
 */


function childHwBean(r) {
  return {
    id: r.id,
    avatar: r.avatar,
    // 客户头像
    uname: r.uname,
    // 客户昵称
    atime_d: r.atime_d,
    imageindex: r.imageindex
  };
}
/**
 * 用户课程列表
 */


function childCourseListBean(r) {
  return {
    video_id: r.video_id,
    video_title: r.video_title,
    ptime_d: r.ptime_d,
    teacherid: r.teacherid,
    bind_teacher: r.bind_teacher
  };
}
/**
 * AI评测列表
 */


function childEvaListBean(r) {
  return {
    score_id: r.score_id,
    uname: r.user && r.user.uname || '',
    video_title: r.video.video_title,
    section: r.section.title,
    bad_word_count: r.bad_word_count,
    good_word_count: r.good_word_count,
    medium_word_count: r.medium_word_count,
    empty_word_count: r.empty_word_count,
    error_word_count: r.error_word_count,
    score_date: r.score_date,
    img_url: r.img_url
  };
}
/**
 * 全部AI评测列表
 */


function evaListBean(r) {
  return {
    score_id: r.score_id,
    uname: r.user && r.user.uname || '',
    video_title: r.video.video_title,
    section: r.section.title,
    bad_word_count: r.bad_word_count,
    good_word_count: r.good_word_count,
    medium_word_count: r.medium_word_count,
    empty_word_count: r.empty_word_count,
    error_word_count: r.error_word_count,
    score_date: r.score_date,
    img_url: r.img_url
  };
}