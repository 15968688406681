"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Link = _interopRequireDefault(require("./Link"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'HeadBarItem',
  components: {
    AppLink: _Link.default
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
exports.default = _default;