var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "", visible: _vm.dialogVisible },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [_c("courseware-data", { on: { confirm: _vm.confirm } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }