var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "30%" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "span",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.confirm("form")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.loading ? "提交中 ..." : "确 定"))]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.formData, rules: _vm.rules } },
        [
          _vm.type === "Clue"
            ? _c(
                "el-form-item",
                { attrs: { label: "类型：", prop: "status_clue" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formData.status_clue,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "status_clue", $$v)
                        },
                        expression: "formData.status_clue"
                      }
                    },
                    _vm._l(_vm.typeArr, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "Chance"
            ? _c(
                "el-form-item",
                { attrs: { label: "类型：", prop: "status_chance" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formData.status_chance,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "status_chance", $$v)
                        },
                        expression: "formData.status_chance"
                      }
                    },
                    _vm._l(_vm.typeArr, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }