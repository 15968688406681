"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _layout = _interopRequireDefault(require("@/layout"));

var salesRouter = {
  path: '/sales',
  component: _layout.default,
  redirect: '/sales/salesBenefit/clue',
  name: 'Sales',
  meta: {
    title: '销售'
  },
  children: [{
    path: 'salesBenefit',
    name: 'SalesBenefit',
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesBenefit'));
      });
    },
    meta: {
      title: '销售'
    },
    children: [{
      path: 'clue',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesBenefit/Clue.vue'));
        });
      },
      name: 'Clue',
      meta: {
        title: '我的线索'
      }
    }, {
      path: 'chance',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesBenefit/Chance.vue'));
        });
      },
      name: 'Chance',
      meta: {
        title: '我的机会'
      }
    }, {
      path: 'orderClient',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesBenefit/OrderClient.vue'));
        });
      },
      name: 'OrderClient',
      meta: {
        title: '我的成单客户'
      }
    }, {
      path: 'saleOrder',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesBenefit/SaleOrder.vue'));
        });
      },
      name: 'SaleOrder',
      meta: {
        title: '我的销售订单'
      }
    }, {
      path: 'wxStatistics',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesBenefit/WxStatistics.vue'));
        });
      },
      name: 'WxStatistics',
      meta: {
        title: '每日微信统计'
      }
    }, {
      path: 'ShipmentReview',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesBenefit/ShipmentReview.vue'));
        });
      },
      name: 'SaleShipmentReview',
      meta: {
        title: '发货'
      }
    }]
  }, {
    path: 'salesManager',
    name: 'SalesManager',
    redirect: 'noRedirect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesManager'));
      });
    },
    meta: {
      title: '销售管理'
    },
    children: [{
      path: 'staff',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesManager/Staff.vue'));
        });
      },
      name: 'Staff',
      meta: {
        title: '销售人员'
      }
    }, {
      path: 'qrCode/:id',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesManager/QrCode.vue'));
        });
      },
      name: 'QrCode',
      hidden: true,
      meta: {
        title: '二维码列表',
        noCache: true
      }
    }, {
      path: 'serviceGroup',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesManager/ServiceGroup.vue'));
        });
      },
      name: 'ServiceGroup',
      meta: {
        title: '客服组'
      }
    }, {
      path: 'serviceCodeList/:id/:lineId',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/sales/salesManager/ServiceCodeList.vue'));
        });
      },
      name: 'ServiceCodeList',
      hidden: true,
      meta: {
        title: '多客服二维码列表',
        noCache: true
      }
    }]
  }]
};
var _default = salesRouter;
exports.default = _default;