var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.workList.length
      ? _c(
          "div",
          _vm._l(_vm.workList, function(item, index) {
            return _c(
              "div",
              { key: index, staticClass: "works-info" },
              [
                _c("p", { staticClass: "title" }, [_vm._v(_vm._s(item.title))]),
                _vm._v(" "),
                _vm._l(item.chapter, function(cItem, cIndex) {
                  return _c(
                    "div",
                    { key: cIndex, staticClass: "works-list" },
                    [
                      _c("p", { staticClass: "count" }, [
                        _vm._v(
                          _vm._s(cItem.title) +
                            "（" +
                            _vm._s(cItem.count) +
                            "课时）"
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(cItem.section, function(sItem, sIndex) {
                        return _c(
                          "div",
                          { key: sIndex, staticClass: "works-item" },
                          [
                            _c("span", [_vm._v(_vm._s(sItem.title))]),
                            _vm._v(" "),
                            _c("div", [
                              _c("span", [_vm._v(_vm._s(sItem.createTime))]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(sItem.status))])
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                })
              ],
              2
            )
          }),
          0
        )
      : _c("div", { staticClass: "empty-wrap" }, [
          _vm._v("\n        暂无数据\n    ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }