var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "40%" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "span",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.confirm("form")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.loading ? "提交中 ..." : "确 定"))]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": _vm.formLabelWidth
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "班主任昵称：", prop: "name", required: "" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", clearable: true },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "企微二维码：", prop: "wechat_img", required: "" }
            },
            [
              _c("el-input", {
                staticStyle: { display: "none" },
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.formData.wechat_img,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "wechat_img", $$v)
                  },
                  expression: "formData.wechat_img"
                }
              }),
              _vm._v(" "),
              _c("upload", {
                attrs: { lineType: "edu", "file-list": _vm.qrCodeList },
                on: { handleResult: _vm.uploadResult }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }