var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课程名称：", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "业务类型：", prop: "business_type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.changeLineType },
                  model: {
                    value: _vm.formData.business_type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "business_type", $$v)
                    },
                    expression: "formData.business_type"
                  }
                },
                _vm._l(_vm.lineTypeArr, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "关联点播课程：", prop: "vid" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.changeCourse },
                  model: {
                    value: _vm.formData.vid,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "vid", $$v)
                    },
                    expression: "formData.vid"
                  }
                },
                _vm._l(_vm.courseArr, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.video_title, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.formData.vid,
                  expression: "formData.vid"
                }
              ]
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addLearnModule("add", _vm.formData.vid)
                    }
                  }
                },
                [_vm._v("添加学习模块")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableDataNew }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "show-header": false,
                                  data: props.row.title
                                }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { prop: "", width: "300" }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "title", width: "400" }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { prop: "操作" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(inProps) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteLearnModuleItem(
                                                      props.$index,
                                                      inProps.$index
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("删除")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "学习框架名称", prop: "id", width: "300" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "章节名称", prop: "", width: "400" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", prop: "desc" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.editLearnModuleDialog(
                                      "edit",
                                      scope.row,
                                      scope.$index
                                    )
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addSessionshowDialog(
                                        "sectionDialog",
                                        scope.$index
                                      )
                                    }
                                  }
                                },
                                [_vm._v("添加章节")]
                              )
                            ],
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteLearnModule(scope.$index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [_vm._v("完成")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("edit-learn-module", {
        ref: "editLearnModule",
        on: { confirm: _vm.confirmLearnModule }
      }),
      _vm._v(" "),
      _c("section-dialog", {
        ref: "sectionDialog",
        attrs: { selectIds: _vm.selectSectionIds },
        on: { confirm: _vm.confirmSection }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }