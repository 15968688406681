"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _BuildAddr = _interopRequireDefault(require("./BuildAddr"));

var _SearchList = _interopRequireDefault(require("./SearchList"));

var _initData = require("../initData");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    PageTable: _PageTable.default,
    BuildAddr: _BuildAddr.default,
    SearchList: _SearchList.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      gridData: _initData.initData,
      tableData: [],
      total: 0,
      listLoading: false,
      searchParam: {},
      showSearch: false,
      addressinfo: '',
      goodsId: '',
      showSearchModal: false
    };
  },
  methods: {
    created: function created() {
      this.reset();
    },
    reset: function reset() {
      this.searchParam = {
        id: '',
        name: '',
        sku: '',
        page: 1,
        page_size: 10
      };
      this.createTime = '';
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;

      _api.default.changeGoodsAdult({
        id: this.goodsId
      }).then(function (res) {
        // console.log(res.data.productlist);
        if (res.data.productlist) {
          _this.tableData = res.data.productlist;
        }

        if (res.data.addressinfo) {
          _this.addressinfo = res.data.addressinfo;
        }
      }).catch(function () {});
    },
    handleClose: function handleClose(done) {
      this.dialogVisible = false;
    },
    onSearch: function onSearch(item) {
      this.showSearchModal = true;
      this.$refs.searchFuc.loadData(item);
    },
    showDialog: function showDialog(item) {
      console.log(item);
      this.dialogVisible = true;
      this.goodsId = item;
      this.fetchData();
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    showAddrDialog: function showAddrDialog(item) {
      console.log(item);
      this.$refs.buildAddr.showDialog(item);
    },
    addrInfo: function addrInfo(address) {
      console.log(address);
      this.addressinfo = address;
    },
    getSearchData: function getSearchData(item) {
      console.log(item);
      console.log(this.tableData);
      this.tableData.push(item);
    },
    delGoods: function delGoods(props, item) {
      this.tableData.splice(props.index, 1);
    },
    sendPageData: function sendPageData() {
      var _this2 = this;

      this.dialogVisible = false; // this.tableData.forEach(item => {
      //     item.num=this.num;
      //     item.sku=this.sku;
      // });
      // console.log(this.tableData);

      var goodsObj = {
        id: this.goodsId,
        goods: this.tableData,
        address: this.addressinfo
      };

      _api.default.saveGoodsAdult(goodsObj).then(function (res) {
        console.log(res.data);

        _this2.$emit('refresh');
      }).catch(function () {});
    }
  }
};
exports.default = _default;