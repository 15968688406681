var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogAddr, width: "40%", center: "" },
      on: {
        "update:visible": function($event) {
          _vm.dialogAddr = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            "status-icon": "",
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "收货人", prop: "contacts" } },
            [
              _c("el-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.ruleForm.contacts,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "contacts", $$v)
                  },
                  expression: "ruleForm.contacts"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "手机号", prop: "phone" } },
            [
              _c("el-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.ruleForm.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "phone", $$v)
                  },
                  expression: "ruleForm.phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "所在地址", prop: "location" } },
            [
              _c("el-cascader", {
                attrs: { size: "large", options: _vm.options },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.selectedOptions,
                  callback: function($$v) {
                    _vm.selectedOptions = $$v
                  },
                  expression: "selectedOptions"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "详细地址", prop: "detail" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.detail,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "detail", $$v)
                  },
                  expression: "ruleForm.detail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogAddr = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }