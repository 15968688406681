"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));

var _Item = _interopRequireDefault(require("./Item"));

var _vodJsSdkV = _interopRequireDefault(require("vod-js-sdk-v6"));

var _cosJsSdkV = _interopRequireDefault(require("cos-js-sdk-v5"));

var _api = _interopRequireDefault(require("@/api"));

var _type = require("./type");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
function getSignature() {
  return _api.default.getSignature().then(function (res) {
    return res.data;
  });
}

var _default = {
  name: 'Index',
  components: {
    RightPanel: _RightPanel.default,
    Item: _Item.default
  },
  data: function data() {
    return {
      uploaderList: [],
      tcVod: null,
      show: false,
      formData: {},
      cosOptions: {
        product: '',
        features: 'courseware',
        terminal: 'pc',
        bucketName: ''
      }
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        switch (route.name) {
          case 'CoursewareList':
            this.cosOptions.product = _type.PRODUCT_ENUM.EDU;
            this.cosOptions.bucketName = _type.BUCKET_NAME_ENUM.EDU;
            break;

          case 'Resource':
            this.cosOptions.product = _type.PRODUCT_ENUM.TABLET;
            this.cosOptions.bucketName = _type.BUCKET_NAME_ENUM.TABLET;
            break;

          case 'AssetsList':
            this.cosOptions.product = _type.PRODUCT_ENUM.SMART_PEN;
            this.cosOptions.bucketName = _type.BUCKET_NAME_ENUM.EDU;
            break;
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.tcVod = new _vodJsSdkV.default({
      getSignature: getSignature
    });
  },
  methods: {
    init: function init(files) {
      this.show = true;
      this.$refs.rightPanel.show = true;

      for (var i = 0, length = files.length; i < length; i++) {
        this._beforeUpload(files[i]);
      }
    },
    _beforeUpload: function _beforeUpload(file) {
      var suffix = file.name.substr(file.name.lastIndexOf('.') + 1, file.name.length);
      var fileType = _type.FILE_TYPE_ENUM.APK;

      if (suffix !== _type.FILE_TYPE_ENUM.APK) {
        fileType = file.type ? file.type.split('/')[0] : suffix;
      }

      if (fileType === _type.FILE_TYPE_ENUM.VIDEO) {
        this._uploadVideo(file, fileType);
      } else {
        this._uploadOtherToCos(file, fileType);
      }
    },
    _uploadOtherToCos: function _uploadOtherToCos(file, type) {
      var _this = this;

      var uploaderInfo = {
        type: _type.FILE_TYPE_ENUM[type.toLocaleUpperCase()],
        name: file.name,
        file: file,
        status: 'wait',
        fileUrl: '',
        progress: 0
      };
      var cosOptions = this.cosOptions;

      _api.default.getTcCOSSignature(cosOptions).then(function (res) {
        var credentials = res.data;
        var cos = new _cosJsSdkV.default({
          getAuthorization: function getAuthorization(options, callback) {
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.token,
              StartTime: credentials.startTime,
              ExpiredTime: credentials.expiredTime,
              ScopeLimit: true // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用

            });
          }
        });
        cos.putObject({
          Bucket: cosOptions.bucketName,
          Region: 'ap-shanghai',
          Key: "".concat(credentials.pathKey, "/").concat(file.name),
          StorageClass: 'STANDARD',
          Body: file,
          // 上传文件对象
          onProgress: function onProgress(progressData) {
            uploaderInfo.progress = progressData.percent;
            uploaderInfo.status = 'uploading';
          }
        }, function (err, data) {
          if (data.statusCode !== 200) {
            _this.$message.error(err.message || '上传失败，请稍后重试');

            return;
          }

          uploaderInfo.fileUrl = data.Location;
          uploaderInfo.status = 'success';

          _this.$emit('handleResult', uploaderInfo);
        });
        var filterData = _this.uploaderList.length ? _this.uploaderList.filter(function (item) {
          return item.name === file.name;
        }) : [];

        if (!filterData.length) {
          _this.uploaderList.push({
            uploaderInfo: uploaderInfo
          });
        } else {
          _this.uploaderList.map(function (item) {
            if (item.name === file.name) {
              item.uploaderInfo = uploaderInfo;
            }

            return item;
          });
        }
      });
    },
    _uploadVideo: function _uploadVideo(file, type) {
      var _this2 = this;

      var uploader = this.tcVod.upload({
        mediaFile: file
      });
      var uploaderInfo = {
        type: type,
        name: uploader.videoInfo.name,
        videoInfo: uploader.videoInfo,
        uploader: uploader,
        file: file,
        status: 'wait',
        progress: 0,
        fileId: '',
        fileUrl: '',
        cancel: function cancel() {
          uploaderInfo.status = 'cancel';
          uploader.cancel();
        },
        upload: function upload(val) {
          _this2._uploadVideo(val);
        }
      };
      var filterData = this.uploaderList.length ? this.uploaderList.filter(function (item) {
        return item.uploader.sessionName === uploader.sessionName;
      }) : [];

      if (!filterData.length) {
        this.uploaderList.push({
          uploader: uploader,
          uploaderInfo: uploaderInfo
        });
      } else {
        this.uploaderList.map(function (item) {
          if (item.uploader.sessionName === uploader.sessionName) {
            item.uploader = uploader;
            item.uploaderInfo = uploaderInfo;
          }

          return item;
        });
      }

      uploader.on('media_progress', function (info) {
        uploaderInfo.progress = info.percent;
        uploaderInfo.status = 'uploading';
      });
      uploader.on('media_upload', function (info) {
        uploaderInfo.status = 'success';
      });
      uploader.done().then(function (doneResult) {
        uploaderInfo.status = 'success';
        uploaderInfo.fileId = doneResult.fileId;
        uploaderInfo.fileUrl = doneResult.video.url;
        _this2.formData.fileId = doneResult.fileId;
        _this2.formData.name = doneResult.fileId;

        _this2.$emit('handleResult', uploaderInfo);
      });
    },
    handleDelete: function handleDelete(val) {
      var _this3 = this;

      this.$confirm('确定要删除吗?', {
        type: 'warning'
      }).then(function () {
        _this3.uploaderList.forEach(function (item, index) {
          if (item.uploader.sessionName === val.uploader.sessionName) {
            _this3.uploaderList.splice(index, 1);

            item.uploader.cancel();
          }
        });
      });
    },
    handleClose: function handleClose() {
      var _this4 = this;

      var uploaderList = this.uploaderList;
      var filterData = uploaderList.length ? uploaderList.filter(function (item) {
        return item.uploaderInfo.status !== 'success';
      }) : [];

      if (filterData.length) {
        this.$confirm('列表中有未上传完成的文件，确定要放弃上传吗?', {
          type: 'warning'
        }).then(function () {
          _this4.uploaderList = [];
          _this4.show = false;
          _this4.$refs.rightPanel.show = false;

          _this4.$emit('handleReset');
        });
      } else {
        this.uploaderList = [];
        this.show = false;
        this.$refs.rightPanel.show = false;
        this.$emit('handleReset');
      }
    }
  }
};
exports.default = _default;