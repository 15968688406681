"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SaleCodeDialog',
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      listLoading: false,
      searchParam: {},
      columns: [{
        label: 'ID',
        width: '60',
        prop: 'id'
      }, {
        label: '微信昵称',
        width: '60',
        prop: 'weChatName'
      }, {
        label: '销售人',
        width: '60',
        prop: 'saleManName'
      }, {
        label: '销售UID',
        width: '60',
        prop: 'uid'
      }],
      total: 0,
      tableData: [],
      dialogVisible: false,
      formData: {},
      selectItem: ''
    };
  },
  methods: {
    showDialog: function showDialog() {
      this.dialogVisible = true;
      this.reset();
    },
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;

      _api.default.getSaleQrCodeList(this.searchParam).then(function (response) {
        _this.tableData = response.data.infos.map(function (item) {
          return _bean.default.qrCodeBean(item);
        });
        _this.total = response.data.totalNum;
        _this.listLoading = false;
      }).catch(function () {
        _this.tableData = null;
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    currentChange: function currentChange(val) {
      this.selectItem = val || '';
      this.formData.id = val && val.id || '';
    },
    onSearch: function onSearch() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      if (!this.selectItem) {
        this.$message({
          type: 'warning',
          message: '请选择要添加的销售二维码~'
        });
        return;
      }

      this.formData.team_id = this.$route.params.id;

      _api.default.addSaleQrCodeToService(this.formData).then(function (response) {
        _this2.$message({
          type: 'success',
          message: '操作成功~'
        });

        _this2.dialogVisible = false;

        _this2.$emit('refresh');
      });
    },
    reset: function reset() {
      this.searchParam = {
        page: 1,
        page_size: 10,
        cid: this.$route.params.lineId,
        is_delete: 1,
        name: '',
        saleman_name: ''
      };
      this.fetchData();
    }
  }
};
exports.default = _default;