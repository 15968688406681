"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _axios = _interopRequireDefault(require("axios"));

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _initData = require("../initData");

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Api from '@/api';
var BASE_URL = process.env.VUE_APP_BASE_API;
var PROXY_ID = '';
var _default = {
  components: {
    PageTable: _PageTable.default
  },
  data: function data() {
    return {
      gridData: _initData.initData,
      tableData: [],
      total: 0,
      listLoading: false,
      searchParam: {},
      tokenUrl: "".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/token-auth?force=0"),
      onceFuc: true
    };
  },
  methods: {
    mounted: function mounted() {
      this.reset();
    },
    reset: function reset() {
      this.createTime = '';
      this.fetchData(this.tokenUrl);
    },
    fetchData: function fetchData(tokenUrl) {
      var that = this;
      var title = this.searchParam.name;
      var id = this.searchParam.id;
      var sku = this.searchParam.sku;
      (0, _axios.default)({
        method: 'get',
        url: tokenUrl,
        headers: {
          authorization: "Bearer ".concat((0, _auth.getToken)())
        }
      }).then(function (ret) {
        console.log(ret.data);
        (0, _axios.default)({
          method: 'get',
          url: "".concat(BASE_URL).concat(PROXY_ID, "/shopService/api/v1/products/list?title=") + title + '&id=' + id + '&sku=' + sku,
          headers: {
            authorization: 'Bearer ' + ret.data.data
          }
        }).then(function (res) {
          console.log(res.data);

          if (res.data.data) {
            res.data.data.forEach(function (item) {
              item.sm_id = 0;
              item.pid = item.id;
              delete item.id;
              delete item.image;
              delete item.invented_count;
              delete item.price;
            });
            console.log(res.data.data);
            that.tableData = res.data.data;
          }
        });
      }).catch(function (err) {
        if (that.onceFuc === true) {
          that.fetchData("".concat(BASE_URL).concat(PROXY_ID, "/sale/childa/token-auth?force=1"));
          that.onceFuc = false;
        }
      }); // Api.searchData(this.searchParam)
      //     .then((res) => {
      //         console.log(res.data);
      //     })
      //     .catch(() => {});
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData(this.tokenUrl);
    },
    loadData: function loadData(item) {
      this.searchParam = item;
      console.log(item);
      this.reset();
    },
    addGoods: function addGoods(props, item) {
      console.log(props);
      console.log(item);

      if (item.sku && item.num) {
        this.$emit('getSearchData', item);
        this.tableData.splice(props.index, 1);
      } else {
        this.$message.error('sku和数量为必填');
      }
    }
  }
};
exports.default = _default;