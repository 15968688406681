"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.number.constructor");

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'EditTeacherInfoDialog',
  components: {},
  data: function data() {
    var _this = this;

    var validateCourseId = function validateCourseId(rule, value, callback) {
      if (value && value.length) {
        callback();
      } else {
        callback(new Error('请选择可点评课程'));
      }
    };

    var validateTopNum = function validateTopNum(rule, value, callback) {
      if (Number(_this.formData.isTop) === 1 && (!value || Number(value) === 0)) {
        callback(new Error('请输入分配作业上限值'));
      } else {
        callback();
      }
    };

    return {
      formLabelWidth: '150px',
      type: '',
      loading: false,
      courseArr: [],
      formData: {},
      dialogVisible: false,
      qrCodeList: [],
      rules: {
        name: [{
          required: true,
          message: '请输入点评师昵称',
          trigger: 'blur'
        }],
        top_num: [{
          validator: validateTopNum,
          trigger: 'blur'
        }],
        course_id: [{
          validator: validateCourseId,
          trigger: 'change'
        }]
      },
      currentDisabledId: []
    };
  },
  methods: {
    showDialog: function showDialog(type, item) {
      console.log(item);
      this.type = type;
      this.dialogVisible = true;
      var id = item.id,
          uname = item.uname,
          isLimit = item.isLimit,
          courseIds = item.courseIds,
          topNum = item.topNum,
          backUserId = item.backUserId,
          phone = item.phone,
          uid = item.uid,
          atWorkValue = item.atWorkValue;

      if (type === 'edit') {
        this.formData = {
          id: id,
          name: uname,
          isTop: Number(isLimit) === 2 ? '1' : '0',
          course_id: courseIds ? courseIds.substring(1, courseIds.length - 1).split(',').map(Number) : [],
          top_num: topNum,
          backUserId: backUserId,
          atWork: atWorkValue
        };
        this.currentDisabledId = this.formData.course_id;
      } else {
        this.formData = {
          uid: uid,
          name: '',
          isTop: '1',
          course_id: [],
          top_num: '',
          phone: phone,
          atWork: 1
        };
      }

      this.initData();
    },
    initData: function initData() {
      var _this2 = this;

      _api.default.getCourseList_2().then(function (res) {
        _this2.courseArr = res.data.infos;

        if (_this2.type === 'add') {
          var ids = [];

          _this2.courseArr.forEach(function (item) {
            ids.push(Number(item.course_id));
          });

          _this2.formData.course_id = ids;
        }
      });
    },
    confirm: function confirm(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          var _this3$formData = _this3.formData,
              name = _this3$formData.name,
              phone = _this3$formData.phone,
              isTop = _this3$formData.isTop,
              top_num = _this3$formData.top_num,
              course_id = _this3$formData.course_id,
              backUserId = _this3$formData.backUserId,
              atWork = _this3$formData.atWork;
          var postData = {
            userAccount: phone,
            userName: name,
            phone: phone,
            map: {
              is_limit: Number(isTop) === 1 ? 2 : 1,
              limit_num: top_num,
              work_status: 1,
              course_id: course_id.join(','),
              at_work: atWork
            }
          };
          if (_this3.type === 'edit') postData.backUserId = backUserId;
          var url = _this3.type === 'add' ? 'addTeacherInfo' : 'updateTeacherInfo';

          _api.default[url](postData).then(function () {
            _this3.$message({
              type: 'success',
              message: '操作成功'
            });

            _this3.$refs[formName].resetFields();

            _this3.loading = false;
            _this3.dialogVisible = false;

            _this3.$emit('refresh');
          }).catch(function () {
            _this3.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
exports.default = _default;