"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PageTable = _interopRequireDefault(require("@/components/PageTable"));

var _SearchBox = _interopRequireDefault(require("@/components/SearchBox"));

var _initData = require("../initData");

var _api = _interopRequireDefault(require("@/api"));

var _StudentDialog = _interopRequireDefault(require("./components/StudentDialog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'StudentList',
  components: {
    PageTable: _PageTable.default,
    SearchBox: _SearchBox.default,
    StudentDialog: _StudentDialog.default
  },
  data: function data() {
    return {
      tableData: null,
      listLoading: false,
      total: 0,
      columns: _initData.initData.studentList.columns,
      searchParam: {}
    };
  },
  activated: function activated() {
    var id = this.$route.params.id;
    this.classId = id;
    this.reset();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      var url = 'getOnlineClassStudentsList';

      _api.default[url](this.searchParam).then(function (response) {
        if (response.code === 200) {
          _this.listLoading = false;
          _this.tableData = response.data;
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageNoChange: function pageNoChange(val) {
      this.searchParam.page = val;
      this.fetchData();
    },
    reset: function reset() {
      this.searchParam = {
        page: 1,
        page_size: 20,
        uid: '',
        uname: '',
        class_id: this.classId
      };
      this.fetchData();
    },
    onSubmit: function onSubmit() {
      this.searchParam.page = 1;
      this.fetchData();
    },
    showDialog: function showDialog() {
      this.$refs.studentDialog.showDialog();
    },
    deleteItem: function deleteItem(rowsData) {
      var _this2 = this;

      this.listLoading = true;
      var postData = {
        uid: rowsData.uid,
        class_id: this.classId
      };
      var url = 'setOnlineClassDelStudent';

      _api.default[url](postData).then(function (response) {
        if (response.code === 200) {
          _this2.listLoading = false;

          _this2.$message.success('移除成功');

          _this2.reset();
        }
      }).catch(function () {
        _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;