"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _api = _interopRequireDefault(require("@/api"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      centerDialogVisible: false,
      prompt: '',
      status: '',
      goodsId: '',
      infoData: []
    };
  },
  methods: {
    showDialog: function showDialog(item) {
      var _this = this;

      console.log(item);
      this.goodsId = item;
      this.centerDialogVisible = true;

      _api.default.buildPop({
        id: item
      }).then(function (res) {
        console.log(res.data);
        _this.status = res.data.status;

        if (res.data.status === 1) {
          _this.infoData = [];
          _this.prompt = '确认发起旺店通建单？';
        } else if (res.data.status === 2) {
          _this.prompt = '';
          _this.infoData = res.data.data;
        } else {
          _this.infoData = [];
          _this.prompt = '无可发货的商品，无法发起建单';
        }
      }).catch(function () {});
    },
    buildOrder: function buildOrder() {
      var _this2 = this;

      this.centerDialogVisible = false;

      _api.default.buildOrder({
        id: this.goodsId
      }).then(function (res) {
        console.log(res.data);

        _this2.$emit('refresh');
      }).catch(function () {});
    }
  }
};
exports.default = _default;