var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-list__item" },
    [
      _c("div", { staticClass: "upload-list__item-inner" }, [
        _vm.itemInfo.fileType === "image"
          ? _c("img", { attrs: { src: _vm.itemInfo.fileUrl } })
          : _vm.itemInfo.fileType === "video" ||
            _vm.itemInfo.fileType === "audio"
          ? _c("i", { staticClass: "icon el-icon-video-camera" })
          : _c("i", { staticClass: "icon el-icon-document" }),
        _vm._v(" "),
        _c("span", { staticClass: "upload-list__item-actions" }, [
          _c("i", {
            staticClass: "icon el-icon-zoom-in",
            on: { click: _vm.handlePreview }
          }),
          _vm._v(" "),
          _c("i", {
            staticClass: "icon el-icon-delete",
            on: { click: _vm.handleRemove }
          })
        ])
      ]),
      _vm._v(" "),
      [
        _vm.type === "radio"
          ? _c(
              "el-radio",
              {
                staticClass: "upload-list__item-radio",
                attrs: { label: _vm.itemInfo.id, name: "radio" },
                model: {
                  value: _vm.itemInfo.id,
                  callback: function($$v) {
                    _vm.$set(_vm.itemInfo, "id", $$v)
                  },
                  expression: "itemInfo.id"
                }
              },
              [_vm._v(_vm._s(_vm.itemInfo.name))]
            )
          : _c("el-input", {
              staticClass: "upload-list__item-input",
              attrs: { placeholder: "请输入内容" },
              model: {
                value: _vm.itemInfo.name,
                callback: function($$v) {
                  _vm.$set(_vm.itemInfo, "name", $$v)
                },
                expression: "itemInfo.name"
              }
            })
      ],
      _vm._v(" "),
      _c("preview-dialog", { ref: "previewDialog" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }