"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.fixed");

var _objectSpread2 = _interopRequireDefault(require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.number.constructor");

var _api = _interopRequireDefault(require("@/api"));

var _bean = _interopRequireDefault(require("@/bean"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CommentWork',
  data: function data() {
    return {
      selectWordIndex: 1,
      selectStrokeIndex: 1,
      homeworkList: [],
      homeworkDetail: null,
      reviewDetailList: [],
      deleteReviewDetailList: [],
      commentValue: '',
      loading: false,
      reviewLoading: false,
      isPlay: false,
      selectAudioIndex: -1,
      selectDeleteAudioIndex: -1,
      selectHomeworkIndex: 0,
      homeworkId: 0
    };
  },
  computed: {
    strokeList: function strokeList() {
      var homeworkDetail = this.homeworkDetail,
          selectWordIndex = this.selectWordIndex;
      return homeworkDetail.scoreRecords && homeworkDetail.scoreRecords[selectWordIndex - 1].strokeRecords || [];
    },
    synBtnDisabled: function synBtnDisabled() {
      var homeworkDetail = this.homeworkDetail,
          selectWordIndex = this.selectWordIndex,
          selectStrokeIndex = this.selectStrokeIndex,
          commentValue = this.commentValue,
          reviewDetailList = this.reviewDetailList; // 点评内容为空则不能生成语音

      if (!commentValue) {
        return true;
      }

      var currentItem = homeworkDetail.scoreRecords[selectWordIndex - 1]; // 单字图、gif为空则不能生成语音

      if (!currentItem.word_pic || !currentItem.word_gif) {
        return true;
      }

      var wordArr = reviewDetailList.filter(function (item) {
        return item.wordId === homeworkDetail.scoreRecords[selectWordIndex - 1].id;
      });
      var strokeArr = [];

      if (selectStrokeIndex > 1) {
        strokeArr = wordArr.filter(function (item) {
          return item.strokeId === homeworkDetail.scoreRecords[selectWordIndex - 1].strokeRecords[selectStrokeIndex - 2].id;
        });
      } else {
        strokeArr = wordArr.filter(function (item) {
          return item.strokeId === 0;
        });
      } // 单字、笔顺之前有数据则不能生成语音


      if (wordArr.length && strokeArr.length) {
        return true;
      }

      return false;
    }
  },
  watch: {
    selectHomeworkIndex: function selectHomeworkIndex(val) {
      this.selectWordIndex = 1;
      this.selectStrokeIndex = 1;
      this.homeworkId = this.homeworkList[val].id;
      this.fetchHomeworkDetail();
    }
  },
  created: function created() {
    var _this$$route$params = this.$route.params,
        classId = _this$$route$params.classId,
        wordId = _this$$route$params.wordId;
    this.classId = Number(classId);

    if (Number(wordId)) {
      this.homeworkId = wordId;
      this.fetchHomeworkDetail();
    } else {
      this.fetchList();
    }
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;

      _api.default.getHomeworkList({
        class_id: this.classId
      }).then(function (response) {
        var infos = response.data.infos;
        _this.homeworkList = (infos || []).map(function (item) {
          return _bean.default.homeWorkBean(item);
        });
        _this.homeworkId = _this.homeworkList[0].id;

        _this.fetchHomeworkDetail();
      });
    },
    fetchHomeworkDetail: function fetchHomeworkDetail() {
      var _this2 = this;

      _api.default.getHomeworkDetail({
        id: this.homeworkId
      }).then(function (response) {
        _this2.homeworkDetail = response.data || null;

        _this2.fetchReviewDetail();

        var scoreRecords = _this2.homeworkDetail.scoreRecords;
        scoreRecords && scoreRecords.length && _this2.doWordTap(scoreRecords[0], 0);
      });
    },
    fetchReviewDetail: function fetchReviewDetail() {
      var _this3 = this;

      // is_delete: 是否删除 [0: 未删除; 1: 已删除]
      this.reviewDetailList = [];

      _api.default.getReviewDetailList({
        homework_id: this.homeworkId,
        is_delete: 0
      }).then(function (response) {
        var list = response.data.infos || [];
        var scoreRecords = _this3.homeworkDetail.scoreRecords;

        var _loop = function _loop(i) {
          var wordIndex = scoreRecords.findIndex(function (value) {
            return list[i].wordId === value.id;
          });
          var strokeIndex = -1;

          if (list[i].strokeId && wordIndex > -1) {
            strokeIndex = scoreRecords[wordIndex].strokeRecords.findIndex(function (value) {
              return list[i].strokeId === value.id;
            });
          }

          _this3.reviewDetailList.push((0, _objectSpread2.default)((0, _objectSpread2.default)({}, list[i]), {}, {
            wordIndex: wordIndex,
            strokeIndex: strokeIndex,
            offLine: false
          }));
        };

        for (var i = 0; i < list.length; i++) {
          _loop(i);
        }

        console.log(_this3.reviewDetailList);
      });

      this.getDeleteReviewDetailList();
    },
    getDeleteReviewDetailList: function getDeleteReviewDetailList() {
      var _this4 = this;

      _api.default.getReviewDetailList({
        homework_id: this.homeworkId,
        is_delete: 1
      }).then(function (response) {
        _this4.deleteReviewDetailList = response.data.infos || [];
      });
    },
    // 合并语音
    synVoice: function synVoice() {
      var _this5 = this;

      var selectWordIndex = this.selectWordIndex,
          selectStrokeIndex = this.selectStrokeIndex,
          homeworkDetail = this.homeworkDetail,
          strokeList = this.strokeList,
          commentValue = this.commentValue;

      if (!commentValue) {
        this.$message.warning('请输入点评~');
        return;
      }

      this.loading = true;

      _api.default.setTextToVoice({
        content: commentValue
      }).then(function (response) {
        _this5.$message.warning('语音合成成功，请到点评记录查看~');

        _this5.reviewDetailList.push({
          id: 0,
          wordId: homeworkDetail.scoreRecords[selectWordIndex - 1].id,
          strokeId: selectStrokeIndex > 1 ? strokeList[selectStrokeIndex - 2].id : 0,
          wordIndex: selectWordIndex - 1,
          strokeIndex: selectStrokeIndex - 2,
          gif: selectStrokeIndex > 1 ? strokeList[selectStrokeIndex - 2].stroke_gif : homeworkDetail.scoreRecords[selectWordIndex - 1].word_gif,
          content: commentValue,
          voiceUrl: response.data.url,
          voiceDuration: response.data.voiceDuration,
          offLine: true
        });

        _this5.commentValue = '';
        _this5.loading = false;
      }).catch(function () {
        _this5.loading = false;
      });
    },
    doWordTap: function doWordTap(item, index) {
      var _this6 = this;

      if (item.word_pic) {
        this.selectWordIndex = index + 1;
      }

      if (!item.word_gif && !item.fixed) {
        _api.default.fixedWordCompoundGif({
          scoreId: item.id
        }).then(function () {
          _this6.$message.success('gif生成中，请稍后查看~');

          item.fixed = true;
        });
      }
    },
    handlePlayAudio: function handlePlayAudio(item, index, isDelete) {
      if (isDelete) {
        this.selectDeleteAudioIndex = index;
        this.selectAudioIndex = -1;
      } else {
        this.selectAudioIndex = index;
        this.selectDeleteAudioIndex = -1;
      }

      if (item.voiceUrl) {
        this.$refs.audio.src = item.voiceUrl;
        this.$refs.audio.play();
      }
    },
    deleteAudio: function deleteAudio(row) {
      var _this7 = this;

      if (row.offLine) {
        var index = this.reviewDetailList.findIndex(function (value) {
          return row.wordId === value.wordId && row.strokeId === value.strokeId;
        });
        this.reviewDetailList.splice(index, 1);
      } else {
        this.$confirm('确定要删除记录吗?', {
          type: 'warning'
        }).then(function () {
          _api.default.deleteReviewDetail({
            id: row.id
          }).then(function (response) {
            _this7.$message.success('操作成功');

            var index = _this7.reviewDetailList.findIndex(function (value) {
              return row.id === value.id;
            });

            _this7.reviewDetailList.splice(index, 1);

            _this7.getDeleteReviewDetailList();
          });
        });
      }
    },
    handleAudioPlay: function handleAudioPlay() {
      this.isPlay = true;
    },
    handleAudioEnd: function handleAudioEnd() {
      this.isPlay = false;
    },
    prev: function prev() {
      var slideWrap = this.$refs.slideWrap;
      var scrollLeft = slideWrap.scrollLeft;
      var itemWidth = this.$refs.slideWrap.offsetWidth / 10;
      this.$refs.slideWrap.scrollTo(scrollLeft - itemWidth, 0);
    },
    next: function next() {
      var slideWrap = this.$refs.slideWrap;
      var scrollLeft = slideWrap.scrollLeft;
      var itemWidth = this.$refs.slideWrap.offsetWidth / 10;
      this.$refs.slideWrap.scrollTo(scrollLeft + itemWidth, 0);
    },
    handleHomework: function handleHomework(row, index) {
      var _this8 = this;

      if (row.id === this.homeworkId) {
        return;
      }

      this.$confirm('确定要切换作业吗?', {
        type: 'warning'
      }).then(function () {
        _this8.selectHomeworkIndex = index;
      });
    },
    // 提交点评
    submit: function submit() {
      var _this9 = this;

      var homeworkId = this.homeworkId,
          reviewDetailList = this.reviewDetailList;
      var offLineList = reviewDetailList.filter(function (item) {
        return item.offLine;
      });

      if (!offLineList.length) {
        this.$message.warning('未进行点评');
        return;
      }

      this.reviewLoading = true;

      _api.default.submitReviewInfo({
        homework_id: homeworkId,
        resource_data: reviewDetailList
      }).then(function () {
        _this9.reviewLoading = false;

        _this9.$message.warning('操作成功');

        if (_this9.classId) {
          _this9.selectHomeworkIndex = _this9.selectHomeworkIndex + 1;
        } else {
          _this9.fetchReviewDetail();
        }
      }).catch(function () {
        _this9.reviewLoading = false;
      });
    }
  }
};
exports.default = _default;