"use strict";

var _interopRequireDefault = require("/home/gitlab-runner/builds/zZ4St2ix/0/lpt-client/lpt-online-edu-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.number.constructor");

var _AssetDialog = _interopRequireDefault(require("../../../../components/Dialog/AssetDialog"));

var _UploadItem = _interopRequireDefault(require("./UploadItem"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    AssetDialog: _AssetDialog.default,
    UploadItem: _UploadItem.default
  },
  props: {
    current: {
      type: Number,
      default: 0
    },
    uploadInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    typeIndex: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      options: [{
        value: '',
        label: '无'
      }, {
        value: 'tape',
        label: '录音'
      }, {
        value: 'breakup',
        label: '笔画分解'
      }],
      value: ''
    };
  },
  created: function created() {},
  methods: {
    handleChangeFile: function handleChangeFile() {
      var type = this.uploadInfo.type;
      var fileType = '';

      if (type === 'radio') {
        fileType = 'image';
      } else if (type === 'video') {
        fileType = 'video';
      }

      this.$refs.assetDialog.showDialog({
        fileType: fileType,
        lineType: 3
      });
    },
    handleRemove: function handleRemove(index) {
      this.uploadInfo.fileList.splice(index, 1);
    },
    // 选择语音文件
    handleChangeAudioFile: function handleChangeAudioFile() {
      this.$refs.assetDialog.showDialog({
        fileType: 'audio',
        lineType: 3
      });
    },
    handleAudioRemove: function handleAudioRemove() {
      this.uploadInfo.audioResource = '';
    },
    getFileList: function getFileList(data) {
      var _this = this;

      if (this.uploadInfo.type === 'radio' && data[0].fileType === 'audio') {
        this.uploadInfo.audioResource = data[0];
        return;
      }

      var fileList = this.uploadInfo.fileList;

      var _loop = function _loop(i) {
        if (fileList.length) {
          var current = fileList.findIndex(function (item) {
            return item.id === data[i].id;
          });

          if (current > -1) {
            _this.uploadInfo.fileList.splice(current, 1);
          }
        }

        _this.uploadInfo.fileList.push(data[i]);
      };

      for (var i = 0; i < data.length; i++) {
        _loop(i);
      }
    },
    handleDelete: function handleDelete() {
      this.$emit('handleDelete', this.typeIndex, this.current);
    }
  }
};
exports.default = _default2;