"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isExternal = isExternal;
exports.isPoneAvailable = isPoneAvailable;

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

function isPoneAvailable(phone) {
  var myReg = /^[1][3,4,5,7,8][0-9]{9}$/;

  if (!myReg.test(phone)) {
    return false;
  } else {
    return true;
  }
}